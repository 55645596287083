<template>
<v-dialog 
	v-model="dialog"
	persistent
	max-width="400">
	<v-card class="py-3">
		<v-card-title class="py-2">
			<span class="text-button grey--text text--darken-3">Datos de Activación</span>
			<v-spacer></v-spacer>
			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn 
						small
						icon
						color="secondary"
						@click="onCopyLicencia()"
						v-bind="attrs"
						v-on="on">
						<v-icon>mdi-content-copy</v-icon>
					</v-btn>
				</template>
				<span>Copiar cadena de activación al portapapeles</span>
			</v-tooltip>
		</v-card-title>
		<v-divider></v-divider>
		<v-card-text class="mt-5">
			<input type="hidden" id="input-llave" :value="datos.cadenaActivacion">
			<v-row>
				<v-col cols="12">
					<div class="text--secondary ml-1">Clave de Producto</div>
					<strong class="grey--text text--darken-3 ml-1">{{datos.claveProducto}}</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<div class="text--secondary ml-1">Codigo de Referencia</div>
					<strong class="grey--text text--darken-3 ml-1">{{datos.codigoReferencia}}</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<div class="text--secondary ml-1">ID de Licencia</div>
					<strong class="grey--text text--darken-3 ml-1">{{datos.idLicencia}}</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<div class="text--secondary ml-1">Clave de Activación</div>
					<strong class="grey--text text--darken-3 ml-1">{{datos.claveActivacion}}</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<div class="text--secondary ml-1">Cadena de Activación</div>
					<v-btn
						x-small
						color="default"
						@click="mostrarCadenaActivacion = !mostrarCadenaActivacion">
						{{mostrarCadenaActivacion? 'Ocultar' : 'Mostrar'}}
					</v-btn><br>
					<strong 
						v-if="mostrarCadenaActivacion"
						class="grey--text text--darken-3 ml-1">
						{{datos.cadenaActivacion}}
					</strong> 
				</v-col>
			</v-row>
		</v-card-text>
		<v-divider></v-divider>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn 
				small 
				color="default"
				@click="onClose">
				Cerrar
			</v-btn>
		</v-card-actions>
	</v-card>
</v-dialog>
</template>
<script>
export default {
	props: {
		dialog: Boolean, 
		datos: Object,
	},
	data: () => ({
		mostrarCadenaActivacion: false,
  }),
	methods: {
		onClose: function() {
			this.$emit('onClose');
		},
		onCopyLicencia: function() {
			let licenciaToCopy = document.querySelector('#input-llave')
			licenciaToCopy.setAttribute('type', 'text')
			licenciaToCopy.select()
			try {
				var successful = document.execCommand('copy');
				if(!successful) {
					alert('Error al copiar los datos de licencia, debera copiarlos manualmente.');
				} else {
					this.$notifier.success('La cadena de activación se ha copiado al portapapeles.');
				}
			} catch (err) {
				alert('Oops, tiene deshabilitado el copiado de texto.');
			}
			/* unselect the range */
			licenciaToCopy.setAttribute('type', 'hidden');
			window.getSelection().removeAllRanges();
		},
	}
}
</script>