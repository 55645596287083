import httpClient from './httpClient';
import httpUtils from '../httpUtils';

const END_POINT = '/TipoTelefono';

export default {
    getAll() {
        return httpClient.get(END_POINT)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
    get(id) {
        return httpClient.get(END_POINT + '/' + id)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
}