import httpClient from './httpClient';
import httpUtils from '../httpUtils';

const END_POINT = '/ParametrosGenerales';

export default {
    get() {
        return httpClient.get(END_POINT + '/Get')
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
    getCodigoRefDistribuidorDefecto() {
        return httpClient.get(END_POINT + '/GetCodigoRefDistribuidorDefault')
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
    add(parametros) {
        return httpClient.post(END_POINT, parametros)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            });
    },
    update(parametros) {
        return httpClient.put(END_POINT, parametros)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            });
    },
    delete() {
        return httpClient.delete(END_POINT)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    }
}