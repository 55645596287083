<template>
<v-dialog v-model="dialog"
	persistent
	max-width="600">
	<validation-observer ref="observerRol">
		<v-form @submit.prevent="">
			<v-card>
				<v-card-title class="py-2">
					<span class="text-button grey--text text--darken-3">ROL</span>
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text>
					<v-row class="mb-2">
						<v-col cols="12">
							<div class="text--secondary ml-1">Seleccione*</div>
							<validation-provider v-slot="{errors}" 
								name="rol" 
								rules="required">
								<v-autocomplete v-model="rol" 
								:items="roles"
								outlined
								hide-details="auto"
								item-value="clave"
								:item-text="onRolToString"
								return-object
								:error-messages="errors"
								clearable
								@change="onChange">
								</v-autocomplete>
							</validation-provider>
						</v-col>
					</v-row>
					<span class="text-button grey--text text--darken-3">LISTADO DE FUNCIONES</span>
					<v-data-table dense
					:headers="tbHeaderFunciones"
					:items="rol.rolFunciones"
					:footer-props="tbFooterPropsFunciones"
					no-data-text="Sin registros"
					:search="tbSearchFunciones"
					class="elevation-1">
					</v-data-table>	
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn small 
					color="default"
					@click="onCancel">
						Cancelar
					</v-btn>
					<v-btn small 
					color="primary"
					@click="onSave">
						Seleccionar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</validation-observer>
</v-dialog>
</template>
<script>
import RolApi from '../services/api/rol.api';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ObjectToStringMixin from '../mixins/objectToStringMixin';

export default {
	props: ['dialog', 'roles'],
	components: {
		ValidationProvider,
		ValidationObserver
	},
	mixins: [ObjectToStringMixin],
    data: () => ({
		rol: '',
		isSaving: false,
		tbSearchFunciones: '',
		tbHeaderFunciones: [
			{ text: 'Clave', value: 'funcion.clave' },
			{ text: 'Descripcion', value: 'funcion.descripcion' }
		],
		tbFooterPropsFunciones: {
			'items-per-page-text': 'Mostrar',
		},
	}),
	methods: {

		//#region Metodos de formateo objetos

		onRolToString: function(item) {
			return this.objectToString(item.clave, item.descripcion);
		},

		//#endregion

		//#region Metodos de eventos input
		
		onChange: async function(item) {
			if(item !== null) {
				await RolApi.get(item.clave)
				.then(response => {
					if(response.success === true) {
						this.rol = response.data;
					} else {
						this.$notifier.warn('El rol no se cargo correctamente. \n' + response.message);
					}
				});
			} else {
				this.rol = {};
			}
		},

		//#endregion

		//#region Metodos de acciones de card

		onClear: function() {
			this.rol = '';
			this.$refs.observerRol.reset();
		},
		onCancel: function() {
			this.$emit('onCancel');
			this.onClear();
		},
		onValidateList: function() {
			//Comprobamos que existan funciones
			let listValid = true;
			let messageValidation = '';
			if(this.rol.rolFunciones.length <= 0) {
				listValid = false;
				messageValidation += 'El rol no tiene funciones ha cargar.';
			}
			if(!listValid) {
				this.$notifier.error(messageValidation);
			}
			return listValid;
		},
		onSave: async function() {
			const isValid = await this.$refs.observerRol.validate();
			if(isValid && this.onValidateList()) {
				this.$emit('onSave', this.rol.rolFunciones);
				this.onClear();
			}
		}

		//#endregion
	}
  }
</script>