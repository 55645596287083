<template>
<div>
	<validation-observer ref="observer">
		<v-form @submit.prevent="">
			<spinner-loading :overlay="isLoading"/>
			<v-card class="mx-4" 
			rounded="lg">
				<v-card-title class="py-4">
					<span class="font-weight-regular">Datos</span>
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12" sm="3" md="2">
							<div class="text--secondary ml-1">ID</div>
							<v-text-field v-model="partner.id" 
							readonly
							outlined  
							hide-details="auto">
							</v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<div class="text--secondary ml-1">Nombre*</div>
							<validation-provider v-slot="{errors}" 
								name="nombre" 
								rules="required">
								<v-text-field v-model="partner.nombre" 
								autofocus
								outlined  
								hide-details="auto"
								@input="partner.nombre = $utilsInput.uppercase(partner.nombre)"
								:error-messages="errors">
								</v-text-field>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" sm="3" md="3">
							<div class="text--secondary ml-1">Estatus*</div>
							<validation-provider v-slot="{errors}" 
								name="estatus" 
								rules="required">
								<v-select v-model="partner.idEstatusDatoMaestro" 
								:items="estatus"
								outlined
								hide-details="auto"
								item-value="id"
								:item-text="onEstatusToString"
								:error-messages="errors">
								</v-select>
							</validation-provider>
						</v-col>
						<v-col cols="12" sm="9" md="9">
							<div class="text--secondary ml-1">Motivo de Estatus</div>
							<v-text-field v-model="partner.motivoEstatus" 
							outlined  
							hide-details="auto"
							@input="partner.motivoEstatus = $utilsInput.uppercase(partner.motivoEstatus)">
							</v-text-field>
						</v-col>
					</v-row>
					<v-card class="mt-5 mb-2" elevation="1">
						<v-tabs v-model="tabs"
						background-color="tab">
							<v-tab>Datos Generales</v-tab>
							<v-tab>Acceso</v-tab>
							<v-tab>Dirección</v-tab>
							<v-tab>Contacto</v-tab>
							<v-tab>Observaciones</v-tab>
						</v-tabs>
					</v-card>
					<v-tabs-items v-model="tabs">
						<v-tab-item class="px-1 py-1">
							<v-row>
								<v-col cols="12" sm="6" md="6">
									<div class="text--secondary ml-1">RFC</div>
									<v-text-field v-model="partner.rfc" 
									outlined  
									hide-details="auto"
									@input="partner.rfc = $utilsInput.uppercase(partner.rfc)">
									</v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<div class="text--secondary ml-1">Proveedor</div>
									<proveedor-autocomplete ref="autocompleteProveedor" 
									:selected="proveedor"
									@onChange="onUpdateProveedor">	
									</proveedor-autocomplete>
								</v-col>
							</v-row>
						</v-tab-item>
						<v-tab-item class="px-1 py-1" eager>
							<v-row>
								<v-col cols="12" sm="6" md="6">
									<div class="text--secondary ml-1">Email*</div>
									<validation-provider v-slot="{errors}" 
									name="email" 
									rules="required|email">
										<v-text-field v-model="partner.email" 
										:error-messages="errors" 
										outlined 
										hide-details="auto"/>
									</validation-provider>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" sm="6" md="6">
									<div class="text--secondary ml-1">Contraseña*</div>
									<validation-provider v-slot="{errors}" 
									name="contraseña" 
									rules="required">
										<v-text-field v-model="partner.password" 
										:error-messages="errors" 
										type="password" 
										outlined 
										hide-details="auto"/>
									</validation-provider>
								</v-col>
							</v-row>
						</v-tab-item>
						<v-tab-item class="px-1 py-1" eager>
							<v-row>
								<v-col cols="12" sm="9" md="9">
									<div class="text--secondary ml-1">Calle</div>
									<v-text-field v-model="partner.calle" 
									outlined  
									hide-details="auto"
									@input="partner.calle = $utilsInput.uppercase(partner.calle)">
									</v-text-field>
								</v-col>
								<v-col cols="12" sm="3" md="3">
									<div class="text--secondary ml-1">Código Postal</div>
									<validation-provider v-slot="{errors}" 
									name="codigo postal" 
									rules="max:6">
										<v-text-field v-model="partner.codigoPostal" 
										outlined  
										hide-details="auto"
										@input="partner.codigoPostal = $utilsInput.uppercase(partner.codigoPostal)"
										:error-messages="errors">
										</v-text-field>
									</validation-provider>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" sm="3" md="3">
									<div class="text--secondary ml-1">No. Ext.</div>
									<v-text-field v-model="partner.numeroExterior" 
									outlined  
									hide-details="auto"
									@input="partner.numeroExterior = $utilsInput.uppercase(partner.numeroExterior)">
									</v-text-field>
								</v-col>
								<v-col cols="12" sm="3" md="3">
									<div class="text--secondary ml-1">No. Int.</div>
									<v-text-field v-model="partner.numeroInterior" 
									outlined  
									hide-details="auto"
									@input="partner.numeroInterior = $utilsInput.uppercase(partner.numeroInterior)">
									</v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" sm="3" md="3">
									<div class="text--secondary ml-1">País*</div>
									<validation-provider v-slot="{errors}" 
										name="pais" 
										rules="required">
										<v-autocomplete v-model="partner.idPais" 
										:items="paises"
										outlined
										hide-details="auto"
										item-value="nId"
										:item-text="onPaisToString"
										:error-messages="errors"
										@change="onChangePais">
										</v-autocomplete>
									</validation-provider>
								</v-col>
								<v-col cols="12" sm="3" md="3">
									<div class="text--secondary ml-1">Estado*</div>
									<validation-provider v-slot="{errors}" 
										name="estado" 
										rules="required">
										<v-autocomplete v-model="partner.idEstado" 
										:items="estados"
										outlined
										hide-details="auto"
										item-value="NClave"
										:item-text="onEstadoToString"
										:error-messages="errors"
										@change="onChangeEstado">
										</v-autocomplete>
									</validation-provider>
								</v-col>
								<v-col cols="12" sm="3" md="3">
									<div class="text--secondary ml-1">Ciudad*</div>
									<validation-provider v-slot="{errors}" 
										name="ciudad" 
										rules="required">
										<v-autocomplete v-model="partner.idCiudad" 
										:items="ciudades"
										outlined
										hide-details="auto"
										item-value="NId"
										:item-text="onCiudadToString"
										:error-messages="errors"
										@change="onChangeCiudad">
										</v-autocomplete>
									</validation-provider>
								</v-col>
								<v-col cols="12" sm="3" md="3">
									<div class="text--secondary ml-1">Colonia</div>
									<v-autocomplete v-model="partner.idColonia" 
									:items="colonias"
									outlined
									hide-details="auto"
									item-value="Id"
									:item-text="onColoniaToString"
									@change="onChangeColonia">
									</v-autocomplete>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<div class="text--secondary ml-1">Referencia Domiciliar</div>
									<v-textarea v-model="partner.referenciaDomicilio"
									outlined
									hide-details="auto"
									rows="2"
									@input="partner.referenciaDomicilio = $utilsInput.uppercase(partner.referenciaDomicilio)">
									</v-textarea>
								</v-col>
							</v-row>
						</v-tab-item>
						<v-tab-item class="px-1 py-1">
							<v-row>
								<v-col cols="12" sm="12" md="8" lg="6">
									<v-row>
										<v-col class="pb-0">
											<span class="text-button grey--text text--darken-3">TELEFONOS</span>
										</v-col>
										<v-spacer></v-spacer>
										<v-col class="text-right pb-0">
											<v-btn color="secondary" 
											small
											@click="onOpenDialogTelefono">
												Agregar
											</v-btn>
										</v-col>
									</v-row>
									<v-card elevation="1" rounded="lg">
										<v-list class="my-5 py-0">
											<template v-for="(item, index) in partner.partnerTelefonos">
												<v-list-item :key="item.id">
													<v-list-item-content>
													<v-list-item-title v-html="item.telefono"></v-list-item-title>
													<v-list-item-subtitle v-html="item.tipoTelefono"></v-list-item-subtitle>
												</v-list-item-content>

												<v-list-item-action>
													<v-btn icon
													color="gray"
													@click="onOpenConfirmQuitTelefono(item)">
														<v-icon color="gray">mdi-close</v-icon>
													</v-btn>
												</v-list-item-action>
												</v-list-item>
												<v-divider :key="index"></v-divider>
											</template>
										</v-list>
									</v-card>
								</v-col>
								<v-col cols="12" sm="12" md="8" lg="6">
									<v-row>
										<v-col class="pb-0">
											<span class="text-button grey--text text--darken-3 text-truncate">CUENTAS DE CORREO</span>
										</v-col>
										<v-spacer></v-spacer>
										<v-col class="text-right pb-0">
											<v-btn color="secondary" 
											small
											@click="onOpenDialogCorreo">
												Agregar
											</v-btn>
										</v-col>
									</v-row>
									<v-card elevation="1" rounded="lg">
										<v-list class="my-5 py-0"
										dense>
											<template v-for="(item, index) in partner.partnerCorreos">
												<v-list-item :key="item.id">
													<v-list-item-content>
													<v-list-item-title v-html="item.correoElectronico"></v-list-item-title>
												</v-list-item-content>

												<v-list-item-action>
													<v-btn icon
													color="gray"
													@click="onOpenConfirmQuitCorreo(item)">
														<v-icon color="gray">mdi-close</v-icon>
													</v-btn>
												</v-list-item-action>
												</v-list-item>
												<v-divider :key="index"></v-divider>
											</template>
										</v-list>
									</v-card>
								</v-col>
							</v-row>
						</v-tab-item>
						<v-tab-item class="px-1 py-1">
							<v-row>
								<v-col cols="12">
									<v-textarea v-model="partner.observaciones"
									outlined
									hide-details="auto"
									rows="5"
									@input="partner.observaciones = $utilsInput.uppercase(partner.observaciones)">
									</v-textarea>
								</v-col>
							</v-row>
						</v-tab-item>
					</v-tabs-items>
				</v-card-text>
				<v-divider class="mx-4 mt-5"></v-divider>
				<v-card-actions>
					<v-row>
						<v-col cols="12" class="text-right">
							<v-btn @click="onCancel"
							color="default"
							:loading="isSaving">
								Cancelar
							</v-btn>
							<v-btn @click="onSubmit"
							color="primary" 
							:disabled="isLoading"
							:loading="isSaving"
							class="ml-2">
								Guardar
							</v-btn>
						</v-col>
					</v-row>
				</v-card-actions>
			</v-card>
			<v-row>
				<v-col cols="12" class="ml-5">
					<app-users-audit v-if="isEdit" 
					:creador="partner.createdBy"
					:fechaCreacion="partner.createdAt"
					:modificador="partner.updatedBy"
					:fechaModificacion="partner.updatedAt">
					</app-users-audit>
				</v-col>
			</v-row>
		</v-form>
	</validation-observer>
	<!--Dialog de telefono-->
	<telefono-dialog :dialog="dialogTelefono" 
	:tiposTelefono="tiposTelefono"
	@onCancel="onCancelDialogTelefono"
	@onSave="onSaveDialogTelefono">
	</telefono-dialog>
	<!--Dialog de correo-->
	<correo-dialog :dialog="dialogCorreo"
	@onCancel="onCancelDialogCorreo"
	@onSave="onSaveDialogCorreo">
	</correo-dialog>
	<!--Dialog de confirmacion eliminacion-->
	<app-dialog-confirm :dialog-confirm="dialogConfirmDeleteChild"
	title="ELIMINAR"
	@onCancel="onCancelConfirmQuitChild"
	@onOk="onOkConfirmQuitChild">
	¿Seguro que desea quitar de la lista el registro?
	</app-dialog-confirm>
</div>
</template>
<script>
import SpinnerLoading from '../components/SpinnerLoading';
import AppDialogConfirm from './AppDialogConfirm';
import AppUsersAudit from './AppUsersAudit';
import TelefonoDialog from './TelefonoDialog';
import CorreoDialog from './CorreoDialog';
import ProveedorAutocomplete from './ProveedorAutocomplete';

import PartnerApi from '../services/api/partner.api';
import EstatusApi from '../services/api/estatusDatoMaestro.api';
import TipoTelefonoErpApi from '../services/api-erp/tipoTelefono.api';
import PaisErpApi from '../services/api-erp/pais.api';
import EstadoErpApi from '../services/api-erp/estado.api';
import CiudadErpApi from '../services/api-erp/ciudad.api';
import ColoniaErpApi from '../services/api-erp/colonia.api';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { ViewMode, DatoMaestro } from '../utils/constants';
import ObjectToStringMixin from '../mixins/objectToStringMixin'
import ProveedorApi from '../services/api-erp/proveedor.api';

export default {
	props: {
		mode: Number
	},
	mixins: [ObjectToStringMixin],
	components: {
		SpinnerLoading,
		ValidationProvider,
		ValidationObserver,
		AppUsersAudit,
		AppDialogConfirm,
		TelefonoDialog,
		CorreoDialog,
		ProveedorAutocomplete,
	},
	created: function() {
		this.onInit();
	},
    data: () => ({
		isLoading: true,
		partner: {},
		proveedor: {},
		estatus: [],
		tiposTelefono: [],
		paises: [],
		estados: [],
		ciudades: [],
		colonias: [],

		tabs: null,
		isSaving: false,

		dialogTelefono: false,
		dialogConfirmDeleteChild: false,
		telefonoCurrent: {},
		dialogCorreo: false,
		correoCurrent: {},
	}),
	computed: {
		isEdit: function() {
			return this.mode === ViewMode.EDIT;
		},
	},
	methods: {
		onInit: async function() {
			this.isLoading = true;
			await EstatusApi.getAll(DatoMaestro.PARTNER)
			.then(response => {
				if(response.success === true) {
					this.estatus = response.data;
				} else {
					this.$notifier.warn('Los estatus no se cargaron correctamente. \n' + response.message);
				}
			});
			await TipoTelefonoErpApi.getAll()
			.then(response => {
				if(response.success === true) {
					this.tiposTelefono = response.data;
				} else {
					this.$notifier.warn('Los tipos de telefono no se cargaron correctamente. \n' + response.message);
				}
			});
			await PaisErpApi.getAll()
			.then(response => {
				if(response.success === true) {
					this.paises = response.data;
				} else {
					this.$notifier.warn('Los paises no se cargaron correctamente. \n' + response.message);
				}
			});
			if(this.isEdit) {
				await PartnerApi.get(this.$route.params.id)
				.then(response => {
					this.isLoading = false;
					if(response.success === true) {
						this.partner = response.data;

						//Actualizamos valor onDemand
						if(this.partner.idProveedor)
							this.onInitProveedor(this.partner.idProveedor);
						if(this.partner.idPais)
							this.onChangePais(this.partner.idPais, true);
						if(this.partner.idEstado)
							this.onChangeEstado(this.partner.idEstado, true);
						if(this.partner.idCiudad)
							this.onChangeCiudad(this.partner.idCiudad, true);
						if(this.partner.idColonia)
							this.onChangeColonia(this.partner.idColonia, true);
						
					} else {
						this.$router.push('/partner');
						this.$notifier.error(response.message);
					}
				});
			} else {
				this.isLoading = false;
				this.partner = {
					idPais: '',
					nombre: '',
					calle: '',
					numeroExterior: '',
					numeroInterior: '',
					codigoPostal: '',
					idEstado: '',
					idCiudad: '',
					idColonia: '',
					referenciaDomicilio: '',
					rfc: '',
					curp: '',
					idEstatusDatoMaestro: this.estatus.length > 0 ? this.estatus[0].id : 0,
					estatusDatoMaestro: null,
					motivoEstatus: '',
					idProveedor: '',
					observaciones: '',
					partnerTelefonos: [],
					partnerCorreos: [],
					email: '',
					password: ''
				};
				this.proveedor = {};
			}
		},

		//#region Metodos de formateo select

		onEstatusToString: function(item) {
			return this.objectToString('', item.descripcion);
		},
		onPaisToString: function(item) {
			return this.objectToString('', item.cNombre);
		},
		onEstadoToString: function(item) {
			return this.objectToString('', item.CNombre);
		},
		onCiudadToString: function(item) {
			return this.objectToString('', item.CNombre);
		},
		onColoniaToString: function(item) {
			return this.objectToString('', item.Nombre);
		},
		//#endregion

		//#region Metodos para actualizar propiedades

		onUpdateProveedor: function(proveedorSelected) {
			if(proveedorSelected !== null) {
				this.proveedor = proveedorSelected;
				this.partner.idProveedor = this.proveedor.NClave;
				this.$refs.autocompleteProveedor.onInitSelection(this.proveedor);
			} else {
				this.proveedor = {};
				this.partner.idProveedor = '';
			}
		},
		onInitProveedor: function(idProveedor) {
			ProveedorApi.get(idProveedor)
			.then(response => {
				if(response.success === true) {
					this.proveedor = response.data;
					this.$refs.autocompleteProveedor.onInitSelection(this.proveedor);
				} else {
					this.$notifier.warn('Los datos de proveedor no se cargaron correctamente. \n' + response.message);
				}
			});
		},

		//#endregion

		//#region Metodos para actualizar dom por cambio en controles

		onChangePais: function(idPaisSelected, callFromInit = false) {
			EstadoErpApi.getAll(idPaisSelected)
			.then(response => {
				if(response.success === true) {
					this.estados = response.data;
					if(!callFromInit) {
						this.ciudades = [];
						this.colonias = [];
					}
				} else {
					this.$notifier.warn('Los estados no se cargaron correctamente. \n' + response.message);
				}
			});
		},
		onChangeEstado: function(idEstadoSelected, callFromInit = false) {
			CiudadErpApi.getAll(idEstadoSelected)
			.then(response => {
				if(response.success === true) {
					this.ciudades = response.data;
					if(!callFromInit) {
						this.colonias = [];
					}
				} else {
					this.$notifier.warn('Las ciudades no se cargaron correctamente. \n' + response.message);
				}
			});
		},
		onChangeCiudad: function(idCiudadSelected, callFromInit = false) {
			ColoniaErpApi.getAll(idCiudadSelected, this.partner.idEstado)
			.then(response => {
				if(response.success === true) {
					this.colonias = response.data;
				} else {
					this.$notifier.warn('Las colonias no se cargaron correctamente. \n' + response.message);
				}
			});
		},
		onChangeColonia: function(idColoniaSelected, callFromInit = false) {
			ColoniaErpApi.get(idColoniaSelected, this.partner.idCiudad, this.partner.idEstado)
			.then(response => {
				if(response.success === true) {
					if(!callFromInit) {
						this.partner.codigoPostal = response.data.CCP;
					}
				} else {
					this.$notifier.warn('Las colonias no se cargaron correctamente. \n' + response.message);
				}
			});
		},

		//#endregion

		//#region Metodos para dialog de telefono
		
		onOpenDialogTelefono: function() {
			//Abrimos dialog
			this.dialogTelefono = true;
		},
		onCancelDialogTelefono: function() {
			//Cerramos dialog
			this.dialogTelefono = false;
		},
		onSaveDialogTelefono: function(telefonoSaved) {
			//Cerramos dialog
			this.dialogTelefono = false;
			//Agregamos telefono
			if(this.partner.partnerTelefonos.findIndex(x => x.telefono === telefonoSaved.telefono) === -1) {
				let partnerTelefono = {
					idPartner: this.isEdit ? this.partner.id : 0,
					tipoTelefono: telefonoSaved.tipoTelefono,
					telefono: telefonoSaved.telefono,
					observaciones: telefonoSaved.observaciones
				};
				this.partner.partnerTelefonos.push(partnerTelefono);
			} else {
				this.$notifier.error('El telefono ya se encuentra agregado.');
			}
		},

		//#endregion

		//#region Metodo para dialog de correo
		
		onOpenDialogCorreo: function() {
			//Abrimos dialog
			this.dialogCorreo = true;
		},
		onCancelDialogCorreo: function() {
			//Cerramos dialog
			this.dialogCorreo = false;
		},
		onSaveDialogCorreo: function(correoSaved) {
			//Cerramos dialog
			this.dialogCorreo = false;
			//Agregamos correo
			if(this.partner.partnerCorreos.findIndex(x => x.correoElectronico === correoSaved.correoElectronico) === -1) {
				let partnerCorreo = {
					idPartner: this.isEdit ? this.partner.id : 0,
					correoElectronico: correoSaved.correoElectronico,
					observaciones: correoSaved.observaciones,
				};
				this.partner.partnerCorreos.push(partnerCorreo);
			} else {
				this.$notifier.error('El correo ya se encuentra agregado.');
			}
		},

		//#endregion

		//#region Metodo para eliminacion de correo y telefono
		
		onOpenConfirmQuitTelefono: function(telefono) {
			//Salvamos telefono current(edit/delete)
			this.telefonoCurrent = telefono;
			//Abrimos dialog
			this.dialogConfirmDeleteChild = true;
		},
		onOpenConfirmQuitCorreo: function(correo) {
			//Salvamos corre current(edit/delete)
			this.correoCurrent = correo;
			//Abrimos dialog
			this.dialogConfirmDeleteChild = true;
		},
		onCancelConfirmQuitChild: function() {
			//Limpiamos telefono/correo current(edit/delete)
			this.telefonoCurrent = {};
			this.correoCurrent = {};
			//Cerramos dialog
			this.dialogConfirmDeleteChild = false;
		},
		onOkConfirmQuitChild: function(callBack) {
			callBack();
			//Eliminacion de telefono
			if(Object.keys(this.telefonoCurrent).length !== 0) {
				//Eliminamos de lista
				let indexDeleted = this.partner.partnerTelefonos.findIndex(x => x.telefono === this.telefonoCurrent.telefono);
				this.partner.partnerTelefonos.splice(indexDeleted, 1);
			}
			//Eliminacion de correo
			else if(Object.keys(this.correoCurrent).length !== 0) {
				//Eliminamos de lista
				let indexDeleted = this.partner.partnerCorreos.findIndex(x => x.correoElectronico === this.correoCurrent.correoElectronico);
				this.partner.partnerCorreos.splice(indexDeleted, 1);
			}
			
			//Limpiamos telefono/correo current(edit/delete)
			this.telefonoCurrent = {};
			this.correoCurrent = {};
			//Cerramos dialog
			this.dialogConfirmDeleteChild = false;
		},

		//#endregion

		//#region Metodo de acciones de card
		
		onCancel: function() {
			this.$router.push('/partner');
		},
		onSubmit: async function() {
			//Prevent multiple guardado
			if(!this.isSaving) {
				this.isSaving = true;
				const isValid = await this.$refs.observer.validate();
				if(isValid) {
					//Copiamos deep objeto para ajustarlo antes de enviarlo
					let partnerSaved = JSON.parse(JSON.stringify(this.partner));
					//Nuleamos objetos
					partnerSaved.estatusDatoMaestro = null;
					if(!this.isEdit) {
						this.$emit('onSubmit', partnerSaved, () => {
							this.isSaving = false;
						});
					} else {
						this.$emit('onSubmit', partnerSaved, () => {
							this.isSaving = false;
						});
					}
				} else {
					this.isSaving = false;
					this.$notifier.error('Llene todos los campos marcados como obligatorios, compruebe su información y vuelva a intentar.');
				}
			}
		}

		//#endregion
	}
}
</script>