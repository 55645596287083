import httpClient from './httpClient';
import httpUtils from '../httpUtils';

const END_POINT = '/SaldoDistribuidor';

export default {
    getAllByDistribuidor(idDistribuidor) {
        return httpClient.get(END_POINT + '/GetAllByDistribuidor/' + idDistribuidor)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
    get(idDistribuidor, claveMoneda) {
        return httpClient.get(END_POINT + '/GetByClave/' + idDistribuidor + '/' + claveMoneda)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
}