<template>
	<v-app :style="{background: $vuetify.theme.themes.light.background}">
		<!--Drawer-->
		<v-navigation-drawer v-model="drawer" app>
			<v-toolbar color="white" class="mb-1" height="70" elevation="0">
				<v-img src="images/brand/logo.png" 
				contain
				position="center" 
				height="17px" />
			</v-toolbar>
			<app-main-partner v-if="isPartner"></app-main-partner>
			<app-main-distribuidor v-else-if="isDistribuidor"></app-main-distribuidor>
			<app-main v-else></app-main>
		</v-navigation-drawer>
		<!--Navbar-->
		<v-app-bar app dark color="primary" dense elevation="0">
			<v-app-bar-nav-icon @click="drawer = !drawer">
			</v-app-bar-nav-icon>
			<v-toolbar-title>
				<span class="white--text text--lighten-1 text-subtitle-1">Portal de activación</span>
			</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-chip
				color="white"
				text-color="primary"
				small
			>
				<strong>v {{version}}</strong>
			</v-chip>
			<v-menu bottom left>
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon
					v-bind="attrs"
					v-on="on">
						<v-icon>mdi-account-circle</v-icon>
					</v-btn>
				</template>
				<v-card max-width="300">
					<v-list dense>
						<v-list-item>
							<v-list-item-avatar color="primary">
								<v-icon dark>mdi-account-circle</v-icon>
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title>{{nombreUsuario}}</v-list-item-title>
								<v-list-item-subtitle>{{emailUsuario}}</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
						<v-divider class="my-2"></v-divider>
						<v-list-item :to="{path: '/datos-usuario'}" color="secondary">
							<v-list-item-icon>
								<v-icon>mdi-account-edit</v-icon>
							</v-list-item-icon>
							<v-list-item-title>Perfil</v-list-item-title>
						</v-list-item>
						<v-list-item @click="onLogout" color="secondary">
							<v-list-item-icon>
								<v-icon>mdi-logout</v-icon>
							</v-list-item-icon>
							<v-list-item-title>Cerrar sesión</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-card>
			</v-menu>
		</v-app-bar>
		<v-main>
			<v-fade-transition origin="center" mode="out-in">
				<router-view/>
			</v-fade-transition>
			<app-notification></app-notification>
		</v-main>
	</v-app>
</template>
<script>
import AppMainPartner from './components/AppMainPartner';
import AppMainDistribuidor from './components/AppMainDistribuidor';
import AppMain from './components/AppMain';
import AuthTypes from '../store/types/AuthTypes';
import { TipoUsuario } from '../utils/constants';

export default {
	data: () => ({ 
		drawer: null,
		}),
	components: {
		AppMainPartner,
		AppMainDistribuidor,
		AppMain
	},
	computed: {
		version() {
			return this.$store.getters[AuthTypes.nameModule + '/' + AuthTypes.getters.VERSION];
		},
		emailUsuario() {
			return this.$store.getters[AuthTypes.nameModule + '/' + AuthTypes.getters.EMAIL_USUARIO];
		},
		nombreUsuario() {
			return this.$store.getters[AuthTypes.nameModule + '/' + AuthTypes.getters.NOMBRE_USUARIO];
		},
		isPartner() {
			let tipoUsuario = this.$store.getters[AuthTypes.nameModule + '/' + AuthTypes.getters.TIPO_USUARIO];
			return tipoUsuario === TipoUsuario.PARTNER;
		},
		isDistribuidor() {
			let tipoUsuario = this.$store.getters[AuthTypes.nameModule + '/' + AuthTypes.getters.TIPO_USUARIO];
			return tipoUsuario === TipoUsuario.DISTRIBUIDOR;
		}
	},
	methods: {
		onLogout: function() {
			this.$store.commit(AuthTypes.nameModule + '/' + AuthTypes.mutations.LOGOUT);
			this.$router.push('/login').catch(() => {})
		}
	}
  }
</script>