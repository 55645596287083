import axios from 'axios';
import httpClient from './httpClient';
import httpUtils from '../httpUtils';

const END_POINT = '/Licencia';

export default {
    getPaged(page, pageSize, idDistribuidor, claveProducto, fechaActivacion, parameterSearch, cancelSource = axios.CancelToken.source()) {
        return httpClient.get(END_POINT + '/GetPaged?page=' + page + '&pageSize=' + pageSize + '&idDistribuidor=' + idDistribuidor + '&claveProducto=' + claveProducto + '&fechaActivacion=' + fechaActivacion + '&parameter=' + parameterSearch, {
            cancelToken: cancelSource.token
        }).then(response => {
            return httpUtils.responsePagination(response.headers, response.data);
        }).catch(error => {
            if (axios.isCancel(error)) {
                return httpUtils.responseCancel(error);
            } else {
                return httpUtils.responseError(error.response);
            }
        })
    },
    getPagedByDistribuidor(idDistribuidor, page, pageSize, parameterSearch, cancelSource = axios.CancelToken.source()) {
        return httpClient.get(END_POINT + '/GetPagedByDistribuidor?idDistribuidor=' + idDistribuidor + '&page=' + page + '&pageSize=' + pageSize + '&parameter=' + parameterSearch, {
            cancelToken: cancelSource.token
        }).then(response => {
            return httpUtils.responsePagination(response.headers, response.data);
        }).catch(error => {
            if (axios.isCancel(error)) {
                return httpUtils.responseCancel(error);
            } else {
                return httpUtils.responseError(error.response);
            }
        })
    },
    getPagedToPago(page, pageSize, idCliente, idLicencia, claveProducto, idDispositivo, parameterSearch, cancelSource = axios.CancelToken.source()) {
        return httpClient.get(END_POINT + '/GetPagedToPago?page=' + page + '&pageSize=' + pageSize + '&idCliente=' + idCliente + '&claveProducto=' + claveProducto + '&idLicencia=' + idLicencia + '&idDispositivo=' + idDispositivo + '&parameter=' + parameterSearch, {
            cancelToken: cancelSource.token
        }).then(response => {
            return httpUtils.responsePagination(response.headers, response.data);
        }).catch(error => {
            if (axios.isCancel(error)) {
                return httpUtils.responseCancel(error);
            } else {
                return httpUtils.responseError(error.response);
            }
        })
    },
    get(id, claveProducto) {
        return httpClient.get(END_POINT + '/GetByClave/' + id + '/' + claveProducto)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
    getToPago(id, claveProducto) {
        return httpClient.get(END_POINT + '/GetToPago/' + id + '/' + claveProducto)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
    activationDummy(datos) {
        return httpClient.post(END_POINT + '/ActivationDummy', datos)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            });
    },
    activationFree(datos) {
        return httpClient.post(END_POINT + '/ActivationFree', datos)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            });
    },
    activation(datos) {
        return httpClient.post(END_POINT + '/Activation', datos)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            });
    },
    activationAndPayStripe(datos) {
        return httpClient.post(END_POINT + '/ActivationAndPayStripe', datos)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            });
    },
    renovation(datos) {
        return httpClient.post(END_POINT + '/Renovation', datos)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            });
    },
    renovationAndPayStripe(datos) {
        return httpClient.post(END_POINT + '/RenovationAndPayStripe', datos)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            });
    },
    pay(datos) {
        return httpClient.post(END_POINT + '/PayCredit', datos)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            });
    },
    payStripe(datos) {
        return httpClient.post(END_POINT + '/PayCreditStripe', datos)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            });
    }
}