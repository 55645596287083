<template>
<div>
	<validation-observer ref="observer">
		<v-form @submit.prevent="">
			<spinner-loading :overlay="isLoading"/>
			<v-card 
				class="mx-4" 
				rounded="lg">
				<v-card-title class="py-4">
					<span class="font-weight-regular">Datos</span>
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12" sm="6" md="6">
							<v-checkbox
								v-model="datosActivacion.solicitaDistribuidor"
								label="Distribuidor solicita la licencia"
								hide-details="auto"
								class="my-0"
								color="secondary">
							</v-checkbox>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<div class="text--secondary ml-1">Distribuidor*</div>
							<distribuidor-autocomplete 
								ref="autocompleteDistribuidor" 
								:selected="distribuidor"
								:validError="validErrorDistribuidor"
								:byCodigoRef="true"
								@onChange="onUpdateDistribuidor">	
							</distribuidor-autocomplete>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" sm="8" md="8">
							<div class="text--secondary ml-1">Producto*</div>
							<validation-provider 
								v-slot="{errors}" 
								name="producto" 
								rules="required">
								<v-select 
									v-model="datosActivacion.claveProducto" 
									:items="productos"
									outlined
									hide-details="auto"
									item-value="clave"
									:item-text="onProductoToString"
									:error-messages="errors"
									@change="onChangeProducto">
								</v-select>
							</validation-provider>
						</v-col>
						<v-col cols="12" sm="4" md="4">
							<div class="text--secondary ml-1">Precio/Periodo*</div>
							<validation-provider
								v-slot="{errors}" 
								name="precio periodo" 
								rules="required">
								<v-select 
									v-model="precio" 
									:items="precios"
									outlined
									hide-details="auto"
									item-value="id"
									:item-text="onPrecioToString"
									return-object
									:error-messages="errors">
								</v-select>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" sm="4" md="4">
							<div class="text--secondary ml-1">ID Dispositivo*</div>
							<validation-provider 
								v-slot="{errors}" 
								name="id dispositivo" 
								rules="required">
								<v-text-field 
									v-model="datosActivacion.idDispositivo" 
									outlined  
									hide-details="auto"
									:error-messages="errors">
								</v-text-field>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<div class="text--secondary ml-1">Nombre de Empresa*</div>
							<validation-provider 
								v-slot="{errors}" 
								name="nombre empresa" 
								rules="required">
								<v-text-field 
									v-model="datosActivacion.nombreEmpresa" 
									outlined  
									hide-details="auto"
									@input="datosActivacion.nombreEmpresa = $utilsInput.uppercase(datosActivacion.nombreEmpresa)"
									:error-messages="errors">
								</v-text-field>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" sm="4" md="4">
							<div class="text--secondary ml-1">País*</div>
							<validation-provider 
								v-slot="{errors}" 
								name="pais" 
								rules="required">
								<v-autocomplete 
									v-model="datosActivacion.idPais" 
									:items="paises"
									outlined
									hide-details="auto"
									item-value="nId"
									:item-text="onPaisToString"
									:error-messages="errors"
									@change="onChangePais">
								</v-autocomplete>
							</validation-provider>
						</v-col>
						<v-col cols="12" sm="4" md="4">
							<div class="text--secondary ml-1">Estado*</div>
							<validation-provider
								v-slot="{errors}" 
								name="estado" 
								rules="required">
								<v-autocomplete 
									v-model="datosActivacion.idEstado" 
									:items="estados"
									outlined
									hide-details="auto"
									item-value="NClave"
									:item-text="onEstadoToString"
									:error-messages="errors"
									@change="onChangeEstado">
								</v-autocomplete>
							</validation-provider>
						</v-col>
						<v-col cols="12" sm="4" md="4">
							<div class="text--secondary ml-1">Ciudad*</div>
							<validation-provider
								v-slot="{errors}" 
								name="ciudad" 
								rules="required">
								<v-autocomplete 
									v-model="datosActivacion.idCiudad" 
									:items="ciudades"
									outlined
									hide-details="auto"
									item-value="NId"
									:item-text="onCiudadToString"
									:error-messages="errors">
								</v-autocomplete>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" sm="4" md="4">
							<div class="text--secondary ml-1">Teléfono*</div>
							<validation-provider 
								v-slot="{errors}" 
								name="telefono" 
								rules="required">
								<v-text-field 
									v-model="datosActivacion.telefono" 
									outlined  
									hide-details="auto"
									:error-messages="errors"
									@keypress="$utilsInput.telephone($event)">
								</v-text-field>
							</validation-provider>
						</v-col>
						<v-col cols="12" sm="8" md="8">
							<div class="text--secondary ml-1">Correo electronico*</div>
							<validation-provider 
								v-slot="{errors}" 
								name="correo electronico" 
								rules="required|email">
								<v-text-field
									v-model="datosActivacion.correo" 
									outlined  
									hide-details="auto"
									:error-messages="errors">
								</v-text-field>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" sm="6" md="6">
							<validation-provider 
								vid="esACredito" 
								v-slot="{errors}"
								rules="">
								<v-checkbox
									name="esACredito"
									v-model="datosActivacion.esACredito"
									label="Es a Credito"
									hide-details="auto"
									class="my-0"
									color="secondary"
									:error-messages="errors">
								</v-checkbox>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row v-if="datosActivacion.esACredito">
						<v-col cols="12" sm="4" md="4">
							<div class="text--secondary ml-1">Numero de Periodos de Credito</div>
							<validation-provider 
								v-slot="{errors}" 
								name="periodos credito" 
								rules="required_if:esACredito,true">
								<v-select 
									v-model="datosActivacion.numeroPeriodosCredito" 
									:items="periodosCredito"
									outlined
									hide-details="auto"
									item-value="id"
									:item-text="onPeriodoCreditoToString"
									:disabled="!datosActivacion.esACredito"
									:error-messages="errors">
								</v-select>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row v-if="datosActivacion.esACredito">
						<v-col cols="12" sm="4" md="4">
							<div class="text--secondary ml-1">No. Cliente*</div>
							<validation-provider 
								v-slot="{errors}" 
								name="numero de cliente" 
								rules="required">
								<v-text-field 
									v-model="numeroCliente" 
									outlined  
									hide-details="auto"
									:error-messages="errors + validErrorCliente">
									<template v-slot:append-outer>
										<v-tooltip right>
											<template v-slot:activator="{ on, attrs }">
												<v-btn 
													@click="onSearchCliente"
													color="secondary"
													:loading="searchingCliente"
													v-bind="attrs"
													v-on="on">
													<v-icon>mdi-account-check</v-icon>
												</v-btn>
											</template>
											<span>Comprobar cliente</span>
										</v-tooltip>
									</template>
								</v-text-field>
							</validation-provider>
						</v-col>
						<v-col cols="12" sm="8" md="8">
							<div class="text--secondary ml-1">Nombre de Cliente</div>
							<strong class="text-subtitle-1 grey--text text--darken-3 ml-1">{{nombreCliente}}</strong>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<div class="text--secondary ml-1">Observaciones</div>
							<v-textarea 
								v-model="datosActivacion.observaciones"
								outlined
								hide-details="auto"
								rows="2"
								@input="datosActivacion.observaciones = $utilsInput.uppercase(datosActivacion.observaciones)">
							</v-textarea>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider class="mx-4 mt-5"></v-divider>
				<v-card-actions>
					<v-row>
						<v-col cols="12" class="text-right">
							<v-btn 
								@click="onCancel"
								color="default"
								:loading="isGenerating">
								Cancelar
							</v-btn>
							<v-btn 
								@click="onSubmit"
								color="primary" 
								:disabled="isLoading"
								:loading="isGenerating"
								class="ml-2">
								Generar
							</v-btn>
						</v-col>
					</v-row>
				</v-card-actions>
			</v-card>
		</v-form>
	</validation-observer>
</div>
</template>
<script>
import SpinnerLoading from '../components/SpinnerLoading';
import DistribuidorAutocomplete from '../components/DistribuidorAutocomplete';

import ProductoApi from '../services/api/producto.api';
import PrecioApi from '../services/api/precio.api';
import PaisErpApi from '../services/api-erp/pais.api';
import EstadoErpApi from '../services/api-erp/estado.api';
import CiudadErpApi from '../services/api-erp/ciudad.api';
import ClienteErpApi from '../services/api-erp/cliente.api';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { PeriodosCredito } from '../utils/constants';
import ObjectToStringMixin from '../mixins/objectToStringMixin'

export default {
	props: {
		mode: Number
	},
	mixins: [ObjectToStringMixin],
	components: {
		SpinnerLoading,
		ValidationProvider,
		ValidationObserver,
		DistribuidorAutocomplete
	},
	created: function() {
		this.onInit();
	},
    data: () => ({
		isLoading: true,
		datosActivacion: {
			solicitaDistribuidor: true,
			idDispositivo: '',
			idDistribuidor: '',
			nombreEmpresa: '',
			idPais: '',
			idEstado: '',
			idCiudad: '',
			telefono: '',
			correo: '',
			claveProducto: '',
			clavePeriodoPago: '',
			claveMoneda: '',
			esACredito: false,
			numeroPeriodosCredito: '',
			idCliente: '',
			observaciones: ''
		},
		distribuidor: {},
		validErrorDistribuidor: '',
		productos: [],
		precios: [],
		precio: {},
		paises: [],
		estados: [],
		ciudades: [],
		periodosCredito: PeriodosCredito,
		numeroCliente: '',
		cliente: {},
		validErrorCliente: '',
		searchingCliente: false,

		isGenerating: false,
	}),
	computed: {
		loadedDistribuidor: function() {
			return Object.keys(this.distribuidor).length !== 0;
		},
		loadedCliente: function() {
			return Object.keys(this.cliente).length !== 0;
		},
		nombreCliente: function() {
			if(this.loadedCliente) {
				return this.cliente.CPrimerNombre + 
				((this.cliente.CSegundoNombre !== null && this.cliente.CSegundoNombre !== '') ? ' ' + this.cliente.CSegundoNombre : '') + 
				((this.cliente.CApellidoPaterno !== null && this.cliente.CApellidoPaterno !== '') ? ' ' + this.cliente.CApellidoPaterno : '') +
				((this.cliente.CApellidoMaterno !== null && this.cliente.CApellidoMaterno !== '') ? ' ' + this.cliente.CApellidoMaterno : '');
			} else {
				return '';
			}
		},
	},
	methods: {
		onInit: async function() {
			this.isLoading = true;
			await ProductoApi.getAll()
			.then(response => {
				if(response.success === true) {
					this.productos = response.data;
				} else {
					this.$notifier.warn('Los productos no se cargaron correctamente. \n' + response.message);
				}
			});
			await PaisErpApi.getAll()
			.then(response => {
				if(response.success === true) {
					this.paises = response.data;
				} else {
					this.$notifier.warn('Los paises no se cargaron correctamente. \n' + response.message);
				}
			});
			this.isLoading = false;
		},

		//#region Metodos de formateo select

		onProductoToString: function(item) {
			return this.objectToString(item.clave, item.descripcion);
		},
		onPrecioToString: function(item) {
			if(Object.keys(item).length !== 0)
				return this.objectToString('', item.precio + ' ' + item.claveMoneda + ' / ' + item.periodoPago.descripcion);
			else 
				return '';
		},
		onPaisToString: function(item) {
			return this.objectToString('', item.cNombre);
		},
		onEstadoToString: function(item) {
			return this.objectToString('', item.CNombre);
		},
		onCiudadToString: function(item) {
			return this.objectToString('', item.CNombre);
		},
		onPeriodoCreditoToString: function(item) {
			return this.objectToString('', item.text);
		},
		
		//#endregion

		//#region Metodos para actualizar propiedades

		onUpdateDistribuidor: function(distribuidorSelected) {
			if(distribuidorSelected !== null) {
				this.distribuidor = distribuidorSelected;
				this.datosActivacion.idDistribuidor = this.distribuidor.id;
				this.$refs.autocompleteDistribuidor.onInitSelection(this.distribuidor);
			} else {
				this.distribuidor = {};
				this.datosActivacion.idDistribuidor = '';
			}
		},
		onChangeProducto: function(idProductoSelected) {
			PrecioApi.getAllByProducto(idProductoSelected)
			.then(response => {
				if(response.success === true) {
					this.precio = {};
					this.precios = response.data;
				} else {
					this.$notifier.warn('Los precios no se cargaron correctamente. \n' + response.message);
				}
			});
		},
		onChangePais: function(idPaisSelected, callFromInit = false) {
			EstadoErpApi.getAll(idPaisSelected)
			.then(response => {
				if(response.success === true) {
					this.estados = response.data;
					if(!callFromInit) {
						this.ciudades = [];
						this.colonias = [];
					}
				} else {
					this.$notifier.warn('Los estados no se cargaron correctamente. \n' + response.message);
				}
			});
		},
		onChangeEstado: function(idEstadoSelected, callFromInit = false) {
			CiudadErpApi.getAll(idEstadoSelected)
			.then(response => {
				if(response.success === true) {
					this.ciudades = response.data;
					if(!callFromInit) {
						this.colonias = [];
					}
				} else {
					this.$notifier.warn('Las ciudades no se cargaron correctamente. \n' + response.message);
				}
			});
		},
		onSearchCliente: function() {
			if(this.numeroCliente !== '') {
				this.searchingCliente = true;
				ClienteErpApi.get(this.numeroCliente)
				.then(response => {
					this.searchingCliente = false;
					if(response.success === true) {
						this.cliente = response.data;
						this.datosActivacion.idCliente = this.cliente.NClave
					} else {
						this.$notifier.warn('Las ciudades no se cargaron correctamente. \n' + response.message);
					}
				});
			}
		},

		//#endregion

		//#region Metodo de acciones de card
		
		onValidCustom: function() {
			//Validamos y creamos mensaje de error
			this.validErrorDistribuidor = this.loadedDistribuidor ? '' : 'El campo distribuidor es requerido';
			this.validErrorCliente = (this.datosActivacion.esACredito && !this.loadedCliente) ? 'Presione el boton para validar el cliente.' : '';
			if(this.datosActivacion.esACredito)
				return this.loadedDistribuidor && this.loadedCliente;
			else
				return this.loadedDistribuidor;
		},
		onCancel: function() {
			this.$router.push('/licencia/all');
		},
		onSubmit: async function() {
			//Prevent multiple guardado
			if(!this.isGenerating) {
				this.isGenerating = true;
				const isValid = await this.$refs.observer.validate();
				if(isValid && this.onValidCustom()) {
					//Copiamos deep objeto para ajustarlo antes de enviarlo
					let datosActivacionSaved = JSON.parse(JSON.stringify(this.datosActivacion));
					datosActivacionSaved.claveMoneda = this.precio.claveMoneda;
					datosActivacionSaved.clavePeriodoPago = this.precio.clavePeriodoPago;
					this.$emit('onSubmit', datosActivacionSaved, () => {
							this.isGenerating = false;
						});
				} else {
					this.isGenerating = false;
					this.$notifier.error('Llene todos los campos marcados como obligatorios, compruebe su información y vuelva a intentar.');
				}
			}
		}

		//#endregion
	}
}
</script>