<template>
<v-dialog v-model="dialog"
	persistent
	max-width="500">
	<validation-observer ref="observerRechazo">
		<v-form @submit.prevent="">
			<v-card>
				<v-card-title class="py-2">
					<span class="text-button grey--text text--darken-3">RECHAZO</span>
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text>
					<v-row>
						<v-col cols="12">
							<div class="text--secondary ml-1">¿Seguro que desea rechazar esta evidencia de carga de saldo?</div>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<div class="text--secondary ml-1">Motivo de Rechazo*</div>
							<validation-provider v-slot="{errors}" 
								name="motivo de rechazo" 
								rules="required">
								<v-text-field v-model="motivoRechazo" 
								outlined  
								hide-details="auto"
								@input="motivoRechazo = $utilsInput.uppercase(motivoRechazo)"
								:error-messages="errors">
								</v-text-field>
							</validation-provider>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn small 
					color="default"
					@click="onCancel">
						Cancelar
					</v-btn>
					<v-btn small 
					color="primary"
					@click="onOk">
						Aceptar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</validation-observer>
</v-dialog>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
	props: ['dialog'],
	components: {
		ValidationProvider,
		ValidationObserver
	},
    data: () => ({
		motivoRechazo: '',
	}),
	methods: {
		onClear: function() {
			this.motivoRechazo = '';
			this.$refs.observerRechazo.reset();
		},
		onCancel: function() {
			this.$emit('onCancel');
			this.onClear();
		},
		onOk: async function() {
			const isValid = await this.$refs.observerRechazo.validate();
			if(isValid) {
				this.$emit('onOk', this.motivoRechazo);
				this.onClear();
			}
		}
	}
  }
</script>