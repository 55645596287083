<template>
<div>
	<validation-observer ref="observer">
		<v-form @submit.prevent="">
			<spinner-loading :overlay="isLoading"/>
			<v-card class="mx-4" 
			rounded="lg">
				<v-card-title class="py-4">
					<span class="font-weight-regular">Datos</span>
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12" sm="6" md="6" lg="6" order="2" order-sm="1">
							<v-row>
								<v-col cols="12">
									<div class="text--secondary ml-1">Imagen de Evidencia*</div>
									<validation-provider v-slot="{errors}" 
									name="imagen de evidencia" 
									rules="required|image">
										<v-file-input v-model="image"
										outlined
										hide-details="auto"
										placeholder="Buscar imagen..."
										accept="image/png, image/jpeg"
										prepend-inner-icon="mdi-camera"
										prepend-icon=""
										:error-messages="errors">
										</v-file-input>
									</validation-provider>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<div class="text--secondary ml-1">Distribuidor*</div>
									<distribuidor-autocomplete ref="autocompleteDistribuidor" 
									:selected="distribuidor"
									:validError="validErrorDistribuidor"
									@onChange="onUpdateDistribuidor">	
									</distribuidor-autocomplete>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" sm="6" md="6">
									<div class="text--secondary ml-1">Monto*</div>
									<validation-provider v-slot="{errors}" 
									name="monto" 
									rules="required">
										<v-text-field v-model="monto" 
										ref="monto"
										outlined  
										hide-details="auto"
										v-currency="$utilsInput.currency(2, false)"
										:error-messages="errors">
										</v-text-field>
									</validation-provider>
								</v-col>
								<v-col cols="12" sm="6" md="6">
									<div class="text--secondary ml-1">Moneda*</div>
									<validation-provider v-slot="{errors}" 
										name="moneda" 
										rules="required">
										<v-select v-model="claveMoneda" 
										:items="monedas"
										outlined
										hide-details="auto"
										item-value="cClave"
										:item-text="onMonedaToString"
										:error-messages="errors">
										</v-select>
									</validation-provider>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<div class="text--secondary ml-1">Monto de Confirmación*</div>
									<validation-provider v-slot="{errors}" 
									name="monto de confirmacion" 
									rules="required|confirmed:monto">
										<v-text-field v-model="montoConfirmacion" 
										ref="montoConfirmacion"
										outlined  
										hide-details="auto"
										v-currency="$utilsInput.currency(2, false)"
										:error-messages="errors"
										:suffix="claveMoneda">
										</v-text-field>
									</validation-provider>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<div class="text--secondary ml-1">Observaciones</div>
									<v-textarea v-model="observaciones"
									outlined
									hide-details="auto"
									rows="3"
									@input="observaciones = $utilsInput.uppercase(observaciones)">
									</v-textarea>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="12" sm="6" md="6" lg="6" order="1" order-sm="2">
							<v-row>
								<v-col cols="12">
									<div class="text--secondary ml-1">Visualización</div>
									<v-img :src="urlImagen" 
									contain
									height="500"
									max-width="600"
									max-height="800" 
									class="rounded-lg">
									</v-img>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider class="mx-4 mt-5"></v-divider>
				<v-card-actions>
					<v-row>
						<v-col cols="12" class="text-right">
							<v-btn @click="onCancel"
							color="default"
							:loading="isSaving">
								Cancelar
							</v-btn>
							<v-btn @click="onSubmit"
							color="primary" 
							:disabled="isLoading"
							:loading="isSaving"
							class="ml-2">
								Finalizar
							</v-btn>
						</v-col>
					</v-row>
				</v-card-actions>
			</v-card>
		</v-form>
	</validation-observer>
</div>
</template>
<script>
import SpinnerLoading from './SpinnerLoading.vue';
import DistribuidorAutocomplete from '../components/DistribuidorAutocomplete';

import MonedaErpApi from '../services/api-erp/moneda.api';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { ViewMode } from '../utils/constants';
import AuthTypes from '../store/types/AuthTypes';
import ObjectToStringMixin from '../mixins/objectToStringMixin'
import { getValue } from 'vue-currency-input';

export default {
	props: {
		mode: Number
	},
	mixins: [ObjectToStringMixin],
	components: {
		SpinnerLoading,
		ValidationProvider,
		ValidationObserver,
		DistribuidorAutocomplete
	},
	created: function() {
		this.onInit();
	},
    data: () => ({
		isLoading: true,
		distribuidor: {},
		validErrorDistribuidor: '',
		monto: '',
		claveMoneda: '',
		monedas: [],
		montoConfirmacion: '',
		observaciones: '',
		image: null,

		isSaving: false,
	}),
	computed: {
		loadedDistribuidor: function() {
			return Object.keys(this.distribuidor).length !== 0;
		},
		urlImagen: function() {
			return this.image !== null ? URL.createObjectURL(this.image) : null;
		}
	},
	methods: {
		onInit: async function() {
			this.isLoading = true;
			await MonedaErpApi.getAll()
			.then(response => {
				if(response.success === true) {
					this.monedas = response.data;
				} else {
					this.$notifier.warn('Las monedas no se cargaron correctamente. \n' + response.message);
				}
			});
			this.isLoading = false;
		},

		//#region Metodos de formateo select

		onMonedaToString: function(item) {
			return this.objectToString(item.cClave, item.cDescripcion);
		},
		
		//#endregion

		//#region Metodos para actualizar propiedades

		onUpdateDistribuidor: function(distribuidorSelected) {
			if(distribuidorSelected !== null) {
				this.distribuidor = distribuidorSelected;
				this.$refs.autocompleteDistribuidor.onInitSelection(this.distribuidor);
			} else {
				this.distribuidor = {};
			}
		},
		onChangeImage: function(img) {
			this.image = img
		},

		//#endregion

		//#region Metodo de acciones de card
		
		onValidCustom: function() {
			//Validamos y creamos mensaje de error
			this.validErrorDistribuidor = this.loadedDistribuidor ? '' : 'El campo distribuidor es requerido';
			return this.loadedDistribuidor;
		},
		onCancel: function() {
			this.$router.push('/');
		},
		onSubmit: async function() {
			//Prevent multiple guardado
			if(!this.isSaving) {
				this.isSaving = true;
				const isValid = await this.$refs.observer.validate();
				if(isValid && this.onValidCustom()) {
					//Copiamos deep objeto para ajustarlo antes de enviarlo
					let formData = new FormData();
					formData.append('IdDistribuidor', this.distribuidor.id)
					formData.append('Evidencia', this.image)
					formData.append('ClaveMoneda', this.claveMoneda)
					formData.append('Monto', getValue(this.$refs.monto))
					formData.append('MontoConfirma', getValue(this.$refs.montoConfirmacion),)
					formData.append('UsuarioConfirma', this.$store.getters[AuthTypes.nameModule + '/' + AuthTypes.getters.EMAIL_USUARIO])
					formData.append('Observaciones', this.observaciones)
					this.$emit('onSubmit', formData, () => {
							this.isSaving = false;
						});
				} else {
					this.isSaving = false;
					this.$notifier.error('Llene todos los campos marcados como obligatorios, compruebe su información y vuelva a intentar.');
				}
			}
		}

		//#endregion
	}
}
</script>