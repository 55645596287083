import Vue from 'vue';
import Vuex from 'vuex';
import AuthModule from './modules/AuthModule';
import AuthTypes from './types/AuthTypes';
import NotifierModule from './modules/NotifierModule';
import NotifierTypes from './types/NotifierTypes';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        [AuthTypes.nameModule]: AuthModule,
        [NotifierTypes.nameModule]: NotifierModule
    }
})