<template>
<div>
	<app-header-page :titulos="titleItems"></app-header-page>
	<carga-saldo-manual-card :mode="mode" 
	@onSubmit="onSubmit">
	</carga-saldo-manual-card>
</div>
</template>
<script>
import AppHeaderPage from '../components/AppHeaderPage';
import CargaSaldoManualCard from '../components/CargaSaldoManualCard';
import EvidenciaApi from '../services/api/evidenciaCargaSaldo.api';
import { ViewMode } from '../utils/constants';

export default {
	components: {
		AppHeaderPage,
		CargaSaldoManualCard
	},
    data: () => ({
		titleItems: ['Carga de saldo manual', 'Registrar'],
		mode: ViewMode.CREATE,
	}),
	methods: {
		onSubmit: function(formDataSaved, callBack) {
			EvidenciaApi.uploadManual(formDataSaved)
			.then(response => {
				callBack();
				if(response.success === true) {
					this.$router.push('/');
					this.$notifier.success('Carga de saldo manual finalizada existosamente.');
				} else {
					this.$notifier.error(response.message);
				}
			});
		}
	}
  }
</script>    