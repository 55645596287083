<template>
<div>
	<app-header-page :titulos="titleItems"></app-header-page>
	<v-card 
		class="mx-4 mb-4" 
		rounded="lg"
	>
		<v-card-text>
			<v-row>
				<v-col cols="12" sm="6" md="6">
					<div class="text--secondary ml-1">Distribuidor</div>
					<distribuidor-autocomplete 
						ref="autocompleteDistribuidor" 
						:selected="distribuidor"	
						@onChange="onUpdateDistribuidor"
					>
					</distribuidor-autocomplete>
				</v-col>
				<v-col cols="6" sm="6" md="6">
					<div class="text--secondary ml-1">Producto</div>
					<v-select 
						v-model="claveProducto" 
						:items="productos"
						outlined
						item-value="clave"
						:item-text="onProductoToString"
						clearable
						@change="onChangeProducto"
					>
					</v-select>
				</v-col>
			</v-row>
			<v-data-table 
				:headers="headers"
				:items="items"
				:footer-props="footerProps"
				:page="page"
				:pageCount="totalPages"
				:options.sync="options"
				:server-items-length="totalItems"
				:items-per-page="pageSize"
				:loading="isLoading"
				loading-text="Cargando..."
				no-data-text="No existen registros."
				item
				class="elevation-1"
				disable-sort
				dense>
				<template v-slot:item.fechaAplicacion="{item}">
					{{ item.fechaAplicacion | dateParse('DD/MM/YYYY HH:mm') }}
				</template>
				<template v-slot:item.nombreDistribuidor="{item}">
					{{item.distribuidor.primerNombre}} {{item.distribuidor.segundoNombre}} {{item.distribuidor.apellidoPaterno}} {{item.distribuidor.apellidoMaterno}}
				</template>
				<template v-slot:item.producto="{item}">
					{{item.producto.clave}} / {{item.producto.descripcion}}
				</template>
				<template v-slot:item.precioPeriodo="{item}">
					{{ item.precio | currencyParse(2) }} {{item.claveMoneda}} / {{item.periodoPago.descripcion}}
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</div>
</template>
<script>
import AppHeaderPage from '../components/AppHeaderPage';
import DistribuidorAutocomplete from '../components/DistribuidorAutocomplete';

import LicenciaObsequioApi from '../services/api/licenciaObsequio.api';
import ProductoApi from '../services/api/producto.api'
import ObjectToStringMixin from '../mixins/objectToStringMixin'

export default {
	mixins: [ObjectToStringMixin],
	components: {
		AppHeaderPage,
		DistribuidorAutocomplete,
	},
  data: () => ({
		titleItems: ['Licencias de obsequio', 'Historial'],
		distribuidor: {},
		claveProducto: '',
		productos: [],
		headers: [
			{ text: 'Fecha', value: 'fechaAplicacion' },
			{ text: 'Distribuidor', value: 'nombreDistribuidor' },
			{ text: 'Producto', value: 'producto' },
			{ text: 'Cantidad', value: 'cantidadLicenciasAplicadas', align: 'right' },
			{ text: 'Precio / Periodo', value: 'precioPeriodo' },
			{ text: 'Periodos', value: 'cantidadPeriodos' },
			{ text: 'Descripcion', value: 'descripcion' },
			
		],
		items: [],
		footerProps: {
			'items-per-page-text': 'Mostrar',
			'items-per-page-options': [10,15,20]
		},
		page: 1,
		totalPages: 0,
		options: {},
		totalItems: 0,
		pageSize: 10,
		isLoading: true,
	}),
	computed: {
		loadedDistribuidor: function() {
			return Object.keys(this.distribuidor).length !== 0;
		},
	},
	mounted: async function() {
		await ProductoApi.getAll()
		.then(response => {
				if(response.success === true) {
					this.productos = response.data;
				} else {
					this.$notifier.warn('Los productos no se cargaron correctamente. \n' + response.message);
				}
			});
		this.onPaged();
	},
	watch: {
		options: {
			handler() {
				this.onPaged();
			}
		},
	},
	methods: {
		onPaged: function() {
			this.isLoading = true;
			//Obtenemos datos de footer para API
			const {page, itemsPerPage} = this.options;
			this.page = page;
			this.pageSize = itemsPerPage;
			LicenciaObsequioApi.getPaged(this.page, this.pageSize, this.loadedDistribuidor ?  this.distribuidor.id : '', this.claveProducto)
			.then(response => {
				this.isLoading = false;
				if(response.success === true) {
					//Cargamos nuevos datos de paginado
					this.items = response.data.items;
					this.totalItems = parseInt(response.data.totalRecordCount);
					this.totalPages = response.data.totalPages;
				} else {
					this.$notifier.error(response.message)
				}
			});
		},
		onChangeProducto: function(claveProductoSelected) {
			this.claveProducto = claveProductoSelected != null ? claveProductoSelected : '';
			//Inicializamos numero de pagina y paginamos
			this.options.page = 1;
			this.onPaged();
		},
		onUpdateDistribuidor: async function(distribuidorSelected) {
			this.distribuidor = distribuidorSelected != null ? distribuidorSelected : {};
			//Inicializamos numero de pagina y paginamos
			this.options.page = 1;
			this.onPaged();
		},

		onProductoToString: function(item) {
			return this.objectToString(item.clave, item.descripcion);
		},
  }
}
</script>