import axios from 'axios';
import httpClient from './httpClient';
import httpUtils from '../httpUtils';

const END_POINT = '/Partner';

export default {
    getAll() {
        return httpClient.get(END_POINT)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
    getPaged(page, pageSize, parameterSearch, cancelSource = axios.CancelToken.source()) {
        return httpClient.get(END_POINT + '/GetPaged?page=' + page + '&pageSize=' + pageSize + '&parameter=' + parameterSearch, {
            cancelToken: cancelSource.token
        }).then(response => {
            return httpUtils.responsePagination(response.headers, response.data);
        }).catch(error => {
            if (axios.isCancel(error)) {
                return httpUtils.responseCancel(error);
            } else {
                return httpUtils.responseError(error.response);
            }
        })
    },
    get(id) {
        return httpClient.get(END_POINT + '/GetById/' + id)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
    add(partner) {
        return httpClient.post(END_POINT, partner)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            });
    },
    update(id, partner) {
        return httpClient.put(END_POINT + '?id=' + id, partner)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            });
    },
    delete(id) {
        return httpClient.delete(END_POINT + '/' + id)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    }
}