<template>
<div>
	<app-header-page :titulos="titleItems"></app-header-page>
	<v-card class="mx-4 mb-4" 
	rounded="lg">
		<v-card-text>
			<v-row>
				<v-col cols="6" sm="3" md="3">
					<div class="text--secondary ml-1">Fecha de Registro</div>
					<v-menu v-model="menuFechaRegistro"
					:close-on-content-click="false"
					transition="scale-transition"
					offset-y
					min-width="auto">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field :value="fechaRegistroFormatted"
							outlined  
							hide-details="auto"
							prepend-inner-icon="mdi-calendar"
							readonly
							clearable
							@click:clear="onChangeFechaRegistro(null)"
							v-bind="attrs"
							v-on="on">
							</v-text-field>
						</template>
						<v-date-picker v-model="fechaRegistro"
						no-title
						@input="menuFechaRegistro = false"
						@change="onChangeFechaRegistro">
						</v-date-picker>
					</v-menu>
				</v-col>
				<v-col cols="6" sm="3" md="3">
					<div class="text--secondary ml-1">Estatus</div>
					<v-select v-model="idEstatus" 
						:items="estatus"
						outlined
						item-value="id"
						:item-text="onEstatusToString"
						clearable
						@change="onChangeEstatus">
						</v-select>
				</v-col>
				<v-col cols="12" sm="6" md="6">
					<div class="text--secondary ml-1">Distribuidor</div>
					<distribuidor-autocomplete ref="autocompleteDistribuidor" 
					:selected="distribuidor"	
					@onChange="onUpdateDistribuidor">	
					</distribuidor-autocomplete>
				</v-col>
			</v-row>
			<v-data-table :headers="headers"
			:items="items"
			:footer-props="footerProps"
			:page="page"
			:pageCount="totalPages"
			:options.sync="options"
			:server-items-length="totalItems"
			:items-per-page="pageSize"
			:loading="isLoading"
			loading-text="Cargando..."
			no-data-text="No existen registros."
			item
			class="elevation-1"
			disable-sort>
				<template v-slot:item.fechaRegistro="{item}">
					{{ item.fechaRegistro | dateParse('DD/MM/YYYY') }}
				</template>
				<template v-slot:item.nombreDistribuidor="{item}">
					{{item.distribuidor.primerNombre}} {{item.distribuidor.segundoNombre}} {{item.distribuidor.apellidoPaterno}} {{item.distribuidor.apellidoMaterno}}
				</template>
				<template v-slot:item.monto="{item}">
					{{ item.montoCargaSaldo | currencyParse(2) }}
				</template>
				<template v-slot:item.acciones="{item}">
					<v-tooltip left>
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon
							color="gray"
							@click="onOpenDialogDetails(item)"
							v-bind="attrs"
							v-on="on">
								<v-icon>mdi-eye</v-icon>
							</v-btn>
						</template>
						<span>Detalles</span>
					</v-tooltip>
					<v-tooltip left v-if="item.estatusEvidencia.clave === 'F'">
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon
							color="gray"
							:to="{path: '/evidencia-carga-saldo/' + item.id + '/confirm'}"
							v-bind="attrs"
							v-on="on">
								<v-icon>mdi-progress-check</v-icon>
							</v-btn>
						</template>
						<span>Ir a Confirmar</span>
					</v-tooltip>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
	<!--Dialog details-->
	<evidencia-carga-saldo-dialog :dialog="dialogDetails"
	:idEvidencia="evidenciaCurrent.id"
	@onClose="onCloseDialogDetails">
	</evidencia-carga-saldo-dialog>
</div>
</template>
<script>
import AppHeaderPage from '../components/AppHeaderPage';
import DistribuidorAutocomplete from '../components/DistribuidorAutocomplete';
import EvidenciaCargaSaldoDialog from '../components/EvidenciaCargaSaldoDialog';

import EvidenciaApi from '../services/api/evidenciaCargaSaldo.api';
import EstatusApi from '../services/api/estatusDocumento.api';
import AuthApi from '../services/api/auth.api';
import AuthTypes from '../store/types/AuthTypes';
import { Funcion, Documento } from '../utils/constants';
import ObjectToStringMixin from '../mixins/objectToStringMixin'
import DatePickerToStringMixin from '../mixins/datePickerToStringMixin'

export default {
	mixins: [ObjectToStringMixin, DatePickerToStringMixin],
	components: {
		AppHeaderPage,
		DistribuidorAutocomplete,
		EvidenciaCargaSaldoDialog
	},
    data: () => ({
		titleItems: ['Evidencias de carga de saldo', 'Todas'],
		distribuidor: {},
		idEstatus: '',
		estatus: [],
		fechaRegistro: '',
		menuFechaRegistro: false,
		headers: [
			{ text: 'Id', value: 'id' },
			{ text: 'Fecha de Registro', value: 'fechaRegistro' },
			{ text: 'Estatus', value: 'estatusEvidencia.descripcion' },
			{ text: 'Distribuidor', value: 'nombreDistribuidor' },
			{ text: 'Monto', value: 'monto', align: 'right' },
			{ text: 'Moneda', value: 'claveMonedaCargaSaldo' },
			{ text: 'Acciones', value: 'acciones'}
		],
		items: [],
		footerProps: {
			'items-per-page-text': 'Mostrar',
			'items-per-page-options': [10,15,20]
		},
		page: 1,
		totalPages: 0,
		options: {},
		totalItems: 0,
		pageSize: 10,
		isLoading: true,
		evidenciaCurrent: {},
		dialogDetails: false,
	}),
	computed: {
		fechaRegistroFormatted: function() {
			return this.datePickerToString(this.fechaRegistro);
		},
		loadedDistribuidor: function() {
			return Object.keys(this.distribuidor).length !== 0;
		},
	},
	mounted: function() {
		EstatusApi.getAll(Documento.EVIDENCIA_CARGA_SALDO)
			.then(response => {
				if(response.success === true) {
					this.estatus = response.data;
				} else {
					this.$notifier.warn('Los estatus no se cargaron correctamente. \n' + response.message);
				}
			});
		this.onPaged();
	},
	watch: {
		options: {
			handler() {
				this.onPaged();
			}
		},
	},
	methods: {
		onPaged: function() {
			this.isLoading = true;
			//Obtenemos datos de footer para API
			const {page, itemsPerPage} = this.options;
			this.page = page;
			this.pageSize = itemsPerPage;
			EvidenciaApi.getPaged(this.page, this.pageSize, this.loadedDistribuidor ?  this.distribuidor.id : '', this.idEstatus, this.fechaRegistro)
			.then(response => {
				this.isLoading = false;
				if(response.success === true) {
					//Cargamos nuevos datos de paginado
					this.items = response.data.items;
					this.totalItems = parseInt(response.data.totalRecordCount);
					this.totalPages = response.data.totalPages;
				} else {
					this.$notifier.error(response.message)
				}
			});
		},

		onChangeFechaRegistro: function(fechaRegistroSelected) {
			this.fechaRegistro = fechaRegistroSelected != null ? fechaRegistroSelected : '';
			//Inicializamos numero de pagina y paginamos
			this.options.page = 1;
			this.onPaged();
		},
		onChangeEstatus: function(idEstatusSelected) {
			this.idEstatus = idEstatusSelected != null ? idEstatusSelected : '';
			//Inicializamos numero de pagina y paginamos
			this.options.page = 1;
			this.onPaged();
		},
		onUpdateDistribuidor: async function(distribuidorSelected) {
			this.distribuidor = distribuidorSelected != null ? distribuidorSelected : {};
			//Inicializamos numero de pagina y paginamos
			this.options.page = 1;
			this.onPaged();
		},

		onEstatusToString: function(item) {
			return this.objectToString('', item.descripcion);
		},

		onOpenDialogDetails: function(evidenciaTemp) {
			let emailUsuario = this.$store.getters[AuthTypes.nameModule + '/' + AuthTypes.getters.EMAIL_USUARIO];
			AuthApi.validatePermission(emailUsuario, Funcion.EVIDENCIA_CARGA_SALDO_VER)
                    .then(response => {
                        if (response.success === true) {
							//Salvamos evidencia current(edit/delete)
							this.evidenciaCurrent = evidenciaTemp
							//Abrimos dialog
							this.dialogDetails = true;
                        } else {
                            this.$notifier.error(response.message)
                        }
                    });
		},
		onCloseDialogDetails: function() {
			//Cerramos dialog
			this.dialogDetails = false;
		},
	}
  }
</script>