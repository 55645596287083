import httpClient from './httpClient';
import httpUtils from '../httpUtils';

const END_POINT = '/PagoOxxo';

export default {
    get(clave) {
        return httpClient.get(END_POINT + '/GetByClave/' + clave)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
    createActivation(datos) {
        return httpClient.post(END_POINT + '/CreateActivation', datos)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            });
    },
    createRenovation(datos) {
        return httpClient.post(END_POINT + '/CreateRenovation', datos)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            });
    },
    report(id) {
        return httpClient.post(END_POINT + '/Report/' + id)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            });
    },
}