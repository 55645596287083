<template>
	<v-autocomplete ref="autocomplete"
	v-model="localSelected"
	outlined
	clearable
	hide-details="auto"
	:items="items"
	:loading="isLoading"
	:search-input.sync="search"
	hide-no-data
	placeholder="Buscar cliente..."
	:item-text="onFormatToText"
	item-value="NClave"
	return-object
	@change="onChange"
	@click:clear="onClear">
		<template v-slot:append-item>
			<div v-if="!isLastPage"
			v-observe-visibility="{
				callback: onVisibilityChanged
			}"
			class="text--secondary ml-3">
				Cargando...
			</div>
		</template>
	</v-autocomplete>
</template>
<script>
import axios from 'axios';
import ClienteErpApi from '../services/api-erp/cliente.api';

export default {
	props: {
		selected: Object,
	},
	mounted: function() {
		if(Object.keys(this.selected).length !== 0) {
			this.onInitSelection(this.selected);
		} else {
			this.onLoad();
		}
	},
	data: () => ({
		idOpcionDefault: '',
		items: [],
		isLoading: false,
		search: '',
		page: 1,
		isLastPage: false,
		localSelected: {},
		cancelSource: null
	}),
	watch: {
		selected () {
			this.localSelected = this.selected;
		},
		search(value) {
			this.page = 1;
			if(value === '') {
				this.onLoad();
			} else {
				value && value !== this.onFormatToText(this.localSelected) && this.onLoad();
			}
		}
	},
	methods: {
		onInitSelection: function(cliente) {
			this.idOpcionDefault = cliente.NClave.toString();
			this.search = cliente.NClave.toString();
		},
		onFormatToText: function(item) {
			return item.NNumero + ' / ' + item.CPrimerNombre + 
			((item.CSegundoNombre !== null && item.CSegundoNombre !== '') ? ' ' + item.CSegundoNombre : '') + 
			((item.CApellidoPaterno !== null && item.CApellidoPaterno !== '') ? ' ' + item.CApellidoPaterno : '') +
			((item.CApellidoMaterno !== null && item.CApellidoMaterno !== '') ? ' ' + item.CApellidoMaterno : '');
		},
		onVisibilityChanged(e) {
			e && this.onLoad();
		},
		onLoad() {
			this.isLoading = true;
			//Cancelamos peticiones previas
			this.onCancelLoad();
			this.cancelSource = axios.CancelToken.source();
			//Load items
			ClienteErpApi.getPaged(this.page, 20, this.search, this.cancelSource)
			.then(response => {
				//Solo cancelamos loading si no es cancelacion de peticiones previas
				if(response.success === false && typeof response.data.isCancel === 'undefined') {
					this.isLoading = false;
				}

				if(response.success === true) {
					this.isLoading = false;
					if(this.page === 1) {
						//Se incia la busqueda
						this.items = response.data.items;
					} else {
						//Agregamos nuevos datos de paginado
						this.items = [
							...this.items,
							...response.data.items
						];
						this.$refs.autocomplete.onScroll();
					}
					//Comprobamos si es ultima pagina, sino avanzamos
					if(parseInt(response.data.totalPages) === this.page) {
						this.isLastPage = true;
					} else {
						this.isLastPage = false;
						this.page += 1;
					}
					//Buscamos y seleccionamos opcion por defecto si esta existe
					if(this.idOpcionDefault !== '') {
						let clienteTemp = response.data.items.find(x => x.NClave == this.idOpcionDefault);
						if(clienteTemp !== null && typeof(clienteTemp) !== 'undefined') {
							this.items = [clienteTemp];
							this.isLastPage = true;
							this.localSelected = clienteTemp;
						} else  {
							this.isLastPage = true;
							this.localSelected = {};
						}
						//Limpiamos primera opcion
						this.idOpcionDefault = '';
					}
				}
			});
		},
		onCancelLoad: function() {
			if(this.cancelSource) {
				this.cancelSource.cancel('Nueva busqueda iniciada.');
			}
		},
		onChange: function(item) {
			this.$emit('onChange', item);
		},
		onClear: function(e) {
			this.search = '';
			this.page = 1
			this.onLoad();
		}
	}
}
</script>