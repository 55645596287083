import NotifierTypes from '../store/types/NotifierTypes'

export default {
    install: (Vue, options) => {
        Vue.prototype.$notifier = {
            success: function(content, timeout = 3000) {
                options.store.commit(NotifierTypes.nameModule + "/" + NotifierTypes.mutations.SHOW, { type: 'primary', content: content, timeout: timeout })
            },
            info: function(content, timeout = 3000) {
                options.store.commit(NotifierTypes.nameModule + "/" + NotifierTypes.mutations.SHOW, { type: 'secondary', content: content, timeout: timeout })
            },
            warn: function(content, timeout = 3000) {
                options.store.commit(NotifierTypes.nameModule + "/" + NotifierTypes.mutations.SHOW, { type: 'warning', content: content, timeout: timeout })
            },
            error: function(content, timeout = 3000) {
                options.store.commit(NotifierTypes.nameModule + "/" + NotifierTypes.mutations.SHOW, { type: 'error', content: content, timeout: timeout })
            },
            custom: function(color, content, timeout = 3000) {
                options.store.commit(NotifierTypes.nameModule + "/" + NotifierTypes.mutations.SHOW, { type: color, content: content, timeout: timeout })
            }
        }
    }
}