export default {
    nameModule: 'AuthModule',
    getters: {
        VERSION: 'VERSION',
        IS_AUTHENTICATED: 'IS_AUTHENTICATED',
        TOKEN: 'TOKEN',
        EMAIL_USUARIO: 'EMAIL_USUARIO',
        NOMBRE_USUARIO: 'NOMBRE_USUARIO',
        TIPO_USUARIO: 'TIPO_USUARIO',
        DISTRIBUIDOR_USUARIO: 'DISTRIBUIDOR_USUARIO',
        PARTNER_USUARIO: 'PARTNER_USUARIO',
    },
    mutations: {
        LOGIN: 'LOGIN',
        LOGOUT: 'LOGOUT',
        SET_LAYOUT: 'SET_LAYOUT',
        SET_VERSION: 'SET_VERSION',
    }
}