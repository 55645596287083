import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                primary: '#3b3dbf',
                secondary: '#ee5c22',
                tertiary: '#62b7d5',
                default: '#eceef5',
                gray: '#757575',
                tab: '#f5f6fa',

                error: '#f44336',
                info: '#62b7d5',
                warning: '#ee5c22',
                background: '#f7f8fb',
            },
        },
    }
});