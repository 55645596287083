<template>
<v-dialog 
	v-model="dialog"
	persistent
	max-width="500">
	<spinner-loading :overlay="isLoading"/>
	<v-card class="py-3">
		<v-card-title class="py-2">
			<span class="text-button grey--text text--darken-3">Amortización</span>
		</v-card-title>
		<v-card-text v-if="loadedAmortizacion">
			<v-row>
				<v-col cols="12" sm="6" md="6">
					<div class="text--secondary ml-1">Fecha</div>
					<strong class="grey--text text--darken-3 ml-1">{{amortizacion.fechaAmortizacion | dateParse('DD/MM/YYYY')}}</strong>
				</v-col>
				<v-col cols="12" sm="6" md="6">
					<div class="text--secondary ml-1">Estatus</div>
					<strong class="grey--text text--darken-3 ml-1">{{amortizacion.estatusAmortizacion.descripcion}}</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="6" md="6">
					<div class="text--secondary ml-1">Periodos Credito</div>
					<strong class="grey--text text--darken-3 ml-1">{{amortizacion.numeroPeriodosCredito}} SEMANAS</strong>
				</v-col>
				<v-col cols="12" sm="6" md="6">
					<div class="text--secondary ml-1">Monto</div>
					<strong class="grey--text text--darken-3 ml-1">{{amortizacion.monto | currencyParse(2)}} {{amortizacion.claveMoneda}}</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<span class="text-button grey--text text--darken-3">PERIODOS DE PAGO</span>
					<v-card 
						elevation="1" 
						rounded="lg">
						<v-list 
							dense
							class="py-0"
							rounded
							elevation="1"
							color="default">
							<template v-for="(item, index) in amortizacion.amortizacionDetalles">
								<v-list-item :key="item.fechaVencimiento">
									<v-list-item-content>
										<v-list-item-title>
											No. {{item.numeroPeriodo}}<br>
											Vencimiento: {{item.fechaVencimiento | dateParse('DD/MM/YYYY')}}<br>
											Monto: {{item.monto | currencyParse(2)}} {{item.claveMoneda}}
										</v-list-item-title>
									</v-list-item-content>
									<v-list-item-action>
										<v-list-item-action-text><strong>{{item.pagado ? 'PAGADO' : ''}}</strong></v-list-item-action-text>
									</v-list-item-action>
								</v-list-item>
								<v-divider :key="index"></v-divider>
							</template>
						</v-list>
					</v-card>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn 
				small 
				color="default"
				@click="onClose">
				Cerrar
			</v-btn>
		</v-card-actions>
	</v-card>
</v-dialog>
</template>
<script>
import SpinnerLoading from '../components/SpinnerLoading';

import AmortizacionApi from '../services/api/amortizacion.api';

export default {
	props: {
		dialog: Boolean, 
		idLicencia: Number,
		claveProducto: String
	},
  data: () => ({
		amortizacion: {},
		isLoading: true,
	}),
	components: {
		SpinnerLoading,
	},
	watch: {
		dialog :{
			handler(newVal, oldVal) {
				if(newVal) {
					this.onLoaded();
				}
			},
			deep: true
		},
	},
	computed: {
		loadedAmortizacion: function() {
			return Object.keys(this.amortizacion).length !== 0;
		},
	},
	methods: {
		onLoaded: function() {
			this.isLoading = true;
			AmortizacionApi.getByLicencia(this.idLicencia, this.claveProducto)
			.then(response => {
				this.isLoading = false;
				if(response.success === true) {
					this.amortizacion = response.data;
				}
			});
		},
		onClose: function() {
			this.$emit('onClose');
		},
	}
}
</script>