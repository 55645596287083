<template>
<div>
	<validation-observer ref="observer">
		<v-form @submit.prevent="">
			<spinner-loading :overlay="isLoading"/>
			<v-card 
				class="mx-4" 
				rounded="lg">
				<v-card-title class="pt-4 pb-1">
					<span class="font-weight-regular">Datos de renovación</span>
				</v-card-title>
				<v-card-text v-if="loadedLicencia">
					<div class="mb-4">
						<licencia-to-renew-card
							:licencia="licencia">
						</licencia-to-renew-card>
					</div>
					<v-row>
						<v-col cols="12" sm="6" md="6">
							<v-checkbox
								v-model="solicitaDistribuidor"
								label="Distribuidor solicita la licencia"
								hide-details="auto"
								class="my-0"
								color="secondary">
							</v-checkbox>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" sm="4" md="4">
							<div class="text--secondary ml-1">Precio/Periodo*</div>
							<validation-provider
								v-slot="{errors}" 
								name="precio periodo" 
								rules="required">
								<v-select 
									v-model="precio" 
									:items="precios"
									outlined
									hide-details="auto"
									item-value="id"
									:item-text="onPrecioToString"
									return-object
									:error-messages="errors">
								</v-select>
							</validation-provider>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider class="mx-4 mt-5"></v-divider>
				<v-card-actions>
					<v-row>
						<v-col cols="12" class="text-right">
							<v-btn 
								@click="onCancel"
								color="default"
								:loading="isGenerating">
								Cancelar
							</v-btn>
							<v-btn 
								@click="onSubmit"
								color="primary" 
								:disabled="isLoading"
								:loading="isGenerating"
								class="ml-2">
								Renovar
							</v-btn>
						</v-col>
					</v-row>
				</v-card-actions>
			</v-card>
		</v-form>
	</validation-observer>
</div>
</template>
<script>
import SpinnerLoading from '../components/SpinnerLoading';
import LicenciaToRenewCard from '../components/LicenciaToRenewCard';

import LicenciaApi from '../services/api/licencia.api';
import PrecioApi from '../services/api/precio.api';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ObjectToStringMixin from '../mixins/objectToStringMixin';

export default {
	props: {
		mode: Number,
		idLicencia: Number,
		claveProducto: String
	},
	mixins: [ObjectToStringMixin],
	components: {
		SpinnerLoading,
		LicenciaToRenewCard,
		ValidationProvider,
		ValidationObserver
	},
	created: function() {
		this.onInit();
	},
  data: () => ({
		isLoading: true,
		licencia: {},
		solicitaDistribuidor: true,
		precios: [],
		precio: null,
		
		isGenerating: false,
	}),
	computed: {
		loadedLicencia: function() {
			return Object.keys(this.licencia).length !== 0;
		},
	},
	methods: {
		onInit: async function() {
			this.isLoading = true;
			await LicenciaApi.get(this.idLicencia, this.claveProducto)
			.then(response => {
				if(response.success === true) {
					this.licencia = response.data;
				} else {
					this.$router.push('/licencia/search-to-renew');
					this.$notifier.error(response.message);
				}
			});
			await PrecioApi.getAllByProducto(this.claveProducto)
			.then(response => {
				if(response.success === true) {
					this.precio = null;
					this.precios = response.data;
				} else {
					this.$notifier.warn('Los precios no se cargaron correctamente. \n' + response.message);
				}
			});
			this.isLoading = false;
		},

		//#region Metodos de formateo select

		onPrecioToString: function(item) {
			if(Object.keys(item).length !== 0)
				return this.objectToString('', item.precio + ' ' + item.claveMoneda + ' / ' + item.periodoPago.descripcion);
			else 
				return '';
		},
		
		//#endregion

		//#region Metodo de acciones de card
		
		onCancel: function() {
			this.$router.push('/licencia/search-to-renew');
		},
		onSubmit: async function() {
			//Prevent multiple guardado
			if(!this.isGenerating) {
				this.isGenerating = true;
				const isValid = await this.$refs.observer.validate();
				if(isValid) {
					let datos = {
						solicitaDistribuidor: this.solicitaDistribuidor,
						idLicencia: this.idLicencia,
						claveProducto: this.claveProducto,
						claveMoneda: this.precio.claveMoneda,
						clavePeriodoPago: this.precio.clavePeriodoPago
					};
					this.$emit('onSubmit', datos, () => {
							this.isGenerating = false;
						});
				} else {
					this.isGenerating = false;
				}
			}
		}

		//#endregion
	}
}
</script>