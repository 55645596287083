export default {
    install: (Vue, options) => {
        Vue.prototype.$utilsInput = {
            uppercase: function(value) {
                return value.toUpperCase();
            },
            alphanumericDash: function(event) {
                let charCode = event.which ? event.which : event.keyCode;
                if ((charCode >= 48 && charCode <= 57) || //0-9
                    (charCode >= 65 && charCode <= 90) || //A-Z
                    (charCode >= 97 && charCode <= 122) || //a-z
                    charCode == 95 || //_
                    charCode == 45) //-
                    return true;
                else
                    event.preventDefault();
            },
            integer: function(allowNegative) {
                return {
                    locale: 'en',
                    currency: null,
                    precision: 0,
                    allowNegative: allowNegative,
                }
            },
            currency: function(decimal, allowNegative) {
                return {
                    locale: 'en',
                    currency: null,
                    precision: decimal,
                    allowNegative: allowNegative
                }
            },
            telephone: function(event) {
                let charCode = event.which ? event.which : event.keyCode;
                if ((charCode >= 48 && charCode <= 57) || //0-9
                    charCode == 40 || //(
                    charCode == 41 || //)
                    charCode == 45) //-
                    return true;
                else
                    event.preventDefault();
            },
        }
    }
}