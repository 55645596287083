<template>
<div>
	<validation-observer ref="observer">
		<v-form @submit.prevent="">
			<spinner-loading :overlay="isLoading"/>
			<v-card class="mx-4" 
			rounded="lg">
				<v-card-title class="py-4">
					<span class="font-weight-regular">Datos</span>
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12" sm="4" md="3">
							<div class="text--secondary ml-1">Clave*</div>
							<validation-provider v-slot="{errors}" 
								name="clave" 
								rules="required">
								<v-text-field v-model="producto.clave" 
								autofocus
								outlined  
								hide-details="auto"
								@input="producto.clave = $utilsInput.uppercase(producto.clave)"
								@keypress="$utilsInput.alphanumericDash($event)"
								:readonly="isEdit"
								:error-messages="errors">
								</v-text-field>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<div class="text--secondary ml-1">Descripción*</div>
							<validation-provider v-slot="{errors}" 
								name="descripcion" 
								rules="required">
								<v-text-field v-model="producto.descripcion" 
								outlined  
								hide-details="auto"
								@input="producto.descripcion = $utilsInput.uppercase(producto.descripcion)"
								:error-messages="errors">
								</v-text-field>
							</validation-provider>
						</v-col>
					</v-row>
					<v-card class="mt-5 mb-2" elevation="1">
						<v-tabs v-model="tabs"
						background-color="tab">
							<v-tab>Tabla Precios</v-tab>
							<v-tab>Observaciones</v-tab>
						</v-tabs>
					</v-card>
					<v-tabs-items v-model="tabs">
						<v-tab-item class="px-1 py-1">
							<v-row class="mb-1">
								<v-col cols="12" sm="3" md="3" offset-sm="9" offset-md="9" 
								class="d-flex flex-row-reverse">
									<v-btn color="secondary"
									small
									@click="onOpenDialogPrecio">
										Agregar
									</v-btn>
								</v-col>
							</v-row>
							<v-simple-table class="elevation-1">
								<template v-slot:default>
									<thead>
										<tr>
											<th class="text-left">Precio</th>
											<th class="text-left">Moneda</th>
											<th class="text-left">Periodo</th>
											<th class="text-left">Acciones</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="item in producto.tablaPrecios" 
										:key="item.name">
											<td>{{item.precio | currencyParse(2)}}</td>
											<td>{{item.claveMoneda}}</td>
											<td>{{item.periodoPago.descripcion}}</td>
											<td>
												<v-btn icon
												color="gray"
												@click="onEditDialogPrecio(item)">
													<v-icon>mdi-pencil</v-icon>
												</v-btn>
												<v-btn icon
												color="gray"
												@click="onOpenConfirmQuitPrecio(item)">
													<v-icon>mdi-delete</v-icon>
												</v-btn>
											</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-tab-item>
						<v-tab-item class="px-1 py-1">
							<v-row>
								<v-col cols="12">
									<v-textarea v-model="producto.observaciones"
									outlined
									hide-details="auto"
									rows="5"
									@input="producto.observaciones = $utilsInput.uppercase(producto.observaciones)">
									</v-textarea>
								</v-col>
							</v-row>
						</v-tab-item>
					</v-tabs-items>
				</v-card-text>
				<v-divider class="mx-4 mt-5"></v-divider>
				<v-card-actions>
					<v-row>
						<v-col cols="12" class="text-right">
							<v-btn @click="onCancel"
							color="default"
							:loading="isSaving">
								Cancelar
							</v-btn>
							<v-btn @click="onSubmit"
							color="primary" 
							:disabled="isLoading"
							:loading="isSaving"
							class="ml-2">
								Guardar
							</v-btn>
						</v-col>
					</v-row>
				</v-card-actions>
			</v-card>
			<v-row>
				<v-col cols="12" class="ml-5">
					<app-users-audit v-if="isEdit" 
					:creador="producto.createdBy"
					:fechaCreacion="producto.createdAt"
					:modificador="producto.updatedBy"
					:fechaModificacion="producto.updatedAt">
					</app-users-audit>
				</v-col>
			</v-row>
		</v-form>
	</validation-observer>
	<!--Dialog de precio-->
	<precio-dialog :dialog="dialogPrecio" 
	:mode="modePrecio"
	:precio="precioCurrent"
	:monedas="monedas"
	:periodos="periodos"
	@onCancel="onCancelDialogPrecio"
	@onSave="onSaveDialogPrecio">
	</precio-dialog>
	<!--Dialog de confirmacion eliminacion-->
	<app-dialog-confirm :dialog-confirm="dialogConfirmDeletePrecio"
	title="ELIMINAR"
	@onCancel="onCancelConfirmQuitPrecio"
	@onOk="onOkConfirmQuitPrecio">
	¿Seguro que desea quitar de la lista el registro?
	</app-dialog-confirm>
</div>
</template>
<script>
import SpinnerLoading from './SpinnerLoading.vue';
import AppDialogConfirm from './AppDialogConfirm';
import AppUsersAudit from './AppUsersAudit';
import PrecioDialog from './PrecioDialog';

import ProductoApi from '../services/api/producto.api';
import PeriodoPagoApi from '../services/api/periodoPago.api'
import MonedaErpApi from '../services/api-erp/moneda.api';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { ViewMode } from '../utils/constants';

export default {
	props: {
		mode: Number
	},
	components: {
		SpinnerLoading,
		ValidationProvider,
		ValidationObserver,
		AppUsersAudit,
		PrecioDialog,
		AppDialogConfirm,
	},
	created: function() {
		this.onInit();
	},
    data: () => ({
		isLoading: true,
		producto: {},
		monedas: [],
		periodos: [],

		tabs: null,
		isSaving: false,
		dialogPrecio: false,
		dialogConfirmDeletePrecio: false,
		modePrecio: 1,
		precioCurrent: {},
	}),
	computed: {
		isEdit: function() {
			return this.mode === ViewMode.EDIT;
		},
	},
	methods: {
		onInit: async function() {
			this.isLoading = true;
			
			await MonedaErpApi.getAll()
			.then(response => {
				if(response.success === true) {
					this.monedas = response.data;
				} else {
					this.$notifier.warn('Las monedas no se cargaron correctamente. \n' + response.message);
				}
			});
			await PeriodoPagoApi.getAll()
			.then(response => {
				if(response.success === true) {
					this.periodos = response.data;
				} else {
					this.$notifier.warn('Los periodos no se cargaron correctamente. \n' + response.message);
				}
			});
			if(this.isEdit) {
				await ProductoApi.get(this.$route.params.clave)
				.then(response => {
					this.isLoading = false;
					if(response.success === true) {
						this.producto = response.data;
					} else {
						this.$router.push('/producto');
						this.$notifier.error(response.message);
					}
				});
			} else {
				this.isLoading = false;
				this.producto = {
					clave: '',
					descripcion: '',
					observaciones: '',
					tablaPrecios: [/*{
						id: '',
						claveProducto: '',
						producto: {
							clave: '',
							descripcion: '',
						},
						precio: '',
						claveMoneda: '',
						periodoPago: '',
						observaciones: ''
					}*/]
				};
			}
		},

		//#region Metodos para dialog de precio

		onOpenDialogPrecio: function() {
			//Init precio current(add)
			this.modePrecio = ViewMode.CREATE;
			this.precioCurrent = {};
			//Abrimos dialog
			this.dialogPrecio = true;
		},
		onEditDialogPrecio: function(precio) {
			//Salvamos precio current(edit/delete)
			this.modePrecio = ViewMode.EDIT;
			this.precioCurrent = precio;
			//Abrimos dialog
			this.dialogPrecio = true;
		},
		onCancelDialogPrecio: function() {
			// Limpiamos precio current
			this.precioCurrent = {}; 
			//Cerramos dialog
			this.dialogPrecio = false;
		},
		onSaveDialogPrecio: function(precioParsed) {
			// Limpiamos precio current
			this.precioCurrent = {}; 
			//Cerramos dialog
			this.dialogPrecio = false;
			if(this.modePrecio === ViewMode.CREATE) {
				//Validamos datos
				if(this.producto.tablaPrecios.findIndex(x => x.claveMoneda === precioParsed.claveMoneda && 
																									x.clavePeriodoPago === precioParsed.clavePeriodoPago) === -1) {
					this.producto.tablaPrecios.push({
						id: precioParsed.id,
						claveProducto: precioParsed.claveProducto,
						precio: precioParsed.precio,
						claveMoneda: precioParsed.claveMoneda,
						clavePeriodoPago: precioParsed.clavePeriodoPago,
						claveProductoERP: precioParsed.claveProductoERP,
						periodoPago: precioParsed.periodoPago,
						observaciones: precioParsed.observaciones,
					});
				} else {
					this.$notifier.error('Un precio con la moneda/periodo especificadas ya se encuentra agregado.');
				}
			} else if(this.modePrecio === ViewMode.EDIT) {
				let idx = this.producto.tablaPrecios.findIndex(x => x.claveMoneda === precioParsed.claveMoneda && 
																												x.clavePeriodoPago == precioParsed.clavePeriodoPago);
				this.producto.tablaPrecios[idx].precio = precioParsed.precio;
				this.producto.tablaPrecios[idx].claveMoneda = precioParsed.claveMoneda;
				this.producto.tablaPrecios[idx].clavePeriodoPago = precioParsed.clavePeriodoPago;
				this.producto.tablaPrecios[idx].claveProductoERP = precioParsed.claveProductoERP;
				this.producto.tablaPrecios[idx].periodoPago = precioParsed.periodoPago;
			}
		},

		//#endregion

		//#region Metodos para eliminacion de precios

		onOpenConfirmQuitPrecio: function(precio) {
			//Salvamos precio current(edit/delete)
			this.precioCurrent = precio;
			//Abrimos dialog
			this.dialogConfirmDeletePrecio = true;
		},
		onCancelConfirmQuitPrecio: function() {
			//Limpiamos precio current(edit/delete)
			this.precioCurrent = {};
			//Cerramos dialog
			this.dialogConfirmDeletePrecio = false;
		},
		onOkConfirmQuitPrecio: function(callBack) {
			callBack();
			//Eliminamos de lista
			let indexDeleted = this.producto.tablaPrecios.findIndex(x => x.id === this.precioCurrent.id);
			this.producto.tablaPrecios.splice(indexDeleted, 1);
			//Limpiamos preccio current(edit/delete)
			this.precioCurrent = {};
			//Cerramos dialog
			this.dialogConfirmDeletePrecio = false;
		},

		//#endregion

		//#region Metodos de acciones de card
		
		onCancel: function() {
			this.$router.push('/producto');
		},
		onValidateList: function() {
			//Comprobamos que existan precios
			let listValid = true;
			let messageValidation = '';
			if(this.producto.tablaPrecios.length <= 0) {
				listValid = false;
				messageValidation += 'Agregue  al menos un precio.';
			}
			if(!listValid) {
				this.$notifier.error(messageValidation);
			}
			return listValid;
		},
		onSubmit: async function() {
			//Prevent multiple guardado
			if(!this.isSaving) {
				this.isSaving = true;
				const isValid = await this.$refs.observer.validate();
				if(isValid && this.onValidateList()) {
					//Copiamos deep objeto para ajustarlo antes de enviarlo
					let productoSaved = JSON.parse(JSON.stringify(this.producto));
					//Copiamos clave de producto en precios y nuleamos objetos
					let claveProducto = productoSaved.clave;
					productoSaved.tablaPrecios.forEach(function(obj) {
						obj.claveProducto = claveProducto;
						obj.periodoPago = null;
					});
					if(!this.isEdit) {
						this.$emit('onSubmit', productoSaved, () => {
							this.isSaving = false;
						});
					} else {
						this.$emit('onSubmit', productoSaved, () => {
							this.isSaving = false;
						});
					}
				} else {
					this.isSaving = false;
				}
			}
		}

		//#endregion
	}
}
</script>