import AuthTypes from '../store/types/AuthTypes';
import store from '../store';

export default {
    createResponse(success, message, data) {
        return {
            success: success,
            message: message,
            data: data
        };
    },
    responsePagination(headers, body) {
        return this.createResponse(true, 'Ok', {
            firstPage: headers['x-paging-firstpage'],
            lastPage: headers['x-paging-lastpage'],
            nextPage: headers['x-paging-nextpage'],
            prevPage: headers['x-paging-prevpage'],
            pageNo: headers['x-paging-pageno'],
            pageSize: headers['x-paging-pagesize'],
            totalPages: headers['x-paging-totalpages'],
            totalRecordCount: headers['x-paging-totalrecordcount'],
            items: body,
        });
    },
    responsePaginationErp(headers, body) {
        let headerData = JSON.parse(headers['paging-headers']);
        return this.createResponse(true, 'Ok', {
            firstPage: 1,
            lastPage: headerData.totalPages,
            nextPage: headerData.nextPage,
            prevPage: headerData.previousPage,
            pageNo: headerData.currentPage,
            pageSize: headerData.pageSize,
            totalPages: headerData.totalPages,
            totalRecordCount: headerData.totalCount,
            items: body,
        });
    },
    responseSuccess(response) {
        if (typeof response.data.isSuccess !== 'undefined') {
            return this.createResponse(response.data.isSuccess, response.data.message, response.data.data);
        } else {
            return this.createResponse(true, 'Ok', response.data);
        }
    },
    responseCancel(error) {
        return this.createResponse(false, error.message, { isCancel: true, message: error.message });
    },
    responseError(error) {
        console.log('error: ', error);
        //Si no hay conexion mandamos error
        if (error === null || typeof error === 'undefined') {
            return this.createResponse(false, 'No se tuvo conexion con el servidor.', null);
        }
        //Si existe un error
        switch (error.status) {
            case 400: //Bad request
                //Error por validacion api
                if (typeof error.data.errors !== 'undefined') {
                    let errores = error.data.errors;
                    if (Array.isArray(errores)) { //Errores en array
                        return this.createResponse(false, errores.join('\n'), null);
                    } else { //Errores en objeto
                        let msg = ''
                        for (let key in errores) {
                            let value = errores[key];
                            msg += (Array.isArray(value) ? value.join('<br>') : value) + '<br>'
                        }
                        return this.createResponse(false, msg, null);
                    }
                }
                //Error de proceso api
                else if (typeof error.data.isSuccess !== 'undefined') {
                    return this.createResponse(error.data.isSuccess, error.data.message, error.data.data);
                }
                //Error general
                else {
                    return this.createResponse(false, error.menssage, null);
                }
            case 401: //Unauthorized
                store.commit(AuthTypes.nameModule + '/' + AuthTypes.mutations.LOGOUT);
                return this.createResponse(false, 'La sesión ha expirado.', null);
            default: // 500,..
                return this.createResponse(false, 'Ha ocurrido un error inesperado.', null);
        }
    }
}