<template>
  <v-dialog v-model="dialog" 
	persistent
	eager
	max-width="500">
		<v-card>
			<v-card-title class="py-2">
				<span class="text-button grey--text text--darken-3">Tarjeta de credito o debito</span>
			</v-card-title>
			<v-card-text>
				<v-row>
					<v-col cols="12">
						<div class="text--secondary ml-1">Total a Pagar</div>
						<span class="grey--text text--darken-3 text-h5">{{montoPago | currencyParse(2)}} {{claveMoneda}}</span>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12">
						<div class="text--secondary ml-1">Nombre de cliente</div>
						<strong class="grey--text text--darken-3 ml-1">{{nombreCliente}}</strong>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" sm="12" md="8">
						<div class="text--secondary ml-1">Tarjetas aceptadas</div>
						<v-row class="ml-1">
							<v-col cols="2" class="px-1">
								<v-img src="images/brand/visa.png" contain position="center" width="50px"/>
							</v-col>
							<v-col cols="2" class="px-1">
								<v-img src="images/brand/mastercard.png" contain position="center" width="50px"/>
								</v-col>
							<v-col cols="2" class="px-1">
								<v-img src="images/brand/maestro.png" contain position="center" width="50px"/>
							</v-col>
							<v-col cols="2" class="px-1">
								<v-img src="images/brand/americanexpress.png" contain position="center" width="50px"/>
							</v-col>
							<v-col cols="2" class="px-1">
								<v-img src="images/brand/discover.png" contain position="center" width="50px"/>
							</v-col>
						</v-row>
					</v-col>
				</v-row>					
				<v-row>
					<v-col cols="12">
						<div class="text--secondary ml-1">Datos de tarjeta</div>
						<v-card class="px-3 pt-3 pb-2" 
						elevation="0"
						outlined>
							<div id='card-element'></div>
						</v-card>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions class="py-3">
				<v-spacer></v-spacer>
				<v-btn small 
				color="default"
				:loading="isProcessing"
				@click="onClose">
					Cerrar
				</v-btn>
				<v-btn small 
				color="primary"
				:loading="isProcessing"
				@click="onPay">
					Pagar
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
export default {
  props: {
		dialog: Boolean, 
		nombreCliente: String, 
		montoPago: Number,
		claveMoneda: String
	},
  mounted() {
		this.onIncludeStripe('js.stripe.com/v3/', function() { 
			this.onConfigureStripe(); 
		}.bind(this));
	},
  data: () => ({
    stripe: null,
		stripeElements: {},
		isProcessing: false,
  }),
  watch: {
		dialog :{
			handler(newVal, oldVal) {
				if(newVal) {
					this.onLoadUIStripe();
				}
			},
			deep: true
		},
	},
  methods: {
		onIncludeStripe(URL, callback) {
			let documentTag = document, tag = 'script',
			object = documentTag.createElement(tag),
			scriptTag = documentTag.getElementsByTagName(tag)[0];
			object.src = '//' + URL;
			if (callback) { 
				object.addEventListener('load', function (e) { 
					callback(null, e); 
				}, false); 
			}
			scriptTag.parentNode.insertBefore(object, scriptTag);
		},
		onConfigureStripe() {
			/* global Stripe */
			this.stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
		},
		onLoadUIStripe: function () {
			//Cargamos elementos UI
			let elements = this.stripe.elements();
			// Set up Stripe.js and Elements to use in checkout for
			var style = {
				base: {
					color: '#32325d',
					fontFamily: '"Open Sans", sans-serif',
					fontSmoothing: 'antialiased',
					fontSize: '16px',
					'::placeholder': {
						color: '#e6e7eb'
					},
				},
				invalid: {
					color: '#f44336',
					iconColor: '#f44336'
				},
			};
			this.stripeElements = elements.create('card', {style: style});
			this.stripeElements.mount('#card-element');
		},
		onClose: function() {
      //Cerramos dialog
			this.$emit('onClose');
		},
		onPay: function() {
			//Prevent multiple guardado
			if(!this.isProcessing) {
				this.isProcessing = true;
				//Creamos PaymentMethod
				this.stripe.createPaymentMethod({
					type: 'card',
					card: this.stripeElements,
					billing_details: {
						// Include any additional collected billing details.
						name: this.nombreCliente,
					},
				}).then(result => {
          this.$emit('onHandleStripePaymentMethod', result, () => {
						this.isProcessing = false;
					}, this.stripe);
				});
			}
		},
	}
}
</script>
