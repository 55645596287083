<template>
  <v-container>
     Dashboard
  </v-container>
</template>
<script>
import AuthTypes from '../store/types/AuthTypes';
	
export default {
	created: function() {
    this.$store.commit(AuthTypes.nameModule + '/' + AuthTypes.mutations.SET_LAYOUT, 'principal-layout');
  }
}
</script>