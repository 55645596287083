<template>
<v-dialog v-model="dialog"
	persistent
	max-width="500">
	<validation-observer ref="observerTelefono">
		<v-form @submit.prevent="">
			<v-card>
				<v-card-title class="py-2">
					<span class="text-button grey--text text--darken-3">TELEFONO</span>
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text>
					<v-row>
						<v-col cols="12" sm="5">
							<div class="text--secondary ml-1">Tipo*</div>
							<validation-provider v-slot="{errors}" 
								name="tipo" 
								rules="required">
								<v-select v-model="tipoTelefono" 
								:items="tiposTelefono"
								outlined
								hide-details="auto"
								item-value="NId"
								item-text="CNombre"
								:error-messages="errors"
								return-object>
								</v-select>
							</validation-provider>
						</v-col>
						<v-col cols="12" sm="7">
							<div class="text--secondary ml-1">Número de Teléfono*</div>
							<validation-provider v-slot="{errors}" 
								name="numero telefono" 
								rules="required">
								<v-text-field v-model="telefono" 
								outlined  
								hide-details="auto"
								:error-messages="errors"
								@keypress="$utilsInput.telephone($event)">
								</v-text-field>
							</validation-provider>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn small 
					color="default"
					@click="onCancel">
						Cancelar
					</v-btn>
					<v-btn small 
					color="primary"
					@click="onSave">
						Guardar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</validation-observer>
</v-dialog>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ObjectToStringMixin from '../mixins/objectToStringMixin';

export default {
	props: ['dialog', 'tiposTelefono'],
	components: {
		ValidationProvider,
		ValidationObserver
	},
	mixins: [ObjectToStringMixin],
    data: () => ({
		tipoTelefono: {
			NId: 1,
			CNombre: 'TELEFONO'
		},
		telefono: '',
		isSaving: false,
	}),
	methods: {

		//#region Metodos de acciones de card

		getDataToSave: function() {
			return {
				tipoTelefono: this.tipoTelefono.CNombre,
				telefono: this.telefono,
			};
		},
		onClear: function() {
			this.tipoTelefono = {
				NId: 1,
				CNombre: 'TELEFONO'
			};
			this.telefono = '';
			this.$refs.observerTelefono.reset();
		},
		onCancel: function() {
			this.$emit('onCancel');
			this.onClear();
		},
		onSave: async function() {
			const isValid = await this.$refs.observerTelefono.validate();
			if(isValid) {
				this.$emit('onSave', this.getDataToSave());
				this.onClear();
			}
		}

		//#endregion
	}
  }
</script>