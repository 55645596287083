<template>
	<validation-observer ref="observer">
		<v-form @submit.prevent="onSubmit">
			<spinner-loading :overlay="isLoading"/>
			<v-card 
				class="mx-4" 
				rounded="lg">
				<v-card-title>{{title}}</v-card-title>
				<v-card-subtitle>{{instructions}}</v-card-subtitle>
				<v-card-text>
					<v-row>
						<v-col cols="12" sm="6" md="6" lg="6">
							<div class="text--secondary ml-1">ID Licencia*</div>
							<validation-provider 
                v-slot="{errors}" 
                name="id licencia" 
                rules="required">
								<v-text-field 
                  v-model="idLicencia" 
                  :error-messages="errors" 
                  outlined 
                  hide-details="auto"/>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<div class="text--secondary ml-1">Producto*</div>
							<validation-provider 
								v-slot="{errors}" 
								name="producto" 
								rules="required">
								<v-select 
									v-model="producto" 
									:items="productos"
									outlined
									hide-details="auto"
									item-value="clave"
									:item-text="onProductoToString"
									return-object
									:error-messages="errors + validErrorProducto">
								</v-select>
							</validation-provider>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider class="mx-4 mt-5"></v-divider>
				<v-card-actions v-if="inAppStack">
					<v-spacer></v-spacer>
					<v-btn 
						@click="onCancel"
						color="default">
						Cancelar
					</v-btn>
					<v-btn
						dark
						type="submit" 
						color="primary" >
						Siguiente
					</v-btn>
				</v-card-actions>
        <v-card-actions v-else>
					<v-btn 
							block 
							dark
							type="submit" 
							color="primary" >
							Siguiente
						</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</validation-observer>
</template>
<script>
import SpinnerLoading from '../components/SpinnerLoading';

import ProductoApi from '../services/api/producto.api';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ObjectToStringMixin from '../mixins/objectToStringMixin'

export default {
  props: {
    inAppStack: Boolean,
    title: String,
    instructions: String,
  },
	mixins: [ObjectToStringMixin],
	components: {
		SpinnerLoading,
		ValidationProvider,
		ValidationObserver
	},
	created: function() {
		this.onInit();
	},
	data: () =>({
		isLoading: true,
		idLicencia: '',
		productos: [],
		producto: {},
		validErrorProducto: '',
	}),
	computed: {
		loadedProducto: function() {
			return Object.keys(this.producto).length !== 0;
		},
	},
	methods: {
		onInit: async function() {
			this.isLoading = true;
			await ProductoApi.getAll()
			.then(response => {
				if(response.success === true) {
					this.productos = response.data;
				} else {
					this.$notifier.warn('Los productos no se cargaron correctamente. \n' + response.message);
				}
			});
			this.isLoading = false;
		},
		onProductoToString: function(item) {
			return this.objectToString(item.clave, item.descripcion);
		},
		onValidCustom: function() {
			//Validamos y creamos mensaje de error
			this.validErrorProducto = this.loadedProducto ? '' : 'El campo producto es requerido';
			return this.loadedProducto;
		},
		onCancel: function() {
			this.$emit('onCancel');
		},
		onSubmit: async function() {
			const isValid = await this.$refs.observer.validate();
			if(this.onValidCustom() && isValid) {
        this.$emit('onSubmit', this.idLicencia, this.producto.clave);
			}
		}
	}
}
</script>