<template>
<div>
	<validation-observer ref="observer">
		<v-form @submit.prevent="">
			<spinner-loading :overlay="isLoading"/>
			<v-card class="mx-4" 
			rounded="lg">
				<v-card-title class="py-4">
					<span class="font-weight-regular">Datos</span>
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12" sm="3" md="2">
							<div class="text--secondary ml-1">ID</div>
							<v-text-field v-model="distribuidor.id" 
							readonly
							outlined  
							hide-details="auto">
							</v-text-field>
						</v-col>
						<v-col cols="12" sm="3" md="3" offset-sm="6" offset-md="7"
						v-show="isEdit">
							<div class="text--secondary ml-1">Codigo de Referencia</div>
							<v-text-field v-model="distribuidor.codigoReferencia" 
							readonly
							outlined  
							hide-details="auto">
							</v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" sm="3" md="3">
							<div class="text--secondary ml-1">Nombre*</div>
							<validation-provider v-slot="{errors}" 
								name="nombre" 
								rules="required">
								<v-text-field v-model="distribuidor.primerNombre" 
								autofocus
								outlined  
								hide-details="auto"
								@input="distribuidor.primerNombre = $utilsInput.uppercase(distribuidor.primerNombre)"
								:error-messages="errors">
								</v-text-field>
							</validation-provider>
						</v-col>
						<v-col cols="12" sm="3" md="3">
							<div class="text--secondary ml-1">Segundo Nombre</div>
							<v-text-field v-model="distribuidor.segundoNombre" 
							outlined  
							hide-details="auto"
							@input="distribuidor.segundoNombre = $utilsInput.uppercase(distribuidor.segundoNombre)">
							</v-text-field>
						</v-col>
						<v-col cols="12" sm="3" md="3">
							<div class="text--secondary ml-1">Apellido Paterno*</div>
							<validation-provider v-slot="{errors}" 
								name="apellido paterno" 
								rules="required">
								<v-text-field v-model="distribuidor.apellidoPaterno" 
								outlined  
								hide-details="auto"
								@input="distribuidor.apellidoPaterno = $utilsInput.uppercase(distribuidor.apellidoPaterno)"
								:error-messages="errors">
								</v-text-field>
							</validation-provider>
						</v-col>
						<v-col cols="12" sm="3" md="3">
							<div class="text--secondary ml-1">Apellido Materno</div>
							<v-text-field v-model="distribuidor.apellidoMaterno" 
							outlined  
							hide-details="auto"
							@input="distribuidor.apellidoMaterno = $utilsInput.uppercase(distribuidor.apellidoMaterno)">
							</v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" sm="3" md="3">
							<div class="text--secondary ml-1">Estatus*</div>
							<validation-provider v-slot="{errors}" 
								name="estatus" 
								rules="required">
								<v-select v-model="distribuidor.idEstatusDatoMaestro" 
								:items="estatus"
								outlined
								hide-details="auto"
								item-value="id"
								:item-text="onEstatusToString"
								:error-messages="errors">
								</v-select>
							</validation-provider>
						</v-col>
						<v-col cols="12" sm="9" md="9">
							<div class="text--secondary ml-1">Motivo de Estatus</div>
							<v-text-field v-model="distribuidor.motivoEstatus" 
							outlined  
							hide-details="auto"
							@input="distribuidor.motivoEstatus = $utilsInput.uppercase(distribuidor.motivoEstatus)">
							</v-text-field>
						</v-col>
					</v-row>
					<v-card class="mt-5 mb-2" elevation="1">
						<v-tabs v-model="tabs" 
						background-color="tab">
							<v-tab>Datos Generales</v-tab>
							<v-tab>Acceso</v-tab>
							<v-tab>Dirección</v-tab>
							<v-tab>Contacto</v-tab>
							<v-tab>Observaciones</v-tab>
						</v-tabs>
					</v-card>
					<v-tabs-items v-model="tabs">
						<v-tab-item class="px-1 py-1">
							<v-row>
								<v-col cols="12" sm="6" md="6">
									<div class="text--secondary ml-1">CURP</div>
									<v-text-field v-model="distribuidor.curp" 
									outlined  
									hide-details="auto"
									@input="distribuidor.curp = $utilsInput.uppercase(distribuidor.curp)">
									</v-text-field>
								</v-col>
								<v-col cols="12" sm="6" md="6">
									<div class="text--secondary ml-1">RFC</div>
									<v-text-field v-model="distribuidor.rfc" 
									outlined  
									hide-details="auto"
									@input="distribuidor.rfc = $utilsInput.uppercase(distribuidor.rfc)">
									</v-text-field>
								</v-col>
							</v-row>
							<div v-show="visiblePartnerDistribuidor">
								<v-row>
									<v-col>
										<div class="text--secondary ml-1">Distribuidor que Recomienda</div>
										<distribuidor-autocomplete ref="autocompleteDistribuidorRecomienda" 
										:selected="distribuidorRecomienda"
										@onChange="onUpdateDistribuidorRecomienda">	
										</distribuidor-autocomplete>
									</v-col>
								</v-row>
								<v-row v-show="visiblePartner">
									<v-col>
										<div class="text--secondary ml-1">Partner</div>
										<partner-autocomplete ref="autocompletePartner" 
										:selected="distribuidor.partner || {}"
										@onChange="onUpdatePartner">	
										</partner-autocomplete>
									</v-col>
								</v-row>
							</div>
							<v-row v-if="isEdit">
								<v-col cols="12">
									<v-btn small
									color="secondary"
									@click="onOpenDialogSaldos"
									class="mr-2 mt-2">
										Saldo de licencia
									</v-btn>
									<v-btn small
									color="secondary"
									@click="onOpenDialogSaldosLicenciaObsequio"
									class="mt-2">
										Saldo de licencia de obsequio
									</v-btn>
								</v-col>
							</v-row>
						</v-tab-item>
						<v-tab-item class="px-1 py-1" eager>
							<v-row>
								<v-col cols="12" sm="6" md="6">
									<div class="text--secondary ml-1">Email*</div>
									<validation-provider v-slot="{errors}" 
									name="email" 
									rules="required|email">
										<v-text-field v-model="distribuidor.email" 
										:error-messages="errors" 
										outlined 
										hide-details="auto"/>
									</validation-provider>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" sm="6" md="6">
									<div class="text--secondary ml-1">Contraseña*</div>
									<validation-provider v-slot="{errors}" 
									name="contraseña" 
									rules="required">
										<v-text-field v-model="distribuidor.password" 
										:error-messages="errors" 
										type="password" 
										outlined 
										hide-details="auto"/>
									</validation-provider>
								</v-col>
							</v-row>
						</v-tab-item>
						<v-tab-item class="px-1 py-1" eager>
							<v-row>
								<v-col cols="12" sm="9" md="9">
									<div class="text--secondary ml-1">Calle</div>
									<v-text-field v-model="distribuidor.calle" 
									outlined  
									hide-details="auto"
									@input="distribuidor.calle = $utilsInput.uppercase(distribuidor.calle)">
									</v-text-field>
								</v-col>
								<v-col cols="12" sm="3" md="3">
									<div class="text--secondary ml-1">Código Postal</div>
									<validation-provider v-slot="{errors}" 
									name="codigo postal" 
									rules="max:6">
										<v-text-field v-model="distribuidor.codigoPostal" 
										outlined  
										hide-details="auto"
										@input="distribuidor.codigoPostal = $utilsInput.uppercase(distribuidor.codigoPostal)"
										:error-messages="errors">
										</v-text-field>
									</validation-provider>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" sm="3" md="3">
									<div class="text--secondary ml-1">No. Ext.</div>
									<v-text-field v-model="distribuidor.numeroExterior" 
									outlined  
									hide-details="auto"
									@input="distribuidor.numeroExterior = $utilsInput.uppercase(distribuidor.numeroExterior)">
									</v-text-field>
								</v-col>
								<v-col cols="12" sm="3" md="3">
									<div class="text--secondary ml-1">No. Int.</div>
									<v-text-field v-model="distribuidor.numeroInterior" 
									outlined  
									hide-details="auto"
									@input="distribuidor.numeroInterior = $utilsInput.uppercase(distribuidor.numeroInterior)">
									</v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" sm="3" md="3">
									<div class="text--secondary ml-1">País*</div>
									<validation-provider v-slot="{errors}" 
										name="pais" 
										rules="required">
										<v-autocomplete v-model="distribuidor.idPais" 
										:items="paises"
										outlined
										hide-details="auto"
										item-value="nId"
										:item-text="onPaisToString"
										:error-messages="errors"
										@change="onChangePais">
										</v-autocomplete>
									</validation-provider>
								</v-col>
								<v-col cols="12" sm="3" md="3">
									<div class="text--secondary ml-1">Estado*</div>
									<validation-provider v-slot="{errors}" 
										name="estado" 
										rules="required">
										<v-autocomplete v-model="distribuidor.idEstado" 
										:items="estados"
										outlined
										hide-details="auto"
										item-value="NClave"
										:item-text="onEstadoToString"
										:error-messages="errors"
										@change="onChangeEstado">
										</v-autocomplete>
									</validation-provider>
								</v-col>
								<v-col cols="12" sm="3" md="3">
									<div class="text--secondary ml-1">Ciudad*</div>
									<validation-provider v-slot="{errors}" 
										name="ciudad" 
										rules="required">
										<v-autocomplete v-model="distribuidor.idCiudad" 
										:items="ciudades"
										outlined
										hide-details="auto"
										item-value="NId"
										:item-text="onCiudadToString"
										:error-messages="errors"
										@change="onChangeCiudad">
										</v-autocomplete>
									</validation-provider>
								</v-col>
								<v-col cols="12" sm="3" md="3">
									<div class="text--secondary ml-1">Colonia</div>
									<v-autocomplete v-model="distribuidor.idColonia" 
									:items="colonias"
									outlined
									hide-details="auto"
									item-value="Id"
									:item-text="onColoniaToString"
									@change="onChangeColonia">
									</v-autocomplete>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<div class="text--secondary ml-1">Referencia Domiciliar</div>
									<v-textarea v-model="distribuidor.referenciaDomicilio"
									outlined
									hide-details="auto"
									rows="2"
									@input="distribuidor.referenciaDomicilio = $utilsInput.uppercase(distribuidor.referenciaDomicilio)">
									</v-textarea>
								</v-col>
							</v-row>
						</v-tab-item>
						<v-tab-item class="px-1 py-1">
							<v-row>
								<v-col cols="12" sm="12" md="8" lg="6">
									<v-row>
										<v-col class="pb-0">
											<span class="text-button grey--text text--darken-3">TELEFONOS</span>	
										</v-col>
										<v-spacer></v-spacer>
										<v-col class="text-right pb-0">
											<v-btn color="secondary" 
											small
											@click="onOpenDialogTelefono">
												Agregar
											</v-btn>
										</v-col>
									</v-row>
									<v-card elevation="1" rounded="lg">
										<v-list class="my-5 py-0"
										dense>
											<template v-for="(item, index) in distribuidor.distribuidorTelefonos">
												<v-list-item :key="item.id">
													<v-list-item-content>
													<v-list-item-title v-html="item.telefono"></v-list-item-title>
													<v-list-item-subtitle v-html="item.tipoTelefono"></v-list-item-subtitle>
												</v-list-item-content>

												<v-list-item-action>
													<v-btn icon
													color="gray"
													@click="onOpenConfirmQuitTelefono(item)">
														<v-icon color="gray">mdi-close</v-icon>
													</v-btn>
												</v-list-item-action>
												</v-list-item>
												<v-divider :key="index"></v-divider>
											</template>
										</v-list>
									</v-card>
								</v-col>
								<v-col cols="12" sm="12" md="8" lg="6">
									<v-row>
										<v-col class="pb-0">
											<span class="text-button grey--text text--darken-3 text-truncate">CUENTAS DE CORREO</span>
										</v-col>
										<v-spacer></v-spacer>
										<v-col class="text-right pb-0">
											<v-btn color="secondary" 
											small
											@click="onOpenDialogCorreo">
												Agregar
											</v-btn>
										</v-col>
									</v-row>
									<v-card elevation="1" rounded="lg">
										<v-list class="my-5 py-0"
										dense>
											<template v-for="(item, index) in distribuidor.distribuidorCorreos">
												<v-list-item :key="item.id">
													<v-list-item-content>
													<v-list-item-title v-html="item.correoElectronico"></v-list-item-title>
												</v-list-item-content>

												<v-list-item-action>
													<v-btn icon
													color="gray"
													@click="onOpenConfirmQuitCorreo(item)">
														<v-icon color="gray">mdi-close</v-icon>
													</v-btn>
												</v-list-item-action>
												</v-list-item>
												<v-divider :key="index"></v-divider>
											</template>
										</v-list>
									</v-card>
								</v-col>
							</v-row>
						</v-tab-item>
						<v-tab-item class="px-1 py-1">
							<v-row>
								<v-col cols="12">
									<v-textarea v-model="distribuidor.observaciones"
									outlined
									hide-details="auto"
									rows="5"
									@input="distribuidor.observaciones = $utilsInput.uppercase(distribuidor.observaciones)">
									</v-textarea>
								</v-col>
							</v-row>
						</v-tab-item>
					</v-tabs-items>
				</v-card-text>
				<v-divider class="mx-4 mt-5"></v-divider>
				<v-card-actions>
					<v-row>
						<v-col cols="12" class="text-right">
							<v-btn @click="onCancel"
							color="default"
							:loading="isSaving">
								Cancelar
							</v-btn>
							<v-btn @click="onSubmit"
							color="primary" 
							:disabled="isLoading"
							:loading="isSaving"
							class="ml-2">
								Guardar
							</v-btn>
						</v-col>
					</v-row>
				</v-card-actions>
			</v-card>
			<v-row>
				<v-col cols="12" class="ml-5">
					<app-users-audit v-if="isEdit" 
					:creador="distribuidor.createdBy"
					:fechaCreacion="distribuidor.createdAt"
					:modificador="distribuidor.updatedBy"
					:fechaModificacion="distribuidor.updatedAt">
					</app-users-audit>
				</v-col>
			</v-row>
		</v-form>
	</validation-observer>
	<!--Dialog de telefono-->
	<telefono-dialog :dialog="dialogTelefono" 
	:tiposTelefono="tiposTelefono"
	@onCancel="onCancelDialogTelefono"
	@onSave="onSaveDialogTelefono">
	</telefono-dialog>
	<!--Dialog de correo-->
	<correo-dialog :dialog="dialogCorreo"
	@onCancel="onCancelDialogCorreo"
	@onSave="onSaveDialogCorreo">
	</correo-dialog>
	<!--Dialog de confirmacion eliminacion-->
	<app-dialog-confirm :dialog-confirm="dialogConfirmDeleteChild"
	title="ELIMINAR"
	@onCancel="onCancelConfirmQuitChild"
	@onOk="onOkConfirmQuitChild">
	¿Seguro que desea quitar de la lista el registro?
	</app-dialog-confirm>
	<!--Dialog de saldo-->
	<saldo-distribuidor-dialog :dialog="dialogSaldos"
	:idDistribuidor='distribuidor.id'
	@onClose="onCloseDialogSaldos">
	</saldo-distribuidor-dialog>
	<!--Dialog de saldo licencia obsequio-->
	<saldo-distribuidor-licencia-obsequio-dialog :dialog="dialogSaldosLicenciaObsequio"
	:idDistribuidor='distribuidor.id'
	@onClose="onCloseDialogSaldosLicenciaObsequio">
	</saldo-distribuidor-licencia-obsequio-dialog>
</div>
</template>
<script>
import SpinnerLoading from './SpinnerLoading.vue';
import AppDialogConfirm from './AppDialogConfirm';
import AppUsersAudit from './AppUsersAudit';
import TelefonoDialog from './TelefonoDialog';
import CorreoDialog from './CorreoDialog';
import DistribuidorAutocomplete from './DistribuidorAutocomplete';
import PartnerAutocomplete from './PartnerAutocomplete';
import SaldoDistribuidorDialog from './SaldoDistribuidorDialog';
import SaldoDistribuidorLicenciaObsequioDialog from './SaldoDistribuidorLicenciaObsequioDialog';

import DistribuidorApi from '../services/api/distribuidor.api';
import PartnerApi from '../services/api/partner.api';
import EstatusApi from '../services/api/estatusDatoMaestro.api';
import TipoTelefonoErpApi from '../services/api-erp/tipoTelefono.api';
import PaisErpApi from '../services/api-erp/pais.api';
import EstadoErpApi from '../services/api-erp/estado.api';
import CiudadErpApi from '../services/api-erp/ciudad.api';
import ColoniaErpApi from '../services/api-erp/colonia.api';
import AuthTypes from '../store/types/AuthTypes';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { ViewMode, DatoMaestro } from '../utils/constants';
import ObjectToStringMixin from '../mixins/objectToStringMixin'

export default {
	props: {
		mode: Number
	},
	mixins: [ObjectToStringMixin],
	components: {
		SpinnerLoading,
		ValidationProvider,
		ValidationObserver,
		AppUsersAudit,
		AppDialogConfirm,
		TelefonoDialog,
		CorreoDialog,
		DistribuidorAutocomplete,
		PartnerAutocomplete,
		SaldoDistribuidorDialog,
		SaldoDistribuidorLicenciaObsequioDialog
	},
	created: function() {
		this.tipoUsuario = this.$store.getters[AuthTypes.nameModule + '/' + AuthTypes.getters.TIPO_USUARIO];
		this.partnerLogueado = this.$store.getters[AuthTypes.nameModule + '/' + AuthTypes.getters.PARTNER_USUARIO];
		this.distribuidorLogueado = this.$store.getters[AuthTypes.nameModule + '/' + AuthTypes.getters.DISTRIBUIDOR_USUARIO];

		this.onInit();
	},
    data: () => ({
		tipoUsuario: '',
		partnerLogueado: '',
		distribuidorLogueado: '',

		isLoading: true,
		distribuidor: {},
		distribuidorRecomienda: {},
		estatus: [],
		tiposTelefono: [],
		paises: [],
		estados: [],
		ciudades: [],
		colonias: [],

		tabs: null,
		isSaving: false,

		dialogTelefono: false,
		dialogConfirmDeleteChild: false,
		telefonoCurrent: {},
		dialogCorreo: false,
		correoCurrent: {},
		dialogSaldos: false,
		dialogSaldosLicenciaObsequio: false
	}),
	computed: {
		visiblePartnerDistribuidor: function() {
			return this.partnerLogueado === '' && this.distribuidorLogueado === '';
		},
		visiblePartner: function() {
			return Object.keys(this.distribuidorRecomienda).length === 0;
		},
		isEdit: function() {
			return this.mode === ViewMode.EDIT;
		}
	},
	methods: {
		onInit: async function() {
			this.isLoading = true;
			await EstatusApi.getAll(DatoMaestro.DISTRIBUIDOR)
			.then(response => {
				if(response.success === true) {
					this.estatus = response.data;
				} else {
					this.$notifier.warn('Los estatus no se cargaron correctamente. \n' + response.message);
				}
			});
			await TipoTelefonoErpApi.getAll()
			.then(response => {
				if(response.success === true) {
					this.tiposTelefono = response.data;
				} else {
					this.$notifier.warn('Los tipos de telefono no se cargaron correctamente. \n' + response.message);
				}
			});
			await PaisErpApi.getAll()
			.then(response => {
				if(response.success === true) {
					this.paises = response.data;
				} else {
					this.$notifier.warn('Los paises no se cargaron correctamente. \n' + response.message);
				}
			});
			if(this.isEdit) {
				await DistribuidorApi.get(this.$route.params.id)
				.then(response => {
					this.isLoading = false;
					if(response.success === true) {
						this.distribuidor = response.data;

						//Actualizamos valor onDemand
						if(this.distribuidor.idDistribuidorRecomienda)
							this.onInitDistribuidorRecomienda(this.distribuidor.idDistribuidorRecomienda);
						if(this.distribuidor.idPartner)
							this.onInitPartner(this.distribuidor.idPartner);
						if(this.distribuidor.idPais)
							this.onChangePais(this.distribuidor.idPais, true);
						if(this.distribuidor.idEstado)
							this.onChangeEstado(this.distribuidor.idEstado, true);
						if(this.distribuidor.idCiudad)
							this.onChangeCiudad(this.distribuidor.idCiudad, true);
						if(this.distribuidor.idColonia)
							this.onChangeColonia(this.distribuidor.idColonia, true);

					} else {
						this.$router.push('/distribuidor');
						this.$notifier.error(response.message);
					}
				});
			} else {
				this.isLoading = false;
				this.distribuidor = {
					idPais: '',
					primerNombre: '',
					segundoNombre: '',
					apellidoPaterno: '',
					apellidoMaterno: '',
					calle: '',
					numeroExterior: '',
					numeroInterior: '',
					codigoPostal: '',
					idEstado: '',
					idCiudad: '',
					idColonia: '',
					referenciaDomicilio: '',
					rfc: '',
					curp: '',
					idEstatusDatoMaestro: this.estatus.length > 0 ? this.estatus[0].id : 0,
					estatusDatoMaestro: null,
					motivoEstatus: '',
					idPartner: this.partnerLogueado,
					partner: null,
					nivelRedPartner: '',
					idDistribuidorRecomienda: this.distribuidorLogueado,
					codigoReferencia: '',
					observaciones: '',
					distribuidorTelefonos: [],
					distribuidorCorreos: [],
					email: '',
					password: ''
				};
			}
		},

		//#region Metodos de formateo select

		onEstatusToString: function(item) {
			return this.objectToString('', item.descripcion);
		},
		onPaisToString: function(item) {
			return this.objectToString('', item.cNombre);
		},
		onEstadoToString: function(item) {
			return this.objectToString('', item.CNombre);
		},
		onCiudadToString: function(item) {
			return this.objectToString('', item.CNombre);
		},
		onColoniaToString: function(item) {
			return this.objectToString('', item.Nombre);
		},
		//#endregion

		//#region Metodos para actualizar propiedades

		onUpdateDistribuidorRecomienda: function(distribuidorSelected) {
			if(distribuidorSelected !== null) {
				this.distribuidorRecomienda = distribuidorSelected;
				this.distribuidor.idDistribuidorRecomienda = this.distribuidorRecomienda.id;
				this.$refs.autocompleteDistribuidorRecomienda.onInitSelection(this.distribuidorRecomienda);
			} else {
				this.distribuidorRecomienda = {};
				this.distribuidor.idDistribuidorRecomienda = '';
			}
		},
		onInitDistribuidorRecomienda: function(idDistribuidor) {
			DistribuidorApi.get(idDistribuidor)
			.then(response => {
				if(response.success === true) {
					this.distribuidorRecomienda = response.data;
					this.$refs.autocompleteDistribuidorRecomienda.onInitSelection(this.distribuidorRecomienda);
				} else {
					this.$notifier.warn('Los datos de distribuidor no se cargaron correctamente. \n' + response.message);
				}
			});
		},
		onUpdatePartner: function(partnerSelected) {
			if(partnerSelected !== null) {
				this.distribuidor.partner = partnerSelected;
				this.distribuidor.idPartner = this.distribuidor.partner.id;
				this.$refs.autocompletePartner.onInitSelection(this.distribuidor.partner);
			} else {
				this.distribuidor.partner = {};
				this.distribuidor.idPartner = '';
			}
		},
		onInitPartner: function(idPartner) {
			PartnerApi.get(idPartner)
			.then(response => {
				if(response.success === true) {
					this.distribuidor.partner = response.data;
					this.$refs.autocompletePartner.onInitSelection(this.distribuidor.partner);
				} else {
					this.$notifier.warn('Los datos de partner no se cargaron correctamente. \n' + response.message);
				}
			});
		},

		//#endregion

		//#region Metodos para actualizar dom por cambio en controles

		onChangePais: function(idPaisSelected, callFromInit = false) {
			EstadoErpApi.getAll(idPaisSelected)
			.then(response => {
				if(response.success === true) {
					this.estados = response.data;
					if(!callFromInit) {
						this.ciudades = [];
						this.colonias = [];
					}
				} else {
					this.$notifier.warn('Los estados no se cargaron correctamente. \n' + response.message);
				}
			});
		},
		onChangeEstado: function(idEstadoSelected, callFromInit = false) {
			CiudadErpApi.getAll(idEstadoSelected)
			.then(response => {
				if(response.success === true) {
					this.ciudades = response.data;
					if(!callFromInit) {
						this.colonias = [];
					}
				} else {
					this.$notifier.warn('Las ciudades no se cargaron correctamente. \n' + response.message);
				}
			});
		},
		onChangeCiudad: function(idCiudadSelected, callFromInit = false) {
			ColoniaErpApi.getAll(idCiudadSelected, this.distribuidor.idEstado)
			.then(response => {
				if(response.success === true) {
					this.colonias = response.data;
				} else {
					this.$notifier.warn('Las colonias no se cargaron correctamente. \n' + response.message);
				}
			});
		},
		onChangeColonia: function(idColoniaSelected, callFromInit = false) {
			ColoniaErpApi.get(idColoniaSelected, this.distribuidor.idCiudad, this.distribuidor.idEstado)
			.then(response => {
				if(response.success === true) {
					if(!callFromInit) {
						this.distribuidor.codigoPostal = response.data.CCP;
					}
				} else {
					this.$notifier.warn('Las colonias no se cargaron correctamente. \n' + response.message);
				}
			});
		},

		//#endregion

		//#region Metodos para dialog de telefono
		
		onOpenDialogTelefono: function() {
			//Abrimos dialog
			this.dialogTelefono = true;
		},
		onCancelDialogTelefono: function() {
			//Cerramos dialog
			this.dialogTelefono = false;
		},
		onSaveDialogTelefono: function(telefonoSaved) {
			//Cerramos dialog
			this.dialogTelefono = false;
			//Agregamos telefono
			if(this.distribuidor.distribuidorTelefonos.findIndex(x => x.telefono === telefonoSaved.telefono) === -1) {
				let distribuidorTelefono = {
					idDistribuidor: this.isEdit ? this.distribuidor.id : 0,
					tipoTelefono: telefonoSaved.tipoTelefono,
					telefono: telefonoSaved.telefono,
					observaciones: telefonoSaved.observaciones
				};
				this.distribuidor.distribuidorTelefonos.push(distribuidorTelefono);
			} else {
				this.$notifier.error('El telefono ya se encuentra agregado.');
			}
		},

		//#endregion

		//#region Metodo para dialog de correo
		
		onOpenDialogCorreo: function() {
			//Abrimos dialog
			this.dialogCorreo = true;
		},
		onCancelDialogCorreo: function() {
			//Cerramos dialog
			this.dialogCorreo = false;
		},
		onSaveDialogCorreo: function(correoSaved) {
			//Cerramos dialog
			this.dialogCorreo = false;
			//Agregamos correo
			if(this.distribuidor.distribuidorCorreos.findIndex(x => x.correoElectronico === correoSaved.correoElectronico) === -1) {
				let distribuidorCorreo = {
					idDistribuidor: this.isEdit ? this.distribuidor.id : 0,
					correoElectronico: correoSaved.correoElectronico,
					observaciones: correoSaved.observaciones,
				};
				this.distribuidor.distribuidorCorreos.push(distribuidorCorreo);
			} else {
				this.$notifier.error('El correo ya se encuentra agregado.');
			}
		},

		//#endregion

		//#region Metodo para eliminacion de correo y telefono
		
		onOpenConfirmQuitTelefono: function(telefono) {
			//Salvamos telefono current(edit/delete)
			this.telefonoCurrent = telefono;
			//Abrimos dialog
			this.dialogConfirmDeleteChild = true;
		},
		onOpenConfirmQuitCorreo: function(correo) {
			//Salvamos corre current(edit/delete)
			this.correoCurrent = correo;
			//Abrimos dialog
			this.dialogConfirmDeleteChild = true;
		},
		onCancelConfirmQuitChild: function() {
			//Limpiamos telefono/correo current(edit/delete)
			this.telefonoCurrent = {};
			this.correoCurrent = {};
			//Cerramos dialog
			this.dialogConfirmDeleteChild = false;
		},
		onOkConfirmQuitChild: function(callBack) {
			callBack();
			//Eliminacion de telefono
			if(Object.keys(this.telefonoCurrent).length !== 0) {
				//Eliminamos de lista
				let indexDeleted = this.distribuidor.distribuidorTelefonos.findIndex(x => x.telefono === this.telefonoCurrent.telefono);
				this.distribuidor.distribuidorTelefonos.splice(indexDeleted, 1);
			}
			//Eliminacion de correo
			else if(Object.keys(this.correoCurrent).length !== 0) {
				//Eliminamos de lista
				let indexDeleted = this.distribuidor.distribuidorCorreos.findIndex(x => x.correoElectronico === this.correoCurrent.correoElectronico);
				this.distribuidor.distribuidorCorreos.splice(indexDeleted, 1);
			}
			
			//Limpiamos telefono/correo current(edit/delete)
			this.telefonoCurrent = {};
			this.correoCurrent = {};
			//Cerramos dialog
			this.dialogConfirmDeleteChild = false;
		},

		//#endregion

		//#region Metodos de dialogs de saldos
		
		onOpenDialogSaldos: function() {
			//Abrimos dialog
			this.dialogSaldos = true;
		},
		onCloseDialogSaldos: function() {
			//Cerramos dialog
			this.dialogSaldos = false;
		},

		onOpenDialogSaldosLicenciaObsequio: function() {
			//Abrimos dialog
			this.dialogSaldosLicenciaObsequio = true;
		},
		onCloseDialogSaldosLicenciaObsequio: function() {
			//Cerramos dialog
			this.dialogSaldosLicenciaObsequio = false;
		},

		//#region Metodo de acciones de card
		
		onCancel: function() {
			this.$router.push('/distribuidor');
		},
		onSubmit: async function() {
			//Prevent multiple guardado
			if(!this.isSaving) {
				this.isSaving = true;
				const isValid = await this.$refs.observer.validate();
				if(isValid) {
					//Copiamos deep objeto para ajustarlo antes de enviarlo
					let distribuidorSaved = JSON.parse(JSON.stringify(this.distribuidor));
					//Nuleamos objetos
					distribuidorSaved.estatusDatoMaestro = null;
					distribuidorSaved.partner = null;
					distribuidorSaved.distribuidorRecomienda = null;
					if(!this.isEdit) {
						this.$emit('onSubmit', distribuidorSaved, () => {
							this.isSaving = false;
						});
					} else {
						this.$emit('onSubmit', distribuidorSaved, () => {
							this.isSaving = false;
						});
					}
				} else {
					this.isSaving = false;
					this.$notifier.error('Llene todos los campos marcados como obligatorios, compruebe su información y vuelva a intentar.');
				}
			}
		}

		//#endregion
	}
}
</script>