<template>
<div>
	<app-header-page :titulos="titleItems"></app-header-page>
	<licencia-renew-card 
		:mode="mode" 
		:idLicencia="idLicencia"
		:claveProducto="claveProducto"
		@onSubmit="onSubmit">
	</licencia-renew-card>
	<!--Dialog de exito-->
	<licencia-success-dialog 
		:dialog="dialogSuccess"
		:datos="licencia"
		@onClose="onCloseDialogSuccess">
	</licencia-success-dialog>
</div>
</template>
<script>
import AppHeaderPage from '../components/AppHeaderPage';
import LicenciaRenewCard from '../components/LicenciaRenewCard';
import LicenciaSuccessDialog from '../components/LicenciaSuccessDialog';

import LicenciaApi from '../services/api/licencia.api';
import { ViewMode } from '../utils/constants';

export default {
	components: {
		AppHeaderPage,
		LicenciaRenewCard,
		LicenciaSuccessDialog
	},
  data: () => ({
		titleItems: ['Licencia', 'Renovar'],
		mode: ViewMode.CREATE,
		idLicencia: 0,
		claveProducto: '',
		dialogSuccess: false,
		licencia: {},
	}),
	created: function() {
		this.idLicencia = Number(this.$route.params.idLicencia);
		this.claveProducto = this.$route.params.claveProducto;
	},
	watch: {
		dialogSuccess :{
			handler(newVal, oldVal) {
				if(!newVal) {
					this.$router.push('/');
					this.$notifier.success('Licencia renovada exitosamente.');
				}
			},
			deep: true
		},
	},
	methods: {
		onSubmit: function(datos, callBack) {
			LicenciaApi.renovation(datos)
			.then(response => {
				callBack();
				if(response.success === true) {
					this.licencia = response.data;
					this.dialogSuccess = true;
				} else {
					this.$notifier.error(response.message);
				}
			});
		},
		onCloseDialogSuccess: function() {
			//Cerramos dialog
			this.dialogSuccess = false;
		},
	}
  }
</script>