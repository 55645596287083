<template>
<v-dialog v-model="dialog"
	persistent
	max-width="500">
	<validation-observer ref="observerCorreo">
		<v-form @submit.prevent="">
			<v-card>
				<v-card-title class="py-2">
					<span class="text-button grey--text text--darken-3">CUENTA DE CORREO</span>
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text>
					<v-row>
						<v-col cols="12">
							<div class="text--secondary ml-1">Correo electronico*</div>
							<validation-provider v-slot="{errors}" 
								name="correo electronico" 
								rules="required|email">
								<v-text-field v-model="correoElectronico" 
								outlined  
								hide-details="auto"
								:error-messages="errors">
								</v-text-field>
							</validation-provider>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn small 
					color="default"
					@click="onCancel">
						Cancelar
					</v-btn>
					<v-btn small 
					color="primary"
					@click="onSave">
						Guardar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</validation-observer>
</v-dialog>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
	props: ['dialog'],
	components: {
		ValidationProvider,
		ValidationObserver
	},
    data: () => ({
		correoElectronico: '',
		isSaving: false,
	}),
	methods: {

		//#region Metodos de acciones de card

		getDataToSave: function() {
			return {
				correoElectronico: this.correoElectronico
			};
		},
		onClear: function() {
			this.correoElectronico = '';
			this.$refs.observerCorreo.reset();
		},
		onCancel: function() {
			this.$emit('onCancel');
			this.onClear();
		},
		onSave: async function() {
			const isValid = await this.$refs.observerCorreo.validate();
			if(isValid) {
				this.$emit('onSave', this.getDataToSave());
				this.onClear();
			}
		}

		//#endregion
	}
  }
</script>