<template>
<v-dialog 
	v-model="dialog"
	persistent
	scrollable
	max-width="400">
	<spinner-loading :overlay="isLoading"/>
	<v-card class="pb-3">
		<v-card-title class="pt-2 pb-0">
			<span class="text-button grey--text text--darken-3">Selecciona un periodo.</span>
		</v-card-title>
		<v-card-text v-if="!isLoading">
			<v-row>
				<v-col cols="12" sm="4" md="3">
					<v-radio-group v-model="clavePeriodoSelected">
						<v-radio
							v-for="periodo in periodos"
							:key="periodo.clave"
							:label="periodo.descripcion"
							:value="periodo.clave">
						</v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn 
				small 
				color="default"
				@click="onClose">
				Cerrar
			</v-btn>
			<v-btn 
				small 
				color="primary"
				@click="onSelect">
				Elegir
			</v-btn>
		</v-card-actions>
	</v-card>
</v-dialog>
</template>
<script>
import SpinnerLoading from '../components/SpinnerLoading';

import PrecioApi from '../services/api/precio.api';

export default {
	props: {
		dialog: Boolean,
		claveProducto: String
	},
  data: () => ({
		isLoading: true,
		periodos: [],
		clavePeriodoSelected: '',
	}),
	components: {
		SpinnerLoading,
	},
	watch: {
		dialog :{
			handler(newVal, oldVal) {
				if(newVal) {
					this.onLoaded();
				}
			},
			deep: true
		},
	},
	methods: {
		onLoaded: async function() {
			await PrecioApi.getPeriodosByProducto(this.claveProducto)
			.then(response => {
				this.isLoading = false;
				if(response.success === true) {
					this.periodos = response.data;
					this.clavePeriodoSelected = this.periodos[0].clave;
				} else {
					this.$notifier.error(response.message);
				}
			});
		},
		onSelect: function() {
			this.$emit('onSelect', this.clavePeriodoSelected);
		},
		onClose: function() {
			this.$emit('onClose');
		},
	}
}
</script>