<template>
<div>
		<v-card 
			class="px-5" 
			rounded="lg"
		>
			<v-card-text>
				<v-row class="mt-5 mb-3">
					<v-col 
						cols="12" 
						class="d-flex flex-column align-center justify-center"
					>
						<v-img 
							src="images/brand/pos_pos.png" 
							contain
							width="250"
						/>
					</v-col>
				</v-row>
				<v-row>
					<v-col 
						cols="12" 
						class="text-center"
					>
						<strong class="grey--text text--darken-3 text-h6 font-weight-light">El control de su negocio en la palma de su mano.</strong>
					</v-col>
				</v-row>
				<v-divider class="my-5"></v-divider>
				<v-row>
					<v-col 
						cols="12"
						sm="12"
						md="5"
						lg="5"
					>
						<p class="grey--text text--darken-3 text-subtitle-1 font-weight-medium">Modulos</p>
						<ul>
							<li class="grey--text text--darken-3 font-weight-light">Datos maestros.</li>
							<li class="grey--text text--darken-3 font-weight-light">Compras.</li>
							<li class="grey--text text--darken-3 font-weight-light">Ventas.</li>
							<li class="grey--text text--darken-3 font-weight-light">Inventario.</li>
							<li class="grey--text text--darken-3 font-weight-light">Manejo de turno.</li>
						</ul>
					</v-col>
					<v-col 
						cols="12"
						sm="12"
						md="6"
						lg="6"
					>
						<p class="grey--text text--darken-3 text-subtitle-1 font-weight-medium">Capacidades</p>
						<ul>
							<li class="grey--text text--darken-3 font-weight-light">Soporte S.O. Android.</li>
							<li class="grey--text text--darken-3 font-weight-light">Soporte lector de codigo de barras.</li>
							<li class="grey--text text--darken-3 font-weight-light">Impresión de ticket de venta.</li>
							<li class="grey--text text--darken-3 font-weight-light">Soporte camara fotográfica.</li>
							<li class="grey--text text--darken-3 font-weight-light">Envío de reportes por Whatsapp, Correo electrónico, Bluetooth, etc.</li>
						</ul>
					</v-col>
				</v-row>
				<v-row>
					<v-col 
						cols="12"
						class="mt-5"
					>
						<strong class="grey--text text--darken-3 text-h6 font-weight-regular">Versiones</strong>
					</v-col>
				</v-row>
				<v-row>
					<v-col 
						v-if="!renew"
						cols="12" 
						sm="6"
						md="4"
						lg="4"
					>
						<v-card 
							elevation="5" 
							color="background"
							height="400">
							<v-card-text class="py-5">
								<div class=" text-center pt-5">
									<p class="grey--text text--darken-3 text-h6 font-weight-medium">GRATIS</p>
									<p class="grey--text text--darken-3 font-weight-light">Funcionalidades esenciales para operar tu negocio.</p>
								</div>
								<v-btn 
									block
									large
									elevation="0"
									color="primary" 
									class="my-5"
									@click="onSelectFree()"
								>
									Elegir
								</v-btn>
								<ul class="py-5">
									<li class="grey--text text--darken-3 font-weight-light">Gestión básica de datos maestros.</li>
									<li class="grey--text text--darken-3 font-weight-light">Registro de ventas.</li>
									<li class="grey--text text--darken-3 font-weight-light">Manejo de turo.</li>
									<li class="grey--text text--darken-3 font-weight-light">Reportes de ventas.</li>
								</ul>
							</v-card-text>
						</v-card>
					</v-col>
					<v-col 
						cols="12" 
						sm="6"
						md="4"
						lg="4"
					>
						<v-card 
							elevation="5" 
							color="background"
							height="400">
							<v-card-text class="py-5">
								<div class=" text-center pt-5">
									<p class="grey--text text--darken-3 text-h6 font-weight-medium">COMPLETA</p>
									<p class="grey--text text--darken-3 font-weight-light">Funcionalidad completa para profesionalizar tu negocio.</p>
								</div>
								<v-btn 
									block
									large
									elevation="0"
									color="primary" 
									class="my-5"
									@click="onOpenDialogPeriodos(POSPOS)"
								>
									Elegir
								</v-btn>
								<ul class="py-5">
									<li class="grey--text text--darken-3 font-weight-light">Gestión completa de datos maestros.</li>
									<li class="grey--text text--darken-3 font-weight-light">Registro de compras y ventas.</li>
									<li class="grey--text text--darken-3 font-weight-light">Control de inventario.</li>
								</ul>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
		<periodo-activacion-dialog
			:dialog="dialogPeriodos"
			:claveProducto="claveProducto"
			@onSelect="onSelectPeriodos"
			@onClose="onClosePeriodos"/>
</div>
</template>
<script>
import PeriodoActivacionDialog from '../components/PeriodoActivacionDialog.vue';
import { Producto } from '../utils/constants';

export default {
  props: {
    product: String,
    renew: Boolean
  },
	components: {
		PeriodoActivacionDialog
	},
	data: () => ({
		dialogPeriodos: false,
		claveProducto: '',
	}),
	computed: {
		POSPOS: function() {
			return Producto.POSPOS;
		}
	},
	methods: {
		onOpenDialogPeriodos: function(claveProductoSelected) {
			this.claveProducto = claveProductoSelected;
			this.dialogPeriodos = true;
		},
		onClosePeriodos: function() {
			this.claveProducto = '';
			this.dialogPeriodos = false;
		},
		onSelectPeriodos: function(clavePeriodoSelected) {
			this.dialogPeriodos = false;
			this.onSelect(this.claveProducto, false, clavePeriodoSelected);
		},
		onSelectFree: function() {
			this.onSelect(Producto.POSPOS, true, null);
		},
		onSelect: function(claveProductoSelected, esGratis, clavePeriodo) {
      this.$emit('onSelect', claveProductoSelected, esGratis, clavePeriodo);
		}
	}
}
</script>