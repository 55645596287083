import httpClient from './httpClient';
import httpUtils from '../httpUtils';

export default {
    getVersion() {
        return httpClient.get('/Version')
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
}