<template>
<div>
	<app-header-page :titulos="titleItems"></app-header-page>
	<validation-observer ref="observer">
		<v-form @submit.prevent="">
			<spinner-loading :overlay="isLoading"/>
			<v-card 
				class="mx-4" 
				rounded="lg"
			>
				<v-card-title class="py-4">
					<span class="font-weight-regular">Datos</span>
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12">
							<div class="text--secondary ml-1">Rol de Partner*</div>
							<validation-provider 
								v-slot="{errors}" 
								name="rol partner" 
								rules="required"
							>
								<v-autocomplete 
									v-model="parametros.rolPartner" 
									:items="roles"
									outlined
									hide-details="auto"
									item-value="clave"
									:item-text="onRolToString"
									return-object
									:error-messages="errors"
								>
								</v-autocomplete>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<div class="text--secondary ml-1">Rol de Distribuidor*</div>
							<validation-provider 
								v-slot="{errors}" 
								name="rol distribuidor" 
								rules="required"
							>
								<v-autocomplete 
									v-model="parametros.rolDistribuidor" 
									:items="roles"
									outlined
									hide-details="auto"
									item-value="clave"
									:item-text="onRolToString"
									return-object
									:error-messages="errors"
								>
								</v-autocomplete>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<div class="text--secondary ml-1">Distribuidor de Empresa</div>
							<distribuidor-autocomplete 
								ref="autocompleteDistribuidor" 
								:selected="distribuidor"
								@onChange="onUpdateDistribuidor"
							>	
							</distribuidor-autocomplete>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider class="mx-4 mt-5"></v-divider>
				<v-card-actions>
					<v-row>
						<v-col 
							cols="12" 
							class="text-right"
						>
							<v-btn 
								@click="onCancel"
								color="default"
								:loading="isSaving"
							>
								Cancelar
							</v-btn>
							<v-btn 
								@click="onSubmit"
								color="primary" 
								:disabled="isLoading"
								:loading="isSaving"
								class="ml-2"
							>
								Guardar
							</v-btn>
						</v-col>
					</v-row>
				</v-card-actions>
			</v-card>
			<v-row>
				<v-col 
					cols="12" 
					class="ml-5"
				>
					<app-users-audit 
						v-if="isEdit" 
						:creador="parametros.createdBy"
						:fechaCreacion="parametros.createdAt"
						:modificador="parametros.updatedBy"
						:fechaModificacion="parametros.updatedAt"
					>
					</app-users-audit>
				</v-col>
			</v-row>
		</v-form>
	</validation-observer>
</div>
</template>
<script>
import SpinnerLoading from '../components/SpinnerLoading';
import AppHeaderPage from '../components/AppHeaderPage';
import AppUsersAudit from '../components/AppUsersAudit';
import DistribuidorAutocomplete from '../components/DistribuidorAutocomplete';

import ParametrosGeneralesApi from '../services/api/parametrosGenerales.api';
import RolApi from '../services/api/rol.api';
import DistribuidorApi from '../services/api/distribuidor.api';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { ViewMode } from '../utils/constants';
import ObjectToStringMixin from '../mixins/objectToStringMixin';

export default {
	components: {
		SpinnerLoading,
		AppHeaderPage,
		ValidationProvider,
		ValidationObserver,
		AppUsersAudit,
		DistribuidorAutocomplete
	},
	mixins: [ObjectToStringMixin],
	created: function(){
		this.onInit();
	},
  data: () => ({
		titleItems: ['Parametros generales', 'Editar'],
		mode: ViewMode.CREATE,
		isLoading: true,
		parametros: {},
		roles: [],
		distribuidor: {},
		isSaving: false,
	}),
	computed: {
		isEdit: function() {
			return this.mode === ViewMode.EDIT;
		},
		loadedDistribuidor: function() {
			return Object.keys(this.distribuidor).length !== 0;
		},
	},
	methods: {
		onInit: async function() {
			this.isLoading = true;

			await RolApi.getAll()
			.then(response => {
				if(response.success === true) {
					this.roles = response.data;
				} else {
					this.$notifier.warn('Los roles no se cargaron correctamente. \n' + response.message);
				}
			});

			ParametrosGeneralesApi.get()
			.then(response => {
				this.isLoading = false;
				if(response.success === true) {
					if(response.data === null) {
						this.mode = ViewMode.CREATE;
						this.parametros = {
							id: 0,
							claveRolPartner: '',
							claveRolDistribuidor: '',
							idDistribuidor: '',
							observaciones: ''
						};
					} else {
						this.mode = ViewMode.EDIT;
						this.parametros = response.data;
						
						//Actualizamos valor onDemand
						if(this.parametros.idDistribuidorEmpresa)
							this.onInitDistribuidor(this.parametros.idDistribuidorEmpresa);
					}
				} else {
					this.$router.push('/');
					this.$notifier.error(response.message);
				}
			});
		},

		//#region Metodos de formateo objetos

		onRolToString: function(item) {
			return this.objectToString(item.clave, item.descripcion);
		},

		//#endregion

		//#region Metodos para actualizar propiedades

		onUpdateDistribuidor: function(distribuidorSelected) {
			if(distribuidorSelected !== null) {
				this.distribuidor = distribuidorSelected;
				this.parametros.idDistribuidorEmpresa = this.distribuidor.id;
				this.$refs.autocompleteDistribuidor.onInitSelection(this.distribuidor);
			} else {
				this.distribuidor = {};
				this.parametros.idDistribuidorEmpresa = '';
			}
		},
		onInitDistribuidor: function(idDistribuidor) {
			DistribuidorApi.get(idDistribuidor)
			.then(response => {
				if(response.success === true) {
					this.distribuidor = response.data;
					this.$refs.autocompleteDistribuidor.onInitSelection(this.distribuidor);
				} else {
					this.$notifier.warn('Los datos de distribuidor no se cargaron correctamente. \n' + response.message);
				}
			});
		},

		//#endregion

		//#region Metodos de acciones de card

		onCancel: function() {
			this.$router.push('/');
		},
		onSubmit: async function() {
			//Prevent multiple guardado
			if(!this.isSaving) {
				this.isSaving = true;
				const isValid = await this.$refs.observer.validate();
				if(isValid) {
					//Copiamos deep objeto para ajustarlo antes de enviarlo
					let parametrosSaved = JSON.parse(JSON.stringify(this.parametros));
					parametrosSaved.claveRolPartner = this.parametros.rolPartner.clave;
					parametrosSaved.claveRolDistribuidor = this.parametros.rolDistribuidor.clave;
					parametrosSaved.rolPartner = null;
					parametrosSaved.rolDistribuidor = null;
					parametrosSaved.distribuidorEmpresa = null;
					if(!this.isEdit) {
						ParametrosGeneralesApi.add(parametrosSaved)
						.then(response => {
							this.isSaving = false;
							if(response.success === true) {
								this.$router.push('/');
								this.$notifier.success('Datos editados exitosamente.');
							} else {
								this.$notifier.error(response.message);
							}
						});
					} else {
						ParametrosGeneralesApi.update(parametrosSaved)
						.then(response => {
							this.isSaving = false;
							if(response.success === true) {
								this.$router.push('/');
								this.$notifier.success('Datos editados exitosamente.');
							} else {
								this.$notifier.error(response.message);
							}
						});
					}
				} else {
					this.isSaving = false;
				}
			}
		}

		//#endregion
	}
  }
</script>