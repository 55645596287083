<template>
  <v-dialog 
    v-model="dialog" 
    persistent
		eager
    max-width="400">
		<v-card>
			<v-card-text class="pt-5 pb-1 text-center">
				<v-row class="mt-0 mb-2">
					<v-col cols="12">
						<div class="text--secondary">Total a Pagar</div>
						<span class="grey--text text--darken-3 text-h5">{{montoPago | currencyParse(2)}} {{claveMoneda}}</span>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-title class="pt-0 pb-2">
				<span class="text-button grey--text text--darken-3">Opciones de pago</span>
			</v-card-title>
			<v-card-text>
				<v-row>
					<v-col cols="12" class="my-1">
						<v-btn
              block
              elevation="0"
              x-large
							@click="onSelectedTarjeta"
            >
              Tarjeta de Crédito o Debito
            </v-btn>
					</v-col>
				</v-row>		
        <v-row>
					<v-col cols="12" class="my-1">
						<v-btn
              block
              elevation="0"
              x-large
							@click="onSelectedOxxo"
            >
              Pago con 
							<v-img 
								src="images/brand/oxxo.png"
								contain 
								width="40" 
								height="30" 
								max-width="90"
							/>
            </v-btn>
					</v-col>
				</v-row>	
				<v-row>
					<v-col cols="12" class="my-1">
						<div id="paypal-button-container"></div>
					</v-col>
				</v-row>					
			</v-card-text>
			<v-card-actions class="py-3">
				<v-spacer></v-spacer>
				<v-btn small 
				color="default"
				@click="onClose">
					Cerrar
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
import { loadScript } from "@paypal/paypal-js";

import PagoPaypalApi from '../services/api/pagoPaypal.api';
import { OpcionPago } from '../utils/constants';

export default {
  props: {
    dialog: Boolean,
		claveProducto: String,
		claveMoneda: String,
		clavePeriodoPago: String,
		montoPago: Number,
		idDispositivo: String
  },
	watch: {
		dialog :{
			handler(newVal, oldVal) {
				if(newVal) {
					loadScript({ 
						'client-id': process.env.VUE_APP_PAYPAL_CLIENT_ID,
						'disable-funding': 'card,mercadopago',
						'currency': this.claveMoneda
					})
					.then((paypal) => {
						let item = document.querySelector('#paypal-button-container');
						while (item.firstChild) {
							item.removeChild(item.firstChild)
						}

						paypal
						.Buttons({
							// Order is created on the server and the order id is returned
							createOrder: (data, actions) => this.onPaypalCreateOrder(),
							// Finalize the transaction on the server after payer approval
							onApprove: (data, actions) => this.onPaypalCapturePayment(data.orderID),
							// Cancel by buyer
							onCancel: (data) => this.onPaypalCancelPayment(),
							// Error
							onError: (err) => this.onPaypalErrorPayment(err)
						})
            .render('#paypal-button-container');
					});
				}
			},
			deep: true
		},
	},
  methods: {
		// Version Paypal SDK tag script
		/*onIncludePaypal(URL, callback) {
			const el = document.querySelector(`script[src="${URL}"]`);
      if(!el) {
				const s = document.createElement('script');
        s.setAttribute('src', URL); s.onload = callback;
        document.head.insertBefore(s, document.head.firstElementChild);
      }
    },*/
		onClose: function() {
      //Cerramos dialog
			this.$emit('onClose', false);
		},
		onSelectedTarjeta: function() {
			this.$emit('onSelected', OpcionPago.TARJETA);
		},
    onSelectedOxxo: function() {
			this.$emit('onSelected', OpcionPago.OXXO);
		},

		onPaypalCreateOrder: function() {
			let orderPaypal = {
				claveProducto: this.claveProducto,
				claveMoneda: this.claveMoneda,
				clavePeriodoPago: this.clavePeriodoPago,
				idDispositivo: this.idDispositivo
			}
			return PagoPaypalApi.createOrder(orderPaypal)
			.then(response => {
				if(response.success === true) {
					// Cerramos dialog
					this.$emit('onClose', true);
					//Retornamos id orden
					let order = response.data;
					return order.id;
				} else {
					this.$notifier.error(response.message);
					return null;
				}
			});
		},
		onPaypalCapturePayment: function(orderId) {
			this.$emit('onHandlePaypalCapturePayment', orderId);
		},
		onPaypalCancelPayment: function() {
			this.$emit('onHandlePaypalCancelPayment');
		},
		onPaypalErrorPayment: function(err) {
			this.$emit('onHandlePaypalErrorPayment', err);
		}
	}
}
</script>