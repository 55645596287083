<template>
	<v-row align="center" justify="center">
		<v-col v-if="!esPagado" cols="12" sm="10" md="8" lg="6">
			<div v-if="!loadedPagoOxxo">
				<!--Busqueda de pago oxxo-->
				<pago-oxxo-search-card 
					@onSearch="onSearchPagoOxxo"
				/>
			</div>
			<div v-else>
				<!--Informacion de pago oxxo-->
				<pago-oxxo-card 
					:pagoOxxo="pagoOxxo"
					@onBack="onBackSearch"
					@onReport="onReportPagoOxxo"
				/>
			</div>
		</v-col>
		<v-col v-else cols="12" sm="10" md="8" lg="6">
			<licencia-cliente-success-card
				:idLicencia="licencia.idLicencia"
				:claveActivacion="licencia.claveActivacion"
				:cadenaActivacion="licencia.cadenaActivacion"
				:descripcionProducto="pagoOxxo.producto.descripcion"
				:esGratis="false"
				:precio="pagoOxxo.precioProducto"
				:claveMoneda="pagoOxxo.claveMoneda"
				:descripcionPeriodo="pagoOxxo.periodoPago.descripcion"
				:codigoReferencia="pagoOxxo.distribuidor.codigoReferencia"
				:nombreEmpresa="pagoOxxo.nombreEmpresa"
				:idDispositivo="pagoOxxo.idDispositivo"
				:telefono="pagoOxxo.telefono"
				:correo="pagoOxxo.correo"
				:mensajeEnvioCorreo="mensajeEnvioCorreo"
				@onCancel="onCancel"
			/>
		</v-col>
	</v-row>
</template>
<script>
import PagoOxxoSearchCard from '../components/PagoOxxoSearchCard';
import PagoOxxoCard from '../components/PagoOxxoCard';
import LicenciaClienteSuccessCard from '../components/LicenciaClienteSuccessCard';

import PagoOxxoApi from '../services/api/pagoOxxo.api';

export default {
	components: {
		PagoOxxoSearchCard,
		PagoOxxoCard,
		LicenciaClienteSuccessCard,
	},
  data: () => ({
		pagoOxxo: {},
		loadedPagoOxxo: false,
		mensajeEnvioCorreo: '',
		
		esPagado: false,
		licencia: {},
	}),
	methods: {
		onCancel: function() {
			window.location.href = 'http://pospos.com.mx';
		},
		onSearchPagoOxxo: function(clavePagoOxxo, callback) {
			PagoOxxoApi.get(clavePagoOxxo)
			.then(response => {
				if(response.success === true) {
					this.pagoOxxo = response.data;
					this.loadedPagoOxxo = true;
				} else {
					callback();
					this.$notifier.error(response.message, 5000);
				}
			});
		},
		onBackSearch: function() {
			this.pagoOxxo = {};
			this.loadedPagoOxxo = false;
		},
		onReportPagoOxxo: function(callback) {
			PagoOxxoApi.report(this.pagoOxxo.id)
			.then(response => {
				callback();
				if(response.success === true) {
					// Obtenemos valores de licencia
					this.licencia = response.data;
					this.mensajeEnvioCorreo = response.message || '';
					this.esPagado = true;
				} else {
					this.$notifier.error(response.message);
				}
			});
		}

	}
}
</script>