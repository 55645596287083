<template>
<div>
	<app-header-page :titulos="titleItems"></app-header-page>
	<partner-card :mode="mode" 
	@onSubmit="onSubmit">
	</partner-card>
</div>
</template>
<script>
import AppHeaderPage from '../components/AppHeaderPage';
import PartnerCard from '../components/PartnerCard';
import PartnerApi from '../services/api/partner.api';
import { ViewMode } from '../utils/constants';

export default {
	components: {
		AppHeaderPage,
		PartnerCard
	},
    data: () => ({
		titleItems: ['Partner', 'Agregar'],
		mode: ViewMode.CREATE,
	}),
	methods: {
		onSubmit: function(partnerSaved, callBack) {
			PartnerApi.add(partnerSaved)
			.then(response => {
				callBack();
				if(response.success === true) {
					this.$router.push('/partner');
					this.$notifier.success('Partner agregado exitosamente.');
				} else {
					this.$notifier.error(response.message);
				}
			});
		}
	}
  }
</script>