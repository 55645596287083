<template>
<v-row align="center" justify="center">
	<v-col cols="12" sm="8" md="6" lg="4">
		<licencia-search-card
			:inAppStack="false"
			title="Pago de Crédito"
			instructions="Ingresa los datos de la licencia a pagar"
			@onSubmit="onSubmit">
		</licencia-search-card>
	</v-col>
</v-row>
</template>
<script>
import AuthTypes from '../store/types/AuthTypes';
import LicenciaSearchCard from '../components/LicenciaSearchCard'

export default {
	components: {
		LicenciaSearchCard
	},
	created: function() {
		if(!this.$store.getters[AuthTypes.nameModule + '/' + AuthTypes.getters.IS_AUTHENTICATED])
			this.$store.commit(AuthTypes.nameModule + '/' + AuthTypes.mutations.SET_LAYOUT, 'login-layout');
		else
			this.$router.push('/');
	},
	methods: {
		onSubmit: function(idLicencia, claveProducto) {
			this.$router.push('/cliente/' + idLicencia + '/' + claveProducto + '/pay-license-credit');
		}
	}
}
</script>