<template>
<div>
	<validation-observer ref="observer">
		<v-form @submit.prevent="">
			<spinner-loading :overlay="isLoading"/>
			<v-card class="mx-4" 
			rounded="lg">
				<v-card-title class="py-4">
					<span class="font-weight-regular">Datos</span>
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12" sm="4" md="3">
							<div class="text--secondary ml-1">Clave*</div>
							<validation-provider v-slot="{errors}" 
								name="clave" 
								rules="required">
								<v-text-field v-model="rol.clave" 
								autofocus
								outlined  
								hide-details="auto"
								@input="rol.clave = $utilsInput.uppercase(rol.clave)"
								@keypress="$utilsInput.alphanumericDash($event)"
								:readonly="isEdit"
								:error-messages="errors">
								</v-text-field>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<div class="text--secondary ml-1">Descripción*</div>
							<validation-provider v-slot="{errors}" 
								name="descripcion" 
								rules="required">
								<v-text-field v-model="rol.descripcion" 
								outlined  
								hide-details="auto"
								@input="rol.descripcion = $utilsInput.uppercase(rol.descripcion)"
								:error-messages="errors">
								</v-text-field>
							</validation-provider>
						</v-col>
					</v-row>
					<v-card class="mt-5 mb-2" elevation="1">
						<v-tabs v-model="tabs"
						background-color="tab">
							<v-tab>Funciones</v-tab>
							<v-tab>Observaciones</v-tab>
						</v-tabs>
					</v-card>
					<v-tabs-items v-model="tabs">
						<v-tab-item class="px-1 py-1">
							<v-row class="mb-1">
								<v-col cols="12" sm="4" md="3">
									<div class="text--secondary ml-1">Buscar</div>
									<v-text-field v-model="tbSearchFunciones"
									outlined
									hide-details
									append-icon="mdi-magnify">
									</v-text-field>
								</v-col>
								<v-col cols="12" sm="3" md="3" offset-sm="5" offset-md="6" 
								class="d-flex flex-row-reverse">
									<v-btn color="secondary"
									small
									class="mb-4"
									@click="onOpenDialogFuncion">
										Agregar
									</v-btn>
								</v-col>
							</v-row>
							<v-data-table :headers="tbHeaderFunciones"
							:items="rol.rolFunciones"
							:footer-props="tbFooterPropsFunciones"
							no-data-text="Sin registros"
							:search="tbSearchFunciones"
							class="elevation-1">
								<template v-slot:item.acciones="{item}">
									<v-btn icon
									color="gray"
									@click="onOpenConfirmQuitFuncion(item)">
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</template>
							</v-data-table>	
						</v-tab-item>
						<v-tab-item class="px-1 py-1">
							<v-row>
								<v-col cols="12">
									<v-textarea v-model="rol.observaciones"
									outlined
									hide-details="auto"
									rows="5"
									@input="rol.observaciones = $utilsInput.uppercase(rol.observaciones)">
									</v-textarea>
								</v-col>
							</v-row>
						</v-tab-item>
					</v-tabs-items>
				</v-card-text>
				<v-divider class="mx-4 mt-5"></v-divider>
				<v-card-actions>
					<v-row>
						<v-col cols="12" class="text-right">
							<v-btn @click="onCancel"
							color="default"
							:loading="isSaving">
								Cancelar
							</v-btn>
							<v-btn @click="onSave"
							color="primary" 
							:disabled="isLoading"
							:loading="isSaving"
							class="ml-2">
								Guardar
							</v-btn>
							<v-btn v-if="isEdit"
							@click="onSaveUpdate"
							color="primary" 
							:disabled="isLoading"
							:loading="isSaving"
							class="ml-2">
								Guardar y actualizar
							</v-btn>
						</v-col>
					</v-row>
				</v-card-actions>
			</v-card>
			<v-row>
				<v-col cols="12" class="ml-5">
					<app-users-audit v-if="isEdit" 
					:creador="rol.createdBy"
					:fechaCreacion="rol.createdAt"
					:modificador="rol.updatedBy"
					:fechaModificacion="rol.updatedAt">
					</app-users-audit>
				</v-col>
			</v-row>
		</v-form>
	</validation-observer>
	<!--Dialog de funcion-->
	<funcion-dialog :dialog="dialogFuncion" 
	:funciones="funciones"
	@onCancel="onCancelDialogFuncion"
	@onSave="onSaveDialogFuncion">
	</funcion-dialog>
	<!--Dialog de confirmacion eliminacion-->
	<app-dialog-confirm :dialog-confirm="dialogConfirmDeleteFuncion"
	title="ELIMINAR"
	@onCancel="onCancelConfirmQuitFuncion"
	@onOk="onOkConfirmQuitFuncion">
	¿Seguro que desea quitar de la lista el registro?
	</app-dialog-confirm>
</div>
</template>
<script>
import SpinnerLoading from '../components/SpinnerLoading';
import AppDialogConfirm from './AppDialogConfirm';
import AppUsersAudit from './AppUsersAudit';
import FuncionDialog from './FuncionDialog';

import RolApi from '../services/api/rol.api';
import FuncionApi from '../services/api/funcion.api';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { ViewMode } from '../utils/constants';

export default {
	props: {
		mode: Number
	},
	components: {
		SpinnerLoading,
		ValidationProvider,
		ValidationObserver,
		AppUsersAudit,
		FuncionDialog,
		AppDialogConfirm,
	},
	created: function() {
		this.onInit();
	},
    data: () => ({
		isLoading: true,
		rol: {},
		funciones: [],

		tabs: null,
		isSaving: false,
		actualizarUsuarios: true,
		tbSearchFunciones: '',
		tbHeaderFunciones: [
			{ text: 'Clave', value: 'funcion.clave' },
			{ text: 'Descripcion', value: 'funcion.descripcion' },
			{ text: 'Acciones', value: 'acciones', sortable: false, filterable: false }
		],
		tbFooterPropsFunciones: {
			'items-per-page-text': 'Mostrar',
		},
		dialogFuncion: false,
		dialogConfirmDeleteFuncion: false,
		funcionCurrent: {},
	}),
	computed: {
		isEdit: function() {
			return this.mode === ViewMode.EDIT;
		}
	},
	methods: {
		onInit: async function() {
			this.isLoading = true;
			
			await FuncionApi.getAll()
			.then(response => {
				if(response.success === true) {
					this.funciones = response.data;
				} else {
					this.$notifier.warn('Las funciones no se cargaron correctamente. \n' + response.message);
				}
			});
			if(this.isEdit) {
				await RolApi.get(this.$route.params.clave)
				.then(response => {
					this.isLoading = false;
					if(response.success === true) {
						this.rol = response.data;
					} else {
						this.$router.push('/rol');
						this.$notifier.error(response.message);
					}
				});
			} else {
				this.isLoading = false;
				this.rol = {
					clave: '',
					descripcion: '',
					observaciones: '',
					rolFunciones: [/*{
						claveRol: '',
						claveFuncion: '',
						funcion: {
							clave: '',
							descripcion: '',
						},
						observaciones: ''
					}*/]
				};
			}
		},

		//#region Metodos para dialog de funciones

		onOpenDialogFuncion: function() {
			//Abrimos dialog
			this.dialogFuncion = true;
		},
		onCancelDialogFuncion: function() {
			//Cerramos dialog
			this.dialogFuncion = false;
		},
		onSaveDialogFuncion: function(funcionParsed) {
			//Cerramos dialog
			this.dialogFuncion = false;
			//Agregamos funcion
			if(this.rol.rolFunciones.findIndex(x => x.claveFuncion === funcionParsed.claveFuncion) === -1) {
				this.rol.rolFunciones.push({
						claveRol: this.isEdit ? this.rol.clave : '',
						claveFuncion: funcionParsed.claveFuncion,
						funcion: funcionParsed.funcion,
						observaciones: null,
					});
			} else {
				this.$notifier.error('La función ya se encuentra agregada.');
			}
		},

		//#endregion

		//#region Metodos para eliminacion de funciones

		onOpenConfirmQuitFuncion: function(funcion) {
			//Salvamos funcion current(edit/delete)
			this.funcionCurrent = funcion;
			//Abrimos dialog
			this.dialogConfirmDeleteFuncion = true;
		},
		onCancelConfirmQuitFuncion: function() {
			//Limpiamos funcion current(edit/delete)
			this.funcionCurrent = {};
			//Cerramos dialog
			this.dialogConfirmDeleteFuncion = false;
		},
		onOkConfirmQuitFuncion: function(callBack) {
			callBack();
			//Eliminamos de lista
			let indexDeleted = this.rol.rolFunciones.findIndex(x => x.claveFuncion === this.funcionCurrent.claveFuncion);
			this.rol.rolFunciones.splice(indexDeleted, 1);
			//Limpiamos funcion current(edit/delete)
			this.funcionCurrent = {};
			//Cerramos dialog
			this.dialogConfirmDeleteFuncion = false;
		},

		//#endregion

		//#region Metodos de acciones de card
		
		onCancel: function() {
			this.$router.push('/rol');
		},
		onValidateList: function() {
			//Comprobamos que existan funciones
			let listValid = true;
			let messageValidation = '';
			if(this.rol.rolFunciones.length <= 0) {
				listValid = false;
				messageValidation += 'Agregue  al menos una función.';
			}
			if(!listValid) {
				this.$notifier.error(messageValidation);
			}
			return listValid;
		},
		onSave: function() {
			this.actualizarUsuarios = false;
			this.onSubmit();
		},
		onSaveUpdate: function() {
			this.actualizarUsuarios = true;
			this.onSubmit();
		},
		onSubmit: async function() {
			//Prevent multiple guardado
			if(!this.isSaving) {
				this.isSaving = true;
				const isValid = await this.$refs.observer.validate();
				if(isValid && this.onValidateList()) {
					//Copiamos deep objeto para ajustarlo antes de enviarlo
					let rolSaved = JSON.parse(JSON.stringify(this.rol));
					//Copiamos clave de rol en funciones y nuleamos objetos
					let claveRol = rolSaved.clave;
					rolSaved.rolFunciones.forEach(function(obj) {
						obj.claveRol = claveRol;
						obj.funcion = null;
					});
					if(!this.isEdit) {
						this.$emit('onSubmit', rolSaved, () => {
							this.isSaving = false;
						});
					} else {
						this.$emit('onSubmit', rolSaved, this.actualizarUsuarios, () => {
							this.isSaving = false;
						});
					}
				} else {
					this.isSaving = false;
				}
			}
		}

		//#endregion
	}
}
</script>