<template>
<div>
	<app-header-page :titulos="titleItems"></app-header-page>
	<licencia-search-card
		:inAppStack="true"
		title="Renovación"
		instructions="Ingresa los datos de la licencia a renovar"
		@onCancel="onCancel"
		@onSubmit="onSubmit">
	</licencia-search-card>
</div>
</template>
<script>
import AppHeaderPage from '../components/AppHeaderPage';
import LicenciaSearchCard from '../components/LicenciaSearchCard';

export default {
	components: {
		AppHeaderPage,
		LicenciaSearchCard
	},
	data: () =>({
		titleItems: ['Licencia', 'Renovar'],
	}),
	methods: {
		onCancel: function() {
			this.$router.push('/');
		},
		onSubmit: function(idLicencia, claveProducto) {
			this.$router.push('/licencia/' + idLicencia + '/' + claveProducto + '/renew');
		}
	}
}
</script>