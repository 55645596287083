<template>
<div>
	<app-header-page :titulos="titleItems"></app-header-page>
	<producto-card :mode="mode" 
	@onSubmit="onSubmit">
	</producto-card>
</div>
</template>
<script>
import AppHeaderPage from '../components/AppHeaderPage';
import ProductoCard from '../components/ProductoCard';
import ProductoApi from '../services/api/producto.api';
import { ViewMode } from '../utils/constants';

export default {
	components: {
		AppHeaderPage,
		ProductoCard
	},
    data: () => ({
		titleItems: ['Producto', 'Agregar'],
		mode: ViewMode.CREATE,
	}),
	methods: {
		onSubmit: function(productoSaved, callBack) {
			ProductoApi.add(productoSaved)
			.then(response => {
				callBack();
				if(response.success === true) {
					this.$router.push('/producto');
					this.$notifier.success('Producto agregado exitosamente.');
				} else {
					this.$notifier.error(response.message);
				}
			});
		}
	}
  }
</script>