<template>
<div>
	<validation-observer ref="observer">
		<v-form @submit.prevent="">
			<spinner-loading :overlay="isLoading"/>
			<v-card 
				class="mx-4" 
				rounded="lg"
			>
				<v-card-title class="pt-4 pb-1">
					<span class="font-weight-regular">Renovación de Licencia de Uso</span>
				</v-card-title>
				<v-card-text v-if="!isLoading">
					<div class="mb-4">
						<licencia-to-renew-card
							:licencia="licencia">
						</licencia-to-renew-card>
					</div>
					<v-row>
						<v-col cols="12">
							<div class="text--secondary ml-1">Periodo</div>
							<strong class="grey--text text--darken-3 ml-1">{{periodo.descripcion}}</strong>
						</v-col>
					</v-row>
					<v-row>
						<v-col 
							cols="12" 
							sm="4" 
							md="4"
							lg="4"
						>
							<div class="text--secondary ml-1">Moneda de pago*</div>
							<validation-provider 
								v-slot="{errors}" 
								name="moneda" 
								rules="required"
							>
								<v-select 
									v-model="precio" 
									:items="monedas"
									outlined
									hide-details="auto"
									return-object
									:item-text="onMonedaToString"
									:error-messages="errors"
								>
								</v-select>
							</validation-provider>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider class="mx-4 mt-5"></v-divider>
				<v-card-actions>
					<v-row>
						<v-col cols="12" class="text-right">
							<v-btn 
								@click="onSubmit"
								color="primary" 
								:disabled="isLoading"
								class="ml-2"
							>
								Renovar
							</v-btn>
						</v-col>
					</v-row>
				</v-card-actions>
			</v-card>
		</v-form>
	</validation-observer>
</div>
</template>
<script>
import SpinnerLoading from '../components/SpinnerLoading';
import LicenciaToRenewCard from '../components/LicenciaToRenewCard';

import LicenciaApi from '../services/api/licencia.api';
import PeriodoApi from '../services/api/periodoPago.api';
import PrecioApi from '../services/api/precio.api';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ObjectToStringMixin from '../mixins/objectToStringMixin'

export default {
	props: {
		idLicencia: Number,
		claveProducto: String,
		clavePeriodo: String,
	},
	mixins: [ObjectToStringMixin],
	components: {
		SpinnerLoading,
		LicenciaToRenewCard,
		ValidationProvider,
		ValidationObserver
	},
	created: function() {
		this.onInit();
	},
  data: () => ({
		isLoading: true,
		licencia: {},
		periodo: {},
		monedas: [],
		precio: null,

		isRenewing: false,
	}),
	computed: {
		loadedLicencia: function() {
			return Object.keys(this.licencia).length !== 0;
		},
	},
	methods: {
		onInit: async function() {
			this.isLoading = true;
			//Asignamos valores
			await LicenciaApi.get(this.idLicencia, this.claveProducto)
			.then(response => {
				if(response.success === true) {
					this.licencia = response.data;
				} else {
					this.$notifier.warn('La licencia no se cargo correctamente. \n' + response.message);
				}
			});
			await PeriodoApi.get(this.clavePeriodo)
			.then(response => {
				if(response.success === true) {
					this.periodo = response.data;
				} else {
					this.$notifier.warn('El periodo no se cargo correctamente. \n' + response.message);
				}
			});
			await PrecioApi.getAllByProductoPeriodo(this.claveProducto, this.clavePeriodo)
			.then(response => {
				if(response.success === true) {
					this.licencia.claveMoneda = '';
					this.monedas = response.data;
				} else {
					this.$notifier.warn('Las monedas no se cargaron correctamente. \n' + response.message);
				}
			});
			this.isLoading = false;
		},

		//#region Metodos de formateo select

		onMonedaToString: function(item) {
			return this.objectToString('', item.claveMoneda);
		},
		
		//#endregion

		//#region Metodo de acciones de card
		
		onSubmit: async function() {
			//Prevent multiple guardado
			if(!this.isRenewing) {
				this.isRenewing = true;
				const isValid = await this.$refs.observer.validate();
				if(isValid) {
					this.$emit('onSubmit', this.licencia, this.precio, () => {
						this.isRenewing = false;
					});
				} else {
					this.isRenewing = false;
					this.$notifier.error('Llene todos los campos marcados como obligatorios, compruebe su información y vuelva a intentar.');
				}
			}
		}

		//#endregion
	}
}
</script>