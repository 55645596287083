<template>
<v-dialog v-model="dialog"
	persistent
	max-width="600">
	<spinner-loading :overlay="isLoading"/>
	<v-card class="py-3">
		<v-card-title class="py-2">
			<span class="text-button grey--text text--darken-3">Saldos de Licencia de Obsequio</span>
		</v-card-title>
		<v-card-text>
			<v-row>
				<v-col cols="12">
					<v-card elevation="1" rounded="lg">
						<v-list v-if="this.items.length > 0" 
						dense
						class="py-0"
						elevation="1"
						rounded
						color="default">
							<template v-for="(item, index) in items">
								<v-list-item :key="item.id" v-if="item.cantidadLicenciasRestantes > 0">
									<v-list-item-content>
										<v-list-item-title class="text-subtitle-1">{{item.claveProducto}}</v-list-item-title>
										<span>{{item.descripcionProducto}}</span>
										<span>
											{{item.descripcionPeriodoPago}} 
											(
											<template v-if="item.cantidadPeriodos === 0">
												<!--nada-->
											</template>
											<template v-else-if="item.cantidadPeriodos === 1">
												{{item.cantidadPeriodos}} PERIODO
											</template>
											<template v-else-if="item.cantidadPeriodos > 1">
												{{item.cantidadPeriodos}} PERIODOS
											</template>
											)
										</span>
									</v-list-item-content>
									<v-list-item-action>
										<v-list-item-action-text>
											<span class="text-subtitle-1">Cantidad: <strong>{{item.cantidadLicenciasRestantes}}</strong></span>
										</v-list-item-action-text>
									</v-list-item-action>
								</v-list-item>
								<v-divider :key="index"></v-divider>
							</template>
						</v-list>
						<div v-else class="px-2 py-2">
							{{mensajeVacio}}
						</div>
					</v-card>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn small 
			color="default"
			@click="onClose">
				Cerrar
			</v-btn>
		</v-card-actions>
	</v-card>
</v-dialog>
</template>
<script>
import SpinnerLoading from '../components/SpinnerLoading';

import LicenciaObsequioApi from '../services/api/licenciaObsequio.api';

export default {
	props: ['dialog', 'idDistribuidor'],
    data: () => ({
		items: [],
		isLoading: true,
		mensajeVacio: ''
	}),
	components: {
		SpinnerLoading
	},
	watch: {
		dialog :{
			handler(newVal, oldVal) {
				if(newVal) {
					this.onList();
				}
			},
			deep: true
		},
	},
	methods: {
		onList: function() {
			this.isLoading = true;
			LicenciaObsequioApi.getRemainingByDistribuidor(this.idDistribuidor)
			.then(response => {
				this.isLoading = false;
				if(response.success === true) {
					this.items = response.data || [];
					this.mensajeVacio = (this.items.length < 1) ? 'No tiene saldos disponibles.' : '';
				} else {
					this.mensajeVacio = 'Error al cargar saldos.';
				}
			});
		},
		onClose: function() {
			this.$emit('onClose');
		},
	}
}
</script>