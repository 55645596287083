<template>
<v-dialog v-model="dialog"
	persistent
	max-width="800">
	<v-card class="py-3">
		<v-card-title class="py-2">
			<span class="text-button grey--text text--darken-3">Historial de Licencias de Obsequio Asignadas</span>
		</v-card-title>
		<v-card-text>
			<v-row>
				<v-col cols="12">
					<div class="text--secondary ml-1">Distribuidor</div>
					<strong class="grey--text text--darken-3 ml-1">{{distribuidorText}}</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<div class="text--secondary ml-1">Producto</div>
					<strong class="grey--text text--darken-3 ml-1">{{productoText}}</strong>
				</v-col>
			</v-row>
			<v-data-table 
				:headers="headers"
				:items="items"
				:footer-props="footerProps"
				:page="page"
				:pageCount="totalPages"
				:options.sync="options"
				:server-items-length="totalItems"
				:items-per-page="pageSize"
				:loading="isLoading"
				loading-text="Cargando..."
				no-data-text="No existen registros."
				item
				class="elevation-1 mt-3"
				disable-sort
				dense>
				<template v-slot:item.fechaAplicacion="{item}">
					{{ item.fechaAplicacion | dateParse('DD/MM/YYYY HH:mm') }}
				</template>
				<template v-slot:item.precioPeriodo="{item}">
					{{ item.precio | currencyParse(2) }} {{item.claveMoneda}} / {{item.periodoPago.descripcion}}
				</template>
			</v-data-table>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn small 
			color="default"
			@click="onClose">
				Cerrar
			</v-btn>
		</v-card-actions>
	</v-card>
</v-dialog>
</template>
<script>
import LicenciaObsequioApi from '../services/api/licenciaObsequio.api';

export default {
	props: ['dialog', 'distribuidor', 'producto'],
    data: () => ({
		headers: [
			{ text: 'Fecha', value: 'fechaAplicacion', width: '20%' },
			{ text: 'Cantidad', value: 'cantidadLicenciasAplicadas', align: 'right', width: '10%'},
			{ text: 'Precio / Periodo', value: 'precioPeriodo', width: '30%' },
			{ text: 'Periodos', value: 'cantidadPeriodos', width: '10%' },
			{ text: 'Descripcion', value: 'descripcion', width: '30%' },
			
		],
		items: [],
		footerProps: {
			'items-per-page-text': 'Mostrar',
			'items-per-page-options': [10,15,20]
		},
		page: 1,
		totalPages: 0,
		options: {},
		totalItems: 0,
		pageSize: 10,
		isLoading: true,
	}),
	watch: {
		options: {
			handler() {
				this.onPaged();
			}
		},
		dialog :{
			handler(newVal, oldVal) {
				if(newVal && Object.keys(this.options).length !== 0) {
					this.options.page = 1;
					this.options.itemsPerPage = 10;
					this.onPaged();
				}
			},
			deep: true
		},
	},
	computed: {
		distribuidorText: function() {
			if(Object.keys(this.distribuidor).length !== 0)
				return this.distribuidor.id + ' / ' + this.distribuidor.primerNombre + ' ' + 
				this.distribuidor.segundoNombre + ' ' + 
				this.distribuidor.apellidoPaterno + ' ' + 
				this.distribuidor.apellidoMaterno;
			else
				return '';
		},
		productoText: function() {
			if(Object.keys(this.producto).length !== 0)
				return this.producto.clave + ' / ' + this.producto.descripcion;
			else
				return '';
		},
	},
	methods: {
		onPaged: function() {
			this.isLoading = true;
			//Obtenemos datos de footer para API
			const {page, itemsPerPage} = this.options;
			this.page = page;
			this.pageSize = itemsPerPage;
			LicenciaObsequioApi.getPagedByDistribuidor(this.distribuidor.id, this.producto.clave, this.page, this.pageSize)
			.then(response => {
				this.isLoading = false;
				if(response.success === true) {
					//Cargamos nuevos datos de paginado
					this.items = response.data.items;
					this.totalItems = parseInt(response.data.totalRecordCount);
					this.totalPages = response.data.totalPages;
				} else {
					this.$notifier.error(response.message)
				}
			});
		},
		onClose: function() {
			this.$emit('onClose');
		},
	}
}
</script>