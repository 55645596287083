<template>
<v-dialog v-model="dialog"
	persistent
	max-width="300">
	<spinner-loading :overlay="isLoading"/>
	<v-card class="py-3">
		<v-card-title class="py-2">
			<span class="text-button grey--text text--darken-3">Saldos de licencia</span>
		</v-card-title>
		<v-card-text>
			<v-row>
				<v-col cols="12">
					<v-card elevation="1" rounded="lg">
						<v-list v-if="this.items.length > 0" 
						dense
						class="py-0"
						rounded
						elevation="1"
						color="default">
							<template v-for="(item, index) in items">
								<v-list-item :key="item.claveMoneda" v-if="item.monto > 0">
									<v-list-item-content>
										<v-list-item-title><strong class="text-h6">{{item.monto | currencyParse(2)}}</strong></v-list-item-title>
										<v-list-item-subtitle><strong class="text-subtitle-2">{{item.claveMoneda}}</strong></v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
								<v-divider :key="index"></v-divider>
							</template>
						</v-list>
						<div v-else class="px-2 py-2">
							{{mensajeVacio}}
						</div>
					</v-card>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn small 
			color="default"
			@click="onClose">
				Cerrar
			</v-btn>
		</v-card-actions>
	</v-card>
</v-dialog>
</template>
<script>
import SpinnerLoading from '../components/SpinnerLoading';

import SaldoApi from '../services/api/saldoDistribuidor.api';

export default {
	props: ['dialog', 'idDistribuidor'],
    data: () => ({
		items: [],
		isLoading: true,
		mensajeVacio: ''
	}),
	components: {
		SpinnerLoading
	},
	watch: {
		dialog :{
			handler(newVal, oldVal) {
				if(newVal) {
					this.onList();
				}
			},
			deep: true
		},
	},
	methods: {
		onList: function() {
			this.isLoading = true;
			SaldoApi.getAllByDistribuidor(this.idDistribuidor)
			.then(response => {
				this.isLoading = false;
				if(response.success === true) {
					this.items = response.data || [];
					this.mensajeVacio = (this.items.length < 1) ? 'No tiene saldos disponibles.' : '';
				} else {
					this.mensajeVacio = 'Error al cargar saldos.';
				}
			});
		},
		onClose: function() {
			this.$emit('onClose');
		},
	}
}
</script>