<template>
	<v-card 
		class="mx-4" 
		rounded="lg"
	>
		<v-card-title class="py-2">
			<span class="text-button grey--text text--darken-3">Voucher de pago OXXO</span>
		</v-card-title>
		<v-card-text v-if="paymentIntentHasValue" ref="voucherPrint">
			<v-row>
				<v-col cols="12">
					<v-img src="images/brand/oxxo.png" contain position="center" width="100px"/>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<span class="grey--text text--darken-3 text-h4">{{montoPago | currencyParse(2)}} {{claveMoneda}}</span>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<span class="grey--text text--darken-1 font-weight-regular">Caduca el {{fechaExpiracion}}</span>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12"  class="text-center">
					<barcode 
						:value="numero"
						height="70"
					>
						No se pudo generar el código de barras
					</barcode>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<strong class="text-subtitle-1 grey--text text--darken-3 ml-1">Instrucciones de pago en OXXO:</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<ol>
						<li class="grey--text text--darken-3 font-weight-light">Entregue el código al cajero en cualquier OXXO para que lo escanee.</li>
						<li class="grey--text text--darken-3 font-weight-light">Proporcione el pago en efectivo al cajero.</li>
						<li class="grey--text text--darken-3 font-weight-light">Una vez completado el pago, guarde el recibo de su pago para sus archivos.</li>
						<li class="grey--text text--darken-3 font-weight-light">Regrese al portal www.pospos.com.mx en el apartado "Licencia -> Reportar pago en OXXO".</li>
						<li class="grey--text text--darken-3 font-weight-light">En la página ingrese la "Clave de Pago OXXO" <strong class="font-weight-bold">{{clavePagoOxxo}}</strong> para continuar con la activación.</li>
						<li class="grey--text text--darken-3 font-weight-light">Para cualquier duda o aclaración, por favor contacte a AppStack.</li>
					</ol>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-actions class="py-3">
			<v-spacer></v-spacer>
			<v-btn 
				small 
				color="default"
				:loading="isPrinting"
				@click="onCancel"
			>
				Salir
			</v-btn>
			<v-btn 
				small 
				color="primary"
				:loading="isPrinting"
				@click="onPrint"
			>
				Imprimir
			</v-btn>
		</v-card-actions>
	</v-card>
</template>
<script>
import VueBarcode from 'vue-barcode';

export default {
	props: {
		paymentIntent: Object,
		clavePagoOxxo: String,
	},
	data: () => ({
		isPrinting: false,
  }),
	components: {
		'barcode': VueBarcode,
	},
	computed: {
		paymentIntentHasValue: function() {
			return Object.keys(this.paymentIntent).length > 0;
		},
		montoPago: function() {
			return this.paymentIntent.amount / 100;
		},
		claveMoneda: function() {
			return this.paymentIntent.currency.toUpperCase();
		},
		fechaExpiracion: function() {
			let unixTimestamp = this.paymentIntent.next_action.oxxo_display_details.expires_after;
			let meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
			let fecha = new Date(unixTimestamp * 1000);
			let formato = String('00' + fecha.getDate()).slice(-2) + 
			' de ' + meses[fecha.getMonth()] +
			' de ' + fecha.getFullYear() + 
			' a las ' + String('00' + fecha.getHours()).slice(-2) +
			':' + String('00' + fecha.getMinutes()).slice(-2);
			
			return formato;
		},
		numero: function() {
			return this.paymentIntent.next_action.oxxo_display_details.number;
		}
	},
	methods: {
		onCancel: function() {
			this.$emit('onCancel');
		},
		onPrint: async function() {
			//Prevent multiple guardado
			if(!this.isPrinting) {
				this.isPrinting = true;
				let el = this.$refs.voucherPrint;
				let options = {
					type: 'dataURL'
				}
				let url = await this.$html2canvas(el, options);

				let win = window.open('', '_blank', 'scrollbars=yes,resizable=yes,status=no,location=no,toolbar=no,menubar=no,top=100,left=200,width=1000,height=550');
					win.document.write(`<html>
					<head>
							<title>OXXO</title>
					</head>
					<body>
							<img src="${url}" onload="window.print();window.close();"/>
					</body>
					</html>`);
					this.isPrinting = false;
			}
		},
		
		//#endregion
	}
}
</script>