<template>
	<v-card class="mx-auto my-14" 
	max-width="400" 
	rounded="lg">
		<v-card-title class="d-flex align-center">
			<v-icon x-large color="tertiary" class="mr-2">mdi-lock</v-icon>
			<div class="text-h3">403</div>
		</v-card-title>
		<v-card-subtitle>
			<div class="text-h5">Acceso denegado</div>
		</v-card-subtitle>
		<v-card-text>
			No cuentas con los permisos suficientes para accesar a la pagina solicitada.
		</v-card-text>
		<v-card-actions>
			<v-btn :to="{path: '/'}"
			text
			color="primary">
				Ir a inicio
			</v-btn>
		</v-card-actions>
    </v-card>
</template>
<script>

export default {
	data: () =>({}),
}
</script>