import axios from 'axios';
import httpClient from './httpClient';
import httpUtils from '../httpUtils';

const END_POINT = '/Proveedor';

export default {
    getPaged(page, pageSize, parameterSearch, cancelSource = axios.CancelToken.source()) {
        return httpClient.get(END_POINT + '/PagingGet?cCriterio1=' + parameterSearch + '&lAnd=false&cCriterio2=&pageNumber=' + page + '&pageSize=' + pageSize, {
            cancelToken: cancelSource.token
        }).then(response => {
            return httpUtils.responsePaginationErp(response.headers, response.data);
        }).catch(error => {
            if (axios.isCancel(error)) {
                return httpUtils.responseCancel(error);
            } else {
                return httpUtils.responseError(error.response);
            }
        })
    },
    get(id) {
        return httpClient.get(END_POINT + '?nClave=' + id)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
}