import httpClient from './httpClient';
import httpUtils from '../httpUtils';

const END_POINT = '/Colonia';

export default {
    getAll(idCiudad, idEstado) {
        return httpClient.get(END_POINT + '?idCiudad=' + idCiudad + '&idEstado=' + idEstado)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
    get(id, idCiudad, idEstado) {
        return httpClient.get(END_POINT + '/' + id + '?idCiudad=' + idCiudad + '&idEstado=' + idEstado)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
    getByCP(cp) {
        return httpClient.get(END_POINT + '?cp=' + cp)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
}