import AuthTypes from '../types/AuthTypes';

export default {
    namespaced: true,
    state: {
        version: localStorage.getItem('version') || '',
        layout: localStorage.getItem('user-layout') || 'login-layout',
        token: localStorage.getItem('user-token') || '',
        email: localStorage.getItem('user-email') || '',
        nombre: localStorage.getItem('user-nombre') || 'USUARIO ADMINISTRATIVO',
        tipo: localStorage.getItem('user-tipo') || '',
        partner: localStorage.getItem('user-partner') || '',
        distribuidor: localStorage.getItem('user-distribuidor') || '',
    },
    getters: {
        [AuthTypes.getters.VERSION]: state => state.version,
        [AuthTypes.getters.IS_AUTHENTICATED]: state => !!state.token,
        [AuthTypes.getters.TOKEN]: state => state.token,
        [AuthTypes.getters.EMAIL_USUARIO]: state => state.email,
        [AuthTypes.getters.NOMBRE_USUARIO]: state => state.nombre,
        [AuthTypes.getters.TIPO_USUARIO]: state => state.tipo,
        [AuthTypes.getters.DISTRIBUIDOR_USUARIO]: state => state.distribuidor,
        [AuthTypes.getters.PARTNER_USUARIO]: state => state.partner,
    },
    mutations: {
        [AuthTypes.mutations.LOGIN]: (state, value) => {
            state.token = value.token;
            state.email = value.email;
            state.nombre = value.nombreCompleto || 'USUARIO ADMINISTRATIVO';
            state.tipo = value.claveTipoUsuario;
            state.partner = value.idPartner || '';
            state.distribuidor = value.idDistribuidor || '';
            localStorage.setItem('user-token', state.token);
            localStorage.setItem('user-email', state.email);
            localStorage.setItem('user-nombre', state.nombre);
            localStorage.setItem('user-tipo', state.tipo);
            localStorage.setItem('user-partner', state.partner);
            localStorage.setItem('user-distribuidor', state.distribuidor);
        },
        [AuthTypes.mutations.LOGOUT]: (state) => {
            state.token = '';
            state.email = '';
            state.nombre = 'USUARIO ADMINISTRATIVO';
            state.tipo = '';
            state.partner = '';
            state.distribuidor = '';
            localStorage.removeItem('user-token');
            localStorage.removeItem('user-email');
            localStorage.removeItem('user-nombre');
            localStorage.removeItem('user-tipo');
            localStorage.removeItem('user-partner');
            localStorage.removeItem('user-distribuidor');

        },
        [AuthTypes.mutations.SET_LAYOUT]: (state, layout) => {
            localStorage.setItem('user-layout', layout);
            state.layout = layout;
        },
        [AuthTypes.mutations.SET_VERSION]: (state, version) => {
            localStorage.setItem('version', version);
            state.version = version;
        },
    }
}