import httpClient from './httpClient';

const END_POINT = '/Reporte';

export default {
    evidenciasCargaSaldo(fechaInicio, fechaFin, idDistribuidor, fileType) {
        return process.env.VUE_APP_BASE_URL + END_POINT + '/EvidenciasCargaSaldo?fechaInicio=' + fechaInicio +
            '&fechaFin=' + fechaFin +
            '&idDistribuidor=' + idDistribuidor +
            '&fileType=' + fileType
    },
}