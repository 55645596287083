import httpClient from './httpClient';
import httpUtils from '../httpUtils';

const END_POINT = '/PagoPaypal';

export default {
    createOrder(datos) {
        return httpClient.post(END_POINT + '/CreateOrder', datos)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            });
    },
    capturePaymentActivation(datos) {
        return httpClient.post(END_POINT + '/CapturePaymentActivation', datos)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            });
    },
    capturePaymentRenovation(datos) {
        return httpClient.post(END_POINT + '/CapturePaymentRenovation', datos)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            });
    },
}