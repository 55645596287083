<template>
<v-row align="center" justify="center">
	<v-col cols="12" sm="8" md="6" lg="4">
		<v-card class="px-5 py-10" rounded="xl">
			<v-row class="my-5">
				<v-col 
					cols="12" 
					class="d-flex flex-column align-center justify-center">
					<v-img 
						src="images/brand/logo.png" 
						contain
						width="300"/>
					</v-col>
				</v-row>
			<validation-observer ref="observer">
				<v-form @submit.prevent="onSubmit">
					<v-card-title>Login</v-card-title>
					<v-card-subtitle>Ingresa tus credenciales</v-card-subtitle>
					<v-card-text>
						<v-row>
							<v-col cols="12">
								<div class="text--secondary ml-1">Email*</div>
								<validation-provider v-slot="{errors}" 
								name="email" 
								rules="required|email">
									<v-text-field v-model="email" 
									:error-messages="errors" 
									outlined 
									hide-details="auto"
									prepend-inner-icon="mdi-account"/>
								</validation-provider>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<div class="text--secondary ml-1">Contraseña*</div>
								<validation-provider v-slot="{errors}" 
								name="contraseña" 
								rules="required">
									<v-text-field v-model="contrasenia" 
									:error-messages="errors" 
									type="password" 
									outlined 
									hide-details="auto"
									prepend-inner-icon="mdi-lock"/>
								</validation-provider>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-btn block 
						dark
						type="submit" 
						color="secondary" 
						:loading="isLoading">
							Ingresar
						</v-btn>
					</v-card-actions>
				</v-form>
			</validation-observer>
		</v-card>
		<div class="fill-width text-center my-2">
			<small>&copy; AppStack | Todos los derechos reservados {{ new Date().getFullYear() }}</small>
		</div>
	</v-col>
</v-row>
</template>
<script>
import ConfiguracionApi from '../services/api/configuracion.api'
import AuthApi from '../services/api/auth.api'
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import AuthTypes from '../store/types/AuthTypes';

export default {
	created: function() {
		if(!this.$store.getters[AuthTypes.nameModule + '/' + AuthTypes.getters.IS_AUTHENTICATED])
			this.$store.commit(AuthTypes.nameModule + '/' + AuthTypes.mutations.SET_LAYOUT, 'login-layout');
		else
			this.$router.push('/');
	},
	components: {
		ValidationProvider,
		ValidationObserver
	},
	data: () =>({
		isLoading: false,
		email: '',
		contrasenia: ''
	}),
	methods: {
		onSubmit: async function() {
			const isValid = await this.$refs.observer.validate();
			if(isValid) {
				this.isLoading = true;
				// Obtenemos numero de version
				ConfiguracionApi.getVersion()
				.then(response => {
					if(response.success === true) {
						this.$store.commit(AuthTypes.nameModule + '/' + AuthTypes.mutations.SET_VERSION, response.data.version);
					}
				});
				// Autenticamos
				AuthApi.signIn(this.email, this.contrasenia)
				.then(response => {
					if(response.success === true) {
						this.$store.commit(AuthTypes.nameModule + '/' + AuthTypes.mutations.LOGIN, response.data);
						this.$router.push('/');
					} else {
						this.$notifier.error(response.message)
					}
					this.isLoading = false;
				});
			}
		}
	}
}
</script>