<template>
<div>
	<app-header-page :titulos="titleItems"></app-header-page>
	<evidencia-carga-saldo-card
	@onReject="onReject"
	@onConfirm="onConfirm">
	</evidencia-carga-saldo-card>
</div>
</template>
<script>
import AppHeaderPage from '../components/AppHeaderPage';
import EvidenciaCargaSaldoCard from '../components/EvidenciaCargaSaldoCard';

import EvidenciaApi from '../services/api/evidenciaCargaSaldo.api';

export default {
	components: {
		AppHeaderPage,
		EvidenciaCargaSaldoCard
	},
    data: () => ({
		titleItems: ['Evidencia de carga de caldo', 'Confirmar'],
	}),
	methods: {
		onReject: function(dataReject, callBack) {
			EvidenciaApi.reject(dataReject)
			.then(response => {
				callBack();
				if(response.success === true) {
					this.$router.push('/evidencia-carga-saldo/list-confirm');
					this.$notifier.success('La evidencia de carga de saldo ha sido rechazada exitosamente.');
				} else {
					this.$notifier.error(response.message)
				}
			});
		},
		onConfirm: function(dataConfirm, callBack) {
			EvidenciaApi.confirm(dataConfirm)
			.then(response => {
				callBack();
				if(response.success === true) {
					this.$router.push('/evidencia-carga-saldo/list-confirm');
					this.$notifier.success('La evidencia de carga de saldo ha sido confirmada exitosamente.');
				} else {
					this.$notifier.error(response.message)
				}
			});
		}
	}
  }
</script>