<template>
<v-dialog v-model="dialog"
	persistent
	max-width="500">
	<validation-observer ref="observerFuncion">
		<v-form @submit.prevent="">
			<v-card>
				<v-card-title class="py-2">
					<span class="text-button grey--text text--darken-3">FUNCION</span>
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text>
					<v-row>
						<v-col cols="12">
							<div class="text--secondary ml-1">Seleccione*</div>
							<validation-provider v-slot="{errors}" 
								name="funcion" 
								rules="required">
								<v-autocomplete v-model="funcion" 
								:items="funciones"
								outlined
								hide-details="auto"
								item-value="clave"
								:item-text="onFuncionToString"
								return-object
								:error-messages="errors"
								clearable>
								</v-autocomplete>
							</validation-provider>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn small 
					color="default"
					@click="onCancel">
						Cancelar
					</v-btn>
					<v-btn small 
					color="primary"
					@click="onSave">
						Guardar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</validation-observer>
</v-dialog>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ObjectToStringMixin from '../mixins/objectToStringMixin';

export default {
	props: ['dialog', 'funciones'],
	components: {
		ValidationProvider,
		ValidationObserver
	},
	mixins: [ObjectToStringMixin],
    data: () => ({
		funcion: '',
		isSaving: false,
	}),
	methods: {

		//#region Metodos de formateo objetos

		onFuncionToString: function(item) {
			return this.objectToString(item.clave, item.descripcion);
		},

		//#endregion

		//#region Metodos de acciones de card

		getDataToSave: function() {
			return {
				claveFuncion: this.funcion.clave,
				funcion: {
					clave: this.funcion.clave,
					descripcion: this.funcion.descripcion,
				},
				observaciones: ''
			};
		},
		onClear: function() {
			this.funcion = '';
			this.$refs.observerFuncion.reset();
		},
		onCancel: function() {
			this.$emit('onCancel');
			this.onClear();
		},
		onSave: async function() {
			const isValid = await this.$refs.observerFuncion.validate();
			if(isValid) {
				this.$emit('onSave', this.getDataToSave());
				this.onClear();
			}
		}

		//#endregion
	}
  }
</script>