<template>
<v-dialog v-model="dialog"
	persistent
	scrollable
	max-width="600">
	<spinner-loading :overlay="isLoading"/>
	<v-card class="pb-3">
		<v-toolbar
		color="primary"
        dark>
			Evidencia de Carga de Saldo
		</v-toolbar>
		<v-card-text v-if="loadedEvidencia"
		class="mt-5">
			<v-row>
				<v-col cols="12" sm="4" md="3">
					<div class="text--secondary ml-1">ID</div>
					<strong class="grey--text text--darken-3 ml-1">{{evidencia.id}}</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="5" md="4">
					<div class="text--secondary ml-1">Fecha de Registro</div>
					<strong class="grey--text text--darken-3 ml-1">{{evidencia.fechaRegistro | dateParse('DD/MM/YYYY')}}</strong>
				</v-col>
				<v-col cols="12" sm="5" md="4" offset-sm="2" offset-md="4">
					<div class="text--secondary ml-1">Monto</div>
					<strong class="grey--text text--darken-3 ml-1">{{evidencia.montoCargaSaldo | currencyParse(2)}} {{evidencia.claveMonedaCargaSaldo}}</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<div class="text--secondary ml-1">Estatus</div>
					<strong class="grey--text text--darken-3 ml-1">{{evidencia.estatusEvidencia.descripcion}}</strong>
				</v-col>
			</v-row>
			<v-row v-if="evidencia.motivoEstatus">
				<v-col cols="12">
					<div class="text--secondary ml-1">Motivo de Estatus</div>
					<strong class="grey--text text--darken-3 ml-1">{{evidencia.motivoEstatus}}</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<div class="text--secondary ml-1">Distribuidor</div>
					<strong class="grey--text text--darken-3 ml-1">{{nombreDistribuidor}}</strong>
				</v-col>
			</v-row>
			<v-row v-if="evidencia.fechaConfirmacion">
				<v-col cols="12" sm="5" md="4">
					<div class="text--secondary ml-1">Fecha de Confirmación</div>
					<strong class="grey--text text--darken-3 ml-1">{{evidencia.fechaConfirmacion | dateParse('DD/MM/YYYY')}}</strong>
				</v-col>
				<v-col cols="12" sm="5" md="4" offset-sm="2" offset-md="4">
					<div class="text--secondary ml-1">Monto de Confirmación</div>
					<strong class="grey--text text--darken-3 ml-1">{{evidencia.montoConfirmacionCargaSaldo | currencyParse(2)}} {{evidencia.claveMonedaCargaSaldo}}</strong>
				</v-col>
			</v-row>
			<v-row v-if="evidencia.fechaConfirmacion">
				<v-col cols="12">
					<div class="text--secondary ml-1">Usuario que Confirmo</div>
					<strong class="grey--text text--darken-3 ml-1">{{evidencia.claveUsuarioConfirmacion}}</strong>
				</v-col>
			</v-row>
			<v-row v-if="evidencia.fechaRechazo">
				<v-col cols="12">
					<div class="text--secondary ml-1">Fecha de Rechazo</div>
					<strong class="grey--text text--darken-3 ml-1">{{evidencia.fechaRechazo | dateParse('DD/MM/YYYY')}}</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<div class="text--secondary ml-1">Observaciones</div>
					<strong class="grey--text text--darken-3 ml-1">{{evidencia.observaciones}}</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<div class="text--secondary ml-1">Imagen de Evidencia</div>
					<v-img :src="urlImagen" 
					contain
					height="700"
					max-width="600"
					max-height="800" 
					class="rounded-lg">
						<template v-slot:placeholder>
							<v-row
							class="fill-height ma-0"
							align="center"
							justify="center">
								<v-progress-circular
								indeterminate>
								</v-progress-circular>
							</v-row>
						</template>
					</v-img>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn small 
			color="default"
			@click="onClose">
				Cerrar
			</v-btn>
		</v-card-actions>
	</v-card>
</v-dialog>
</template>
<script>
import SpinnerLoading from '../components/SpinnerLoading';

import EvidenciaApi from '../services/api/evidenciaCargaSaldo.api';

export default {
	props: ['dialog', 'idEvidencia'],
    data: () => ({
		isLoading: true,
		evidencia : {}
	}),
	components: {
		SpinnerLoading,
	},
	watch: {
		dialog :{
			handler(newVal, oldVal) {
				if(newVal) {
					this.onLoaded();
				}
			},
			deep: true
		},
	},
	computed: {
		loadedEvidencia: function() {
			return Object.keys(this.evidencia).length !== 0;
		},
		nombreDistribuidor: function() {
			if(this.loadedEvidencia)
				return this.evidencia.distribuidor.primerNombre + ' ' + this.evidencia.distribuidor.segundoNombre + ' ' + this.evidencia.distribuidor.apellidoPaterno + ' ' + this.evidencia.distribuidor.apellidoMaterno;
			else
				return '';
		},
		urlImagen: function() {
			if(this.loadedEvidencia) 
				return EvidenciaApi.getImage(this.evidencia.id);
			else
				return '';
				//return 'http://localhost:8582/api/EvidenciaCargaSaldo/GetImageById/' + this.evidencia.id;
		}
	},
	methods: {
		onLoaded: async function() {
			this.isLoading = true;
			//Obtenemos evidencia para ver detalles
			await EvidenciaApi.get(this.idEvidencia)
				.then(response => {
					this.isLoading = false;
					if(response.success === true) {
						this.evidencia = response.data
					} else {
						this.$notifier.error(response.message);
					}
				});

		},
		onClose: function() {
			this.$emit('onClose');
		},
	}
}
</script>