import axios from 'axios';
import httpClient from './httpClient';
import httpUtils from '../httpUtils';

const END_POINT = '/Distribuidor';

export default {
    getAll() {
        return httpClient.get(END_POINT)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
    getPaged(byPartner, idPartner, byDistribuidor, idDistribuidor, page, pageSize, parameterSearch, cancelSource = axios.CancelToken.source()) {
        return httpClient.get(END_POINT + '/GetPaged?byPartner=' + byPartner + '&idPartner=' + idPartner + '&byDistribuidor=' + byDistribuidor + '&idDistribuidor=' + idDistribuidor + '&page=' + page + '&pageSize=' + pageSize + '&parameter=' + parameterSearch, {
            cancelToken: cancelSource.token
        }).then(response => {
            return httpUtils.responsePagination(response.headers, response.data);
        }).catch(error => {
            if (axios.isCancel(error)) {
                return httpUtils.responseCancel(error);
            } else {
                return httpUtils.responseError(error.response);
            }
        })
    },
    get(id) {
        return httpClient.get(END_POINT + '/GetById/' + id)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
    getBasicById(id) {
        return httpClient.get(END_POINT + '/GetBasicById/' + id)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
    getBasicByCodigoRef(codigoReferencia) {
        return httpClient.get(END_POINT + '/GetBasicByCodigoRef/' + codigoReferencia)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
    getToActivacionGift(codigoReferencia, claveProducto, clavePeriodo) {
        return httpClient.get(END_POINT + '/GetToActivationGift/' + codigoReferencia + '/' + claveProducto + '/' + clavePeriodo)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
    add(distribuidor) {
        return httpClient.post(END_POINT, distribuidor)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            });
    },
    update(id, distribuidor) {
        return httpClient.put(END_POINT + '?id=' + id, distribuidor)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            });
    },
    delete(id) {
        return httpClient.delete(END_POINT + '/' + id)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    }
}