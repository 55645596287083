import httpClient from './httpClient';
import httpUtils from '../httpUtils';

const END_POINT = '/TablaPrecio';

export default {
    getAllByProducto(claveProducto) {
        return httpClient.get(END_POINT + '/GetAllByProducto/' + claveProducto)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
    getAllByProductoPeriodo(claveProducto, clavePeriodo) {
        return httpClient.get(END_POINT + '/GetAllByProductoPeriodo/' + claveProducto + '/' + clavePeriodo)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
    getPeriodosByProducto(claveProducto) {
        return httpClient.get(END_POINT + '/GetPeriodosByProducto/' + claveProducto)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
    get(id) {
        return httpClient.get(END_POINT + '/GetById/' + id)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
}