<template>
<v-dialog v-model="dialog"
	persistent
	scrollable
	max-width="600">
	<spinner-loading :overlay="isLoading"/>
	<v-card class="pb-3">
	<v-toolbar
		color="primary"
        dark>
			Documento de Afectacion de Saldo
		</v-toolbar>
		<v-card-text v-if="loadedDocumento"
		class="mt-5">
			<v-row>
				<v-col cols="12" sm="4" md="3">
					<div class="text--secondary ml-1">ID</div>
					<strong class="grey--text text--darken-3 ml-1">{{documento.id}}</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="5" md="4">
					<div class="text--secondary ml-1">Fecha de Registro</div>
					<strong class="grey--text text--darken-3 ml-1">{{documento.fechaRegistro | dateParse('DD/MM/YYYY')}}</strong>
				</v-col>
				<v-col cols="12" sm="5" md="4" offset-sm="2" offset-md="4">
					<div class="text--secondary ml-1">Monto</div>
					<strong class="grey--text text--darken-3 ml-1">{{documento.monto | currencyParse(2)}} {{documento.claveMonedaDocumento}}</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<div class="text--secondary ml-1">Tipo de Documento</div>
					<strong class="grey--text text--darken-3 ml-1">{{documento.tipoDocumentoAfectacionSaldo.descripcion}}</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<div class="text--secondary ml-1">Estatus</div>
					<strong class="grey--text text--darken-3 ml-1">{{documento.estatusDocumento.descripcion}}</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<div class="text--secondary ml-1">Distribuidor</div>
					<strong class="grey--text text--darken-3 ml-1">{{nombreDistribuidor}}</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<div class="text--secondary ml-1">Descripción</div>
					<strong class="grey--text text--darken-3 ml-1">{{documento.descripcion}}</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<div class="text--secondary ml-1">Documento Generado Por Mesa de Ayuda</div>
					<strong class="grey--text text--darken-3 ml-1">{{documento.documentoGenerado | booleanParse()}}</strong>
				</v-col>
			</v-row>
			<v-row v-if="documento.idDocumentoOrigen">
				<v-col cols="12">
					<div class="text--secondary ml-1">Documento Origen</div>
					<strong class="grey--text text--darken-3 ml-1">{{documento.tipoDocumentoOrigen.descripcion}} / NO. {{documento.idDocumentoOrigen}}</strong>
				</v-col>
			</v-row>
			<v-row v-if="documento.fechaFinalizacion">
				<v-col cols="12">
					<div class="text--secondary ml-1">Fecha de Finalización</div>
					<strong class="grey--text text--darken-3 ml-1">{{documento.fechaFinalizacion | dateParse('DD/MM/YYYY')}}</strong>
				</v-col>
			</v-row>
			<v-row v-if="documento.fechaCancelacion">
				<v-col cols="12">
					<div class="text--secondary ml-1">Fecha de Cancelación</div>
					<strong class="grey--text text--darken-3 ml-1">{{documento.fechaCancelacion | dateParse('DD/MM/YYYY')}}</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<div class="text--secondary ml-1">Observaciones</div>
					<strong class="grey--text text--darken-3 ml-1">{{documento.observaciones}}</strong>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn small 
			color="default"
			@click="onClose">
				Cerrar
			</v-btn>
		</v-card-actions>
	</v-card>
</v-dialog>
</template>
<script>
import SpinnerLoading from '../components/SpinnerLoading';

import DocumentoApi from '../services/api/documentoAfectacionSaldo.api';

export default {
	props: ['dialog', 'idDocumento'],
    data: () => ({
		isLoading: true,
		documento : {}
	}),
	components: {
		SpinnerLoading
	},
	watch: {
		dialog :{
			handler(newVal, oldVal) {
				if(newVal) {
					this.onLoaded();
				}
			},
			deep: true
		},
	},
	computed: {
		loadedDocumento: function() {
			return Object.keys(this.documento).length !== 0;
		},
		nombreDistribuidor: function() {
			if(this.loadedDocumento)
				return this.documento.distribuidor.primerNombre + ' ' + this.documento.distribuidor.segundoNombre + ' ' + this.documento.distribuidor.apellidoPaterno + ' ' + this.documento.distribuidor.apellidoMaterno;
			else
				return '';
		},
	},
	methods: {
		onLoaded: async function() {
			this.isLoading = true;
			//Obtenemos documento para ver detalles
			await DocumentoApi.get(this.idDocumento)
				.then(response => {
					this.isLoading = false;
					if(response.success === true) {
						this.documento = response.data
					} else {
						this.$notifier.error(response.message);
					}
				});

		},
		onClose: function() {
			this.$emit('onClose');
		},
	}
}
</script>