<template>
<div>
	<app-header-page :titulos="titleItems"></app-header-page>
	<validation-observer ref="observer">
		<v-form @submit.prevent="">
			<spinner-loading :overlay="isLoading"/>
			<v-card class="mx-4" 
			rounded="lg">
				<v-card-title class="py-4">
					<span class="font-weight-regular">Datos</span>
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12">
							<div class="text--secondary ml-1">Distribuidor*</div>
							<distribuidor-autocomplete 
								ref="autocompleteDistribuidor" 
								:selected="distribuidor"	
								:validError="validErrorDistribuidor"	
								@onChange="onUpdateDistribuidor">	
							</distribuidor-autocomplete>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<div class="text--secondary ml-1">Producto*</div>
							<validation-provider 
								v-slot="{errors}" 
								name="producto" 
								rules="required">
								<v-select 
									v-model="producto" 
									:items="productos"
									outlined
									hide-details="auto"
									item-value="clave"
									:item-text="onProductoToString"
									return-object
									@change="onChangeProducto"
									:error-messages="errors + validErrorProducto">
								<template v-slot:append-outer>
									<v-btn 
										color="secondary"
										text
										small
										:disabled="!loadedDistribuidor || !loadedProducto"
										@click="onOpenDialogHistorial">
										Ver historial
										<v-icon>mdi-history</v-icon>
									</v-btn>
								</template>
								</v-select>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" sm="4" md="4">
							<div class="text--secondary ml-1">Cantidad de Licencias*</div>
							<validation-provider 
								v-slot="{errors}" 
								name="cantidad" 
								rules="required">
								<v-text-field 
									v-model="licenciaObsequio.cantidad" 
									ref="cantidad"
									outlined  
									hide-details="auto"
									v-currency="$utilsInput.integer(false)"
									:error-messages="errors">
								</v-text-field>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" sm="4" md="4">
							<div class="text--secondary ml-1">Precio/Periodo*</div>
							<validation-provider 
								v-slot="{errors}" 
								name="precio periodo" 
								rules="required">
								<v-select 
									v-model="licenciaObsequio.idPrecio" 
									:items="precios"
									outlined
									hide-details="auto"
									item-value="id"
									:item-text="onPrecioToString"
									:error-messages="errors">
								</v-select>
							</validation-provider>
						</v-col>
						<v-col cols="12" sm="4" md="4">
							<div class="text--secondary ml-1">Cantidad de Periodos*</div>
							<validation-provider 
								v-slot="{errors}" 
								name="cantidad periodos" 
								rules="required">
								<v-text-field 
									v-model="licenciaObsequio.cantidadPeriodos" 
									ref="cantidadPeriodos"
									outlined  
									hide-details="auto"
									v-currency="$utilsInput.integer(false)"
									:error-messages="errors">
								</v-text-field>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<div class="text--secondary ml-1">Descripción*</div>
							<validation-provider 
								v-slot="{errors}" 
								name="descripcion" 
								rules="required">
								<v-text-field 
									v-model="licenciaObsequio.descripcion" 
									outlined  
									hide-details="auto"
									@input="licenciaObsequio.descripcion = $utilsInput.uppercase(licenciaObsequio.descripcion)"
									:error-messages="errors">
								</v-text-field>
							</validation-provider>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider class="mx-4 mt-5"></v-divider>
				<v-card-actions>
					<v-row>
						<v-col cols="12" class="text-right">
							<v-btn 
								@click="onCancel"
								color="default"
								:loading="isSaving">
								Cancelar
							</v-btn>
							<v-btn 
								@click="onPreSubmit"
								color="primary" 
								:loading="isSaving"
								class="ml-2">
								Asignar
							</v-btn>
						</v-col>
					</v-row>
				</v-card-actions>
			</v-card>
		</v-form>
	</validation-observer>
	<!--Dialog de historial-->
	<licencias-obsequio-historial-dialog 
		:dialog="dialogHistorial" 
		:distribuidor="distribuidor"
		:producto="producto"
		@onClose="onCloseDialogHistorial">
	</licencias-obsequio-historial-dialog>
	<!--Dialog de confirmacion asignacion-->
	<app-dialog-confirm 
		:dialog-confirm="dialogConfirm"
		title="ASIGNAR"
		@onCancel="onCancelConfirm"
		@onOk="onSubmit">
		¿Seguro que desea asignar {{licenciaObsequio.cantidad}} licencias de obsequio al distribuidor con ID {{licenciaObsequio.idDistribuidor}}?
	</app-dialog-confirm>
</div>
</template>
<script>
import SpinnerLoading from '../components/SpinnerLoading';
import AppHeaderPage from '../components/AppHeaderPage';
import DistribuidorAutocomplete from '../components/DistribuidorAutocomplete';
import LicenciasObsequioHistorialDialog from '../components/LicenciasObsequioHistorialDialog';
import AppDialogConfirm from '../components/AppDialogConfirm';

import LicenciaObsequioApi from '../services/api/licenciaObsequio.api';
import DistribuidorApi from '../services/api/distribuidor.api';
import ProductoApi from '../services/api/producto.api';
import PrecioApi from '../services/api/precio.api';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ObjectToStringMixin from '../mixins/objectToStringMixin'
import { getValue } from 'vue-currency-input';

export default {
	mixins: [ObjectToStringMixin],
	components: {
		SpinnerLoading,
		ValidationProvider,
		ValidationObserver,
		AppHeaderPage,
		DistribuidorAutocomplete,
		LicenciasObsequioHistorialDialog,
		AppDialogConfirm
	},
	created: function() {
		this.onInit();
	},
  data: () => ({
		titleItems: ['Licencias de obsequio', 'Asignar'],
		isLoading: true,
		licenciaObsequio: {
			idDistribuidor: null,
			descripcion: '',
			cantidad: '',
			claveProducto: '',
			idPrecio: null,
			cantidadPeriodos: '',
		},
		distribuidor: {},
		validErrorDistribuidor: '',
		validErrorProducto: '',
		productos: [],
		producto: {},
		precios: [],
		isSaving: false,

		dialogHistorial: false,
		dialogConfirm: false
	}),
	computed: {
		loadedDistribuidor: function() {
			return Object.keys(this.distribuidor).length !== 0;
		},
		loadedProducto: function() {
			return Object.keys(this.producto).length !== 0;
		},
	},
	methods: {
		onInit: async function() {
			this.isLoading = true;
			await ProductoApi.getAll()
			.then(response => {
				if(response.success === true) {
					this.productos = response.data;
				} else {
					this.$notifier.warn('Los productos no se cargaron correctamente. \n' + response.message);
				}
			});
			this.isLoading = false;
		},

		//#region Metodos de formateo select

		onProductoToString: function(item) {
			return this.objectToString(item.clave, item.descripcion);
		},
		onPrecioToString: function(item) {
			return this.objectToString('', item.precio + ' ' + item.claveMoneda + ' / ' + item.periodoPago.descripcion);
		},

		//#endregion

		//#region Metodos para actualizar propiedades

		onUpdateDistribuidor: async function(distribuidorSelected) {
			if(distribuidorSelected != null) {
				let idDistribuidor = distribuidorSelected.id;
				await DistribuidorApi.getBasicById(idDistribuidor)
					.then(response => {
						if(response.success === true) {
							this.distribuidor = response.data;
							this.licenciaObsequio.idDistribuidor = this.distribuidor.id;
							this.$refs.autocompleteDistribuidor.onInitSelection(distribuidorSelected);
						} else {
							this.distribuidor = {};
							this.licenciaObsequio.IdDistribuidor = '';
						}
					});
			} else {
				this.distribuidor = {};
				this.licenciaObsequio.IdDistribuidor = '';
			}
		},
		onChangeProducto: function(productoSelected) {
			if(productoSelected !== null) {
				this.licenciaObsequio.claveProducto = productoSelected.clave
				PrecioApi.getAllByProducto(this.licenciaObsequio.claveProducto)
				.then(response => {
					if(response.success === true) {
						this.precios = response.data;
					} else {
						this.$notifier.warn('Los precios no se cargaron correctamente. \n' + response.message);
					}
				});
			}
		},

		//#endregion

		//#region Metodos de historial de licencias de obsequio
		
		onOpenDialogHistorial: function() {
			//Abrimos dialog
			this.dialogHistorial = true;
		},
		onCloseDialogHistorial: function() {
			//Cerramos dialog
			this.dialogHistorial = false;
		},

		onCancelConfirm: function() {
			//Cerramos dialog
			this.dialogConfirm = false;
			//Cancelamos guardando
			this.isSaving = false;
		},

		//#endregion

		//#region Metodos de acciones de card

		onValidCustom: function() {
			//Validamos y creamos mensaje de error
			this.validErrorDistribuidor = this.loadedDistribuidor ? '' : 'El campo distribuidor es requerido';
			this.validErrorProducto = this.loadedProducto ? '' : 'El campo producto es requerido';
			return this.loadedDistribuidor && this.loadedProducto;
		},
		onCancel: function() {
			this.$router.push('/');
		},
		onPreSubmit: async function() {
			//Prevent multiple guardado
			if(!this.isSaving) {
				this.isSaving = true;
				const isValid = await this.$refs.observer.validate();
				if(this.onValidCustom() && isValid) {
					this.dialogConfirm = true;
				} else {
					this.isSaving = false;
				}
			}
		},
		onSubmit: function(callBack) {
			//Copiamos deep objeto para ajustarlo antes de enviarlo
			let licenciaSaved = JSON.parse(JSON.stringify(this.licenciaObsequio));
			licenciaSaved.cantidad = getValue(this.$refs.cantidad);
			licenciaSaved.cantidadPeriodos = getValue(this.$refs.cantidadPeriodos);
			LicenciaObsequioApi.apply(licenciaSaved)
				.then(response => {
					callBack();
					this.isSaving = false;
					if(response.success === true) {
						this.$router.push('/');
						this.$notifier.success('Licencias de obsequio asignadas exitosamente.');
					} else {
						this.$notifier.error(response.message);
					}
				});
		}
		//#endregion
	}
  }
</script>