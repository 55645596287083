import httpClient from './httpClient';
import httpUtils from '../httpUtils';

const END_POINT = '/EstatusDocumento';

export default {
    getAll(documento) {
        return httpClient.get(END_POINT + '/GetByDocumento/' + documento)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
    get(id) {
        return httpClient.get(END_POINT + '/GetById/' + id)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
    getByEstatus(documento, claveEstatus) {
        return httpClient.get(END_POINT + '/GetByEstatus/' + documento + '/' + claveEstatus)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
}