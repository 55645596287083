<template>
<div>
	<app-header-page :titulos="titleItems"></app-header-page>
	<validation-observer ref="observer">
		<v-form @submit.prevent="">
			<spinner-loading :overlay="isLoading"/>
			<v-card class="mx-4" 
			rounded="lg">
				<v-card-title class="py-2">
					<span class="text-button grey--text text--darken-3">CRITERIOS</span>
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12" sm="4" md="3">
							<div class="text--secondary ml-1">Fecha de Inicio</div>
							<v-menu v-model="menuFechaInicio"
							:close-on-content-click="false"
							transition="scale-transition"
							offset-y
							min-width="auto">
								<template v-slot:activator="{ on, attrs }">
									<validation-provider v-slot="{errors}" 
									name="fecha de inicio" 
									rules="required">
										<v-text-field :value="fechaInicioFormatted"
										outlined  
										hide-details="auto"
										:error-messages="errors"
										prepend-inner-icon="mdi-calendar"
										readonly
										v-bind="attrs"
										v-on="on">
										</v-text-field>
									</validation-provider>
								</template>
								<v-date-picker v-model="fechaInicio"
								no-title
								@input="menuFechaInicio = false">
								</v-date-picker>
							</v-menu>
						</v-col>
						<v-col cols="12" sm="4" md="3">
							<div class="text--secondary ml-1">Fecha de Fin</div>
							<v-menu v-model="menuFechaFin"
							:close-on-content-click="false"
							transition="scale-transition"
							offset-y
							min-width="auto">
								<template v-slot:activator="{ on, attrs }">
									<validation-provider v-slot="{errors}" 
									name="fecha de fin" 
									rules="required">
										<v-text-field :value="fechaFinFormatted"
										outlined  
										hide-details="auto"
										:error-messages="errors"
										prepend-inner-icon="mdi-calendar"
										readonly
										v-bind="attrs"
										v-on="on">
										</v-text-field>
									</validation-provider>
								</template>
								<v-date-picker v-model="fechaFin"
								no-title
								@input="menuFechaFin = false">
								</v-date-picker>
							</v-menu>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<div class="text--secondary ml-1">Distribuidor</div>
							<distribuidor-autocomplete ref="autocompleteDistribuidor" 
							:selected="distribuidor"	
							@onChange="onUpdateDistribuidor">	
							</distribuidor-autocomplete>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider class="mx-4 mt-5"></v-divider>
				<v-card-actions>
					<v-row>
						<v-col cols="12" class="text-right">
							<v-btn 
							color="default"
							@click="onCancel">
								Cancelar
							</v-btn>
							<v-btn @click="onPdf"
							color="primary" 
							:disabled="isLoading"
							class="ml-2">
								PDF
							</v-btn>
							<v-btn @click="onExcel"
							color="primary" 
							:disabled="isLoading"
							class="ml-2">
								Excel
							</v-btn>
						</v-col>
					</v-row>
				</v-card-actions>
			</v-card>
		</v-form>
	</validation-observer>
</div>
</template>
<script>
import SpinnerLoading from '../components/SpinnerLoading';
import AppHeaderPage from '../components/AppHeaderPage';
import DistribuidorAutocomplete from '../components/DistribuidorAutocomplete';

import ReporteApi from '../services/api/reporte.api';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { TipoArchivo } from '../utils/constants';
import DatePickerToStringMixin from '../mixins/datePickerToStringMixin'

export default {
	mixins: [DatePickerToStringMixin],
	components: {
		SpinnerLoading,
		AppHeaderPage,
		ValidationProvider,
		ValidationObserver,
		DistribuidorAutocomplete,
	},
    data: () => ({
		titleItems: ['Reporte', 'Evidencias de carga de saldo por distribuidor'],
		isLoading: false,

		fechaInicio: new Date().toISOString().substr(0, 10),
		fechaFin: new Date().toISOString().substr(0, 10),
		distribuidor: {},
		menuFechaInicio: false,
		menuFechaFin: false
	}),
	computed: {
		fechaInicioFormatted: function() {
			return this.datePickerToString(this.fechaInicio);
		},
		fechaFinFormatted: function() {
			return this.datePickerToString(this.fechaFin);
		},
		loadedDistribuidor: function() {
			return Object.keys(this.distribuidor).length !== 0;
		},
	},
	mounted: function() {
		this.onInit();
	},
	methods: {
		onInit: function() {

		},
		onUpdateDistribuidor: function(distribuidorSelected) {
			if(distribuidorSelected !== null) {
				this.distribuidor = distribuidorSelected;
				this.$refs.autocompleteDistribuidor.onInitSelection(this.distribuidor);
			} else {
				this.distribuidor = {};
			}
		},
		onCancel: function() {
			this.$router.push('/');
		},
		onExcel: async function() {
			const isValid = await this.$refs.observer.validate();
			if(isValid) {
				let url = ReporteApi.evidenciasCargaSaldo(this.fechaInicio, this.fechaFin, this.loadedDistribuidor ? this.distribuidor.id : '', TipoArchivo.EXCEL)
				window.open(url)
			}
		},
		onPdf: async function() {
			const isValid = await this.$refs.observer.validate();
			if(isValid) {
				let url = ReporteApi.evidenciasCargaSaldo(this.fechaInicio, this.fechaFin, this.loadedDistribuidor ? this.distribuidor.id : '', TipoArchivo.PDF)
				window.location.href = url;
				//window.open(url)
			}
		},
	}
  }
</script>