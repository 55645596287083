<template>
<div>
	<app-header-page :titulos="titleItems"></app-header-page>
	<v-card 
		class="mx-4 mb-4" 
		rounded="lg">
		<v-card-text>
			<v-row>
				<v-col cols="12">
					<div class="text--secondary ml-1">Cliente</div>
					<cliente-autocomplete 
						ref="autocompleteCliente" 
						:selected="cliente"
						@onChange="onUpdateCliente">	
					</cliente-autocomplete>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="4" md="4">
					<div class="text--secondary ml-1">ID Licencia</div>
					<v-text-field 
						v-model="idLicencia" 
						outlined  
						@change="onChangeIdLicencia"
						hide-details="auto">
					</v-text-field>
				</v-col>
				<v-col cols="12" sm="4" md="4">
					<div class="text--secondary ml-1">Producto</div>
					<v-select 
						v-model="claveProducto" 
						:items="productos"
						outlined
						hide-details="auto"
						item-value="clave"
						:item-text="onProductoToString"
						clearable
						@change="onChangeProducto">
					</v-select>
				</v-col>
				<v-col cols="12" sm="4" md="4">
					<div class="text--secondary ml-1">ID Dispositivo</div>
					<v-text-field 
						v-model="idDispositivo" 
						outlined  
						@change="onChangeIdDispositivo"
						hide-details="auto">
					</v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="8" md="8">
					<div class="text--secondary ml-1">Buscar</div>
					<v-text-field 
						v-model="search" 
						outlined
						append-icon="mdi-magnify"
						@change="onSearch"
						placeholder="Nombre de Empresa">
					</v-text-field>
				</v-col>
			</v-row>
			<v-data-table 
				:headers="headers"
				:items="items"
				:footer-props="footerProps"
				:page="page"
				:pageCount="totalPages"
				:options.sync="options"
				:server-items-length="totalItems"
				:items-per-page="pageSize"
				:loading="isLoading"
				loading-text="Cargando..."
				no-data-text="No existen registros."
				item
				class="elevation-1"
				disable-sort>
				<template v-slot:item.saldoCredito="{item}">
					{{ item.saldoCredito | currencyParse(2) }} {{item.claveMonedaCredito}}
				</template>			
				<template v-slot:item.producto="{item}">
					{{item.claveProducto}} / {{item.descripcionProducto}}
				</template>	
				<template v-slot:item.acciones="{item}">
					<v-tooltip left>
						<template v-slot:activator="{ on, attrs }">
							<v-btn 
								icon
								color="gray"
								@click="onOpenDialogDetails(item)"
								v-bind="attrs"
								v-on="on">
								<v-icon>mdi-eye</v-icon>
							</v-btn>
						</template>
						<span>Detalles</span>
					</v-tooltip>
					<v-tooltip left>
						<template v-slot:activator="{ on, attrs }">
							<v-btn 
								icon
								color="gray"
								:to="{path: '/licencia/' + item.id + '/' + item.claveProducto + '/pay'}"
								v-bind="attrs"
								v-on="on">
								<v-icon>mdi-cash-plus</v-icon>
							</v-btn>
						</template>
						<span>Ir a Pagar</span>
					</v-tooltip>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
	<!--Dialog details-->
	<licencia-dialog 
		:dialog="dialogDetails"
		:idLicencia="licenciaCurrent.id"
		:claveProducto="licenciaCurrent.claveProducto"
		@onClose="onCloseDialogDetails">
	</licencia-dialog>
</div>
</template>
<script>
import AppHeaderPage from '../components/AppHeaderPage';
import ClienteAutocomplete from '../components/ClienteAutocomplete';
import LicenciaDialog from '../components/LicenciaDialog';

import LicenciaApi from '../services/api/licencia.api';
import ProductoApi from '../services/api/producto.api';
import AuthApi from '../services/api/auth.api';
import AuthTypes from '../store/types/AuthTypes';
import { Funcion } from '../utils/constants';
import ObjectToStringMixin from '../mixins/objectToStringMixin'
import DatePickerToStringMixin from '../mixins/datePickerToStringMixin'

export default {
	mixins: [ObjectToStringMixin, DatePickerToStringMixin],
	components: {
		AppHeaderPage,
		ClienteAutocomplete,
		LicenciaDialog,
	},
    data: () => ({
		titleItems: ['Licencias', 'Por pagar'],
		cliente: {},
		idLicencia: '',
		claveProducto: '',
		productos: [],
		idDispositivo: '',
		search: '',
		headers: [
			{ text: 'Nombre de empresa', value: 'nombreEmpresa' },
			{ text: 'ID Licencia', value: 'id' },
			{ text: 'Producto', value: 'producto' },
			{ text: 'ID Dispositivo', value: 'idDispositivo' },
			{ text: 'Saldo', value: 'saldoCredito' },
			{ text: 'Acciones', value: 'acciones'}
		],
		items: [],
		footerProps: {
			'items-per-page-text': 'Mostrar',
			'items-per-page-options': [10,15,20]
		},
		page: 1,
		totalPages: 0,
		options: {},
		totalItems: 0,
		pageSize: 10,
		isLoading: true,
		licenciaCurrent: {},
		dialogDetails: false,
	}),
	computed: {
		loadedCliente: function() {
			return Object.keys(this.cliente).length !== 0;
		},
		selectedLicencia: function() {
			return Object.keys(this.licenciaCurrent).length !== 0;
		},
	},
	mounted: function() {
		ProductoApi.getAll()
			.then(response => {
				if(response.success === true) {
					this.productos = response.data;
				} else {
					this.$notifier.warn('Los productos no se cargaron correctamente. \n' + response.message);
				}
			});
		this.onPaged();
	},
	watch: {
		options: {
			handler() {
				this.onPaged();
			}
		},
	},
	methods: {
		onPaged: function() {
			this.isLoading = true;
			//Obtenemos datos de footer para API
			const {page, itemsPerPage} = this.options;
			this.page = page;
			this.pageSize = itemsPerPage;
			LicenciaApi.getPagedToPago(this.page, this.pageSize, this.loadedCliente ?  this.cliente.NClave : '', this.idLicencia, this.claveProducto, this.idDispositivo, this.search)
			.then(response => {
				this.isLoading = false;
				if(response.success === true) {
					//Cargamos nuevos datos de paginado
					this.items = response.data.items;
					this.totalItems = parseInt(response.data.totalRecordCount);
					this.totalPages = response.data.totalPages;
				} else {
					this.$notifier.error(response.message)
				}
			});
		},

		onUpdateCliente: async function(clienteSelected) {
			this.cliente = clienteSelected != null ? clienteSelected : {};
			//Inicializamos numero de pagina y paginamos
			this.options.page = 1;
			this.onPaged();
		},
		onChangeIdLicencia: function() {
			//Inicializamos numero de pagina y paginamos
			this.options.page = 1;
			this.onPaged();
		},
		onChangeProducto: function(claveProductoSelected) {
			this.claveProducto = claveProductoSelected != null ? claveProductoSelected : '';
			//Inicializamos numero de pagina y paginamos
			this.options.page = 1;
			this.onPaged();
		},
		onChangeIdDispositivo: function() {
			//Inicializamos numero de pagina y paginamos
			this.options.page = 1;
			this.onPaged();
		},
		onSearch: function() {
			//Inicializamos numero de pagina y paginamos
			this.options.page = 1;
			this.onPaged();
		},

		onProductoToString: function(item) {
			return this.objectToString(item.clave, item.descripcion);
		},

		onOpenDialogDetails: function(licenciaTemp) {
			let emailUsuario = this.$store.getters[AuthTypes.nameModule + '/' + AuthTypes.getters.EMAIL_USUARIO];
			AuthApi.validatePermission(emailUsuario, Funcion.LICENCIA_VER)
                    .then(response => {
                        if (response.success === true) {
							//Salvamos licnecia current(edit/delete)
							this.licenciaCurrent = licenciaTemp
							//Abrimos dialog
							this.dialogDetails = true;
                        } else {
                            this.$notifier.error(response.message)
                        }
                    });
		},
		onCloseDialogDetails: function() {
			//Cerramos dialog
			this.dialogDetails = false;
		},
	}
  }
</script>