<template>
<div>
	<validation-observer ref="observer">
		<v-form @submit.prevent="">
			<v-card 
				class="mx-4" 
				rounded="lg"
			>
				<v-row class="pt-5 pb-1">
					<v-col 
						cols="12" 
						class="d-flex flex-column align-center justify-center"
					>
						<v-img 
							src="images/brand/oxxo.png" 
							contain
							width="300"
						/>	
					</v-col>
				</v-row>
				<v-card-title class="py-4">
					<span class="font-weight-regular">Reportar pago con OXXO</span>
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12">
							<div class="text--secondary ml-1">
								Indica la <b>Clave de Pago OXXO</b> que te asignamos y presiona el boton "Buscar"
								para obtener la información de tu pago en nuestros registros y poder seguir con el proceso de activación.
							</div>
						</v-col>
					</v-row>
					<v-row >
						<v-col 
							cols="12" 
							sm="10" 
							md="8"
							lg="6"
							offset-sm="1"
							offset-md="2"
							offset-lg="3"
						>
							<validation-provider 
								v-slot="{errors}" 
								name="clave de pago" 
								rules="required"
							>
								<v-text-field 
									v-model="clavePagoOxxo" 
									placeholder="Clave de pago*"
									outlined  
									hide-details="auto"
									:error-messages="errors"
								>
								</v-text-field>
							</validation-provider>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider class="mx-4 mt-5"></v-divider>
				<v-card-actions>
					<v-row>
						<v-col cols="12" class="text-right">
							<v-btn 
								@click="onSearch"
								color="primary" 
								:loading="isSearching"
								class="ml-2"
							>
								Buscar
							</v-btn>
						</v-col>
					</v-row>
				</v-card-actions>
			</v-card>
		</v-form>
	</validation-observer>
</div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
	components: {
		ValidationProvider,
		ValidationObserver
	},
  data: () => ({
		clavePagoOxxo: '',
		isSearching: false,
	}),
	methods: {
		onSearch: async function() {
			//Prevent multiple busqueda
			if(!this.isSearching) {
				this.isSearching = true;
				const isValid = await this.$refs.observer.validate();
				if(isValid) {
					this.$emit('onSearch', this.clavePagoOxxo, () => {
							this.isSearching = false;
						});
				} else {
					this.isSearching = false;
				}
			}
		}

		//#endregion
	}
}
</script>