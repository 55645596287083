import NotifierTypes from '@/store/types/NotifierTypes'

export default {
    namespaced: true,
    state: {
        snackbar: {
            show: false,
            type: '',
            content: '',
            timeout: 3000
        }
    },
    mutations: {
        [NotifierTypes.mutations.SHOW]: (state, payload) => {
            state.snackbar.type = payload.type
            state.snackbar.content = payload.content
            state.snackbar.timeout = payload.timeout || state.timeout
            state.snackbar.show = true
        },
        [NotifierTypes.mutations.CLOSE]: (state) => {
            state.snackbar.show = false
            state.snackbar.type = ''
            state.snackbar.content = ''
            state.snackbar.timeout = 3000
        },
    },
}