import axios from 'axios';
import httpClient from './httpClient';
import httpUtils from '../httpUtils';

const END_POINT = '/DocumentoAfectacionSaldo';

export default {
    getPaged(page, pageSize, idDistribuidor, idEstatus, fechaRegistro, cancelSource = axios.CancelToken.source()) {
        return httpClient.get(END_POINT + '/GetPaged?page=' + page + '&pageSize=' + pageSize + '&idDistribuidor=' + idDistribuidor + '&idEstatus=' + idEstatus + '&fechaRegistro=' + fechaRegistro, {
            cancelToken: cancelSource.token
        }).then(response => {
            return httpUtils.responsePagination(response.headers, response.data);
        }).catch(error => {
            if (axios.isCancel(error)) {
                return httpUtils.responseCancel(error);
            } else {
                return httpUtils.responseError(error.response);
            }
        })
    },
    getPagedByDistribuidor(idDistribuidor, page, pageSize, cancelSource = axios.CancelToken.source()) {
        return httpClient.get(END_POINT + '/GetPagedByDistribuidor?idDistribuidor=' + idDistribuidor + '&page=' + page + '&pageSize=' + pageSize, {
            cancelToken: cancelSource.token
        }).then(response => {
            return httpUtils.responsePagination(response.headers, response.data);
        }).catch(error => {
            if (axios.isCancel(error)) {
                return httpUtils.responseCancel(error);
            } else {
                return httpUtils.responseError(error.response);
            }
        })
    },
    get(id) {
        return httpClient.get(END_POINT + '/GetById/' + id)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
    finalize(data) {
        return httpClient.post(END_POINT + '/Finalize', data)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            });
    },
    cancel(id) {
        return httpClient.put(END_POINT + '/Cancel/' + id)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            });
    },
}