import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import VueObserveVisibility from 'vue-observe-visibility'
import VueCurrencyInput from 'vue-currency-input'
import VueHtml2Canvas from 'vue-html2canvas';
import AppNotification from './components/AppNotification'
import notifier from './plugins/notifier'
import utilsInput from './plugins/utils-input'
import validationConfig from './utils/validationConfig'
import filtros from './utils/filters'
import 'core-js/stable'; 
import 'regenerator-runtime/runtime';

//Vue observe visibility
Vue.use(VueObserveVisibility);
//Vue input currency
Vue.use(VueCurrencyInput)
//Vue html canvas
Vue.use(VueHtml2Canvas);

//Notifcador:snackbar | Store
Vue.use(notifier, { store: store });
//Plugin de utilidades de text-field
Vue.use(utilsInput);
//Register components
Vue.component('app-notification', AppNotification);

Vue.config.productionTip = false

new Vue({
    vuetify,
    store,
    router,
    render: h => h(App)
}).$mount('#app')