<template>
<v-row class="px-4 pt-4 pb-1 mb-4" style="background-color: white">
	<v-col cols="8">
		<span class="grey--text text--darken-3 text-h5">{{titulos[0]}} </span>
		<v-icon color="tertiary" class="mb-1">mdi-chevron-right</v-icon>
		<span class="grey--text text--lighten-1 text-subtitle-1">{{titulos[1]}}</span>
	</v-col>
</v-row>
</template>
<script>
export default {
	props: ['titulos']
}
</script>