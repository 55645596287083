import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import AuthTypes from '../store/types/AuthTypes';
import AuthApi from '../services/api/auth.api';
import { Funcion } from '../utils/constants';

import LoginHeader from '../layout/components/LoginHeader';

import LoginPage from '../views/LoginPage';
import PrincipalPage from '../views/PrincipalPage';
import Error403Page from '../views/Error403Page';
import ProductoListPage from '../views/ProductoListPage';
import ProductoAddPage from '../views/ProductoAddPage';
import ProductoEditPage from '../views/ProductoEditPage';
import RolListPage from '../views/RolListPage';
import RolAddPage from '../views/RolAddPage';
import RolEditPage from '../views/RolEditPage';
import ParametrosGeneralesPage from '../views/ParametrosGeneralesPage';
import PartnerListPage from '../views/PartnerListPage';
import PartnerAddPage from '../views/PartnerAddPage';
import PartnerEditPage from '../views/PartnerEditPage';
import DistribuidorListPage from '../views/DistribuidorListPage';
import DistribuidorAddPage from '../views/DistribuidorAddPage';
import DistribuidorEditPage from '../views/DistribuidorEditPage';
import UsuarioListPage from '../views/UsuarioListPage';
import UsuarioAddPage from '../views/UsuarioAddPage';
import UsuarioEditPage from '../views/UsuarioEditPage';
import LicenciaObsequioHistoryPage from '../views/LicenciaObsequioHistoryPage';
import LicenciaObsequioAddPage from '../views/LicenciaObsequioAddPage';
import EvidenciaCargaSaldoListByDistribuidorPage from '../views/EvidenciaCargaSaldoListByDistribuidorPage';
import EvidenciaCargaSaldoListPage from '../views/EvidenciaCargaSaldoListPage';
import EvidenciaCargaSaldoListConfirmPage from '../views/EvidenciaCargaSaldoListConfirmPage';
import EvidenciaCargaSaldoConfirmPage from '../views/EvidenciaCargaSaldoConfirmPage';
import DocumentoAfectacionSaldoListPage from '../views/DocumentoAfectacionSaldoListPage';
import DocumentoAfectacionSaldoAddPage from '../views/DocumentoAfectacionSaldoAddPage';
import CargaSaldoManualPage from '../views/CargaSaldoManualPage';
import LicenciaListByDistribuidorPage from '../views/LicenciaListByDistribuidorPage';
import LicenciaDummyPage from '../views/LicenciaDummyPage';
import LicenciaListPage from '../views/LicenciaListPage';
import LicenciaAddPage from '../views/LicenciaAddPage';
import LicenciaSearchToRenewPage from '../views/LicenciaSearchToRenewPage';
import LicenciaRenewPage from '../views/LicenciaRenewPage';
import LicenciaListToPagoPage from '../views/LicenciaListToPagoPage';
import PagoAddPage from '../views/PagoAddPage';
import ReporteEvidenciasCargaSaldoPage from '../views/ReporteEvidenciasCargaSaldoPage';

import ClienteSearchLicenciaCreditoPage from '../views/ClienteSearchLicenciaCreditoPage';
import ClientePayLicenciaCreditoPage from '../views/ClientePayLicenciaCreditoPage';
import ClienteReviewProductoPage from '../views/ClienteReviewProductoPage';
import ClienteGenerateLicenciaPage from '../views/ClienteGenerateLicenciaPage';
import ClienteSearchLicenciaRenewPage from '../views/ClienteSearchLicenciaRenewPage';
import ClienteRenovateLicenciaPage from '../views/ClienteRenovateLicenciaPage';
import ClienteReportPagoOxxoPage from '../views/ClienteReportPagoOxxoPage';

Vue.use(VueRouter);

//Definimos rutas
const routes = [{
    path: '/login',
    name: 'Login',
    components: {
        header: LoginHeader,
        default: LoginPage,
    },
}, {
    path: '/cliente/search-license-credit',
    name: 'ClienteSearchLicenciaCredito',
    components: {
        header: LoginHeader,
        default: ClienteSearchLicenciaCreditoPage,
    },
}, {
    path: '/cliente/:id/:claveProducto/pay-license-credit',
    name: 'ClientePayLicenciaCredito',
    components: {
        header: LoginHeader,
        default: ClientePayLicenciaCreditoPage,
    },
}, {
    path: '/license/review',
    name: 'ClienteReviewGenerateProducto',
    components: {
        header: LoginHeader,
        default: ClienteReviewProductoPage,
    },
}, {
    path: '/license/generate',
    name: 'ClienteGenerateLicencia',
    components: {
        header: LoginHeader,
        default: ClienteGenerateLicenciaPage,
    },
}, {
    path: '/license/search-license-renew',
    name: 'ClienteSearchLicenciaRenew',
    components: {
        header: LoginHeader,
        default: ClienteSearchLicenciaRenewPage,
    },
}, {
    path: '/license/:id/:claveProducto/review',
    name: 'ClienteReviewRenovateProducto',
    components: {
        header: LoginHeader,
        default: ClienteReviewProductoPage,
    },
}, {
    path: '/license/:id/:claveProducto/:clavePeriodo/renovate',
    name: 'ClienteRenovateLicencia',
    components: {
        header: LoginHeader,
        default: ClienteRenovateLicenciaPage,
    },
}, {
    path: '/license/report-pago-oxxo',
    name: 'ClienteReportPagoOxxo',
    components: {
        header: LoginHeader,
        default: ClienteReportPagoOxxoPage,
    },
}, {
    path: '/',
    name: 'Principal',
    components: {
        default: PrincipalPage,
    },
}, {
    path: '/403',
    name: 'Error403',
    components: {
        default: Error403Page,
    },
}, {
    path: '/producto',
    name: 'Productos',
    components: {
        default: ProductoListPage,
    },
    meta: { function: Funcion.PRODUCTO_LISTADO }
}, {
    path: '/producto/add',
    name: 'ProductoAdd',
    components: {
        default: ProductoAddPage,
    },
    meta: { function: Funcion.PRODUCTO_AGREGAR }
}, {
    path: '/producto/:clave/edit',
    name: 'ProductoEdit',
    components: {
        default: ProductoEditPage,
    },
    meta: { function: Funcion.PRODUCTO_EDITAR }
}, {
    path: '/rol',
    name: 'Roles',
    components: {
        default: RolListPage,
    },
    meta: { function: Funcion.ROL_LISTADO }
}, {
    path: '/rol/add',
    name: 'RolAdd',
    components: {
        default: RolAddPage,
    },
    meta: { function: Funcion.ROL_AGREGAR }
}, {
    path: '/rol/:clave/edit',
    name: 'RolEdit',
    components: {
        default: RolEditPage,
    },
    meta: { function: Funcion.ROL_EDITAR }
}, {
    path: '/parametros',
    name: 'ParametrosGenerales',
    components: {
        default: ParametrosGeneralesPage,
    },
    meta: { function: Funcion.PARAMETROS_GENERALES }
}, {
    path: '/partner',
    name: 'Partners',
    components: {
        default: PartnerListPage,
    },
    meta: { function: Funcion.PARTNER_LISTADO }
}, {
    path: '/partner/add',
    name: 'PartnerAdd',
    components: {
        default: PartnerAddPage,
    },
    meta: { function: Funcion.PARTNER_AGREGAR }
}, {
    path: '/partner/:id/edit',
    name: 'PartnerEdit',
    components: {
        default: PartnerEditPage,
    },
    meta: { function: Funcion.PARTNER_EDITAR }
}, {
    path: '/distribuidor',
    name: 'Distribuidores',
    components: {
        default: DistribuidorListPage,
    },
    meta: { function: Funcion.DISTRIBUIDOR_LISTADO }
}, {
    path: '/distribuidor/add',
    name: 'DistribuidorAdd',
    components: {
        default: DistribuidorAddPage,
    },
    meta: { function: Funcion.DISTRIBUIDOR_AGREGAR }
}, {
    path: '/distribuidor/:id/edit',
    name: 'DistribuidorEdit',
    components: {
        default: DistribuidorEditPage,
    },
    meta: { function: Funcion.DISTRIBUIDOR_EDITAR }
}, {
    path: '/usuario',
    name: 'Usuarios',
    components: {
        default: UsuarioListPage,
    },
    meta: { function: Funcion.USUARIO_LISTADO }
}, {
    path: '/usuario/add',
    name: 'UsuarioAdd',
    components: {
        default: UsuarioAddPage,
    },
    meta: { function: Funcion.USUARIO_AGREGAR }
}, {
    path: '/usuario/:id/edit',
    name: 'UsuarioEdit',
    components: {
        default: UsuarioEditPage,
    },
    meta: { function: Funcion.USUARIO_EDITAR }
}, {
    path: '/licencia-obsequio/history',
    name: 'LicenciasObsequio',
    components: {
        default: LicenciaObsequioHistoryPage,
    },
    meta: { function: Funcion.LICENCIAS_OBSEQUIO_HISTORIAL }
}, {
    path: '/licencia-obsequio/add',
    name: 'LicenciaObsequioAdd',
    components: {
        default: LicenciaObsequioAddPage,
    },
    meta: { function: Funcion.LICENCIAS_OBSEQUIO_ASIGNAR }
}, {
    path: '/evidencia-carga-saldo/distribuidor',
    name: 'EvidenciasCargaSaldoDistribuidor',
    components: {
        default: EvidenciaCargaSaldoListByDistribuidorPage,
    },
    meta: { function: Funcion.EVIDENCIA_CARGA_SALDO_LISTADO }
}, {
    path: '/evidencia-carga-saldo/all',
    name: 'EvidenciasCargaSaldo',
    components: {
        default: EvidenciaCargaSaldoListPage,
    },
    meta: { function: Funcion.EVIDENCIA_CARGA_SALDO_LISTADO }
}, {
    path: '/evidencia-carga-saldo/list-confirm',
    name: 'EvidenciasCargaSaldoConfirm',
    components: {
        default: EvidenciaCargaSaldoListConfirmPage,
    },
    meta: { function: Funcion.EVIDENCIA_CARGA_SALDO_CONFIRMAR_RECHAZAR }
}, {
    path: '/evidencia-carga-saldo/:id/confirm',
    name: 'EvidenciaCargaSaldoConfirm',
    components: {
        default: EvidenciaCargaSaldoConfirmPage,
    },
    meta: { function: Funcion.EVIDENCIA_CARGA_SALDO_CONFIRMAR_RECHAZAR }
}, {
    path: '/documento-afectacion-saldo/all',
    name: 'DocumentosAfectacionSaldo',
    components: {
        default: DocumentoAfectacionSaldoListPage,
    },
    meta: { function: Funcion.DOCUMENTO_AFECTACION_SALDO_LISTADO }
}, {
    path: '/documento-afectacion-saldo/add',
    name: 'DocumentoAfectacionSaldoAdd',
    components: {
        default: DocumentoAfectacionSaldoAddPage,
    },
    meta: { function: Funcion.DOCUMENTO_AFECTACION_SALDO_REGISTRAR }
}, {
    path: '/carga-saldo-manual',
    name: 'CargaSaldoManual',
    components: {
        default: CargaSaldoManualPage,
    },
    meta: { function: Funcion.CARGA_SALDO_MANUAL_REGISTRAR }
}, {
    path: '/licencia/distribuidor',
    name: 'LicenciasDistribuidor',
    components: {
        default: LicenciaListByDistribuidorPage,
    },
    meta: { function: Funcion.LICENCIA_LISTADO }
}, {
    path: '/licencia/all',
    name: 'Licencias',
    components: {
        default: LicenciaListPage,
    },
    meta: { function: Funcion.LICENCIA_LISTADO }
}, {
    path: '/licencia/add',
    name: 'LicenciaAdd',
    components: {
        default: LicenciaAddPage,
    },
    meta: { function: Funcion.LICENCIA_GENERAR }
}, {
    path: '/licencia/search-to-renew',
    name: 'LicenciaSearchToRenew',
    components: {
        default: LicenciaSearchToRenewPage,
    },
    meta: { function: Funcion.LICENCIA_RENOVAR }
}, {
    path: '/licencia/:idLicencia/:claveProducto/renew',
    name: 'LicenciaRenew',
    components: {
        default: LicenciaRenewPage,
    },
    meta: { function: Funcion.LICENCIA_RENOVAR }
}, {
    path: '/licencia/list-to-pay',
    name: 'LicenciasListToPay',
    components: {
        default: LicenciaListToPagoPage,
    },
    meta: { function: Funcion.LICENCIA_PAGAR }
}, {
    path: '/licencia/:id/:claveProducto/pay',
    name: 'PagoAdd',
    components: {
        default: PagoAddPage,
    },
    meta: { function: Funcion.LICENCIA_PAGAR }
}, {
    path: '/reporte/evidencias-carga-saldo',
    name: 'ReporteEvidenciasCargaSaldo',
    components: {
        default: ReporteEvidenciasCargaSaldoPage,
    },
    //meta: { function: Funcion.LICENCIA_PAGAR }
}, {
    path: '/licencia-dummy/add',
    name: 'LicenciaDummy',
    components: {
        default: LicenciaDummyPage,
    }
}];

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    if (to.name !== 'Login' &&
        to.name !== 'ClienteSearchLicenciaCredito' &&
        to.name !== 'ClientePayLicenciaCredito' &&
        to.name !== 'ClienteReviewGenerateProducto' &&
        to.name !== 'ClienteGenerateLicencia' &&
        to.name !== 'ClienteSearchLicenciaRenew' &&
        to.name !== 'ClienteReviewRenovateProducto' &&
        to.name !== 'ClienteRenovateLicencia' &&
        to.name !== 'ClienteReportPagoOxxo') {
        if (!store.getters[AuthTypes.nameModule + '/' + AuthTypes.getters.IS_AUTHENTICATED])
            next({ name: 'Login' })
        else {
            //Validamos permiso
            let claveFuncion = (typeof to.meta.function !== 'undefined') ? to.meta.function : '';
            if (claveFuncion !== '') {
                let emailUsuario = store.getters[AuthTypes.nameModule + '/' + AuthTypes.getters.EMAIL_USUARIO];
                AuthApi.validatePermission(emailUsuario, claveFuncion)
                    .then(response => {
                        if (response.success === true) {
                            next();
                        } else {
                            next({ name: 'Error403' })
                        }
                    });
            } else {
                next();
            }
        }
    } else {
        next();
    }
})

export default router;