<template>
<div>
	<app-header-page :titulos="titleItems"></app-header-page>
	<validation-observer ref="observer">
		<v-form @submit.prevent="">
			<v-card class="mx-4" 
			rounded="lg">
				<v-card-title class="py-4">
					<span class="font-weight-regular">Datos</span>
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12">
							<div class="text--secondary ml-1">Distribuidor*</div>
							<distribuidor-autocomplete ref="autocompleteDistribuidor" 
							:selected="distribuidor"
							:validError="validErrorDistribuidor"	
							@onChange="onUpdateDistribuidor">	
							</distribuidor-autocomplete>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<div class="text--secondary ml-1">Nombre Empresa*</div>
							<validation-provider v-slot="{errors}" 
							name="nombre empresa" 
							rules="required">
								<v-text-field v-model="datosActivacion.nombreEmpresa" 
								outlined  
								hide-details="auto"
								@input="datosActivacion.nombreEmpresa = $utilsInput.uppercase(datosActivacion.nombreEmpresa)"
								:error-messages="errors">
								</v-text-field>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" sm="8" md="8">
							<div class="text--secondary ml-1">Producto*</div>
							<validation-provider v-slot="{errors}" 
							name="producto" 
							rules="required">
								<v-select v-model="datosActivacion.claveProducto" 
								:items="productos"
								outlined
								hide-details="auto"
								item-value="clave"
								:item-text="onProductoToString"
								:error-messages="errors">
								</v-select>
							</validation-provider>
						</v-col>
						<v-col cols="12" sm="4" md="4">
							<div class="text--secondary ml-1">ID Dispositivo*</div>
							<validation-provider v-slot="{errors}" 
							name="id dispositivo" 
							rules="required">
								<v-text-field v-model="datosActivacion.idDispositivo" 
								outlined  
								hide-details="auto"
								:error-messages="errors">
								</v-text-field>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" sm="4" md="4">
							<div class="text--secondary ml-1">País*</div>
							<validation-provider v-slot="{errors}" 
								name="pais" 
								rules="required">
								<v-autocomplete v-model="datosActivacion.idPais" 
								:items="paises"
								outlined
								hide-details="auto"
								item-value="nId"
								:item-text="onPaisToString"
								:error-messages="errors"
								@change="onChangePais">
								</v-autocomplete>
							</validation-provider>
						</v-col>
						<v-col cols="12" sm="4" md="4">
							<div class="text--secondary ml-1">Estado*</div>
							<validation-provider v-slot="{errors}" 
								name="estado" 
								rules="required">
								<v-autocomplete v-model="datosActivacion.idEstado" 
								:items="estados"
								outlined
								hide-details="auto"
								item-value="NClave"
								:item-text="onEstadoToString"
								:error-messages="errors"
								@change="onChangeEstado">
								</v-autocomplete>
							</validation-provider>
						</v-col>
						<v-col cols="12" sm="4" md="4">
							<div class="text--secondary ml-1">Ciudad*</div>
							<validation-provider v-slot="{errors}" 
								name="ciudad" 
								rules="required">
								<v-autocomplete v-model="datosActivacion.idCiudad" 
								:items="ciudades"
								outlined
								hide-details="auto"
								item-value="NId"
								:item-text="onCiudadToString"
								:error-messages="errors">
								</v-autocomplete>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" sm="4" md="4">
							<validation-provider vid="esACredito" v-slot="{errors}"
							rules="">
								<v-checkbox
								name="esACredito"
								v-model="datosActivacion.esACredito"
								label="Es a Credito"
								:error-messages="errors">
								</v-checkbox>
							</validation-provider>
						</v-col>
						<v-col cols="12" sm="4" md="4">
							<div class="text--secondary ml-1">Numero de Periodos de Credito</div>
							<validation-provider v-slot="{errors}" 
							name="periodos credito" 
							rules="required_if:esACredito,true">
								<v-select v-model="datosActivacion.numeroPeriodosCredito" 
								:items="periodosCredito"
								outlined
								hide-details="auto"
								item-value="id"
								:item-text="onPeriodoCreditoToString"
								:disabled="!datosActivacion.esACredito"
								:error-messages="errors">
								</v-select>
							</validation-provider>
						</v-col>
					</v-row>
					<v-divider class="my-4"></v-divider>
					<v-row class="my-4">
						<v-col cols="12" class="text-center">
							<v-btn @click="onSubmit"
							width="200"
							color="primary" 
							:loading="isSaving"
							class="ml-2">
								Generar
							</v-btn>
						</v-col>
					</v-row>
					<v-card v-show="activacionSuccess"
					class="mt-5 mb-2" 
					elevation="0"
					color="default">
							<v-card-title class="py-2">
								<span class="text-button grey--text text--darken-3">LICENCIA</span>
							</v-card-title>
							<v-card-text>
								<table>
									<tr>
										<td><span class="grey--text text--darken-3">Codigo de Referencia:</span></td>
										<td><span class="text-h6 ml-3">{{codigoReferencia}}</span></td>
									</tr>
									<tr>
										<td><span class="grey--text text--darken-3">ID Licencia:</span></td>
										<td><span class="text-h6 ml-3">{{idLicencia}}</span></td>
									</tr>
									<tr>
										<td><span class="grey--text text--darken-3">Clave de Activación:</span></td>
										<td><span class="text-h6 ml-3">{{claveActivacion}}</span></td>
									</tr>
								</table>
								<input type="hidden" id="input-licencia" :value="licenciaFormattedCopy">
								<v-btn small
								color="secondary"
								@click="onCopyLicencia()">
									<v-icon class="mr-2" small>mdi-content-copy</v-icon>
									Copiar llave de activación al portapapeles
								</v-btn>
							</v-card-text>
						</v-card>
				</v-card-text>
			</v-card>
		</v-form>
	</validation-observer>
</div>
</template>
<script>
import AppHeaderPage from '../components/AppHeaderPage';
import DistribuidorAutocomplete from '../components/DistribuidorAutocomplete';

import LicenciaApi from '../services/api/licencia.api';
import DistribuidorApi from '../services/api/distribuidor.api';
import ProductoApi from '../services/api/producto.api';
import PaisErpApi from '../services/api-erp/pais.api';
import EstadoErpApi from '../services/api-erp/estado.api';
import CiudadErpApi from '../services/api-erp/ciudad.api';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { PeriodosCredito } from '../utils/constants';
import ObjectToStringMixin from '../mixins/objectToStringMixin'

export default {
	mixins: [ObjectToStringMixin],
	components: {
		ValidationProvider,
		ValidationObserver,
		AppHeaderPage,
		DistribuidorAutocomplete,
	},
	created: function() {
		this.onInit();
	},
    data: () => ({
		titleItems: ['Licencia', 'Generar'],
		isLoading: true,
		datosActivacion: {
			idDistribuidor: null,
			nombreEmpresa: '',
			idDispositivo: '',
			esACredito: false,
			numeroPeriodosCredito: '',
			idPais: '',
			idEstado: '',
			idCiudad: '',
			claveProducto: '',
		},
		distribuidor: {},
		validErrorDistribuidor: '',
		periodosCredito: PeriodosCredito,
		productos: [],
		paises: [],
		estados: [],
		ciudades: [],

		codigoReferencia: '',
		idLicencia: '',
		claveActivacion: '',
		isSaving: false,

		dialogHistorial: false,
	}),
	computed: {
		loadedDistribuidor: function() {
			return Object.keys(this.distribuidor).length !== 0;
		},
		activacionSuccess: function() {
			return this.claveActivacion !== '';
		},
		licenciaFormattedCopy: function() {
			return this.codigoReferencia + '|' + this.idLicencia + '|' + this.claveActivacion;
		}
	},
	methods: {
		onInit: async function() {
			this.isLoading = true;
			await ProductoApi.getAll()
			.then(response => {
				if(response.success === true) {
					this.productos = response.data;
				} else {
					this.$notifier.warn('Los productos no se cargaron correctamente. \n' + response.message);
				}
			});
			await PaisErpApi.getAll()
			.then(response => {
				if(response.success === true) {
					this.paises = response.data;
				} else {
					this.$notifier.warn('Los paises no se cargaron correctamente. \n' + response.message);
				}
			});
		},

		//#region Metodos de formateo select
		
		onProductoToString: function(item) {
			return this.objectToString('', item.descripcion);
		},
		onPeriodoCreditoToString: function(item) {
			return this.objectToString('', item.text);
		},
		onPaisToString: function(item) {
			return this.objectToString('', item.cNombre);
		},
		onEstadoToString: function(item) {
			return this.objectToString('', item.CNombre);
		},
		onCiudadToString: function(item) {
			return this.objectToString('', item.CNombre);
		},
		
		//#endregion

		//#region Metodos para actualizar propiedades

		onUpdateDistribuidor: async function(distribuidorSelected) {
			if(distribuidorSelected != null) {
				let idDistribuidor = distribuidorSelected.id;
				await DistribuidorApi.getBasicById(idDistribuidor)
					.then(response => {
						if(response.success === true) {
							this.distribuidor = response.data;
							this.datosActivacion.idDistribuidor = this.distribuidor.id;
							this.$refs.autocompleteDistribuidor.onInitSelection(distribuidorSelected);
						} else {
							this.distribuidor = {};
							this.datosActivacion.IdDistribuidor = '';
						}
					});
			} else {
				this.distribuidor = {};
				this.datosActivacion.idDistribuidor = '';
			}
		},

		//#endregion
		
		//#region Metodos para actualizar dom por cambio en controles

		onChangePais: function(idPaisSelected, callFromInit = false) {
			EstadoErpApi.getAll(idPaisSelected)
			.then(response => {
				if(response.success === true) {
					this.estados = response.data;
					if(!callFromInit) {
						this.ciudades = [];
						this.colonias = [];
					}
				} else {
					this.$notifier.warn('Los estados no se cargaron correctamente. \n' + response.message);
				}
			});
		},
		onChangeEstado: function(idEstadoSelected, callFromInit = false) {
			CiudadErpApi.getAll(idEstadoSelected)
			.then(response => {
				if(response.success === true) {
					this.ciudades = response.data;
					if(!callFromInit) {
						this.colonias = [];
					}
				} else {
					this.$notifier.warn('Las ciudades no se cargaron correctamente. \n' + response.message);
				}
			});
		},

		//#endregion

		//#region Metodos de acciones de card
		
		onCopyLicencia: function() {
			let licenciaToCopy = document.querySelector('#input-licencia')
			licenciaToCopy.setAttribute('type', 'text')
			licenciaToCopy.select()
			try {
				var successful = document.execCommand('copy');
				if(!successful) {
					alert('Error al copiar los datos de licencia, debera copiarlos manualmente.');
				}
			} catch (err) {
				alert('Oops, tiene deshabilitado el copiado de texto.');
			}
			/* unselect the range */
			licenciaToCopy.setAttribute('type', 'hidden');
			window.getSelection().removeAllRanges();
		},
		onValidCustom: function() {
			//Validamos y creamos mensaje de error
			this.validErrorDistribuidor = this.loadedDistribuidor ? '' : 'El campo distribuidor es requerido';
			return this.loadedDistribuidor;
		},
		onCancel: function() {
			this.$router.push('/');
		},
		onSubmit: async function() {
			//Prevent multiple guardado
			if(!this.isSaving) {
				this.isSaving = true;
				const isValid = await this.$refs.observer.validate();
				if(isValid && this.onValidCustom()) {
					//Copiamos deep objeto para ajustarlo antes de enviarlo
					let datosActivacionSaved = JSON.parse(JSON.stringify(this.datosActivacion));
					LicenciaApi.activationDummy(datosActivacionSaved)
						.then(response => {
							this.isSaving = false;
							if(response.success === true) {
								this.codigoReferencia = response.data.codigoReferencia;
								this.idLicencia = response.data.idLicencia;
								this.claveActivacion = response.data.claveActivacion;
								this.$notifier.success('Clave de activación generada exitosamente.');
							} else {
								this.$notifier.error(response.message);
							}
						});
				} else {
					this.isSaving = false;
				}
			}
		}

		//#endregion
	}
  }
</script>