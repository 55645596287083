<template>
	<v-row align="center" justify="center">
		<v-col v-if="esLicenciaGenerada" cols="12" sm="10" md="8" lg="6">
			<licencia-cliente-success-card
				:idLicencia="licencia.idLicencia"
				:claveActivacion="licencia.claveActivacion"
				:cadenaActivacion="licencia.cadenaActivacion"
				:descripcionProducto="producto.descripcion"
				:esGratis="licencia.esGratis"
				:precio="precio.precio"
				:claveMoneda="datosActivacion.claveMoneda"
				:descripcionPeriodo="licencia.descripcionPeriodo"
				:codigoReferencia="distribuidor.codigoReferencia"
				:nombreEmpresa="datosActivacion.nombreEmpresa"
				:idDispositivo="datosActivacion.idDispositivo"
				:telefono="datosActivacion.telefono"
				:correo="datosActivacion.correo"
				:mensajeEnvioCorreo="mensajeEnvioCorreo"
				@onCancel="onCancel"
			/>
		</v-col>
		<v-col v-else-if="esVoucherOxxoGenerado" cols="12" sm="10" md="8" lg="6">
			<licencia-cliente-voucher-oxxo-card
				:paymentIntent="paymentIntent"
				:clavePagoOxxo="pagoOxxo.clavePagoOxxo"
				@onCancel="onCancel"
			/>
		</v-col>
		<v-col v-else cols="12" sm="12" md="10" lg="8">
			<spinner-loading :overlay="isProcessing"/>
			<licencia-cliente-card 
				:esGratis="esGratis"
				:claveProducto="claveProducto"
				:clavePeriodo="clavePeriodo"
				@onSubmit="onGenerate"
			/>
		</v-col>
		<!--Dialog de opcion de pago-->
		<opciones-pago-dialog
			:dialog="dialogOpcionesPago"
			:claveProducto="datosActivacion.claveProducto"
			:claveMoneda="datosActivacion.claveMoneda"
			:clavePeriodoPago="datosActivacion.clavePeriodoPago"
			:montoPago="precio.precio"
			:idDispositivo="datosActivacion.idDispositivo"
			@onClose="onCloseDialogOpcionesPago"
			@onSelected="onSelectedOpcionPago"
			@onHandlePaypalCapturePayment="onHandlePaypalCapturePayment"
			@onHandlePaypalCancelPayment="onHandlePaypalCancelPayment"
			@onHandlePaypalErrorPayment="onHandlePaypalErrorPayment"
		/>
		<!--Dialog de pago stripe/tarjeta-->
		<pago-cliente-tarjeta-dialog 
			:dialog="dialogTarjeta"
			:nombreCliente="datosActivacion.nombreEmpresa"
			:montoPago="precio.precio"
			:claveMoneda="datosActivacion.claveMoneda"
			@onClose="onCloseDialogTarjeta"
			@onHandleStripePaymentMethod="onHandleStripePaymentMethod"
		/>
		<!--Dialog de pago stripe/oxxo-->
		<pago-cliente-oxxo-dialog 
			:dialog="dialogOxxo"
			:correo="datosActivacion.correo"
			:montoPago="precio.precio"
			:claveMoneda="datosActivacion.claveMoneda"
			@onClose="onCloseDialogOxxo"
			@onHandleStripeOxxo="onHandleStripeOxxo"
		/>
	</v-row>
</template>
<script>
import SpinnerLoading from '../components/SpinnerLoading';
import LicenciaClienteCard from '../components/LicenciaClienteCard';
import LicenciaClienteSuccessCard from '../components/LicenciaClienteSuccessCard';
import OpcionesPagoDialog from '../components/OpcionesPagoDialog';
import PagoClienteTarjetaDialog from '../components/PagoClienteTarjetaDialog';
import PagoClienteOxxoDialog from '../components/PagoClienteOxxoDialog';
import LicenciaClienteVoucherOxxoCard from '../components/LicenciaClienteVoucherOxxoCard';

import LicenciaApi from '../services/api/licencia.api';
import PagoOxxoApi from '../services/api/pagoOxxo.api';
import PagoPaypalApi from '../services/api/pagoPaypal.api';
import { OpcionPago } from '../utils/constants';

export default {
	components: {
		SpinnerLoading,
		LicenciaClienteCard,
		LicenciaClienteSuccessCard,
		OpcionesPagoDialog,
		PagoClienteTarjetaDialog,
		PagoClienteOxxoDialog,
		LicenciaClienteVoucherOxxoCard
	},
	created: function() {
		this.esGratis = (this.$route.query.esGratis === 'true');
		this.claveProducto = this.$route.query.claveProducto;
		this.clavePeriodo = this.$route.query.clavePeriodo;
	},
  data: () => ({
		esGratis: false,
		claveProducto: '',
		clavePeriodo: '',

		datosActivacion: {},
		producto: {},
		precio: {},
		distribuidor: {},
		stripe: null,
		pagoOxxo: {},
		paymentIntent: {},
		mensajeEnvioCorreo: '',

		dialogOpcionesPago: false,
		dialogTarjeta: false,
		dialogOxxo: false,
		
		isProcessing: false,
		esVoucherOxxoGenerado: false,
		esLicenciaGenerada: false,
		licencia: {},
	}),
	methods: {
		onCancel: function() {
			window.location.href = 'http://pospos.com.mx';
		},

		onGenerate: function(datosActivacion, producto, precio, distribuidor, callBack) {
			this.producto = producto;
			this.precio = precio;
			this.distribuidor = distribuidor;
			if(this.esGratis) {
				this.onGenerateLicenciaFree(datosActivacion, distribuidor, callBack);
			} else {
				this.onOpenDialogOpcionesPago(datosActivacion, distribuidor, callBack);
			}
		},

		//#region Gratis

		onGenerateLicenciaFree: function(datosActivacion, distribuidor, callBack) {
			this.isProcessing = true;
			this.datosActivacion = {
				idDispositivo: datosActivacion.idDispositivo,
				idDistribuidor: distribuidor.id,
				nombreEmpresa: datosActivacion.nombreEmpresa,
				idPais: datosActivacion.idPais,
				idEstado: datosActivacion.idEstado,
				idCiudad: datosActivacion.idCiudad,
				telefono: datosActivacion.telefono,
				correo: datosActivacion.correo,
				claveProducto: datosActivacion.claveProducto,
			};
			LicenciaApi.activationFree(this.datosActivacion)
			.then(response => {
				// Ocultamos spinner loading
				this.isProcessing = false;
				callBack();
				// Mostramos resultado api
				if(response.success === true) {
					this.licencia = response.data;
					this.mensajeEnvioCorreo = response.message || '';
					this.esLicenciaGenerada = true;
				} else {
					this.$notifier.error(response.message);
				}
			});
		},

		//#endregion

		//#region Dialog opciones de pago

		onOpenDialogOpcionesPago: function (datosActivacion, distribuidor, callBack) {
			callBack();
			this.datosActivacion = {
				idDispositivo: datosActivacion.idDispositivo,
				idDistribuidor: distribuidor.id,
				nombreEmpresa: datosActivacion.nombreEmpresa,
				idPais: datosActivacion.idPais,
				idEstado: datosActivacion.idEstado,
				idCiudad: datosActivacion.idCiudad,
				claveProducto: datosActivacion.claveProducto,
				clavePeriodoPago: datosActivacion.clavePeriodoPago,
				claveMoneda: datosActivacion.claveMoneda,
				telefono: datosActivacion.telefono,
				correo: datosActivacion.correo,
			};
			//Abrimos dialog 
			this.dialogOpcionesPago = true;
		},
		onCloseDialogOpcionesPago: function(mostrarSpinner) {
			//Cerramos dialog 
			this.dialogOpcionesPago = false;
			if(mostrarSpinner === true) {
				this.isProcessing = true;
			}
		},
		onSelectedOpcionPago: function(opcion) {
				//Cerramos dialog 
			this.dialogOpcionesPago = false;
			switch(opcion) {
				case OpcionPago.TARJETA:
					this.onOpenDialogTarjeta();
					break;
				case OpcionPago.OXXO:
					this.onOpenDialogOxxo();
					break;
			}
		},

		//#endregion

		//#region Dialog pago con tarjeta

		onOpenDialogTarjeta: function () {
			//Abrimos dialog
			this.dialogTarjeta = true;
		},
		onCloseDialogTarjeta: function() {
			//Cerramos dialog
			this.dialogTarjeta = false;
		},
		onFormatPagoStripe: function(paymentMethodId, paymentIntentId) {
			if(paymentMethodId !== null && paymentMethodId != '') {
				this.datosActivacion.paymentMethodId = paymentMethodId;
			} else if(paymentIntentId !== null && paymentIntentId != '') {
				this.datosActivacion.paymentIntentId = paymentIntentId;
			}
		},
		onHandleStripePaymentMethod: function(result, callback, stripe) {
			this.stripe = stripe;
			if (result.error) {
				callback();
				this.$notifier.error(result.error.message);
			} else {
				//Mandamos datos de pago junto con paymentMethod.id creado
				this.onFormatPagoStripe(result.paymentMethod.id, null);
				LicenciaApi.activationAndPayStripe(this.datosActivacion)
				.then(response => {
					this.onHandleStripeApiResponse(response, callback);
				});
			}
		},
		onHandleStripeApiResponse: function(response, callback) {
			if(response.success === true) {
				if(response.data !== null && typeof response.data.requiresAction !== 'undefined' && response.data.requiresAction) {
					this.stripe.handleCardAction(
						response.data.paymentIntentClientSecret
					).then(result => {
						this.onHandleStripeCardAction(result, callback);
					});
				} else {
					callback();
					// Obtenemos valores de licencia
					this.licencia = response.data;
					this.mensajeEnvioCorreo = response.message || '';
					this.dialogTarjeta = false;
					this.esLicenciaGenerada = true;
				}
			} else {
				callback();
				this.$notifier.error(response.message);
			}
		},
		onHandleStripeCardAction: function(result, callback) {
			if(result.error) {
				callback();
				this.$notifier.error(result.error.message, 5000);
			} else {
				//Mandamos datos de pago junto con paymentIntent.id confirmado
				this.onFormatPagoStripe(null, result.paymentIntent.id)
				LicenciaApi.activationAndPayStripe(this.datosActivacion)
				.then(response => {
					this.onHandleStripeApiResponse(response, callback);
				});
			}
		},

		//#endregion

		//#region Dialog paypal
		
		onHandlePaypalCapturePayment: function(orderId) {
			this.datosActivacion.orderId = orderId;
			PagoPaypalApi.capturePaymentActivation(this.datosActivacion)
			.then(response => {
				// Ocultamos spinner loading
				this.isProcessing = false;
				// Mostramos resultado api
				if(response.success === true) {
					this.licencia = response.data;
					this.mensajeEnvioCorreo = response.message || '';
					this.esLicenciaGenerada = true;
				} else {
					this.$notifier.error(response.message);
				}
			});
		},
		onHandlePaypalCancelPayment: function() {
			// Ocultamos spinner loading
			this.isProcessing = false;
		},
		onHandlePaypalErrorPayment: function(err) {
			// Ocultamos spinner loading
			this.isProcessing = false;
		},

		//#endregion

		//#region Dialog oxxo
		
		onOpenDialogOxxo: function () {
			//Abrimos dialog
			this.dialogOxxo = true;
		},
		onCloseDialogOxxo: function() {
			//Cerramos dialog
			this.dialogOxxo = false;
		},
		onHandleStripeOxxo: function(nombreCliente, correoCliente, callback, stripe) {
			this.stripe = stripe;
			this.datosActivacion.nombreCliente = nombreCliente;
			this.datosActivacion.correoCliente = correoCliente;
			PagoOxxoApi.createActivation(this.datosActivacion)
			.then(response => {
				if(response.success === true) {
					this.onHandleStripeOxxoConfirmation(response.data, callback);
				} else {
					callback();
					this.$notifier.error(response.message, 5000);
				}
			});
		},
		onHandleStripeOxxoConfirmation: function(pagoOxxo, callback) {
			this.pagoOxxo = pagoOxxo;
			this.stripe.confirmOxxoPayment(pagoOxxo.paymentIntentClientSecret, {
				payment_method: {
					billing_details: {
						name: this.datosActivacion.nombreCliente,
						email: this.datosActivacion.correoCliente,
					},
				},
			}, {handleActions: false}) 
			.then(result => {
				callback();
				// This promise resolves when the customer closes the modal
				if (result.error) {
					this.$notifier.error(result.error.message, 5000);
				} else {
					this.onCloseDialogOxxo();
					this.paymentIntent = result.paymentIntent;
					this.esVoucherOxxoGenerado = true;
				}
			});
		},

		//#endregion
	}
}
</script>