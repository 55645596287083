<template>
<div>
	<validation-observer ref="observer">
		<v-form @submit.prevent="">
			<spinner-loading :overlay="isLoading"/>
			<v-card 
				class="mx-4" 
				rounded="lg"
			>
				<v-card-title class="py-4">
					<span class="font-weight-regular">Activación de Licencia de Uso</span>
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12">
							<strong class="text-subtitle-1 grey--text text--darken-3">Datos de Activación</strong>
						</v-col>
					</v-row>
					<v-row>
						<v-col 
							cols="12" 
							sm="8" 
							md="8"
							lg="8"
						>
							<div class="text--secondary ml-1">Producto</div>
							<strong class="grey--text text--darken-3 ml-1">{{producto.descripcion}}</strong>
						</v-col>
						<v-col 
							v-if="!esGratis"
							cols="12" 
							sm="4" 
							md="4"
							lg="4"
						>
							<div class="text--secondary ml-1">Periodo</div>
							<strong class="grey--text text--darken-3 ml-1">{{periodo.descripcion}}</strong>
						</v-col>
					</v-row>
					<v-row v-if="!esGratis">
						<v-col 
							cols="12" 
							sm="4" 
							md="4"
							lg="4"
						>
							<div class="text--secondary ml-1">Moneda de pago*</div>
							<validation-provider 
								v-slot="{errors}" 
								name="moneda" 
								rules="required"
							>
								<v-select 
									v-model="datosActivacion.claveMoneda" 
									:items="monedas"
									outlined
									hide-details="auto"
									item-value="claveMoneda"
									:item-text="onMonedaToString"
									:error-messages="errors"
									@change="onChangeMoneda"
								>
								</v-select>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row>
						<v-col 
							cols="12" 
							sm="6" 
							md="6"
							lg="6"
						>
							<div class="text--secondary ml-1">Codigo de Referencia (Distribuidor)*</div>
							<validation-provider 
								v-slot="{errors}" 
								name="codigo de referencia" 
								rules="required"
							>
								<v-text-field 
									v-model="datosActivacion.codigoReferencia" 
									outlined  
									hide-details="auto"
									:error-messages="errors + validErrorCodigoReferencia"
									@change="onChangeCodigoReferencia"
								>
									<template v-slot:append-outer>
										<v-tooltip right>
											<template v-slot:activator="{ on, attrs }">
												<v-btn 
													icon
													@click="onLoadCodigoReferenciaDefault"
													color="secondary"
													v-bind="attrs"
													v-on="on"
												>
													<v-img 
														src="images/brand/icono.png" 
														contain 
														position="center" 
														height="28"
													/>
												</v-btn>
											</template>
											<span>Usar codigo de referencia por defecto</span>
										</v-tooltip>
									</template>
								</v-text-field>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row>
						<v-col 
							cols="12"
							sm="8"
							md="8"
							lg="8"
						>
							<div class="text--secondary ml-1">Nombre de Empresa*</div>
							<validation-provider 
								v-slot="{errors}" 
								name="nombre empresa" 
								rules="required"
							>
								<v-text-field 
									v-model="datosActivacion.nombreEmpresa" 
									outlined  
									hide-details="auto"
									@input="datosActivacion.nombreEmpresa = $utilsInput.uppercase(datosActivacion.nombreEmpresa)"
									:error-messages="errors"
								>
								</v-text-field>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row>
						<v-col 
							cols="12" 
							sm="8" 
							md="8"
							lg="8"
						>
							<div class="text--secondary ml-1">ID Dispositivo*</div>
							<validation-provider 
								v-slot="{errors}" 
								name="id dispositivo" 
								rules="required"
							>
								<v-text-field 
									v-model="datosActivacion.idDispositivo" 
									outlined  
									hide-details="auto"
									:error-messages="errors"
								>
								</v-text-field>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row>
						<v-col 
							cols="12" 
							sm="8" 
							md="8"
							lg="8"
						>
							<div class="text--secondary ml-1">Telefono*</div>
							<validation-provider 
								v-slot="{errors}" 
								name="telefono" 
								rules="required"
							>
								<v-text-field 
									v-model="datosActivacion.telefono" 
									outlined  
									hide-details="auto"
									:error-messages="errors"
									@keypress="$utilsInput.telephone($event)"
								>
								</v-text-field>
							</validation-provider>
						</v-col>
						<v-col 
							cols="12" 
							sm="8" 
							md="8"
							lg="8"
						>
							<div class="text--secondary ml-1">Correo Electronico*</div>
							<validation-provider 
								v-slot="{errors}" 
								name="correo electronico" 
								rules="required|email"
							>
								<v-text-field 
									v-model="datosActivacion.correo" 
									outlined  
									hide-details="auto"
									:error-messages="errors"
								>
								</v-text-field>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<strong class="text-subtitle-1 grey--text text--darken-3">Ubicación</strong>
						</v-col>
					</v-row>
					<v-row>
						<v-col 
							cols="12" 
							sm="8" 
							md="8"
							lg="8"
						>
							<div class="text--secondary ml-1">País*</div>
							<validation-provider 
								v-slot="{errors}" 
								name="pais" 
								rules="required"
							>
								<v-autocomplete 
									v-model="datosActivacion.idPais" 
									:items="paises"
									outlined
									hide-details="auto"
									item-value="nId"
									:item-text="onPaisToString"
									:error-messages="errors"
									@change="onChangePais"
								>
								</v-autocomplete>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row>
						<v-col 
							cols="12" 
							sm="8" 
							md="8"
							lg="8"
						>
							<div class="text--secondary ml-1">Estado*</div>
							<validation-provider 
								v-slot="{errors}" 
								name="estado" 
								rules="required"
							>
								<v-autocomplete 
									v-model="datosActivacion.idEstado" 
									:items="estados"
									outlined
									hide-details="auto"
									item-value="NClave"
									:item-text="onEstadoToString"
									:error-messages="errors"
									@change="onChangeEstado"
								>
								</v-autocomplete>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row>
						<v-col 
							cols="12" 
							sm="8" 
							md="8"
							lg="8"
						>
							<div class="text--secondary ml-1">Ciudad*</div>
							<validation-provider 
								v-slot="{errors}" 
								name="ciudad" 
								rules="required"
							>
								<v-autocomplete 
									v-model="datosActivacion.idCiudad" 
									:items="ciudades"
									outlined
									hide-details="auto"
									item-value="NId"
									:item-text="onCiudadToString"
									:error-messages="errors"
								>
								</v-autocomplete>
							</validation-provider>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider class="mx-4 mt-5"></v-divider>
				<v-card-actions>
					<v-row>
						<v-col cols="12" class="text-right">
							<v-btn 
								@click="onSubmit"
								color="primary" 
								:disabled="isLoading"
								class="ml-2"
							>
								{{esGratis ? "Generar licencia" : "Pagar / Generar licencia"}}
							</v-btn>
						</v-col>
					</v-row>
				</v-card-actions>
			</v-card>
		</v-form>
	</validation-observer>
</div>
</template>
<script>
import SpinnerLoading from '../components/SpinnerLoading';

import ProductoApi from '../services/api/producto.api';
import PeriodoApi from '../services/api/periodoPago.api';
import PrecioApi from '../services/api/precio.api';
import ParametrosGeneralesApi from '../services/api/parametrosGenerales.api';
import DistribuidorApi from '../services/api/distribuidor.api';
import PaisErpApi from '../services/api-erp/pais.api';
import EstadoErpApi from '../services/api-erp/estado.api';
import CiudadErpApi from '../services/api-erp/ciudad.api';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ObjectToStringMixin from '../mixins/objectToStringMixin'

export default {
	props: {
		esGratis: {
			type:  Boolean,
			default: false
		},
		claveProducto: String,
		clavePeriodo: String,
	},
	mixins: [ObjectToStringMixin],
	components: {
		SpinnerLoading,
		ValidationProvider,
		ValidationObserver
	},
	created: function() {
		this.onInit();
	},
  data: () => ({
		isLoading: true,
		datosActivacion: {
			claveProducto: '',
			clavePeriodoPago: '',
			claveMoneda: '',
			codigoReferencia:'',
			nombreEmpresa: '',
			idDispositivo: '',
			telefono: '',
			correo: '',
			idPais: '',
			idEstado: '',
			idCiudad: '',
		},
		producto: {},
		periodo: {},
		precio: {},
		distribuidor: {},
		validErrorCodigoReferencia: '',
		monedas: [],
		paises: [],
		estados: [],
		ciudades: [],

		isGenerating: false,
	}),
	computed: {
		loadedDistribuidor: function() {
			return Object.keys(this.distribuidor).length !== 0;
		},
		loadedCliente: function() {
			return Object.keys(this.cliente).length !== 0;
		},
	},
	methods: {
		onInit: async function() {
			this.isLoading = true;
			//Asignamos valores
			this.datosActivacion.claveProducto = this.claveProducto;
			this.datosActivacion.clavePeriodoPago = this.clavePeriodo;
			await ProductoApi.getBasic(this.datosActivacion.claveProducto)
			.then(response => {
				if(response.success === true) {
					this.producto = response.data;
				} else {
					this.$notifier.warn('El producto no se cargo correctamente. \n' + response.message);
				}
			});
			// En caso de ser gratis, no se cargan valores de precios
			if(!this.esGratis) {
				await PeriodoApi.get(this.datosActivacion.clavePeriodoPago)
				.then(response => {
					if(response.success === true) {
						this.periodo = response.data;
					} else {
						this.$notifier.warn('El periodo no se cargo correctamente. \n' + response.message);
					}
				});
				await PrecioApi.getAllByProductoPeriodo(this.datosActivacion.claveProducto, this.datosActivacion.clavePeriodoPago)
				.then(response => {
					if(response.success === true) {
						this.datosActivacion.claveMoneda = '';
						this.monedas = response.data;
					} else {
						this.$notifier.warn('Las monedas no se cargaron correctamente. \n' + response.message);
					}
				});
			}
			await PaisErpApi.getAll()
			.then(response => {
				if(response.success === true) {
					this.paises = response.data;
				} else {
					this.$notifier.warn('Los paises no se cargaron correctamente. \n' + response.message);
				}
			});
			this.isLoading = false;
		},

		//#region Metodos de formateo select

		onMonedaToString: function(item) {
			return this.objectToString('', item.claveMoneda);
		},
		onPaisToString: function(item) {
			return this.objectToString('', item.cNombre);
		},
		onEstadoToString: function(item) {
			return this.objectToString('', item.CNombre);
		},
		onCiudadToString: function(item) {
			return this.objectToString('', item.CNombre);
		},
		
		//#endregion

		//#region Metodos para actualizar propiedades

		onChangeMoneda: function(claveMonedaSelected) {
			this.precio = this.monedas.find(x => x.claveMoneda == claveMonedaSelected);
		},
		onLoadCodigoReferenciaDefault: function() {
			ParametrosGeneralesApi.getCodigoRefDistribuidorDefecto()
			.then(response => {
				if(response.success === true) {
					this.datosActivacion.codigoReferencia = response.data;
					this.onChangeCodigoReferencia(this.datosActivacion.codigoReferencia);
				} else {
					this.$notifier.warn('No se pudo obtener el codigo de referencia por defecto. \n' + response.message);
				}
			});
		},
		onChangeCodigoReferencia: function(value) {
			if(value !== '') {
				DistribuidorApi.getToActivacionGift(value, this.datosActivacion.claveProducto, this.datosActivacion.clavePeriodoPago)
				.then(response => {
					if(response.success === true) {
						this.distribuidor = response.data;
						this.validErrorCodigoReferencia = '';
					} else {
						this.distribuidor = null;
						this.validErrorCodigoReferencia = response.message;
					}
				});
			} else {
				this.distribuidor = null;
				this.validErrorCodigoReferencia = '';
			}
		},
		onChangePais: function(idPaisSelected, callFromInit = false) {
			EstadoErpApi.getAll(idPaisSelected)
			.then(response => {
				if(response.success === true) {
					this.estados = response.data;
					if(!callFromInit) {
						this.ciudades = [];
						this.colonias = [];
					}
				} else {
					this.$notifier.warn('Los estados no se cargaron correctamente. \n' + response.message);
				}
			});
		},
		onChangeEstado: function(idEstadoSelected, callFromInit = false) {
			CiudadErpApi.getAll(idEstadoSelected)
			.then(response => {
				if(response.success === true) {
					this.ciudades = response.data;
					if(!callFromInit) {
						this.colonias = [];
					}
				} else {
					this.$notifier.warn('Las ciudades no se cargaron correctamente. \n' + response.message);
				}
			});
		},

		//#endregion

		//#region Metodo de acciones de card
		
		onValidCustom: function() {
			//Validamos y creamos mensaje de error
			if(this.loadedDistribuidor) {
				this.validErrorCodigoReferencia = '';
				return true;
			} else {
				this.validErrorCodigoReferencia = 'Compruebe el codigo de referencia indicado.';
				return false;
			}
			
		},
		onSubmit: async function() {
			//Prevent multiple guardado
			if(!this.isGenerating) {
				this.isGenerating = true;
				const isValid = await this.$refs.observer.validate();
				if(isValid && this.onValidCustom()) {
					//Copiamos deep objeto para ajustarlo antes de enviarlo
					let datosActivacionData = JSON.parse(JSON.stringify(this.datosActivacion));
					this.$emit('onSubmit', datosActivacionData, this.producto, this.precio, this.distribuidor, () => {
						this.isGenerating = false;
					});
				} else {
					this.isGenerating = false;
					this.$notifier.error('Llene todos los campos marcados como obligatorios, compruebe su información y vuelva a intentar.');
				}
			}
		}

		//#endregion
	}
}
</script>