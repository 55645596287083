<template>
<div>
	<app-header-page :titulos="titleItems"></app-header-page>
	<v-card class="mx-4 mb-4" 
	rounded="lg">
		<v-card-text>
			<v-row>
				<v-col cols="12" sm="6" md="6">
					<div class="text--secondary ml-1">Buscar</div>
					<v-text-field v-model="search" 
					outlined
					append-icon="mdi-magnify"
					@change="onSearch"
					placeholder="Id/Id Dispositivo/Nombre de Empresa">
					</v-text-field>
				</v-col>
			</v-row>
			<v-data-table :headers="headers"
			:items="items"
			:footer-props="footerProps"
			:page="page"
			:pageCount="totalPages"
			:options.sync="options"
			:server-items-length="totalItems"
			:items-per-page="pageSize"
			:loading="isLoading"
			loading-text="Cargando..."
			no-data-text="No existen registros."
			item
			class="elevation-1 mt-5"
			disable-sort>
				<template v-slot:item.fechaCreacion="{item}">
					{{ item.fechaCreacion | dateParse('DD/MM/YYYY') }}
				</template>
				<template v-slot:item.producto="{item}">
					{{item.claveProducto}} / {{item.descripcionProducto}}
				</template>
				<template v-slot:item.credito="{item}">
					{{item.esACredito | booleanParse()}}
				</template>
				<template v-slot:item.acciones="{item}">
					<v-tooltip left>
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon
							color="gray"
							@click="onOpenDialogDetails(item)"
							v-bind="attrs"
							v-on="on">
								<v-icon>mdi-eye</v-icon>
							</v-btn>
						</template>
						<span>Detalles</span>
					</v-tooltip>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
	<!--Dialog details-->
	<licencia-dialog 
		:dialog="dialogDetails"
		:idLicencia="licenciaCurrent.id"
		:claveProducto="licenciaCurrent.claveProducto"
		@onClose="onCloseDialogDetails">
	</licencia-dialog>
</div>
</template>
<script>
import AppHeaderPage from '../components/AppHeaderPage';
import LicenciaDialog from '../components/LicenciaDialog';

import LicenciaApi from '../services/api/licencia.api';
import AuthApi from '../services/api/auth.api';
import AuthTypes from '../store/types/AuthTypes';
import { Funcion } from '../utils/constants';

export default {
	components: {
		AppHeaderPage,
		LicenciaDialog,
	},
    data: () => ({
		titleItems: ['Licencias', 'Listado'],
		idDistribuidor: '',
		search: '',
		headers: [
			{ text: 'Id', value: 'id' },
			{ text: 'Estatus', value: 'estatusLicencia.descripcion' },
			{ text: 'Fecha de Creación', value: 'fechaCreacion' },
			{ text: 'Producto', value: 'producto' },
			{ text: 'ID Dispositivo', value: 'idDispositivo' },
			{ text: 'Nombre de Empresa', value: 'nombreEmpresa' },
			{ text: 'Credito', value: 'credito' },
			{ text: 'Acciones', value: 'acciones'}
		],
		items: [],
		footerProps: {
			'items-per-page-text': 'Mostrar',
			'items-per-page-options': [10,15,20]
		},
		page: 1,
		totalPages: 0,
		options: {},
		totalItems: 0,
		pageSize: 10,
		isLoading: true,
		licenciaCurrent: {},
		dialogDetails: false,
	}),
	computed: {
		selectedLicencia: function() {
			return Object.keys(this.licenciaCurrent).length !== 0;
		},
	},
	mounted: function() {
		this.idDistribuidor = this.$store.getters[AuthTypes.nameModule + '/' + AuthTypes.getters.DISTRIBUIDOR_USUARIO];
		this.onPaged();
	},
	watch: {
		options: {
			handler() {
				this.onPaged();
			}
		},
	},
	methods: {
		onPaged: function() {
			this.isLoading = true;
			//Obtenemos datos de footer para API
			const {page, itemsPerPage} = this.options;
			this.page = page;
			this.pageSize = itemsPerPage;
			LicenciaApi.getPagedByDistribuidor(this.idDistribuidor, this.page, this.pageSize, this.search)
			.then(response => {
				this.isLoading = false;
				if(response.success === true) {
					//Cargamos nuevos datos de paginado
					this.items = response.data.items;
					this.totalItems = parseInt(response.data.totalRecordCount);
					this.totalPages = response.data.totalPages;
				} else {
					this.$notifier.error(response.message)
				}
			});
		},

		onSearch: function() {
			//Inicializamos numero de pagina y paginamos
			this.options.page = 1;
			this.onPaged();
		},

		onOpenDialogDetails: function(licenciaTemp) {
			let emailUsuario = this.$store.getters[AuthTypes.nameModule + '/' + AuthTypes.getters.EMAIL_USUARIO];
			AuthApi.validatePermission(emailUsuario, Funcion.LICENCIA_VER)
                    .then(response => {
                        if (response.success === true) {
							//Salvamos licnecia current(edit/delete)
							this.licenciaCurrent = licenciaTemp
							//Abrimos dialog
							this.dialogDetails = true;
                        } else {
                            this.$notifier.error(response.message)
                        }
                    });
		},
		onCloseDialogDetails: function() {
			//Cerramos dialog
			this.dialogDetails = false;
		},
	}
  }
</script>