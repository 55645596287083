import Vue from 'vue';

Vue.filter('dateParse', (value, format) => {
    if (value === null || typeof value === 'undefined') {
        return ';'
    }

    let date = new Date(value);
    let dateParsed = '';

    if (format.includes('DD')) {
        dateParsed += String('00' + date.getDate()).slice(-2);
    }

    if (format.includes('MM')) {
        dateParsed += '-' + String('00' + (date.getMonth() + 1)).slice(-2);
    }

    if (format.includes('YYYY')) {
        dateParsed += '-' + date.getFullYear().toString();
    }

    if (format.includes('HH')) {
        dateParsed += ' ' + String('00' + date.getHours()).slice(-2);
    }

    if (format.includes('mm')) {
        dateParsed += ':' + String('00' + date.getMinutes()).slice(-2);
    }

    if (format.includes('ss')) {
        dateParsed += ':' + String('00' + date.getSeconds()).slice(-2);
    }

    return dateParsed;
});


Vue.filter('currencyParse', (value, decimal) => {
    return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: decimal
    }).format(value);
});

Vue.filter('booleanParse', (value) => {
    return value ? 'SI' : 'NO'
});