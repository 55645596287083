<template>
	<v-autocomplete ref="autocomplete"
	v-model="localSelected"
	outlined
	clearable
	hide-details="auto"
	:items="items"
	:loading="isLoading"
	:search-input.sync="search"
	hide-no-data
	placeholder="Buscar distribuidor..."
	:item-text="onFormatToText"
	item-value="id"
	return-object
	@change="onChange"
	@click:clear="onClear"
	:error-messages="validError">
		<template v-slot:append-item>
			<div v-if="!isLastPage"
			v-observe-visibility="{
				callback: onVisibilityChanged
			}"
			class="text--secondary ml-3">
				Cargando...
			</div>
		</template>
	</v-autocomplete>
</template>
<script>
import axios from 'axios';
import DistribuidorApi from '../services/api/distribuidor.api';

export default {
	props: {
		selected: Object,
		validError: String,
		byCodigoRef: {
			type:  Boolean,
			default: false
		}
	},
	mounted: function() {
		if(Object.keys(this.selected).length !== 0) {
			this.onInitSelection(this.selected);
		} else {
			this.onLoad();
		}
	},
	data: () => ({
		idOpcionDefault: '',
		items: [],
		isLoading: false,
		search: '',
		page: 1,
		isLastPage: false,
		localSelected: {},
		cancelSource: null
	}),
	watch: {
		selected () {
			this.localSelected = this.selected;
		},
		search(value) {
			this.page = 1;
			if(value === '') {
				this.onLoad();
			} else {
				value && value !== this.onFormatToText(this.localSelected) && this.onLoad();
			}
		}
	},
	methods: {
		onInitSelection: function(distribuidor) {
			this.idOpcionDefault = distribuidor.id.toString();
			this.search = distribuidor.id.toString();
		},
		onFormatToText: function(item) {
			if(this.byCodigoRef)
				return item.codigoReferencia + ' / ' + item.primerNombre + ' ' + item.segundoNombre + ' ' + item.apellidoPaterno + ' ' + item.apellidoMaterno;
			else
				return item.id + ' / ' + item.primerNombre + ' ' + item.segundoNombre + ' ' + item.apellidoPaterno + ' ' + item.apellidoMaterno;
		},
		onVisibilityChanged(e) {
			e && this.onLoad();
		},
		onLoad() {
			this.isLoading = true;
			//Cancelamos peticiones previas
			this.onCancelLoad();
			this.cancelSource = axios.CancelToken.source();
			//Load items
			DistribuidorApi.getPaged(false, '', false, '', this.page, 20, this.search)
			.then(response => {
				//Solo cancelamos loading si no es cancelacion de peticiones previas
				if(response.success === false && typeof response.data.isCancel === 'undefined') {
					this.isLoading = false;
				}

				if(response.success === true) {
					this.isLoading = false;
					if(this.page === 1) {
						//Se incia la busqueda
						this.items = response.data.items;
					} else {
						//Agregamos nuevos datos de paginado
						this.items = [
							...this.items,
							...response.data.items
						];
						this.$refs.autocomplete.onScroll();
					}
					//Comprobamos si es ultima pagina, sino avanzamos
					if(parseInt(response.data.totalPages) === this.page) {
						this.isLastPage = true;
					} else {
						this.isLastPage = false;
						this.page += 1;
					}
					//Buscamos y seleccionamos opcion por defecto si esta existe
					if(this.idOpcionDefault !== '') {
						let distribuidorTemp = response.data.items.find(x => x.id == this.idOpcionDefault);
						if(distribuidorTemp !== null && typeof(distribuidorTemp) !== 'undefined') {
							this.items = [distribuidorTemp];
							this.isLastPage = true;
							this.localSelected = distribuidorTemp;
						} else {
							this.isLastPage = true;
							this.localSelected = {};
						}
						//Limpiamos primera opcion
						this.idOpcionDefault = '';
					}
				}
			});
		},
		onCancelLoad: function() {
			if(this.cancelSource) {
				this.cancelSource.cancel('Nueva busqueda iniciada.');
			}
		},
		onChange: function(item) {
			this.$emit('onChange', item);
		},
		onClear: function(e) {
			this.search = '';
			this.page = 1
			this.onLoad();
		}
	}
}
</script>