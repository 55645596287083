import { required, required_if, email, max, confirmed, image } from 'vee-validate/dist/rules';
import { extend, setInteractionMode } from 'vee-validate';

setInteractionMode('passive')

extend('required', {
    ...required,
    message: 'El campo {_field_} es requerido.',
});
extend('required_if', {
    ...required_if,
    message: 'El campo {_field_} es requerido.',
});
extend('email', {
    ...email,
    message: 'El campo {_field_} no es valido.',
});
extend('max', {
    ...max,
    params: ['length'],
    message: 'El campo {_field_} no puede tener mas de {max} caracteres.'
});
extend('confirmed', {
    ...confirmed,
    message: 'El campo {_field_} no coincide.',
});
extend('image', {
    ...image,
    message: 'El campo {_field_} debe ser una imagen.',
});