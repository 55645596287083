<template>
  <v-card
		elevation="0"
		color="default">
		<v-card-text>
			<v-row>
				<v-col cols="12" sm="3" md="3">
					<div class="text--secondary ml-1">ID</div>
					<strong class="grey--text text--darken-3 ml-1">{{licencia.id}}</strong>
				</v-col>
				<v-col cols="12" sm="3" md="3">
					<div class="text--secondary ml-1">Estatus</div>
					<strong class="grey--text text--darken-3 ml-1">{{licencia.estatusDescripcion}}</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<div class="text--secondary ml-1">Producto</div>
					<strong class="grey--text text--darken-3 ml-1">{{licencia.claveProducto}} / {{licencia.productoDescripcion}}</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="3" md="3">
					<div class="text--secondary ml-1">ID Dispositivo</div>
					<strong class="grey--text text--darken-3 ml-1">{{licencia.idDispositivo}}</strong>
				</v-col>
				<v-col cols="12" sm="9" md="9">
					<div class="text--secondary ml-1">Nombre de Empresa</div>
					<strong class="grey--text text--darken-3 ml-1">{{licencia.nombreEmpresa}}</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="3">
					<div class="text--secondary ml-1">Pais</div>
					<strong class="grey--text text--darken-3 ml-1">{{licencia.paisNombre.toUpperCase()}}</strong>
				</v-col>
				<v-col cols="3">
					<div class="text--secondary ml-1">Estado</div>
					<strong class="grey--text text--darken-3 ml-1">{{licencia.estadoNombre.toUpperCase()}}</strong>
				</v-col>
				<v-col cols="3">
					<div class="text--secondary ml-1">Ciudad</div>
					<strong class="grey--text text--darken-3 ml-1">{{licencia.ciudadNombre.toUpperCase()}}</strong>
				</v-col>
				<v-col cols="3">
					<div class="text--secondary ml-1">Colonia</div>
					<strong class="grey--text text--darken-3 ml-1">{{licencia.coloniaNombre ? licencia.coloniaNombre.toUpperCase() : ''}}</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="3" md="3">
					<div class="text--secondary ml-1">Telefono</div>
					<strong class="grey--text text--darken-3 ml-1">{{licencia.telefono}}</strong>
				</v-col>
				<v-col cols="12" sm="9" md="9">
					<div class="text--secondary ml-1">Correo Electronico</div>
					<strong class="grey--text text--darken-3 ml-1">{{licencia.correo}}</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<v-dialog
						v-model="dialogLlave"
						max-width="500">
						<template v-slot:activator="{ on, attrs }">
							<v-btn 
								text
								small
								color="secondary"
								v-bind="attrs"
								v-on="on"
							>
								Ultima Llave de Activación
							</v-btn>
						</template>
						<v-card>
							<v-card-title>
								<span class="text-button grey--text text--darken-3">Llave de activación</span>
							</v-card-title>
							<v-card-text>
								<v-row>
									<v-col cols="12" sm="4" md="4">
										<div class="text--secondary ml-1">Fecha de Activación</div>
										<strong class="grey--text text--darken-3 ml-1">{{licencia.llaveFechaActivacion | dateParse('DD/MM/YYYY')}}</strong>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="12" sm="4" md="4">
										<div class="text--secondary ml-1">Gratis</div>
										<strong class="grey--text text--darken-3 ml-1">{{licencia.llaveEsGratis | booleanParse()}}</strong>
									</v-col>
									<v-col cols="12" sm="4" md="4">
										<div class="text--secondary ml-1">Obsequio de Disstribuidor</div>
										<strong class="grey--text text--darken-3 ml-1">{{licencia.llaveEsObsequio | booleanParse()}}</strong>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="12" sm="4" md="4">
										<div class="text--secondary ml-1">Periodo</div>
										<strong class="grey--text text--darken-3 ml-1">{{licencia.llavePeriodoPagoDescripcion}}</strong>
									</v-col>
									<v-col cols="12" sm="4" md="4">
										<div class="text--secondary ml-1">Días de Vigencia</div>
										<strong class="grey--text text--darken-3 ml-1">{{licencia.llaveDiasVigencia}}</strong>
									</v-col>
									<v-col cols="12" sm="4" md="4">
										<div class="text--secondary ml-1">Monto</div>
										<strong class="grey--text text--darken-3 ml-1" v-if="licencia.llaveMonto !== null">{{licencia.llaveMonto | currencyParse(2)}} {{licencia.llaveClaveMoneda}}</strong>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="12" sm="12" md="12">
										<div class="text--secondary ml-1">Clave de Activación</div>
										<strong class="grey--text text--darken-3 ml-1">{{licencia.llaveClaveActivacion}}</strong>
									</v-col>
								</v-row>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn
									small
									color="default"
									@click="dialogLlave = false"
								>
									Cerrar
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>
<script>
export default {
	props: {
		licencia: Object,
	},
	data: () => ({
		dialogLlave: false,
	}),
}
</script>
