<template>
<div>
	<app-header-page :titulos="titleItems"></app-header-page>
	<rol-card :mode="mode" 
	@onSubmit="onSubmit">
	</rol-card>
</div>
</template>
<script>
import AppHeaderPage from '../components/AppHeaderPage';
import RolCard from '../components/RolCard';
import RolApi from '../services/api/rol.api';
import { ViewMode } from '../utils/constants';

export default {
	components: {
		AppHeaderPage,
		RolCard
	},
    data: () => ({
		titleItems: ['Rol', 'Agregar'],
		mode: ViewMode.CREATE,
	}),
	methods: {
		onSubmit: function(rolSaved, callBack) {
			RolApi.add(rolSaved)
			.then(response => {
				callBack();
				if(response.success === true) {
					this.$router.push('/rol');
					this.$notifier.success('Rol agregado exitosamente.');
				} else {
					this.$notifier.error(response.message);
				}
			});
		}
	}
  }
</script>