<template>
<div>
		<v-card class="mx-4" 
		rounded="lg">
			<v-card-text>
				<v-row class="mt-5">
					<v-col cols="12" class="d-flex flex-column align-center justify-center">
						<v-img src="images/theme/check_success.png" width="100"/>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" class="text-center my-3">
						<strong 
							v-if="esGratis"
							class="grey--text text--darken-3 text-h4"
						>
							¡Registro Exitoso!
						</strong>
						<strong 
							v-else
							class="grey--text text--darken-3 text-h4"
						>
							¡Pago Exitoso!
						</strong>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" class="text-center">			
						<span class="font-weight-regular">Datos de activación procesados con exito, gracias por utilizar productos de AppStack.</span>
					</v-col>
				</v-row>
				<v-row v-if="existeCorreo">
					<v-col cols="12">
						<v-alert
							v-if="existeAdvertencia"
							dense
							border="left"
							colored-border
							type="warning"
							elevation="1"
						>
							{{mensajeEnvioCorreo}}
						</v-alert>
						<v-alert
							v-else
							dense
							border="left"
							colored-border
							type="info"
							elevation="1"
							color="primary"
						>
							<small class="font-weight-regular">Los datos de activación se enviaron al correo electronico.</small>
						</v-alert>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12">
						<strong class="text-subtitle-1 grey--text text--darken-3">Datos de activación</strong>
						<input type="hidden" id="input-llave" :value="cadenaActivacion">
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12">
						<p class="ml-2">
							ID Licencia<br>
							<strong class="font-weight-medium">{{idLicencia}}</strong> 
						</p>
						<p class="ml-2">
							Clave de Activación<br>
							<strong class="font-weight-medium">{{claveActivacion}}</strong> 
						</p>
						<p class="ml-2">
							Cadena de Activación
							<br>
							<v-btn
								x-small
								color="default"
								@click="mostrarCadenaActivacion = !mostrarCadenaActivacion">
								{{mostrarCadenaActivacion? 'Ocultar' : 'Mostrar'}}
							</v-btn>
							<br>
							<strong 
								v-if="mostrarCadenaActivacion" 
								class="font-weight-medium">
								{{cadenaActivacion}}
							</strong> 
						</p>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" class="text-center">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn 
									small
									color="secondary"
									class="ml-3"
									@click="onCopyLicencia"
									v-bind="attrs"
									v-on="on"
								>
									Copiar cadena
								</v-btn>
							</template>
							<span>Copiar cadena de activacion.</span>
						</v-tooltip>
						<v-dialog
							v-model="dialogInstrucciones"
							max-width="500">
							<template v-slot:activator="{ on, attrs }">
								<v-btn 
									small
									color="default"
									class="ml-3"
									v-bind="attrs"
									v-on="on"
								>
									Instrucciones
								</v-btn>
							</template>
							<v-card>
								<v-card-title>
									<span class="text-button grey--text text--darken-3">Instrucciones de activación.</span>
								</v-card-title>
								<v-card-text>
									<v-row>
										<v-col cols="12">
											<ol>
												<li class="grey--text text--darken-3 font-weight-light">Copie el valor de <b>Cadena de Activación</b> por medio del boton <b>Copiar cadena</b> o manualmente.</li>
												<li class="grey--text text--darken-3 font-weight-light">Regrese a la aplicación a activar/renovar.</li>
												<li class="grey--text text--darken-3 font-weight-light">Seleccione la version a activar/renovar.</li>
												<li class="grey--text text--darken-3 font-weight-light">Seleccione la opción <b>Mediante cadena de activación</b></li>
												<li class="grey--text text--darken-3 font-weight-light">Pegue la cadena de activación por medio del boton <b>Pegar cadena de activación</b>.</li>
												<li class="grey--text text--darken-3 font-weight-light">Una vez que los valores se pegaron correctamente, presione <b>Activar</b>.</li>
												<li class="grey--text text--darken-3 font-weight-light">¡Listo! La aplicación ha quedado activada.</li>
											</ol>
										</v-col>
									</v-row>
								</v-card-text>
								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn
										small
										color="default"
										@click="dialogInstrucciones = false"
									>
										Cerrar
									</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>
					</v-col>
				</v-row>
				<v-divider class="my-4"></v-divider>
				<v-row>
					<v-col cols="12">
						<strong class="text-subtitle-1 grey--text text--darken-3">Datos de licencia</strong>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12">
						<p class="ml-2">
							Producto<br>
							<strong class="font-weight-medium">{{descripcionProducto}}</strong> 
						</p>
						<p class="ml-2" v-if="!esGratis">
							Precio<br>
							<strong class="font-weight-medium">{{precio | currencyParse(2)}} {{claveMoneda}}</strong> 
						</p>
						<p class="ml-2" v-if="!esGratis">
							Periodo<br>
							<strong class="font-weight-medium">{{descripcionPeriodo}}</strong> 
						</p>
						<p class="ml-2">
							Codigo de Referencia<br>
							<strong class="font-weight-medium">{{codigoReferencia}}</strong> 
						</p>
						<p class="ml-2">
							Nombre de Empresa<br>
							<strong class="font-weight-medium">{{nombreEmpresa}}</strong> 
						</p>
						<p class="ml-2">
							ID Dispositivo<br>
							<strong class="font-weight-medium">{{idDispositivo}}</strong> 
						</p>
						<p class="ml-2" v-if="existeTelefono">
							Telefono<br>
							<strong class="font-weight-medium">{{telefono}}</strong> 
						</p>
						<p class="ml-2" v-if="existeCorreo">
							Correo Electronico<br>
							<strong class="font-weight-medium">{{correo}}</strong> 
						</p>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-row>
					<v-col cols="12" class="text-left">
						<v-btn 
							color="default"
							@click="onCancel"
						>
							Salir
						</v-btn>
					</v-col>
				</v-row>
			</v-card-actions>
		</v-card>
</div>
</template>
<script>
export default {
	props: {
		idLicencia: Number,
		claveActivacion: String,
		cadenaActivacion: String,
		descripcionProducto: String,
		esGratis: Boolean,
		precio: Number,
		claveMoneda: String,
		descripcionPeriodo: String,
		codigoReferencia: String,
		nombreEmpresa: String,
		idDispositivo: String,
		telefono: String,
		correo: String,
		mensajeEnvioCorreo: String,
	},
	data: () => ({
		mostrarCadenaActivacion: false,
    dialogInstrucciones: false,
  }),
	computed:{
		existeAdvertencia: function() {
			return this.mensajeEnvioCorreo !== null && this.mensajeEnvioCorreo !== '';
		},
		existeTelefono: function() {
			return this.telefono !== null && this.telefono !== '';
		},
		existeCorreo: function() {
			return this.correo !== null && this.correo !== '';
		},
	},
  methods: {
		onCopyLicencia: function() {
			let licenciaToCopy = document.querySelector('#input-llave')
			licenciaToCopy.setAttribute('type', 'text')
			licenciaToCopy.select()
			try {
				var successful = document.execCommand('copy');
				if(!successful) {
					alert('Error al copiar los datos de licencia, debera copiarlos manualmente.');
				} else {
					this.$notifier.success('La cadena de activación se ha copiado al portapapeles.');
				}
			} catch (err) {
				alert('Oops, tiene deshabilitado el copiado de texto.');
			}
			/* unselect the range */
			licenciaToCopy.setAttribute('type', 'hidden');
			window.getSelection().removeAllRanges();
		},
		onCancel: function() {
			this.$emit('onCancel')
		},
	}
}
</script>