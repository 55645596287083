<template>
<div>
	<validation-observer ref="observer">
		<v-form @submit.prevent="">
			<spinner-loading :overlay="isLoading"/>
			<v-card class="mx-4" 
			rounded="lg">
				<v-card-title class="py-4">
					<span class="font-weight-regular">Datos</span>
				</v-card-title>
				<v-card-text>
					<v-row v-if="loadedEvidencia">
						<v-col cols="12" sm="6" md="6" lg="6">
							<v-row>
								<v-col cols="12" sm="4" md="3">
									<div class="text--secondary ml-1">ID</div>
									<strong class="grey--text text--darken-3 ml-1">{{evidencia.id}}</strong>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<div class="text--secondary ml-1">Fecha de Registro</div>
									<strong class="grey--text text--darken-3 ml-1">{{evidencia.fechaRegistro | dateParse('DD/MM/YYYY')}}</strong>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<div class="text--secondary ml-1">Monto</div>
									<strong class="grey--text text--darken-3 ml-1">{{evidencia.montoCargaSaldo | currencyParse(2)}} {{evidencia.claveMonedaCargaSaldo}}</strong>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<div class="text--secondary ml-1">Estatus</div>
									<strong class="grey--text text--darken-3 ml-1">{{evidencia.estatusEvidencia.descripcion}}</strong>
								</v-col>
							</v-row>
							<v-row v-if="evidencia.motivoEstatus">
								<v-col cols="12">
									<div class="text--secondary ml-1">Motivo de Estatus</div>
									<strong class="grey--text text--darken-3 ml-1">{{evidencia.motivoEstatus}}</strong>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<div class="text--secondary ml-1">Distribuidor</div>
									<strong class="grey--text text--darken-3 ml-1">{{nombreDistribuidor}}</strong>
								</v-col>
							</v-row>
							<v-row class="mb-3">
								<v-col cols="12">
									<div class="text--secondary ml-1">Observaciones</div>
									<strong class="grey--text text--darken-3 ml-1">{{evidencia.observaciones}}</strong>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" sm="8" md="8">
									<div class="text--secondary ml-1">Monto de Confirmación*</div>
									<validation-provider v-slot="{errors}" 
									name="monto de confirmacion" 
									rules="required">
										<v-text-field v-model="montoConfirmacion" 
										ref="montoConfirmacion"
										outlined  
										hide-details="auto"
										v-currency="$utilsInput.currency(2, false)"
										:error-messages="errors"
										:suffix="evidencia.claveMonedaCargaSaldo">
										</v-text-field>
									</validation-provider>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="12" sm="6" md="6" lg="6">
							<div class="text--secondary ml-1">Imagen de Evidencia</div>
							<v-img :src="urlImagen" 
							contain
							height="700"
							max-width="600"
							max-height="800" 
							class="rounded-lg">
								<template v-slot:placeholder>
									<v-row
									class="fill-height ma-0"
									align="center"
									justify="center">
										<v-progress-circular
										indeterminate>
										</v-progress-circular>
									</v-row>
								</template>
							</v-img>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider class="mx-4 mt-5"></v-divider>
				<v-card-actions>
					<v-row>
						<v-col cols="4">
							<v-btn @click="onOpenDialogRechazo" 
							:disabled="isLoading"
							:loading="isProcessing"
							class="ml-2">
								Rechazar
							</v-btn>
						</v-col>
						<v-col cols="8" class="text-right">
							<v-btn @click="onClose"
							:loading="isProcessing">
								Salir
							</v-btn>
							<v-btn @click="onConfirm"
							color="primary" 
							:disabled="isLoading"
							:loading="isProcessing"
							class="ml-2">
								Confirmar
							</v-btn>
						</v-col>
					</v-row>
				</v-card-actions>
			</v-card>
		</v-form>
	</validation-observer>
	<!--Dialog de confirmacion d erechazo-->
	<evidencia-carga-saldo-reject-dialog :dialog="dialogRechazo"
	@onCancel="onCancelDialogRechazo"
	@onOk="onOkDialogRechazo">
	</evidencia-carga-saldo-reject-dialog>
</div>
</template>
<script>
import SpinnerLoading from '../components/SpinnerLoading';
import EvidenciaCargaSaldoRejectDialog from './EvidenciaCargaSaldoRejectDialog';

import EvidenciaApi from '../services/api/evidenciaCargaSaldo.api';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import AuthTypes from '../store/types/AuthTypes';
import { getValue } from 'vue-currency-input';

export default {
	components: {
		SpinnerLoading,
		EvidenciaCargaSaldoRejectDialog,
		ValidationProvider,
		ValidationObserver,
	},
	created: function() {
		this.onInit();
	},
    data: () => ({
		isLoading: true,
		evidencia: {},
		montoConfirmacion: '',

		isProcessing: false,
		dialogRechazo: false
	}),
	computed: {
		loadedEvidencia: function() {
			return Object.keys(this.evidencia).length !== 0;
		},
		nombreDistribuidor: function() {
			if(this.loadedEvidencia)
				return this.evidencia.distribuidor.primerNombre + ' ' + this.evidencia.distribuidor.segundoNombre + ' ' + this.evidencia.distribuidor.apellidoPaterno + ' ' + this.evidencia.distribuidor.apellidoMaterno;
			else
				return '';
		},
		urlImagen: function() {
			if(this.loadedEvidencia) 
				return EvidenciaApi.getImage(this.evidencia.id);
			else
				return '';
				//return 'http://localhost:8582/api/EvidenciaCargaSaldo/GetImageById/' + this.evidencia.id;
		}
	},
	methods: {
		onInit: async function() {
			this.isLoading = true;
			
			await EvidenciaApi.get(this.$route.params.id)
			.then(response => {
				this.isLoading = false;
				if(response.success === true) {
					this.evidencia = response.data;
				} else {
					this.$router.push('/evidencia-carga-saldo/list-confirm');
					this.$notifier.error(response.message);
				}
			});
		},

		//#region Metodos para dialog

		onOpenDialogRechazo: function() {
			//Abrimos dialog
			this.dialogRechazo = true;
		},
		onCancelDialogRechazo: function() {
			//Cerramos dialog
			this.dialogRechazo = false;
		},
		onOkDialogRechazo: function(motivoRechazo) {
			//Cerramos dialog
			this.dialogRechazo = false;
			//Mandmaos a rechazar
			this.onReject(motivoRechazo)
		},

		//#endregion

		//#region Metodos de acciones de card
		
		onClose: function() {
			this.$router.push('/evidencia-carga-saldo/list-confirm');
		},
		onReject: async function(motivoRechazo) {
			//Prevent multiple guardado
			if(!this.isProcessing) {
				this.isProcessing = true;
				//Generamos objeto a enviar
				let dataReject = JSON.parse(JSON.stringify({
					idEvidencia: this.evidencia.id,
					motivoEstatus: motivoRechazo
				}));
				this.$emit('onReject', dataReject, () => {
					this.isProcessing = false;
				});
			}
		},
		onConfirm: async function() {
			//Prevent multiple guardado
			if(!this.isProcessing) {
				this.isProcessing = true;
				const isValid = await this.$refs.observer.validate();
				if(isValid) {
					//Generamos objeto a enviar
					let dataConfirm = JSON.parse(JSON.stringify({
						idEvidencia: this.evidencia.id,
						montoConfirma: getValue(this.$refs.montoConfirmacion),
						usuarioConfirma: this.$store.getters[AuthTypes.nameModule + '/' + AuthTypes.getters.EMAIL_USUARIO]
					}));
					this.$emit('onConfirm', dataConfirm, () => {
						this.isProcessing = false;
					});
				} else {
					this.isProcessing = false;
				}
			}
		}

		//#endregion
	}
}
</script>