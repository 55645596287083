<template>
<div>
	<app-header-page :titulos="titleItems"></app-header-page>
	<v-card class="mx-4 mb-4" 
	rounded="lg">
		<v-card-text>
			<v-row>
				<v-col cols="8">
					<div class="text--secondary ml-1">Distribuidor</div>
					<distribuidor-autocomplete ref="autocompleteDistribuidor" 
					:selected="distribuidor"	
					@onChange="onUpdateDistribuidor">	
					</distribuidor-autocomplete>
				</v-col>
			</v-row>
			<v-data-table :headers="headers"
			:items="items"
			:footer-props="footerProps"
			:page="page"
			:pageCount="totalPages"
			:options.sync="options"
			:server-items-length="totalItems"
			:items-per-page="pageSize"
			:loading="isLoading"
			loading-text="Cargando..."
			no-data-text="No existen registros."
			item
			class="elevation-1 mt-5"
			disable-sort>
				<template v-slot:item.fechaRegistro="{item}">
					{{ item.fechaRegistro | dateParse('DD/MM/YYYY') }}
				</template>
				<template v-slot:item.nombreDistribuidor="{item}">
					{{item.distribuidor.primerNombre}} {{item.distribuidor.segundoNombre}} {{item.distribuidor.apellidoPaterno}} {{item.distribuidor.apellidoMaterno}}
				</template>
				<template v-slot:item.monto="{item}">
					{{ item.montoCargaSaldo | currencyParse(2) }}
				</template>
				<template v-slot:item.acciones="{item}">
					<v-tooltip left>
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon
							color="gray"
							:to="{path: '/evidencia-carga-saldo/' + item.id + '/confirm'}"
							v-bind="attrs"
							v-on="on">
								<v-icon>mdi-progress-check</v-icon>
							</v-btn>
						</template>
						<span>Ir a Confirmar</span>
					</v-tooltip>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</div>
</template>
<script>
import AppHeaderPage from '../components/AppHeaderPage';
import DistribuidorAutocomplete from '../components/DistribuidorAutocomplete';

import EvidenciaApi from '../services/api/evidenciaCargaSaldo.api';
import AuthApi from '../services/api/auth.api';
import AuthTypes from '../store/types/AuthTypes';
import { Funcion } from '../utils/constants';

export default {
	components: {
		AppHeaderPage,
		DistribuidorAutocomplete,
	},
    data: () => ({
		titleItems: ['Evidencias de carga de saldo', 'Por confirmar'],
		distribuidor: {},
		headers: [
			{ text: 'Id', value: 'id' },
			{ text: 'Fecha de Registro', value: 'fechaRegistro' },
			{ text: 'Estatus', value: 'estatusEvidencia.descripcion' },
			{ text: 'Distribuidor', value: 'nombreDistribuidor' },
			{ text: 'Monto', value: 'monto', align: 'right' },
			{ text: 'Moneda', value: 'claveMonedaCargaSaldo' },
			{ text: 'Acciones', value: 'acciones'}
		],
		items: [],
		footerProps: {
			'items-per-page-text': 'Mostrar',
			'items-per-page-options': [10,15,20]
		},
		page: 1,
		totalPages: 0,
		options: {},
		totalItems: 0,
		pageSize: 10,
		isLoading: true,
		evidenciaCurrent: {},
	}),
	computed: {
		loadedDistribuidor: function() {
			return Object.keys(this.distribuidor).length !== 0;
		},
	},
	mounted: function() {
		this.onPaged();
	},
	watch: {
		options: {
			handler() {
				this.onPaged();
			}
		},
	},
	methods: {
		onPaged: function() {
			this.isLoading = true;
			//Obtenemos datos de footer para API
			const {page, itemsPerPage} = this.options;
			this.page = page;
			this.pageSize = itemsPerPage;
			EvidenciaApi.getPagedToConfirm(this.page, this.pageSize, this.loadedDistribuidor ?  this.distribuidor.id : '')
			.then(response => {
				this.isLoading = false;
				if(response.success === true) {
					//Cargamos nuevos datos de paginado
					this.items = response.data.items;
					this.totalItems = parseInt(response.data.totalRecordCount);
					this.totalPages = response.data.totalPages;
				} else {
					this.$notifier.error(response.message)
				}
			});
		},

		onUpdateDistribuidor: async function(distribuidorSelected) {
			this.distribuidor = distribuidorSelected != null ? distribuidorSelected : {};
			//Inicializamos numero de pagina y paginamos
			this.options.page = 1;
			this.onPaged();
		},
	}
  }
</script>