import httpClient from './httpClient';
import httpUtils from '../httpUtils';

const END_POINT = '/Producto';

export default {
    getAll() {
        return httpClient.get(END_POINT)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
    getPaged(page, pageSize, parameterSearch) {
        return httpClient.get(END_POINT + '/GetPaged?page=' + page + '&pageSize=' + pageSize + '&parameter=' + parameterSearch)
            .then(response => {
                return httpUtils.responsePagination(response.headers, response.data);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
    getBasic(clave) {
        return httpClient.get(END_POINT + '/GetBasicByClave/' + clave)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
    get(clave) {
        return httpClient.get(END_POINT + '/GetByClave/' + clave)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
    add(producto) {
        return httpClient.post(END_POINT, producto)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            });
    },
    update(clave, producto) {
        return httpClient.put(END_POINT + '?clave=' + clave, producto)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            });
    },
    delete(clave) {
        return httpClient.delete(END_POINT + '/' + clave)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    }
}