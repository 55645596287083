<template>
<v-dialog 
	v-model="dialog"
	persistent
	max-width="700">
	<v-card class="py-3">
		<v-card-title class="py-2">
			<span class="text-button grey--text text--darken-3">Historial de Llaves de Activación</span>
		</v-card-title>
		<v-card-text>
			<v-data-table 
				dense
				:headers="headers"
				:items="items"
				:footer-props="footerProps"
				:search="search"
				no-data-text="Sin registros"
				class="elevation-1">>
				<template v-slot:item.fechaActivacion="{item}">
					{{ item.fechaActivacion | dateParse('DD/MM/YYYY') }}
				</template>
				<template v-slot:item.esGratis="{item}">
					{{ item.esGratis | booleanParse()}}
				</template>
				<template v-slot:item.esObsequio="{item}">
					{{ item.esObsequio | booleanParse()}}
				</template>
				<template v-slot:item.monto="{item}">
					<span v-if="item.monto != null">{{ item.monto | currencyParse(2) }} {{item.claveMoneda}}</span>
				</template>
				<template v-slot:item.acciones="{item}">
					<input 
						type="hidden" 
						:id="'input-llave-' + item.id"
						:value="item.cadenaActivacion">
					<v-tooltip left>
						<template v-slot:activator="{ on, attrs }">
							<v-btn 
								icon
								color="gray"
								@click="onCopyLlave(item)"
								v-bind="attrs"
								v-on="on">
								<v-icon>mdi-content-copy</v-icon>
							</v-btn>
						</template>
						<span>Copiar llave de activacion</span>
					</v-tooltip>
				</template>
			</v-data-table>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn small 
			color="default"
			@click="onClose">
				Cerrar
			</v-btn>
		</v-card-actions>
	</v-card>
</v-dialog>
</template>
<script>
import LlaveActivacionApi from '../services/api/llaveActivacion.api';

export default {
	props: {
		dialog: Boolean, 
		idLicencia: Number,
		claveProducto: String
	},
  data: () => ({
		headers: [
			{ text: 'Fecha', value: 'fechaActivacion', width: '150' },
			{ text: 'Gratis', value: 'esGratis', width: '80' },
			{ text: 'Obsequio', value: 'esObsequio', width: '80' },
			{ text: 'Periodo', value: 'periodoPago.descripcion', width: '100'},
			{ text: 'Días de Vigencia', value: 'diasVigencia', width: '100'},
			{ text: 'Monto', value: 'monto', align: 'right', width: '150'},
			{ text: 'Clave de Activación', value: 'claveActivacion', width: '350' },
			{ text: 'Acciones', value: 'acciones', width: '80'}
		],
		items: [],
		footerProps: {
			'items-per-page-text': 'Mostrar',
			'items-per-page-options': [10,15,20]
		},
		search: '',
		isLoading: true,
	}),
	watch: {
		dialog :{
			handler(newVal, oldVal) {
				if(newVal) {
					this.onLoaded();
				}
			},
			deep: true
		},
	},
	methods: {
		onLoaded: function() {
			this.isLoading = true;
			LlaveActivacionApi.getAllByLicencia(this.idLicencia, this.claveProducto)
			.then(response => {
				this.isLoading = false;
				if(response.success === true) {
					this.items = response.data;
				}
			});
		},
		onCopyLlave: function(llave) {
			let llaveToCopy = document.querySelector('#input-llave-' + llave.id)
			llaveToCopy.setAttribute('type', 'text')
			llaveToCopy.select()
			try {
				var successful = document.execCommand('copy');
				if(!successful) {
					alert('Error al copiar los datos de llave de activacion, debera copiarlos manualmente.');
				} else {
					this.$notifier.success('Llave de activación copiada al portapapeles.');
				}
			} catch (err) {
				alert('Oops, tiene deshabilitado el copiado de texto.');
			}
			/* unselect the range */
			llaveToCopy.setAttribute('type', 'hidden');
			window.getSelection().removeAllRanges();
		},
		onClose: function() {
			this.$emit('onClose');
		},
	}
}
</script>