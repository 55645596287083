import axios from 'axios';
import AuthTypes from '../../store/types/AuthTypes';
import store from '../../store';

const httpClient = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    }
});

//Buscamos token
const getAuthToken = function() {
    return store.getters[AuthTypes.nameModule + '/' + AuthTypes.getters.TOKEN];
}
const authInterceptor = (config) => {
    let token = getAuthToken();
    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
}
httpClient.interceptors.request.use(authInterceptor);

export default httpClient;