<template>
	<v-row 
		align="center" 
		justify="center"
	>
		<v-col 
			cols="12" 
			sm="12" 
			md="10" 
			lg="8"
		>
			<!--Para mas productos agregar demas condiciones-->
			<pos-pos-review-card 
				v-if="isPOSPOS" 
				:product="product"
				:renew="renew"
				@onSelect="onSelect"
			/>
			<v-card 
				v-else
				class="px-5" 
				rounded="lg"
			>
				<v-card-text>
					<v-row class="my-5">
						<v-col 
							cols="12" 
							class="d-flex flex-column align-center justify-center"
						>
							<v-img 
								src="images/brand/product_not_found.png" 
								width="200"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col 
							cols="12" 
							class="text-center my-5"
						>
							<strong class="grey--text text--darken-3 text-h6 font-weight-light">Lo sentimos pero el producto al que intentas activar no existe.</strong>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>
<script>
import PosPosReviewCard from '../components/PosPosReviewCard.vue'
import { Producto } from '../utils/constants';

export default {
	components: {
		PosPosReviewCard,
	},
	data: () => ({
		product: '',
		renew: false,
		idLicencia: 0,
		claveProducto: '',
		clavePeriodo: '',
	}),
	created: function() {
		this.product = this.$route.query.product;
		let routeName = this.$route.name;
		if(routeName === 'ClienteReviewGenerateProducto') { // Primera activacion
			this.renew = false;
		} else if(routeName === 'ClienteReviewRenovateProducto') { // Renovacion
			this.renew = true;
			this.idLicencia = this.$route.params.id;
			this.claveProducto = this.$route.params.claveProducto;
			this.clavePeriodo = this.$route.params.clavePeriodo;
		}
	},
	computed: {
		isPOSPOS: function() {
			return this.product === Producto.POSPOS
		}
	},
	methods: {
		onSelect: function(claveProducto, esGratis, clavePeriodo) {
			if(this.renew) {
				this.$router.push('/license/' + this.idLicencia + '/' + claveProducto + '/' + clavePeriodo + '/renovate');
			} else {
				this.$router.push({ 
				path: '/license/generate', 
				query: { 
					esGratis: esGratis,
					claveProducto: claveProducto, 
					clavePeriodo: clavePeriodo 
				}
			});
			}
		}
	}
}
</script>