<template>
<div>
	<v-card 
		class="mx-4" 
		rounded="lg"
	>
		<v-card-title class="py-4">
			<span class="font-weight-regular">Pago OXXO</span>
		</v-card-title>
		<v-card-text>
			<v-row>
				<v-col 
					cols="12"
					sm="8"
					md="8"
					lg="8"
					class="pl-5">
					Clave Pago OXXO<br>
					<strong class="font-weight-medium">{{pagoOxxo.clavePagoOxxo}}</strong> 
				</v-col>
				<v-col 
					cols="12"
					sm="4"
					md="4"
					lg="4"
					class="pl-5">
					Estatus<br>
					<strong class="font-weight-medium">{{pagoOxxo.estatusPago.descripcion}}</strong> 
				</v-col>
			</v-row>
			<v-row>
				<v-col 
					cols="12"
					class="pl-5">
					Nombre de Cliente<br>
					<strong class="font-weight-medium">{{pagoOxxo.nombreCliente}}</strong> 
				</v-col>
			</v-row>
			<v-row>
				<v-col 
					cols="12"
					class="pl-5">
					Correo de Cliente<br>
					<strong class="font-weight-medium">{{pagoOxxo.correoCliente}}</strong> 
				</v-col>
			</v-row>
			<v-row>
				<v-col 
					cols="12"
					class="mt-4">
					<strong class="text-subtitle-1 grey--text text--darken-3">Datos de activación</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col 
					cols="12"
					class="pl-5">
					Código de Referencia<br>
					<strong class="font-weight-medium">{{pagoOxxo.distribuidor.codigoReferencia}}</strong> 
				</v-col>
			</v-row>
			<v-row>
				<v-col 
					cols="12"
					class="pl-5">
					Nombre de Empresa<br>
					<strong class="font-weight-medium">{{pagoOxxo.nombreEmpresa}}</strong> 
				</v-col>
			</v-row>
			<v-row>
				<v-col 
					cols="12"
					class="pl-5">
					ID Dispositivo<br>
					<strong class="font-weight-medium">{{pagoOxxo.idDispositivo}}</strong> 
				</v-col>
			</v-row>
			<v-row>
				<v-col 
					cols="12"
					class="pl-5">
					Producto<br>
					<strong class="font-weight-medium">{{pagoOxxo.producto.descripcion}}</strong> 
				</v-col>
			</v-row>
			<v-row>
				<v-col 
					cols="12"
					class="pl-5">
					Precio<br>
					<strong class="font-weight-medium">{{pagoOxxo.precioProducto | currencyParse(2)}} {{pagoOxxo.claveMoneda}}</strong> 
				</v-col>
			</v-row>
			<v-row>
				<v-col 
					cols="12"
					class="pl-5">
					Periodo<br>
					<strong class="font-weight-medium">{{pagoOxxo.periodoPago.descripcion}}</strong> 
				</v-col>
			</v-row>
		</v-card-text>
		<v-divider class="mx-4 mt-5"></v-divider>
		<v-card-actions>
			<v-row>
				<v-col cols="12" class="text-right">
					<v-btn 
						color="default" 
						:loading="isReporting"
						class="ml-2"
						@click="onBack"
					>
						Regresar
					</v-btn>
					<v-btn 
						v-if="pagoOxxo.estatusPago.clave === 'PE'"
						color="primary" 
						:loading="isReporting"
						class="ml-2"
						@click="onReport"
					>
						Reportar
					</v-btn>
				</v-col>
			</v-row>
		</v-card-actions>
	</v-card>
</div>
</template>
<script>
export default {
	props: {
		pagoOxxo: Object
	},
  data: () => ({
		isReporting: false,
	}),
	methods: {
		onBack: function() {
			this.$emit('onBack');
		},
		onReport: async function() {
			//Prevent multiple reporte
			if(!this.isReporting) {
				this.isReporting = true;
				this.$emit('onReport', () => {
					this.isReporting = false;
				});
			}
		}

		//#endregion
	}
}
</script>