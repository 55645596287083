<template>
	<v-dialog v-model="dialogConfirm" 
	persistent
	max-width="500">
		<v-card>
			<v-card-title class="py-2">
				<span class="text-button grey--text text--darken-3">{{title}}</span>
			</v-card-title>
			<v-card-text>
				<slot></slot>
			</v-card-text>
            <v-card-actions class="py-3">
				<v-spacer></v-spacer>
				<v-btn small 
				color="default"
				:loading="isProcessing"
				@click="onCancel">
					NO
				</v-btn>
				<v-btn small 
				color="primary"
				:loading="isProcessing"
				@click="onOk">
					SI
				</v-btn>
			</v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
	props: {
		dialogConfirm: Boolean,
		title: String,
	},
	data: () => ({
		isProcessing: false,
	}),
	methods: {
		onCancel: function() {
			this.$emit('onCancel');
		},
		onOk: function() {
			//Prevent multiple ejecucion
			if(!this.isProcessing) {
				this.isProcessing = true;
				this.$emit('onOk', () => {
					this.isProcessing = false;
				});
			}
		},
	}
}
</script>