import httpClient from './httpClient';
import httpUtils from '../httpUtils';

const END_POINT = '/Auth';

export default {
    signIn(email, password) {
        return httpClient.post(END_POINT + '/SignIn', {
            email: email,
            password: password
        }).then(response => {
            return httpUtils.responseSuccess(response);
        }).catch(error => {
            return httpUtils.responseError(error.response);
        });
    },
    validatePermission(email, claveFuncion) {
        return httpClient.get(END_POINT + '/ValidatePermission/' + email + '/' + claveFuncion)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
}