<template>
<small class="text--secondary">
	<strong>Creación: </strong>
	{{creador}} /
	{{fechaCreacion | dateParse('DD-MM-YYYY HH:mm:ss')}}
	<br>
	<strong>Modificación: </strong>
	{{modificador}} /
	{{fechaModificacion | dateParse('DD-MM-YYYY HH:mm:ss')}}
</small>
</template>
<script>
export default {
	props: ['creador', 'fechaCreacion', 'modificador', 'fechaModificacion']
}
</script>