<template>
	<div>
		<component :is="layout"></component>
	</div>
</template>

<script>
import LoginLayout from './layout/LoginLayout';
import PrincipalLayout from './layout/PrincipalLayout';
import {mapState} from 'vuex';
import AuthTypes from './store/types/AuthTypes';

export default {
	name: 'App',
	components: {
		LoginLayout,
		PrincipalLayout
	},
	computed: mapState(AuthTypes.nameModule, ['layout'])
};
</script>