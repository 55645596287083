import httpClient from './httpClient';
import httpUtils from '../httpUtils';

const END_POINT = '/Moneda';

export default {
    getAll() {
        return httpClient.get(END_POINT)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
    get(clave) {
        return httpClient.get(END_POINT + '?clave=' + clave)
            .then(response => {
                return httpUtils.responseSuccess(response);
            }).catch(error => {
                return httpUtils.responseError(error.response);
            })
    },
}