<template>
<div>
	<app-header-page :titulos="titleItems"></app-header-page>
	<pago-card 
		:mode="mode" 
		:inAppStack="true"
		@onCancel="onCancel"
		@onSubmit="onSubmit">
	</pago-card>
	<!--Dialog de exito-->
	<licencia-success-dialog 
		:dialog="dialogLicencia"
		:datos="datosLicencia"
		@onClose="onCloseDialogLicencia">
	</licencia-success-dialog>
</div>
</template>
<script>
import AppHeaderPage from '../components/AppHeaderPage';
import PagoCard from '../components/PagoCard';
import LicenciaSuccessDialog from '../components/LicenciaSuccessDialog'

import LicenciaApi from '../services/api/licencia.api';
import { ViewMode } from '../utils/constants';

export default {
	components: {
		AppHeaderPage,
		PagoCard,
		LicenciaSuccessDialog
	},
  data: () => ({
		titleItems: ['Licencia', 'Pago'],
		mode: ViewMode.CREATE,
		dialogLicencia: false,
		datosLicencia: {}
	}),
	methods: {
		onCancel: function() {
			this.$router.push('/licencia/list-to-pay');
		},
		onSubmit: function(pagoSaved, callBack) {
			LicenciaApi.pay(pagoSaved)
			.then(response => {
				callBack();
				if(response.success === true) {
					if(response.data !== null) {
						this.datosLicencia = response.data;
						this.dialogLicencia = true;
						this.$notifier.success('Credito saldado.');
					} else {
						this.$router.push('/licencia/list-to-pay');
						this.$notifier.success('Pago registrado exitosamente.');
					}
				} else {
					this.$notifier.error(response.message);
				}
			});
		},
		onCloseDialogLicencia: function() {
			this.$router.push('/licencia/list-to-pay');
		}
	}
}
</script>