<template>
<div>
		<v-card class="mx-4" 
		rounded="lg">
			<v-card-text>
				<v-row class="mt-5">
					<v-col cols="12" class="d-flex flex-column align-center justify-center">
						<v-img src="images/theme/check_success.png" width="100"/>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" class="text-center my-3">
						<strong class="grey--text text--darken-3 text-h4">¡Pago Exitoso!</strong>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" class="text-center">			
						<p class="font-weight-regular">Gracias por usar productos de APPSTACK. Tu pago del credito de licencia se proceso con exito.</p>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12">
						<p class="ml-2">
							Cliente<br>
							<strong class="font-weight-medium">{{nombreCliente}}</strong> 
						</p>
						<p class="ml-2">
							Monto<br>
							<strong class="font-weight-medium">{{montoPago | currencyParse(2)}} {{claveMoneda}}</strong> 
						</p>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-row>
					<v-col cols="12" class="text-left">
						<v-btn 
							@click="onCancel"
							color="default">
							Salir
						</v-btn>
					</v-col>
				</v-row>
			</v-card-actions>
		</v-card>
</div>
</template>
<script>
export default {
	props: {
		nombreCliente: String,
		montoPago: Number,
		claveMoneda: String,
	},
  methods: {
		onCancel: function() {
			this.$emit('onCancel')
		},
	}
}
</script>