<template>
  <v-dialog 
		v-model="dialog" 
		persistent
		eager
		max-width="500"
	>
		<validation-observer ref="observerOxxo">
			<v-form @submit.prevent="">
				<v-card>
					<v-card-title class="py-2">
						<span class="text-button grey--text text--darken-3">Pago con OXXO</span>
					</v-card-title>
					<v-card-text>
						<v-row class="pt-5 pb-1">
							<v-col 
								cols="12" 
								class="d-flex flex-column align-center justify-center"
							>
								<v-img 
									src="images/brand/oxxo.png" 
									contain
									width="200"
								/>	
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<div class="text--secondary ml-1">Total a Pagar</div>
								<span class="grey--text text--darken-3 text-h5">{{montoPago | currencyParse(2)}} {{claveMoneda}}</span>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<div class="text--secondary ml-1">Nombre de cliente*</div>
								<validation-provider 
									v-slot="{errors}" 
									name="nombre cliente" 
									rules="required"
								>
									<v-text-field 
										v-model="nombreCliente" 
										outlined  
										placeholder="Nombre y Apellido(s)"
										hide-details="auto"
										@input="nombreCliente = $utilsInput.uppercase(nombreCliente)"
										:error-messages="errors"
									>
									</v-text-field>
								</validation-provider>
							</v-col>
							<v-col cols="12">
								<div class="text--secondary ml-1">Correo electronico*</div>
								<validation-provider 
									v-slot="{errors}" 
									name="correo electronico" 
									rules="required|email">
									<v-text-field
										v-model="correoCliente" 
										outlined  
										hide-details="auto"
										:error-messages="errors">
									</v-text-field>
								</validation-provider>
							</v-col>
						</v-row>			
					</v-card-text>
					<v-card-actions class="py-3">
						<v-spacer></v-spacer>
						<v-btn small 
						color="default"
						:loading="isProcessing"
						@click="onClose">
							Cerrar
						</v-btn>
						<v-btn small 
						color="primary"
						:loading="isProcessing"
						@click="onCreate">
							Crear Voucher
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</validation-observer>
	</v-dialog>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  props: {
		dialog: Boolean, 
		correo: String,
		montoPago: Number,
		claveMoneda: String
	},
	components: {
		ValidationProvider,
		ValidationObserver
	},
  mounted() {
		this.onIncludeStripe('js.stripe.com/v3/', function() { 
			this.onConfigureStripe(); 
		}.bind(this));
	},
	watch: {
		dialog :{
			handler(newVal, oldVal) {
				if(newVal) {
					this.correoCliente = this.correo;
				}
			},
			deep: true
		},
	},
  data: () => ({
		nombreCliente: '',
		correoCliente: '',
		isProcessing: false,
  }),
  methods: {
		onIncludeStripe(URL, callback) {
			let documentTag = document, tag = 'script',
			object = documentTag.createElement(tag),
			scriptTag = documentTag.getElementsByTagName(tag)[0];
			object.src = '//' + URL;
			if (callback) { 
				object.addEventListener('load', function (e) { 
					callback(null, e); 
				}, false); 
			}
			scriptTag.parentNode.insertBefore(object, scriptTag);
		},
		onConfigureStripe() {
			/* global Stripe */
			this.stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
		},
		onClose: function() {
      //Cerramos dialog
			this.$emit('onClose');
		},
		onCreate: async function() {
			const isValid = await this.$refs.observerOxxo.validate();
			if(isValid) {
				//Prevent multiple guardado
				if(!this.isProcessing) {
					this.isProcessing = true;
					this.$emit('onHandleStripeOxxo', this.nombreCliente, this.correoCliente, () => {
						this.isProcessing = false;
					}, this.stripe);
				}
			}
		},
	}
}
</script>
