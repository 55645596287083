<template>
<div>
	<app-header-page :titulos="titleItems"></app-header-page>
	<documento-afectacion-saldo-card :mode="mode" 
	@onSubmit="onSubmit">
	</documento-afectacion-saldo-card>
</div>
</template>
<script>
import AppHeaderPage from '../components/AppHeaderPage';
import DocumentoAfectacionSaldoCard from '../components/DocumentoAfectacionSaldoCard';
import DocumentoApi from '../services/api/documentoAfectacionSaldo.api';
import { ViewMode } from '../utils/constants';

export default {
	components: {
		AppHeaderPage,
		DocumentoAfectacionSaldoCard
	},
    data: () => ({
		titleItems: ['Documento de afectación de saldo', 'Registrar'],
		mode: ViewMode.CREATE,
	}),
	methods: {
		onSubmit: function(documentoSaved, callBack) {
			DocumentoApi.finalize(documentoSaved)
			.then(response => {
				callBack();
				if(response.success === true) {
					this.$router.push('/documento-afectacion-saldo/all');
					this.$notifier.success('Documento de afectación de saldo finalizado exitosamente.');
				} else {
					this.$notifier.error(response.message);
				}
			});
		}
	}
  }
</script>