<template>
	<v-row align="center" justify="center">
		<v-col v-if="!pagoProcesado" cols="12" sm="12" md="10" lg="8">
			<pago-card 
				:mode="mode" 
				:inAppStack="false"
				@onCancel="onCancel"
				@onSubmit="onOpenDialogPago">
			</pago-card>
		</v-col>
		<v-col v-else cols="12" sm="10" md="8" lg="6">
			<licencia-cliente-success-card
				v-if="creditoPagado"
				:idLicencia="datosActivacion.idLicencia"
				:claveActivacion="datosActivacion.claveActivacion"
				:cadenaActivacion="datosActivacion.cadenaActivacion"
				:descripcionProducto="licencia.productoDescripcion"
				:esGratis="false"
				:precio="licencia.monto"
				:claveMoneda="licencia.claveMoneda"
				:descripcionPeriodo="datosActivacion.descripcionPeriodo"
				:codigoReferencia="datosActivacion.codigoReferencia"
				:nombreEmpresa="datosActivacion.nombreEmpresa"
				:idDispositivo="licencia.idDispositivo"
				telefono=""
				correo=""
				mensajeEnvioCorreo=""
				@onCancel="onCancel"/>
			<pago-cliente-licencia-credito-success-card
				v-else
				:nombreCliente="nombreCliente"
				:montoPago="montoPago"
				:claveMoneda="claveMoneda"
				@onCancel="onCancel">
			</pago-cliente-licencia-credito-success-card>
		</v-col>
		<!--Dialog de pago stripe/paypal-->
		<pago-cliente-tarjeta-dialog 
			:dialog="dialogPago"
			:nombreCliente="nombreCliente"
			:montoPago="montoPago"
			:claveMoneda="claveMoneda"
			@onClose="onCloseDialogPago"
			@onHandleStripePaymentMethod="onHandleStripePaymentMethod">
		</pago-cliente-tarjeta-dialog>
	</v-row>
</template>
<script>
import PagoCard from '../components/PagoCard';
import PagoClienteTarjetaDialog from '../components/PagoClienteTarjetaDialog';
import PagoClienteLicenciaCreditoSuccessCard from '../components/PagoClienteLicenciaCreditoSuccessCard';
import LicenciaClienteSuccessCard from '../components/LicenciaClienteSuccessCard';

import LicenciaApi from '../services/api/licencia.api';
import { ViewMode } from '../utils/constants';

export default {
	components: {
		PagoCard,
		PagoClienteTarjetaDialog,
		PagoClienteLicenciaCreditoSuccessCard,
		LicenciaClienteSuccessCard
	},
  data: () => ({
		mode: ViewMode.CREATE,
		licencia: {},
		pagoLicencia: {},
		nombreCliente: '',
		montoPago: 0,
		claveMoneda: '',
		dialogPago: false,
		stripe: null,
		pagoProcesado: false,
		creditoPagado: false,
		datosActivacion: {}
	}),
	methods: {
		onCancel: function() {
			this.$router.push('/cliente/search-license-credit');
		},
		onOpenDialogPago: function (licencia, pagoSaved) {
			this.licencia = licencia;
			this.pagoLicencia = pagoSaved;
			this.nombreCliente = pagoSaved.nombreCliente;
			this.montoPago = pagoSaved.montoPago;
			this.claveMoneda = pagoSaved.claveMoneda;
			//Abrimos dialog
			this.dialogPago = true;
		},
		onCloseDialogPago: function() {
			//Cerramos dialog
			this.dialogPago = false;
		},
		onFormatPagoStripe: function(paymentMethodId, paymentIntentId) {
			if(paymentMethodId !== null && paymentMethodId != '') {
				return {
					paymentMethodId: paymentMethodId,
					pagoLicencia: this.pagoLicencia
				}
			} else if(paymentIntentId !== null && paymentIntentId != '') {
				return {
					paymentIntentId: paymentIntentId,
					pagoLicencia: this.pagoLicencia
				}
			}
		},
		onHandleStripePaymentMethod: function(result, callback, stripe) {
			this.stripe = stripe;
			if (result.error) {
				callback();
				this.$notifier.error(result.error.message);
			} else {
				//Mandamos datos de pago junto con paymentMethod.id creado
				LicenciaApi.payStripe(this.onFormatPagoStripe(result.paymentMethod.id, null))
				.then(response => {
					this.onHandleStripeApiResponse(response, callback);
				});
			}
		},
		onHandleStripeApiResponse: function(response, callback) {
			if(response.success === true) {
				if(response.data !== null) {
					if(typeof response.data.requiresAction !== 'undefined' && response.data.requiresAction) {
						this.stripe.handleCardAction(
							response.data.paymentIntentClientSecret
						).then(result => {
							this.onHandleStripeCardAction(result, callback);
						});
					} else {
						callback();
						this.dialogPago = false;
						this.pagoProcesado = true;
						this.creditoPagado = true;
						this.datosActivacion = response.data;
					}
				} else {
					callback();
					this.dialogPago = false;
					this.pagoProcesado = true;
				}
			} else {
				callback();
				this.$notifier.error(response.message);
			}
		},
		onHandleStripeCardAction: function(result, callback) {
			if(result.error) {
				callback();
				this.$notifier.error(result.error.message, 5000);
			} else {
				//Mandamos datos de pago junto con paymentIntent.id confirmado
				LicenciaApi.payStripe(this.onFormatPagoStripe(null, result.paymentIntent.id))
				.then(response => {
					this.onHandleStripeApiResponse(response, callback);
				});
			}
		}
	}
}
</script>