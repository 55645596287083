<template>
<div>
	<app-header-page :titulos="titleItems"></app-header-page>
	<licencia-card 
		:mode="mode" 
		@onSubmit="onSubmit">
	</licencia-card>
	<!--Dialog de exito-->
	<licencia-success-dialog 
		:dialog="dialogSuccess"
		:datos="licencia"
		@onClose="onCloseDialogSuccess">
	</licencia-success-dialog>
</div>
</template>
<script>
import AppHeaderPage from '../components/AppHeaderPage';
import LicenciaCard from '../components/LicenciaCard';
import LicenciaSuccessDialog from '../components/LicenciaSuccessDialog';

import LicenciaApi from '../services/api/licencia.api';
import { ViewMode } from '../utils/constants';

export default {
	components: {
		AppHeaderPage,
		LicenciaCard,
		LicenciaSuccessDialog
	},
  data: () => ({
		titleItems: ['Licencia', 'Generar'],
		mode: ViewMode.CREATE,
		dialogSuccess: false,
		licencia: {},
	}),
	watch: {
		dialogSuccess :{
			handler(newVal, oldVal) {
				if(!newVal) {
					this.$router.push('/licencia/all');
					this.$notifier.success('Licencia generada exitosamente.');
				}
			},
			deep: true
		},
	},
	methods: {
		onSubmit: function(licenciaSaved, callBack) {
			LicenciaApi.activation(licenciaSaved)
			.then(response => {
				callBack();
				if(response.success === true) {
					this.licencia = response.data;
					this.dialogSuccess = true;
				} else {
					this.$notifier.error(response.message);
				}
			});
		},
		onCloseDialogSuccess: function() {
			//Cerramos dialog
			this.dialogSuccess = false;
		},
	}
  }
</script>