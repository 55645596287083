<template>
<v-dialog v-model="dialog"
	persistent
	max-width="500">
	<validation-observer ref="observerPrecio">
		<v-form @submit.prevent="">
			<v-card>
				<v-card-title class="py-2">
					<span class="text-button grey--text text--darken-3">PRECIO</span>
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text>
					<v-row>
						<v-col cols="12" sm="7" md="7">
							<div class="text--secondary ml-1">Precio*</div>
							<validation-provider v-slot="{errors}" 
							name="precio" 
							rules="required">
								<v-text-field v-model="localPrecio.precio" 
								ref="precio"
								outlined  
								hide-details="auto"
								v-currency="$utilsInput.currency(2, false)"
								:error-messages="errors">
								</v-text-field>
							</validation-provider>
						</v-col>
						<v-col cols="12" sm="5" md="5">
							<div class="text--secondary ml-1">Moneda*</div>
							<validation-provider v-slot="{errors}" 
								name="moneda" 
								rules="required">
								<v-autocomplete v-model="localPrecio.claveMoneda" 
								:items="monedas"
								:disabled="isEdit"
								outlined
								hide-details="auto"
								item-value="cClave"
								:item-text="onMonedaToString"
								:error-messages="errors">
								</v-autocomplete>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<div class="text--secondary ml-1">Periodo*</div>
							<validation-provider v-slot="{errors}" 
								name="periodo" 
								rules="required">
								<v-autocomplete v-model="localPrecio.periodoPago" 
								:items="periodos"
								:disabled="isEdit"
								outlined
								hide-details="auto"
								item-value="clave"
								:item-text="onPeriodoToString"
								return-object
								:error-messages="errors">
								</v-autocomplete>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<div class="text--secondary ml-1">Producto ERP*</div>
							<producto-erp-autocomplete ref="autocompleteProductoErp" 
							:selected="productoErp"
							:validError="validErrorProductoErp"
							@onChange="onUpdateProductoErp">	
							</producto-erp-autocomplete>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn small 
					color="default"
					@click="onCancel">
						Cancelar
					</v-btn>
					<v-btn small 
					color="primary"
					@click="onSave">
						Guardar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</validation-observer>
</v-dialog>
</template>
<script>
import ProductoErpAutocomplete from './ProductoErpAutocomplete';

import ProductoErpApi from '../services/api-erp/producto.api';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ObjectToStringMixin from '../mixins/objectToStringMixin';
import { getValue } from 'vue-currency-input';
import { ViewMode } from '../utils/constants';

export default {
	props: {
		dialog: Boolean, 
		mode: Number,
		precio: Object,
		monedas: Array,
		periodos: Array,
	},
	components: {
		ValidationProvider,
		ValidationObserver,
		ProductoErpAutocomplete,
	},
	mixins: [ObjectToStringMixin],
  data: () => ({
		localPrecio: {},
		productoErp: {},
		validErrorProductoErp: '',
		isSaving: false,
	}),
	watch: {
		dialog :{
			handler(newVal, oldVal) {
				if(newVal) {
					this.localPrecio = {
						id: typeof this.precio.id === 'undefined' ? 0 : this.precio.id,
						claveProducto: typeof this.precio.claveProducto === 'undefined' ? '' : this.precio.claveProducto,
						precio: this.precio.precio,
						claveMoneda: this.precio.claveMoneda,
						clavePeriodoPago: this.precio.clavePeriodoPago,
						claveProductoERP: this.precio.claveProductoERP,
						periodoPago: this.precio.periodoPago,
						observaciones: this.precio.observaciones
					};
					if(this.isEdit) {
						if(this.precio.claveProductoERP)
							this.onInitProductoErp(this.precio.claveProductoERP);
					} else {
						this.productoErp = {};
					}
				}
			},
			deep: true
		},
	},
	computed: {
		isEdit: function() {
			return this.mode === ViewMode.EDIT;
		},
		loadedProductoErp: function() {
			return Object.keys(this.productoErp).length !== 0;
		},
	},
	methods: {
		//#region Metodos para actualizar propiedades

		onUpdateProductoErp: function(productoErpSelected) {
			if(productoErpSelected !== null) {
				this.productoErp = productoErpSelected;
				this.localPrecio.claveProductoERP = this.productoErp.CClave;
				this.$refs.autocompleteProductoErp.onInitSelection(this.productoErp);
			} else {
				this.productoErp = {};
				this.localPrecio.claveProductoERP = '';
				this.$refs.autocompleteProductoErp.onInitSelection(null);
			}
		},
		onInitProductoErp: function(claveProductoERP) {
			ProductoErpApi.get(claveProductoERP)
			.then(response => {
				if(response.success === true) {
					this.productoErp = response.data;
					this.$refs.autocompleteProductoErp.onInitSelection(this.productoErp);
				} else {
					this.$notifier.warn('Los datos de producto ERP no se cargaron correctamente. \n' + response.message);
				}
			});
		},

		//#endregion

		//#region Metodos de formateo objetos

		onMonedaToString: function(item) {
			return this.objectToString(item.cClave, item.cDescripcion);
		},
		onPeriodoToString: function(item) {
			return this.objectToString('', item.descripcion);
		},

		//#endregion

		//#region Metodos de acciones de card

		onClearControlProducto: function() {
			this.productoErp = {};
			this.validErrorProductoErp = '';
			this.$refs.autocompleteProductoErp.onInitSelection(null);
		},
		onValidCustom: function() {
			//Validamos y creamos mensaje de error
			this.validErrorProductoErp = this.loadedProductoErp ? '' : 'El campo producto ERP es requerido';
			return this.loadedProductoErp;
		},
		onCancel: function() {
			this.onClearControlProducto();
			this.$emit('onCancel');
		},
		onSave: async function() {
			const isValid = await this.$refs.observerPrecio.validate();
			if(isValid && this.onValidCustom()) {
				this.localPrecio.precio = getValue(this.$refs.precio);
				this.localPrecio.clavePeriodoPago = this.localPrecio.periodoPago.clave;
				this.onClearControlProducto();
				this.$emit('onSave', this.localPrecio);
			}
		}

		//#endregion
	}
}
</script>