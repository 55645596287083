<template>
<v-dialog 
	v-model="dialog"
	persistent
	scrollable
	max-width="800">
	<spinner-loading :overlay="isLoading"/>
	<v-card class="pb-3">
		<v-toolbar
			color="primary"
      dark>
			Licencia
		</v-toolbar>
		<v-card-text 
			v-if="loadedLicencia"
			class="mt-5">
			<v-row>
				<v-col cols="12" sm="4" md="4">
					<div class="text--secondary ml-1">ID</div>
					<strong class="grey--text text--darken-3 ml-1">{{licencia.id}}</strong>
				</v-col>
				<v-col cols="12" sm="4" md="4">
					<div class="text--secondary ml-1">Estatus</div>
					<strong class="grey--text text--darken-3 ml-1">{{licencia.estatusDescripcion}}</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="4" md="4">
					<div class="text--secondary ml-1">Fecha de Creación</div>
					<strong class="grey--text text--darken-3 ml-1">{{licencia.fechaCreacion | dateParse('DD/MM/YYYY')}}</strong>
				</v-col>
				<v-col cols="12" sm="8" md="8">
					<div class="text--secondary ml-1">Producto</div>
					<strong class="grey--text text--darken-3 ml-1">{{licencia.claveProducto}} / {{licencia.productoDescripcion}}</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="4" md="4">
					<div class="text--secondary ml-1">Codigo de Referencia</div>
					<strong class="grey--text text--darken-3 ml-1">{{licencia.distribuidorCodigoReferencia}}</strong>
				</v-col>
				<v-col cols="12" sm="8" md="8">
					<div class="text--secondary ml-1">Distribuidor</div>
					<strong class="grey--text text--darken-3 ml-1">{{distribuidor}}</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="4" md="4">
					<div class="text--secondary ml-1">ID Dispositivo</div>
					<strong class="grey--text text--darken-3 ml-1">{{licencia.idDispositivo}}</strong>
				</v-col>
				<v-col cols="12" sm="8" md="8">
					<div class="text--secondary ml-1">Nombre de Empresa</div>
					<strong class="grey--text text--darken-3 ml-1">{{licencia.nombreEmpresa}}</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<div class="text--secondary ml-1">Direccion</div>
					<strong class="grey--text text--darken-3 ml-1">{{direccion}}</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="3">
					<div class="text--secondary ml-1">Pais</div>
					<strong class="grey--text text--darken-3 ml-1">{{licencia.paisNombre.toUpperCase()}}</strong>
				</v-col>
				<v-col cols="3">
					<div class="text--secondary ml-1">Estado</div>
					<strong class="grey--text text--darken-3 ml-1">{{licencia.estadoNombre.toUpperCase()}}</strong>
				</v-col>
				<v-col cols="3">
					<div class="text--secondary ml-1">Ciudad</div>
					<strong class="grey--text text--darken-3 ml-1">{{licencia.ciudadNombre.toUpperCase()}}</strong>
				</v-col>
				<v-col cols="3">
					<div class="text--secondary ml-1">Colonia</div>
					<strong class="grey--text text--darken-3 ml-1">{{licencia.coloniaNombre ? licencia.coloniaNombre.toUpperCase() : ''}}</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="3" md="3">
					<div class="text--secondary ml-1">Telefono</div>
					<strong class="grey--text text--darken-3 ml-1">{{licencia.telefono}}</strong>
				</v-col>
				<v-col cols="12" sm="9" md="9">
					<div class="text--secondary ml-1">Correo Electronico</div>
					<strong class="grey--text text--darken-3 ml-1">{{licencia.correo}}</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<div class="text--secondary ml-1">Observaciones</div>
					<strong class="grey--text text--darken-3 ml-1">{{licencia.observaciones}}</strong>
				</v-col>
			</v-row>
			<v-card 
				v-if="licencia.esACredito"
				class="my-2 px-3 py-2"
				elevation="0"
				color="default">
				<v-row>
					<v-col cols="12">
						<strong class="text-subtitle-1 grey--text text--darken-3">Crédito</strong>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" sm="8" md="8">
						<div class="text--secondary ml-1">Numero de Periodos de Credito</div>
						<strong class="grey--text text--darken-3 ml-1">{{licencia.numeroPeriodosCredito}} SEMANAS</strong>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12">
						<div class="text--secondary ml-1">Cliente</div>
						<strong class="grey--text text--darken-3 ml-1">{{cliente}}</strong>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" sm="4" md="4">
						<div class="text--secondary ml-1">Saldo</div>
						<strong class="grey--text text--darken-3 ml-1">{{licencia.saldoCredito | currencyParse(2)}} {{licencia.claveMonedaCredito}}</strong>
						<v-tooltip right>
							<template v-slot:activator="{ on, attrs }">
								<v-btn 
									color="primary"
									small
									icon
									@click="onOpenDialogAmortizacion()"
									class="ml-2"
									v-bind="attrs"
									v-on="on">
									<v-icon>mdi-table-eye</v-icon>
								</v-btn>
							</template>
							<span>Ver Amortizacion</span>
						</v-tooltip>
					</v-col>
					<v-col cols="12" sm="4" md="4">
						<div class="text--secondary ml-1">Licencia Pagada</div>
						<strong class="grey--text text--darken-3 ml-1">{{licencia.licenciaPagada | booleanParse()}}</strong>
					</v-col>
				</v-row>
			</v-card>
			<v-card 
				class="my-2 px-3 py-2"
				elevation="0"
				color="default">
				<input 
					type="hidden" 
					id="input-llave" 
					:value="licencia.cadenaActivacion">
				<v-row>
					<v-col cols="12" sm="8" md="8">
						<strong class="text-subtitle-1 grey--text text--darken-3">Llave de Activación Actual</strong>
						<v-tooltip right>
							<template v-slot:activator="{ on, attrs }">
								<v-btn 
									color="primary"
									small
									icon
									@click="onCopyLlave()"
									class="ml-2"
									v-bind="attrs"
									v-on="on">
									<v-icon>mdi-content-copy</v-icon>
								</v-btn>
							</template>
							<span>Copiar llave de activación</span>
						</v-tooltip>
					</v-col>
					<v-col cols="12" sm="4" md="4" class="text-right">
						<v-btn 
							color="secondary"
							text
							small
							@click="onOpenDialogHistorialLlaves">
							Ver historial
							<v-icon>mdi-history</v-icon>
						</v-btn>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" sm="4" md="4">
						<div class="text--secondary ml-1">Fecha de Activación</div>
						<strong class="grey--text text--darken-3 ml-1">{{licencia.llaveFechaActivacion | dateParse('DD/MM/YYYY')}}</strong>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" sm="4" md="4">
						<div class="text--secondary ml-1">Gratis</div>
						<strong class="grey--text text--darken-3 ml-1">{{licencia.llaveEsGratis | booleanParse()}}</strong>
					</v-col>
					<v-col cols="12" sm="4" md="4">
						<div class="text--secondary ml-1">Obsequio de Distribuidor</div>
						<strong class="grey--text text--darken-3 ml-1">{{licencia.llaveEsObsequio | booleanParse()}}</strong>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" sm="4" md="4">
						<div class="text--secondary ml-1">Periodo</div>
						<strong class="grey--text text--darken-3 ml-1">{{licencia.llavePeriodoPagoDescripcion}}</strong>
					</v-col>
					<v-col cols="12" sm="4" md="4">
						<div class="text--secondary ml-1">Días de Vigencia</div>
						<strong class="grey--text text--darken-3 ml-1">{{licencia.llaveDiasVigencia}}</strong>
					</v-col>
					<v-col cols="12" sm="4" md="4">
						<div class="text--secondary ml-1">Monto</div>
						<strong class="grey--text text--darken-3 ml-1">{{licencia.llaveMonto | currencyParse(2)}} {{licencia.llaveClaveMoneda}}</strong>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" sm="12" md="12">
						<div class="text--secondary ml-1">Clave de Activación</div>
						<strong class="grey--text text--darken-3 ml-1">{{licencia.llaveClaveActivacion}}</strong>
					</v-col>
				</v-row>
			</v-card>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn 
				small 
				color="default"
				@click="onClose">
				Cerrar
			</v-btn>
		</v-card-actions>
	</v-card>
	<!--Dialog de amortizacion-->
	<amortizacion-dialog 
		v-if="loadedLicencia" 
		:dialog="dialogAmortizacion"
		:idLicencia='licencia.id'
		:claveProducto='licencia.claveProducto'
		@onClose="onCloseDialogAmortizacion">
	</amortizacion-dialog>
	<!--Dialog de llaves de activacion-->
	<llaves-activacion-historial-dialog 
		v-if="loadedLicencia" 
		:dialog="dialogHistorialLlaves"
		:idLicencia='licencia.id'
		:claveProducto='licencia.claveProducto'
		@onClose="onCloseDialogHistorialLlaves">
	</llaves-activacion-historial-dialog>
</v-dialog>
</template>
<script>
import SpinnerLoading from '../components/SpinnerLoading';
import AmortizacionDialog from '../components/AmortizacionDialog';
import LlavesActivacionHistorialDialog from '../components/LlavesActivacionHistorialDialog'

import LicenciaApi from '../services/api/licencia.api';

export default {
	props: {
		dialog: Boolean, 
		idLicencia: Number, 
		claveProducto: String
	},
	components: {
		SpinnerLoading,
		AmortizacionDialog,
		LlavesActivacionHistorialDialog
	},
  data: () => ({
		isLoading: true,
		licencia : {},
		dialogAmortizacion: false,
		dialogHistorialLlaves: false
	}),
	watch: {
		dialog :{
			handler(newVal, oldVal) {
				if(newVal) {
					this.onLoaded();
				}
			},
			deep: true
		},
	},
	computed: {
		loadedLicencia: function() {
			return Object.keys(this.licencia).length !== 0;
		},
		distribuidor: function() {
			if(this.loadedLicencia)
				return this.licencia.idDistribuidor + ' / ' + this.licencia.distribuidorPrimerNombre + ' ' + 
				this.licencia.distribuidorSegundoNombre + ' ' + 
				this.licencia.distribuidorApellidoPaterno + ' ' + 
				this.licencia.distribuidorApellidoMaterno;
			else
				return '';
		},
		cliente: function() {
			if(this.loadedLicencia &&  this.licencia.esACredito)
				return this.licencia.idClienteCredito + ' / ' + this.licencia.clienteCreditoPrimerNombre + ' ' + 
				(this.licencia.clienteCreditoSegundoNombre ? this.licencia.clienteCreditoSegundoNombre + ' ' : '') + 
				(this.licencia.clienteCreditoApellidoPaterno ? this.licencia.clienteCreditoApellidoPaterno + ' ' : '') + 
				(this.licencia.clienteCreditoApellidoMaterno ? this.licencia.clienteCreditoApellidoMaterno : '');
			else
				return '';
		},
		direccion: function() {
			if(this.loadedLicencia)
				return (this.licencia.calle ? this.licencia.calle + ' ' : '') +
				(this.licencia.numeroExterior ? 'NO. ' + this.licencia.numeroExterior + ' ' : '') +
				(this.licencia.numeroInterior ? '-' + this.licencia.numeroInterior + ' ' : '') +
				(this.licencia.codigoPostal ? 'C.P. ' + this.licencia.codigoPostal : '')
			else
				return '';
		},
	},
	methods: {
		onLoaded: async function() {
			this.isLoading = true;
			//Obtenemos licencia para ver detalles
			await LicenciaApi.get(this.idLicencia, this.claveProducto)
				.then(response => {
					this.isLoading = false;
					if(response.success === true) {
						this.licencia = response.data
					} else {
						this.$notifier.error(response.message);
					}
				});
		},
		onOpenDialogAmortizacion: function() {
			//Abrimos dialog
			this.dialogAmortizacion = true;
		},
		onCloseDialogAmortizacion: function() {
			//Cerramos dialog
			this.dialogAmortizacion = false;
		},
		onOpenDialogHistorialLlaves: function() {
			//Abrimos dialog
			this.dialogHistorialLlaves = true;
		},
		onCloseDialogHistorialLlaves: function() {
			//Cerramos dialog
			this.dialogHistorialLlaves = false;
		},
		onClose: function() {
			this.$emit('onClose');
		},
		onCopyLlave: function() {
			let llaveToCopy = document.querySelector('#input-llave')
			llaveToCopy.setAttribute('type', 'text')
			llaveToCopy.select()
			try {
				var successful = document.execCommand('copy');
				if(!successful) {
					alert('Error al copiar los datos de llave de activacion, debera copiarlos manualmente.');
				} else {
					this.$notifier.success('La llave de activación se ha copiado al portapapeles.');
				}
			} catch (err) {
				alert('Oops, tiene deshabilitado el copiado de texto.');
			}
			/* unselect the range */
			llaveToCopy.setAttribute('type', 'hidden');
			window.getSelection().removeAllRanges();
		},
	}
}
</script>