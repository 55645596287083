<template>
<div>
	<v-form @submit.prevent="">
		<spinner-loading :overlay="isLoading"/>
		<v-card 
			class="mx-4" 
			rounded="lg">
			<div v-if="inAppStack">
				<v-card-title class="py-4">
					<span class="font-weight-regular">Datos</span>
				</v-card-title>
			</div>
			<div v-else>
				<v-card-title>Pago de licencia</v-card-title>
			</div>
			<v-card-text>
				<v-row>
					<v-col cols="12" sm="8" md="8">
						<v-row>
							<v-col cols="12">
								<div class="text--secondary ml-1">Cliente</div>
								<strong class="grey--text text--darken-3 ml-1">{{cliente}}</strong>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<div class="text--secondary ml-1">ID Licencia</div>
								<strong class="grey--text text--darken-3 ml-1">{{licenciaPago.idLicencia}}</strong>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<div class="text--secondary ml-1">Producto</div>
								<strong class="grey--text text--darken-3 ml-1">{{licenciaPago.claveProducto}} / {{licenciaPago.productoDescripcion}}</strong>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" sm="6" md="5">
								<div class="text--secondary ml-1">ID Dispositivo</div>
								<strong class="grey--text text--darken-3 ml-1">{{licenciaPago.idDispositivo}}</strong>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<div class="text--secondary ml-1">Nombre de Empresa</div>
								<strong class="grey--text text--darken-3 ml-1">{{licenciaPago.nombreEmpresa}}</strong>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="12" sm="4" md="4" align-self="end">
						<div class="text--secondary">Total a Pagar</div>
						<span class="grey--text text--darken-3 text-h5">{{montoPago | currencyParse(2)}} {{licenciaPago.claveMoneda}}</span>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" class="pb-1">
						<strong class="text-subtitle-1 grey--text text--darken-3">Marca los periodos a pagar</strong>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12">
						<v-card 
							elevation="1" 
							rounded="lg">
							<v-list 
								class="py-0"
								color="default"
								dense>
								<template v-for="(item, index) in licenciaPago.pagos">
									<v-list-item :key="item.id" v-if="!item.pagado">
										<v-list-item-content>
											<v-list-item-subtitle>
												No. <strong>{{item.numeroPeriodo}}</strong> <br> 
												Vencimiento <strong>{{item.fechaVencimiento | dateParse('DD/MM/YYYY')}}</strong></v-list-item-subtitle>
											<v-list-item-title><strong class="text-h6">{{item.monto | currencyParse(2)}} {{item.claveMoneda}}</strong></v-list-item-title>
										</v-list-item-content>

										<v-list-item-action>
											<v-row align="center" justify="center">
												<v-col cols="12" align-self="center">
													<v-list-item-subtitle>Pagar</v-list-item-subtitle>
													<v-checkbox 
														class="pl-1" 
														v-model="item.checked"
														@change="onUpdateMontoPago"
														color="secondary">
													</v-checkbox>
												</v-col>
											</v-row>
										</v-list-item-action>
									</v-list-item>
									<v-divider :key="index" v-if="!item.pagado"></v-divider>
								</template>
							</v-list>
						</v-card>
					</v-col>
				</v-row>
			</v-card-text>
			<v-divider class="mx-4 mt-5"></v-divider>
			<v-card-actions>
				<v-row>
					<v-col cols="12" class="text-right">
						<v-btn 
							@click="onCancel"
							color="default"
							:loading="isPaying">
							{{inAppStack ? 'Cancelar' : 'Regresar'}}
						</v-btn>
						<v-btn 
							@click="onSubmit"
							color="primary" 
							:disabled="isLoading"
							:loading="isPaying"
							class="ml-2">
							{{inAppStack ? 'Pagar' : 'Realizar Pago'}}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-actions>
		</v-card>
	</v-form>
</div>
</template>
<script>
import SpinnerLoading from '../components/SpinnerLoading';

import LicenciaApi from '../services/api/licencia.api';
import { ViewMode } from '../utils/constants';

export default {
	props: {
		mode: Number,
		inAppStack: Boolean
	},
	components: {
		SpinnerLoading
	},
	created: function() {
		this.onInit();
	},
  data: () => ({
		isLoading: true,
		licenciaPago: {},
		montoPago: 0,
		
		isPaying: false,
	}),
	computed: {
		loadedLicenciaPago: function() {
			return Object.keys(this.licenciaPago).length !== 0;
		},
		cliente: function() {
			if(this.loadedLicenciaPago) {
				return this.licenciaPago.clientePrimerNombre + 
				((this.licenciaPago.clienteSegundoNombre !== null && this.licenciaPago.clienteSegundoNombre !== '') ? ' ' + this.licenciaPago.clienteSegundoNombre : '') + 
				((this.licenciaPago.clienteApellidoPaterno !== null && this.licenciaPago.clienteApellidoPaterno !== '') ? ' ' + this.licenciaPago.clienteApellidoPaterno : '') +
				((this.licenciaPago.clienteApellidoMaterno !== null && this.licenciaPago.clienteApellidoMaterno !== '') ? ' ' + this.licenciaPago.clienteApellidoMaterno : '');
			} else {
				return '';
			}
		},
	},
	methods: {
		onInit: async function() {
			this.isLoading = true;
			//Obtenemos licencia para ver detalles
			await LicenciaApi.getToPago(this.$route.params.id, this.$route.params.claveProducto)
				.then(response => {
					this.isLoading = false;
					if(response.success === true) {
						this.licenciaPago = response.data
						//Agregamos propiedad de checkbox
						this.licenciaPago.pagos.map(function(item, index, array) {
							item.checked = false;
							return item;
						});
					} else {
						this.$notifier.error(response.message);
						this.onCancel();
					}
				});
		},

		onUpdateMontoPago: function() {
			this.montoPago = this.licenciaPago.pagos.reduce((acumulador, item) => acumulador + ((!item.pagado && item.checked) ? item.monto : 0), 0)
		},

		//#region Metodo de acciones de card

		onValidCustom(periodosPago) {
			let esValido = true;
			//Debe de haber pagos marcados
			if(periodosPago.length <= 0) {
				esValido = false;
				this.$notifier.error('Seleccione al menos un periodo de pago para proceder.');
			} else {
				//Obtenemos pago disponibles a pagar
				let periodosDisponiblesPago = this.licenciaPago.pagos.filter(function(item) {
					return !item.pagado;
				});
				//El primer pago debe estar marcado
				if(!periodosDisponiblesPago[0].checked) {
					esValido = false;
					this.$notifier.error('Los pagos deben ser en orden cronologico.');
				} else {
					for (let i = periodosPago[0]; i <= periodosPago[periodosPago.length - 1]; i++) {
						let periodo = periodosPago.find(x => x === i);
						if(periodo === null || typeof periodo === 'undefined') {
							esValido = false;
							this.$notifier.error('Los pagos no puede saltarse y deben ser en orden cronologico.');
							break;
						}
					}
				}
			}
			return esValido;
		},
		onCancel: function() {
			this.$emit('onCancel')
		},
		onSubmit: async function() {
			//Prevent multiple guardado
			if(!this.isPaying) {
				this.isPaying = true;
				//Obtenemos solo numeros de periodo de pago en orden
				let periodosPago = this.licenciaPago.pagos.filter(function(item) {
					return (!item.pagado && item.checked);
				}).map(function(item, index, array) {
					return item.numeroPeriodo;
				}).sort();
				if(this.onValidCustom(periodosPago)) {
					//Copiamos deep objeto para ajustarlo antes de enviarlo
					let pagoSaved = {
						idLicencia: this.licenciaPago.idLicencia,
						claveProducto: this.licenciaPago.claveProducto,
						periodosPago: periodosPago,
						nombreCliente: this.cliente,
						montoPago: this.montoPago,
						claveMoneda: this.licenciaPago.claveMoneda
					};
					if(this.inAppStack) {// Procesar dentro del sistema
						this.$emit('onSubmit', pagoSaved, () => {
							this.isPaying = false;
						});
					} else { // Procesar fuera del sistema
						this.isPaying = false;
						this.$emit('onSubmit', this.licenciaPago, pagoSaved);
					}
				} else {
					this.isPaying = false;
				}
			}
		}

		//#endregion
	}
}
</script>