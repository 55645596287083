<template>
<div>
	<validation-observer ref="observer">
		<v-form @submit.prevent="">
			<spinner-loading :overlay="isLoading"/>
			<v-card class="mx-4" 
			rounded="lg">
				<v-card-title class="py-4">
					<span class="font-weight-regular">Datos</span>
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12" sm="4" md="3" order="2" order-sm="1">
							<div class="text--secondary ml-1">Fecha de Registro</div>
							<v-menu v-model="menuFechaRegistro"
							:close-on-content-click="false"
							transition="scale-transition"
							offset-y
							min-width="auto">
								<template v-slot:activator="{ on, attrs }">
									<validation-provider v-slot="{errors}" 
									name="fecha de registro" 
									rules="required">
										<v-text-field v-model="fechaRegistroFormatted"
										outlined  
										hide-details="auto"
										:error-messages="errors"
										prepend-inner-icon="mdi-calendar"
										readonly
										v-bind="attrs"
										v-on="on">
										</v-text-field>
									</validation-provider>
								</template>
								<v-date-picker v-model="documento.fechaRegistro"
								no-title
								@input="menuFechaRegistro = false">
								</v-date-picker>
							</v-menu>
						</v-col>
						<v-col cols="12" sm="3" md="3" offset-sm="5" offset-md="6" order="1" order-sm="2">
							<div class="text--secondary ml-1">Estatus</div>
							<strong class="grey--text text--darken-3 ml-1">{{estatus.descripcion}}</strong>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" sm="6" md="6">
							<div class="text--secondary ml-1">Tipo de Documento*</div>
							<validation-provider v-slot="{errors}" 
								name="tipo de documento" 
								rules="required">
								<v-select v-model="documento.claveTipoDocumentoAfectacionSaldo" 
								:items="tiposDocumento"
								outlined
								hide-details="auto"
								item-value="clave"
								:item-text="onTipoDocumentoToString"
								:error-messages="errors">
								</v-select>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<div class="text--secondary ml-1">Distribuidor*</div>
							<distribuidor-autocomplete ref="autocompleteDistribuidor" 
							:selected="distribuidor"
							:validError="validErrorDistribuidor"
							@onChange="onUpdateDistribuidor">	
							</distribuidor-autocomplete>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<div class="text--secondary ml-1">Descripcion*</div>
							<validation-provider v-slot="{errors}" 
								name="descripcion" 
								rules="required">
								<v-text-field v-model="documento.descripcion" 
								outlined  
								hide-details="auto"
								@input="documento.descripcion = $utilsInput.uppercase(documento.descripcion)"
								:error-messages="errors">
								</v-text-field>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" sm="4" md="4">
							<div class="text--secondary ml-1">Monto*</div>
							<validation-provider v-slot="{errors}" 
							name="monto" 
							rules="required">
								<v-text-field v-model="documento.monto" 
								ref="monto"
								outlined  
								hide-details="auto"
								v-currency="$utilsInput.currency(2, false)"
								:error-messages="errors">
								</v-text-field>
							</validation-provider>
						</v-col>
						<v-col cols="12" sm="4" md="4">
							<div class="text--secondary ml-1">Moneda*</div>
							<validation-provider v-slot="{errors}" 
								name="moneda" 
								rules="required">
								<v-select v-model="documento.claveMonedaDocumento" 
								:items="monedas"
								outlined
								hide-details="auto"
								item-value="cClave"
								:item-text="onMonedaToString"
								:error-messages="errors">
								</v-select>
							</validation-provider>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider class="mx-4 mt-5"></v-divider>
				<v-card-actions>
					<v-row>
						<v-col cols="12" class="text-right">
							<v-btn @click="onCancel"
							color="default"
							:loading="isSaving">
								Cancelar
							</v-btn>
							<v-btn @click="onSubmit"
							color="primary" 
							:disabled="isLoading"
							:loading="isSaving"
							class="ml-2">
								Finalizar
							</v-btn>
						</v-col>
					</v-row>
				</v-card-actions>
			</v-card>
		</v-form>
	</validation-observer>
</div>
</template>
<script>
import SpinnerLoading from '../components/SpinnerLoading';
import DistribuidorAutocomplete from '../components/DistribuidorAutocomplete';

import EstatusApi from '../services/api/estatusDocumento.api';
import TipoDocumentoApi from '../services/api/tipoDocumentoAfectacionSaldo.api';
import MonedaErpApi from '../services/api-erp/moneda.api';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { ViewMode, Documento } from '../utils/constants';
import ObjectToStringMixin from '../mixins/objectToStringMixin'
import DatePickerToStringMixin from '../mixins/datePickerToStringMixin'

export default {
	props: {
		mode: Number
	},
	mixins: [ObjectToStringMixin, DatePickerToStringMixin],
	components: {
		SpinnerLoading,
		ValidationProvider,
		ValidationObserver,
		DistribuidorAutocomplete
	},
	created: function() {
		this.onInit();
	},
    data: () => ({
		isLoading: true,
		documento: {
			fechaRegistro: new Date().toISOString().substr(0, 10),
			claveTipoDocumentoAfectacionSaldo: '',
			idDistribuidor: '',
			descripcion: '',
			claveMonedaDocumento: '',
			monto: '',
			observaciones: ''
		},
		estatus: {},
		distribuidor: {},
		validErrorDistribuidor: '',
		tiposDocumento: [],
		monedas: [],

		isSaving: false,
		menuFechaRegistro: false,
	}),
	computed: {
		fechaRegistroFormatted: function() {
			return this.datePickerToString(this.documento.fechaRegistro);
		},
		loadedDistribuidor: function() {
			return Object.keys(this.distribuidor).length !== 0;
		},
	},
	methods: {
		onInit: async function() {
			this.isLoading = true;
			await EstatusApi.getByEstatus(Documento.DOCUMENTO_AFECTACION_SALDO, 'B')
			.then(response => {
				if(response.success === true) {
					this.estatus = response.data;
				} else {
					this.$notifier.warn('El estatus no se cargo correctamente. \n' + response.message);
				}
			});
			await TipoDocumentoApi.getToSelection()
			.then(response => {
				if(response.success === true) {
					this.tiposDocumento = response.data;
				} else {
					this.$notifier.warn('Los tipos de documento no se cargaron correctamente. \n' + response.message);
				}
			});
			await MonedaErpApi.getAll()
			.then(response => {
				if(response.success === true) {
					this.monedas = response.data;
				} else {
					this.$notifier.warn('Las monedas no se cargaron correctamente. \n' + response.message);
				}
			});
			this.isLoading = false;
		},

		//#region Metodos de formateo select

		onTipoDocumentoToString: function(item) {
			return this.objectToString('', item.descripcion);
		},
		onMonedaToString: function(item) {
			return this.objectToString(item.cClave, item.cDescripcion);
		},
		
		//#endregion

		//#region Metodos para actualizar propiedades

		onUpdateDistribuidor: function(distribuidorSelected) {
			if(distribuidorSelected !== null) {
				this.distribuidor = distribuidorSelected;
				this.documento.idDistribuidor = this.distribuidor.id;
				this.$refs.autocompleteDistribuidor.onInitSelection(this.distribuidor);
			} else {
				this.distribuidor = {};
				this.documento.idDistribuidor = '';
			}
		},

		//#endregion

		//#region Metodo de acciones de card
		
		onValidCustom: function() {
			//Validamos y creamos mensaje de error
			this.validErrorDistribuidor = this.loadedDistribuidor ? '' : 'El campo distribuidor es requerido';
			return this.loadedDistribuidor;
		},
		onCancel: function() {
			this.$router.push('/documento-afectacion-saldo/all');
		},
		onSubmit: async function() {
			//Prevent multiple guardado
			if(!this.isSaving) {
				this.isSaving = true;
				const isValid = await this.$refs.observer.validate();
				if(isValid && this.onValidCustom()) {
					//Copiamos deep objeto para ajustarlo antes de enviarlo
					let documentoSaved = JSON.parse(JSON.stringify(this.documento));
					this.$emit('onSubmit', documentoSaved, () => {
							this.isSaving = false;
						});
				} else {
					this.isSaving = false;
					this.$notifier.error('Llene todos los campos marcados como obligatorios, compruebe su información y vuelva a intentar.');
				}
			}
		}

		//#endregion
	}
}
</script>