<template>
  <v-overlay
    :absolute="true"
    :opacity="0.2"
    :value="overlay"
  >
    <v-progress-circular
      indeterminate
      color="primary"
      size="64"
    />
  </v-overlay>
</template>
<script>
  export default {
    props: {
      overlay: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>