<template>
<div>
	<validation-observer ref="observer">
		<v-form @submit.prevent="">
			<spinner-loading :overlay="isLoading"/>
			<v-card class="mx-4" 
			rounded="lg">
				<v-card-title class="py-4">
					<span class="font-weight-regular">Datos</span>
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12" sm="6" md="6">
							<div class="text--secondary ml-1">Email*</div>
							<validation-provider v-slot="{errors}" 
							name="email" 
							rules="required|email">
								<v-text-field v-model="usuario.email" 
								autofocus
								:error-messages="errors" 
								outlined 
								hide-details="auto"/>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" sm="6" md="6">
							<div class="text--secondary ml-1">Contraseña*</div>
							<validation-provider v-slot="{errors}" 
							name="contraseña" 
							rules="required">
								<v-text-field v-model="usuario.password" 
								:error-messages="errors" 
								type="password" 
								outlined 
								hide-details="auto"/>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row v-if="isEdit">
						<v-col v-if="visiblePartner" cols="12" sm="4" md="3">
							<div class="text--secondary ml-1">Partner Relacionado</div>
							<v-text-field v-model="usuario.idPartner" 
								outlined  
								hide-details="auto"
								readonly>
							</v-text-field>
						</v-col>
						<v-col v-else-if="visibleDistribuidor" cols="12" sm="4" md="3">
							<div class="text--secondary ml-1">Distribuidor Relacionado</div>
							<v-text-field v-model="usuario.idDistribuidor" 
								outlined  
								hide-details="auto"
								readonly>
							</v-text-field>
						</v-col>
						<v-col v-else cols="12" sm="4" md="3">
							<!--No existe un campo que mostrar-->
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" sm="3" md="3">
							<div class="text--secondary ml-1">Nombre*</div>
							<validation-provider v-slot="{errors}" 
								name="nombre" 
								rules="required">
								<v-text-field v-model="usuario.primerNombre" 
								outlined  
								hide-details="auto"
								@input="usuario.primerNombre = $utilsInput.uppercase(usuario.primerNombre)"
								:readonly="!isUsuarioAdministrativo"
								:error-messages="errors">
								</v-text-field>
							</validation-provider>
						</v-col>
						<v-col cols="12" sm="3" md="3">
							<div class="text--secondary ml-1">Segundo Nombre</div>
							<v-text-field v-model="usuario.segundoNombre" 
							outlined  
							hide-details="auto"
							@input="usuario.segundoNombre = $utilsInput.uppercase(usuario.segundoNombre)"
							:readonly="!isUsuarioAdministrativo">
							</v-text-field>
						</v-col>
						<v-col cols="12" sm="3" md="3">
							<div class="text--secondary ml-1">Apellido Paterno</div>
							<v-text-field v-model="usuario.apellidoPaterno" 
							outlined  
							hide-details="auto"
							@input="usuario.apellidoPaterno = $utilsInput.uppercase(usuario.apellidoPaterno)"
							:readonly="!isUsuarioAdministrativo">
							</v-text-field>
						</v-col>
						<v-col cols="12" sm="3" md="3">
							<div class="text--secondary ml-1">Apellido Materno</div>
							<v-text-field v-model="usuario.apellidoMaterno" 
							outlined  
							hide-details="auto"
							@input="usuario.apellidoMaterno = $utilsInput.uppercase(usuario.apellidoMaterno)"
							:readonly="!isUsuarioAdministrativo">
							</v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" sm="3" md="3">
							<div class="text--secondary ml-1">Estatus*</div>
							<validation-provider v-slot="{errors}" 
								name="estatus" 
								rules="required">
								<v-select v-model="usuario.idEstatusDatoMaestro" 
								:items="estatus"
								outlined
								hide-details="auto"
								item-value="id"
								:item-text="onEstatusToString"
								:error-messages="errors">
								</v-select>
							</validation-provider>
						</v-col>
						<v-col cols="12" sm="9" md="9">
							<div class="text--secondary ml-1">Motivo de Estatus</div>
							<v-text-field v-model="usuario.motivoEstatus" 
							outlined  
							hide-details="auto"
							@input="usuario.motivoEstatus = $utilsInput.uppercase(usuario.motivoEstatus)">
							</v-text-field>
						</v-col>
					</v-row>
					<v-card class="mt-5 mb-2" elevation="1">
						<v-tabs v-model="tabs"
						background-color="tab">
							<v-tab>Funciones</v-tab>
							<v-tab>Observaciones</v-tab>
						</v-tabs>
					</v-card>
					<v-tabs-items v-model="tabs">
						<v-tab-item class="px-1 py-1">
							<v-row class="mb-1">
								<v-col cols="12" sm="4" md="3">
									<div class="text--secondary ml-1">Buscar</div>
									<v-text-field v-model="tbSearchFunciones"
									outlined
									hide-details
									append-icon="mdi-magnify">
									</v-text-field>
								</v-col>
								<v-col cols="12" sm="4" md="4" offset-sm="4" offset-md="5" 
								class="d-flex flex-row-reverse">
									<v-btn color="secondary"
									small
									class="mb-4 ml-2"
									@click="onOpenDialogRol">
										Cargar Rol
									</v-btn>
									<v-btn color="secondary"
									small
									class="mb-4"
									@click="onOpenDialogFuncion">
										Agregar
									</v-btn>
								</v-col>
							</v-row>
							<v-data-table :headers="tbHeaderFunciones"
							:items="usuario.usuarioFunciones"
							:footer-props="tbFooterPropsFunciones"
							no-data-text="Sin registros"
							:search="tbSearchFunciones"
							class="elevation-1">
								<template v-slot:item.acciones="{item}">
									<v-btn icon
									color="gray"
									@click="onOpenConfirmQuitFuncion(item)">
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</template>
							</v-data-table>	
						</v-tab-item>
						<v-tab-item class="px-1 py-1">
							<v-row>
								<v-col cols="12">
									<v-textarea v-model="usuario.observaciones"
									outlined
									hide-details="auto"
									rows="5"
									@input="usuario.observaciones = $utilsInput.uppercase(usuario.observaciones)">
									</v-textarea>
								</v-col>
							</v-row>
						</v-tab-item>
					</v-tabs-items>
				</v-card-text>
				<v-divider class="mx-4 mt-5"></v-divider>
				<v-card-actions>
					<v-row>
						<v-col cols="12" class="text-right">
							<v-btn @click="onCancel"
							color="default"
							:loading="isSaving">
								Cancelar
							</v-btn>
							<v-btn @click="onSubmit"
							color="primary" 
							:disabled="isLoading"
							:loading="isSaving"
							class="ml-2">
								Guardar
							</v-btn>
						</v-col>
					</v-row>
				</v-card-actions>
			</v-card>
			<v-row>
				<v-col cols="12" class="ml-5">
					<app-users-audit v-if="isEdit" 
					:creador="usuario.createdBy"
					:fechaCreacion="usuario.createdAt"
					:modificador="usuario.updatedBy"
					:fechaModificacion="usuario.updatedAt">
					</app-users-audit>
				</v-col>
			</v-row>
		</v-form>
	</validation-observer>
	<!--Dialog de funcion-->
	<funcion-dialog :dialog="dialogFuncion" 
	:funciones="funciones"
	@onCancel="onCancelDialogFuncion"
	@onSave="onSaveDialogFuncion">
	</funcion-dialog>
	<!--Dialog de rol-->
	<rol-dialog :dialog="dialogRol" 
	:roles="roles"
	@onCancel="onCancelDialogRol"
	@onSave="onSaveDialogRol">
	</rol-dialog>
	<!--Dialog de confirmacion eliminacion-->
	<app-dialog-confirm :dialog-confirm="dialogConfirmDeleteFuncion"
	title="ELIMINAR"
	@onCancel="onCancelConfirmQuitFuncion"
	@onOk="onOkConfirmQuitFuncion">
	¿Seguro que desea quitar de la lista el registro?
	</app-dialog-confirm>
</div>
</template>
<script>
import SpinnerLoading from '../components/SpinnerLoading';
import AppDialogConfirm from './AppDialogConfirm';
import AppUsersAudit from './AppUsersAudit';
import FuncionDialog from './FuncionDialog';
import RolDialog from './RolDialog';

import UsuarioApi from '../services/api/usuario.api';
import EstatusApi from '../services/api/estatusDatoMaestro.api';
import FuncionApi from '../services/api/funcion.api';
import RolApi from '../services/api/rol.api';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { ViewMode, TipoUsuario, DatoMaestro } from '../utils/constants';
import ObjectToStringMixin from '../mixins/objectToStringMixin'

export default {
	props: {
		mode: Number
	},
	mixins: [ObjectToStringMixin],
	components: {
		SpinnerLoading,
		ValidationProvider,
		ValidationObserver,
		AppUsersAudit,
		FuncionDialog,
		RolDialog,
		AppDialogConfirm,
	},
	created: function() {
		this.onInit();
	},
    data: () => ({
		isLoading: true,
		usuario: {},
		estatus: [],
		funciones: [],
		roles: [],

		tabs: null,
		isSaving: false,
		tbSearchFunciones: '',
		tbHeaderFunciones: [
			{ text: 'Clave', value: 'funcion.clave' },
			{ text: 'Descripcion', value: 'funcion.descripcion' },
			{ text: 'Acciones', value: 'acciones', sortable: false, filterable: false }
		],
		tbFooterPropsFunciones: {
			'items-per-page-text': 'Mostrar',
		},
		
		dialogFuncion: false,
		dialogConfirmDeleteFuncion: false,
		funcionCurrent: {},

		dialogRol: false,
	}),
	computed: {
		visiblePartner: function() {
			return this.usuario.idPartner !== null && this.usuario.idPartner !== '';
		},
		visibleDistribuidor: function() {
			return this.usuario.idDistribuidor !== null && this.usuario.idDistribuidor !== '';
		},
		isUsuarioAdministrativo: function() {
			return this.usuario.claveTipoUsuario === TipoUsuario.ADMINISTRATIVO;
		},
		isEdit: function() {
			return this.mode === ViewMode.EDIT;
		}
	},
	methods: {
		onInit: async function() {
			this.isLoading = true;
			
			await EstatusApi.getAll(DatoMaestro.USUARIO)
			.then(response => {
				if(response.success === true) {
					this.estatus = response.data;
				} else {
					this.$notifier.warn('Los estatus no se cargaron correctamente. \n' + response.message);
				}
			});
			await FuncionApi.getAll()
			.then(response => {
				if(response.success === true) {
					this.funciones = response.data;
				} else {
					this.$notifier.warn('Las funciones no se cargaron correctamente. \n' + response.message);
				}
			});
			await RolApi.getAll()
			.then(response => {
				if(response.success === true) {
					this.roles = response.data;
				} else {
					this.$notifier.warn('Los roles no se cargaron correctamente. \n' + response.message);
				}
			});
			if(this.isEdit) {
				await UsuarioApi.get(this.$route.params.id)
				.then(response => {
					this.isLoading = false;
					if(response.success === true) {
						this.usuario = response.data;
					} else {
						this.$router.push('/usuario');
						this.$notifier.error(response.message);
					}
				});
			} else {
				this.isLoading = false;
				this.usuario = {
					//id: '',//Este valor no va porque como es cadena se va a guardar ''
					primerNombre: '',
					segundoNombre: '',
					apellidoPaterno: '',
					apellidoMaterno: '',
					idEstatusDatoMaestro: this.estatus.length > 0 ? this.estatus[0].id : 0,
					estatusDatoMaestro: null,
					motivoEstatus: '',
					claveTipoUsuario: TipoUsuario.ADMINISTRATIVO,
					tipoUsuario: null,
					idPartner: null,
					partner: null,
					idDistribuidor: null,
					distribuidor: null,
					claveRol: null,
					rol: null,
					observaciones: '',
					usuarioFunciones: [/*{
						idUser: '',
						claveFuncion: '',
						funcion: {
							clave: '',
							descripcion: '',
						},
						observaciones: ''
					}*/]
				};
			}
		},

		//#region Metodos de formateo select
		
		onEstatusToString: function(item) {
			return this.objectToString('', item.descripcion);
		},

		//#endregion

		//#region Metodos para dialog de funciones

		onOpenDialogFuncion: function() {
			//Abrimos dialog
			this.dialogFuncion = true;
		},
		onCancelDialogFuncion: function() {
			//Cerramos dialog
			this.dialogFuncion = false;
		},
		onSaveDialogFuncion: function(funcionParsed) {
			//Cerramos dialog
			this.dialogFuncion = false;
			//Agregamos funcion
			if(this.usuario.usuarioFunciones.findIndex(x => x.claveFuncion === funcionParsed.claveFuncion) === -1) {
				this.usuario.usuarioFunciones.push({
					idUser: this.isEdit ? this.usuario.id : '',
					claveFuncion: funcionParsed.claveFuncion,
					funcion: funcionParsed.funcion,
					observaciones: null,
				});
			} else {
				this.$notifier.error('La función ya se encuentra agregada.');
			}
		},

		//#endregion

		//#region Metodos para dialog de rol

		onOpenDialogRol: function() {
			//Abrimos dialog
			this.dialogRol = true;
		},
		onCancelDialogRol: function() {
			//Cerramos dialog
			this.dialogRol = false;
		},
		onSaveDialogRol: function(rolFunciones) {
			//Cerramos dialog
			this.dialogRol = false;
			//Cargamos funciones de rol
			for(let obj of rolFunciones) {
				if(this.usuario.usuarioFunciones.findIndex(x => x.claveFuncion === obj.claveFuncion) === -1) {
					this.usuario.usuarioFunciones.push({
						idUser: null,
						claveFuncion: obj.claveFuncion,
						funcion: obj.funcion,
						observaciones: null,
					});
				}
			}
			this.$notifier.success('Las funciones del rol se cargaron correctamente.');
		},

		//#endregion

		//#region Metodos para eliminacion de funciones

		onOpenConfirmQuitFuncion: function(funcion) {
			//Salvamos funcion current(edit/delete)
			this.funcionCurrent = funcion;
			//Abrimos dialog
			this.dialogConfirmDeleteFuncion = true;
		},
		onCancelConfirmQuitFuncion: function() {
			//Limpiamos funcion current(edit/delete)
			this.funcionCurrent = {};
			//Cerramos dialog
			this.dialogConfirmDeleteFuncion = false;
		},
		onOkConfirmQuitFuncion: function(callBack) {
			callBack();
			//Eliminamos de lista
			let indexDeleted = this.usuario.usuarioFunciones.findIndex(x => x.claveFuncion === this.funcionCurrent.claveFuncion);
			this.usuario.usuarioFunciones.splice(indexDeleted, 1);
			//Limpiamos funcion current(edit/delete)
			this.funcionCurrent = {};
			//Cerramos dialog
			this.dialogConfirmDeleteFuncion = false;
		},

		//#endregion

		//#region Metodos de acciones de card
		
		onCancel: function() {
			this.$router.push('/usuario');
		},
		onValidateList: function() {
			//Comprobamos que existan funciones
			let listValid = true;
			let messageValidation = '';
			if(this.usuario.usuarioFunciones.length <= 0) {
				listValid = false;
				messageValidation += 'Agregue  al menos una función.';
			}
			if(!listValid) {
				this.$notifier.error(messageValidation);
			}
			return listValid;
		},
		onSubmit: async function() {
			//Prevent multiple guardado
			if(!this.isSaving) {
				this.isSaving = true;
				const isValid = await this.$refs.observer.validate();
				if(isValid && this.onValidateList()) {
					//Copiamos deep objeto para ajustarlo antes de enviarlo
					let usuarioSaved = JSON.parse(JSON.stringify(this.usuario));
					//Nuleamos objetos
					usuarioSaved.estatusDatoMaestro = null;
					usuarioSaved.partner = null;
					usuarioSaved.distribuidor = null;
					usuarioSaved.rol = null;
					usuarioSaved.tipoUsuario = null;
					for(let obj of usuarioSaved.usuarioFunciones) {
						obj.idUsuario = this.isEdit ? usuarioSaved.id : '',
						obj.funcion = null;
					}
					if(!this.isEdit) {
						this.$emit('onSubmit', usuarioSaved, () => {
							this.isSaving = false;
						});
					} else {
						this.$emit('onSubmit', usuarioSaved, () => {
							this.isSaving = false;
						});
					}
				} else {
					this.isSaving = false;
				}
			}
		}

		//#endregion
	}
}
</script>