<template>
<div>
	<app-header-page :titulos="titleItems"></app-header-page>
	<v-card class="mx-4 mb-4" 
	rounded="lg">
		<v-card-text>
			<v-row>
				<v-col cols="12" sm="5" order="2" order-sm="1">
					<div class="text--secondary ml-1">Buscar</div>
					<v-text-field v-model="search" 
					outlined
					append-icon="mdi-magnify"
					@change="onSearch">
					</v-text-field>
				</v-col>
				<v-col cols="6" sm="2" offset="6" offset-sm="5" order="1" order-sm="2" class="text-right">
					<v-btn :to="{path: '/distribuidor/add'}" 
					color="secondary" 
					small>
						<v-icon small class="mr-2">mdi-plus-circle</v-icon>
						Agregar
					</v-btn>
				</v-col>
			</v-row>
			<v-data-table :headers="headers"
			:items="items"
			:footer-props="footerProps"
			:page="page"
			:pageCount="totalPages"
			:options.sync="options"
			:server-items-length="totalItems"
			:items-per-page="pageSize"
			:loading="isLoading"
			loading-text="Cargando..."
			no-data-text="No existen registros."
			item
			class="elevation-1"
			disable-sort>
				<template v-slot:item.nombre="{item}">
					{{item.primerNombre}} {{item.segundoNombre}} {{item.apellidoPaterno}} {{item.apellidoMaterno}}
				</template>
				<template v-slot:item.acciones="{item}">
					<v-tooltip left v-if="distribuidorLogueado === ''">
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon
							color="gray"
							:to="{path: '/distribuidor/' + item.id + '/edit'}"
							v-bind="attrs"
							v-on="on">
								<v-icon>mdi-pencil</v-icon>
							</v-btn>
						</template>
						<span>Editar</span>
					</v-tooltip>
					<v-tooltip left v-if="distribuidorLogueado === ''">
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon
							color="gray"
							@click="onOpenConfirmDelete(item)"
							v-bind="attrs"
							v-on="on">
								<v-icon>mdi-delete</v-icon>
							</v-btn>
						</template>
						<span>Eliminar</span>
					</v-tooltip>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
	<!--Dialog de confirmacion eliminacion-->
	<app-dialog-confirm :dialog-confirm="dialogConfirmDelete"
	title="ELIMINAR"
	@onCancel="onCancelConfirmDelete"
	@onOk="onOkConfirmDelete">
	¿Seguro que desea eliminar el registro?
	</app-dialog-confirm>
</div>
</template>
<script>
import AppHeaderPage from '../components/AppHeaderPage';
import AppDialogConfirm from '../components/AppDialogConfirm';
import DistribuidorApi from '../services/api/distribuidor.api';
import AuthApi from '../services/api/auth.api';
import AuthTypes from '../store/types/AuthTypes';
import { Funcion } from '../utils/constants';

export default {
	components: {
		AppHeaderPage,
		AppDialogConfirm
	},
	created: function() {
		this.tipoUsuario = this.$store.getters[AuthTypes.nameModule + '/' + AuthTypes.getters.TIPO_USUARIO];
		this.partnerLogueado = this.$store.getters[AuthTypes.nameModule + '/' + AuthTypes.getters.PARTNER_USUARIO];
		this.distribuidorLogueado = this.$store.getters[AuthTypes.nameModule + '/' + AuthTypes.getters.DISTRIBUIDOR_USUARIO];
	},
  data: () => ({
		tipoUsuario: '',
		partnerLogueado: '',
		distribuidorLogueado: '',
		search: '',
		headers: [
			{ text: 'Id', value: 'id' },
			{ text: 'Nombre', value: 'nombre' },
			{ text: 'RFC', value: 'rfc' },
			{ text: 'CURP', value: 'curp' },
			{ text: 'Codigo Ref.', value: 'codigoReferencia' },
			{ text: 'Estatus', value: 'estatusDatoMaestro.descripcion' },
			{ text: 'Acciones', value: 'acciones'}
		],
		items: [],
		footerProps: {
			'items-per-page-text': 'Mostrar',
			'items-per-page-options': [10,15,20]
		},
		page: 1,
		totalPages: 0,
		options: {},
		totalItems: 0,
		pageSize: 10,
		isLoading: true,
		distribuidorCurrent: {},
		dialogConfirmDelete: false,
	}),
	computed: {
		titleItems: function() {
			//Si el usuario logueado es distribuidor/partner, solo se mostraran los distribuidores de ese usuario
			//Si no entonces es usuario administrivo y se muestran todos los distribuidores
			if(this.distribuidorLogueado !== '' || this.partnerLogueado !== '') {
				return ['Mi grupo de ventas', 'Listado'];
			} else {
				return ['Distribuidores', 'Listado'];
			}
		}
	},
	mounted: function() {
		this.onPaged();
	},
	watch: {
		options: {
			handler() {
				this.onPaged();
			}
		},
	},
	methods: {
		onPaged: function() {
			this.isLoading = true;
			//Obtenemos datos de footer para API
			const {page, itemsPerPage} = this.options;
			this.page = page;
			this.pageSize = itemsPerPage;
			DistribuidorApi.getPaged(this.partnerLogueado !== '', this.partnerLogueado, this.distribuidorLogueado !== '', this.distribuidorLogueado, this.page, this.pageSize, this.search)
			.then(response => {
				this.isLoading = false;
				if(response.success === true) {
					//Cargamos nuevos datos de paginado
					this.items = response.data.items;
					this.totalItems = parseInt(response.data.totalRecordCount);
					this.totalPages = response.data.totalPages;
				} else {
					this.$notifier.error(response.message)
				}
			});
		},
		onSearch: function() {
			//Inicializamos numero de pagina y paginamos
			this.options.page = 1;
			this.onPaged();
		},
		onOpenConfirmDelete: function(distribuidor) {
			let emailUsuario = this.$store.getters[AuthTypes.nameModule + '/' + AuthTypes.getters.EMAIL_USUARIO];
			AuthApi.validatePermission(emailUsuario, Funcion.DISTRIBUIDOR_ELIMINAR)
                    .then(response => {
                        if (response.success === true) {
                            //Salvamos distribuidor current(edit/delete)
							this.distribuidorCurrent = distribuidor;
							//Abrimos dialog
							this.dialogConfirmDelete = true;
                        } else {
                            this.$notifier.error(response.message)
                        }
                    });
		},
		onCancelConfirmDelete: function() {
			//Limpiamos distribuidor current(edit/delete)
			this.distribuidorCurrent = {};
			//Cerramos dialog
			this.dialogConfirmDelete = false;
		},
		onOkConfirmDelete: function(callBack) {
			DistribuidorApi.delete(this.distribuidorCurrent.id)
			.then(response => {
				callBack();
				//Limpiamos distribuidor current(edit/delete)
				this.distribuidorCurrent = {};
				//Cerramos dialog
				this.dialogConfirmDelete = false;
				//Procemos respuesta
				if(response.success === true) {
					//Inicializamos numero de pagina y paginamos
					this.options.page = 1;
					this.onPaged();
				} else {
					this.$notifier.error(response.message)
				}
			});
		},
	}
  }
</script>