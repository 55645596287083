<template>
<div >
	<app-header-page :titulos="titleItems"></app-header-page>
	<distribuidor-card :mode="mode" 
	@onSubmit="onSubmit">
	</distribuidor-card>
</div>
</template>
<script>
import AppHeaderPage from '../components/AppHeaderPage';
import DistribuidorCard from '../components/DistribuidorCard';
import DistribuidorApi from '../services/api/distribuidor.api';
import { ViewMode } from '../utils/constants';

export default {
	components: {
		AppHeaderPage,
		DistribuidorCard
	},
    data: () => ({
		titleItems: ['Distribuidor', 'Agregar'],
		mode: ViewMode.CREATE,
	}),
	methods: {
		onSubmit: function(distribuidorSaved, callBack) {
			DistribuidorApi.add(distribuidorSaved)
			.then(response => {
				callBack();
				if(response.success === true) {
					this.$router.push('/distribuidor');
					this.$notifier.success('Distribuidor agregado exitosamente.');
				} else {
					this.$notifier.error(response.message);
				}
			});
		}
	}
  }
</script>