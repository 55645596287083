export const ViewMode = {
    CREATE: 1,
    EDIT: 2,
    VIEW: 3
}

export const TipoUsuario = {
    ADMINISTRATIVO: 'ADMIN',
    PARTNER: 'PART',
    DISTRIBUIDOR: 'DIST'
}

export const DatoMaestro = {
    DISTRIBUIDOR: 'DIST',
    PARTNER: 'PART',
    USUARIO: 'USUA'
}

export const Documento = {
    EVIDENCIA_CARGA_SALDO: 'ECSALD',
    DOCUMENTO_AFECTACION_SALDO: 'DASALD',
    AUXILIAR_SALDO_DISTRIBUIDOR: 'ASDIST'
}

export const Funcion = {
    DISTRIBUIDOR_AGREGAR: 'DISTA',
    DISTRIBUIDOR_EDITAR: 'DISTE',
    DISTRIBUIDOR_ELIMINAR: 'DISTEL',
    DISTRIBUIDOR_LISTADO: 'DISTL',
    PARTNER_AGREGAR: 'PARTA',
    PARTNER_EDITAR: 'PARTE',
    PARTNER_ELIMINAR: 'PARTEL',
    PARTNER_LISTADO: 'PARTL',
    PARAMETROS_GENERALES: 'PGENE',
    ROL_AGREGAR: 'ROLA',
    ROL_EDITAR: 'ROLE',
    ROL_ELIMINAR: 'ROLEL',
    ROL_LISTADO: 'ROLL',
    USUARIO_AGREGAR: 'USUAA',
    USUARIO_EDITAR: 'USUAE',
    USUARIO_ELIMINAR: 'USUAEL',
    USUARIO_LISTADO: 'USUAL',
    PRODUCTO_AGREGAR: 'PRODA',
    PRODUCTO_EDITAR: 'PRODE',
    PRODUCTO_ELIMINAR: 'PRODEL',
    PRODUCTO_LISTADO: 'PRODL',
    LICENCIAS_OBSEQUIO_ASIGNAR: 'LOBSEA',
    LICENCIAS_OBSEQUIO_HISTORIAL: 'LOBSEH',
    EVIDENCIA_CARGA_SALDO_SUBIR: 'EVIDS',
    EVIDENCIA_CARGA_SALDO_VER: 'EVIDV',
    EVIDENCIA_CARGA_SALDO_LISTADO: 'EVIDL',
    EVIDENCIA_CARGA_SALDO_CANCELAR: 'EVIDC',
    EVIDENCIA_CARGA_SALDO_CONFIRMAR_RECHAZAR: 'EVIDCR',
    DOCUMENTO_AFECTACION_SALDO_REGISTRAR: 'DOCUR',
    DOCUMENTO_AFECTACION_SALDO_VER: 'DOCUV',
    DOCUMENTO_AFECTACION_SALDO_LISTADO: 'DOCUL',
    DOCUMENTO_AFECTACION_SALDO_CANCELAR: 'DOCUC',
    CARGA_SALDO_MANUAL_REGISTRAR: 'CARGR',
    LICENCIA_GENERAR: 'LICEG',
    LICENCIA_VER: 'LICEV',
    LICENCIA_LISTADO: 'LICEL',
    LICENCIA_PAGAR: 'LICEP',
    LICENCIA_RENOVAR: 'LICER',
}

export const PeriodosCredito = [{
    id: 4,
    text: '4 SEMANAS'
}, {
    id: 8,
    text: '8 SEMANAS'
}]

export const TipoArchivo = {
    PDF: 'PDF',
    EXCEL: 'EXCEL',
    WORD: 'WORD'
}

export const OpcionPago = {
    TARJETA: 1,
    PAYPAL: 2,
    OXXO: 3
}

export const Producto = {
    POSPOS: 'POSPOS',
}