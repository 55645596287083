<template>
	<v-snackbar v-model='snackbar.show' 
	:color='snackbar.type' 
	:timeout='snackbar.timeout' 
	vertical 
	bottom>
		<span v-html='snackbar.content'></span>
		<template v-slot:action="{ attrs }">
        <v-btn color="white" 
		text 
		v-bind="attrs"
		@click="onClose">Cerrar</v-btn>
      </template>
	</v-snackbar>
</template>

<script>
import NotifierTypes from '../store/types/NotifierTypes';
import { mapMutations } from 'vuex';

export default {
	computed: {
		snackbar() {
			return this.$store.state.NotifierModule.snackbar;
		}
	},
	methods: {
		...mapMutations({
			onClose: NotifierTypes.nameModule + '/' + NotifierTypes.mutations.CLOSE
		})
	}
}
</script>